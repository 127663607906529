import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../../../store/rootReducer"
import { useDispatch } from "react-redux"
import {
  destroyUnitLabelThunk,
  getCompanyUnitLabelsThunk,
  getFiduciaryUnitLabelsThunk,
  getUnitsThunk,
} from "../../../../store/ducks/unitLabel.ducks"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getIdFromParams } from "../../../../utils/company"
import { Text } from "../../../../components/Commons/Text"
import { Alert } from "../../../../components/Commons/Alert"
import { AddUnitLabelModal } from "../../../../components/fiduciary/AddUnitLabelModal"
import { UnitLabel, UnitLabelEvents } from "../../../../utils/unit"
import { UnitLabelEventsModal } from "../../../../components/fiduciary/UnitLabelEventsModal"
import { Table, TableBuilder } from "../../../../components/Commons/Table"
import { ReactComponent as Eye } from "../../../../assets/eyeFilled.svg"
import { ReactComponent as Trash } from "../../../../assets/drop-documents/TrashCan.svg"
import { TableWrapper } from "../../../../components/Commons/TableStylesForSelect"
import { ReactComponent as Calculator } from "../../../../assets/calculator.svg"

export const CompanyUnitLabels = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const fiduciary = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId || 0]
  )

  const [addUnitLabel, setAddUnitLabel] = useState<boolean>(false)
  const [displayEvents, setDisplayEvents] = useState<UnitLabelEvents[] | null>(
    null
  )
  const [backgroundColorsCompany, setBackgroundColorsCompany] = useState<
    Array<keyof typeof colors>
  >([])

  if (
    fiduciary &&
    fiduciary.comptaSoftware !== "" &&
    fiduciary.comptaSoftware !== "isacompta" &&
    company &&
    company.accounting_software !== "isacompta"
  ) {
    navigate("/Unauthorized")
  }

  useEffect(() => {
    if (fiduciary.id) {
      dispatch(getFiduciaryUnitLabelsThunk(fiduciary.id))
    }
  }, [fiduciary.id, dispatch])

  useEffect(() => {
    if (selectedCompanyId && selectedCompanyId !== 0) {
      dispatch(getCompanyUnitLabelsThunk(selectedCompanyId))
    }
  }, [selectedCompanyId, dispatch])

  const rowBackgroundColorsFiduciary = fiduciary.unit_labels?.map(
    (_, index) => {
      return index % 2 === 0 ? "white" : "clearBlue"
    }
  )

  useEffect(() => {
    if (company?.unit_labels && company?.unit_labels?.length > 0) {
      setBackgroundColorsCompany(
        company.unit_labels?.map((_, index) => {
          return index % 2 === 0 ? "white" : "clearBlue"
        })
      )
    }
  }, [company?.unit_labels])

  const columnsCompany: TableBuilder<UnitLabel>[] = [
    {
      headerText: "unit_labels.unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.unit}</>,
    },
    {
      headerText: "unit_labels.display-unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_unit}</>,
    },
    {
      headerText: "unit_labels.display-symbol",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_symbol}</>,
    },
    {
      headerText: "",
      width: "10%",
      flexGrow: "none",
      content: (row: UnitLabel) => {
        const index = company?.unit_labels?.indexOf(row)

        return (
          <Actions>
            <StyledEye
              onClick={() => {
                setDisplayEvents(row.events)
              }}
            />
            <Ct.Spacer />
            <div
              onClick={() => {
                dispatch(
                  destroyUnitLabelThunk(fiduciary.id, row.id, company.id)
                )
              }}
              onMouseEnter={() => {
                if (company?.unit_labels && company?.unit_labels?.length > 0) {
                  setBackgroundColorsCompany(
                    company?.unit_labels?.map((_, id) => {
                      return id === index
                        ? "deleteBackground"
                        : id % 2 === 0
                        ? "white"
                        : "clearBlue"
                    })
                  )
                }
              }}
              onMouseLeave={() => {
                if (company?.unit_labels && company?.unit_labels?.length > 0) {
                  setBackgroundColorsCompany(
                    company?.unit_labels?.map((_, id) => {
                      return id % 2 === 0 ? "white" : "clearBlue"
                    })
                  )
                }
              }}
            >
              <StyledTrash />
            </div>
          </Actions>
        )
      },
    },
  ]

  const columnsFiduciary: TableBuilder<UnitLabel>[] = [
    {
      headerText: "unit_labels.unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.unit}</>,
    },
    {
      headerText: "unit_labels.display-unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_unit}</>,
    },
    {
      headerText: "unit_labels.display-symbol",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_symbol}</>,
    },
    {
      headerText: "",
      width: "10%",
      flexGrow: "none",
      content: (row: UnitLabel) => (
        <Actions>
          <StyledEye
            onClick={() => {
              setDisplayEvents(row.events)
            }}
          />
        </Actions>
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        <ButtonRight>
          <Ct.Button
            label={intl.formatMessage({
              id: "unit_labels.add-button",
            })}
            onClick={() => {
              dispatch(getUnitsThunk(fiduciary.id))
              setAddUnitLabel(true)
            }}
            width={35}
            prefix={<StyledCalculator />}
          />
        </ButtonRight>

        <StyledContent>
          {displayEvents && (
            <UnitLabelEventsModal
              isDisplayed={!!displayEvents}
              onClose={() => {
                setDisplayEvents(null)
              }}
              events={displayEvents}
            />
          )}
          {addUnitLabel && company.unit_labels && (
            <AddUnitLabelModal
              isDisplayed={!!addUnitLabel}
              onClose={() => {
                setAddUnitLabel(false)
              }}
              unit_labels={company.unit_labels}
              companyId={company.id}
            />
          )}

          <Ct.Spacer height={2} />

          <StyledText
            text={intl.formatMessage({
              id: "company.unit_labels.title",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
            }}
          />

          <Ct.Spacer height={2} />

          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "company.unit_labels.alert",
              })}
            />
          </Alert>

          <Ct.Spacer height={2} />

          {company &&
          company?.unit_labels &&
          company?.unit_labels?.length > 0 ? (
            <TableWrapper height={"initial"}>
              <Table
                intl={intl}
                columns={columnsCompany}
                rows={company.unit_labels}
                alignItems={"center"}
                width={"100%"}
                height={"30rem"}
                padding={"0"}
                paddingRows={"1rem 2rem 1rem 4rem"}
                fontWeightTitle={600}
                alertMessage={"unit_labels.empty"}
                sortableColumnsLength={0}
                customScrollBar={true}
                rowBackgroundColors={backgroundColorsCompany}
              />
            </TableWrapper>
          ) : (
            <CenteredDiv>
              <Alert alertType={"info"}>
                <Text
                  text={intl.formatMessage({
                    id: "fiduciary.unit_labels.empty",
                  })}
                />
              </Alert>
            </CenteredDiv>
          )}

          <Ct.Spacer height={4} />

          <StyledText
            text={intl.formatMessage({
              id: "company.unit_labels.title.fiduciary",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
            }}
          />

          <Ct.Spacer height={2} />

          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "company.unit_labels.alert.fiduciary",
              })}
            />
          </Alert>

          <Ct.Spacer height={2} />

          {fiduciary.unit_labels.length === 0 ? (
            <CenteredDiv>
              <Alert alertType={"info"}>
                <Text
                  text={intl.formatMessage({
                    id: "fiduciary.unit_labels.empty",
                  })}
                />
              </Alert>
            </CenteredDiv>
          ) : (
            <TableWrapper height={"initial"}>
              <Table
                intl={intl}
                columns={columnsFiduciary}
                rows={fiduciary.unit_labels}
                alignItems={"center"}
                width={"100%"}
                height={"30rem"}
                padding={"0"}
                paddingRows={"1rem 2rem 1rem 4rem"}
                fontWeightTitle={600}
                alertMessage={"unit_labels.empty"}
                sortableColumnsLength={0}
                customScrollBar={true}
                rowBackgroundColors={rowBackgroundColorsFiduciary}
              />
            </TableWrapper>
          )}

          <Ct.Spacer height={4} />
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 5rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  flex: 1;
`
const StyledContent = styled.div`
  width: 80%;
`
const ButtonRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
`
const StyledText = styled((props) => <Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: pointer;
`
const Actions = styled.div`
  display: flex;
  justify-content: end;
`
const StyledTrash = styled(({ ...props }) => <Trash {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.amaranth};
    }
  }
  cursor: pointer;
`
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const StyledCalculator = styled(({ ...props }) => <Calculator {...props} />)`
  & path {
    fill: ${colors.white};
  }
`
