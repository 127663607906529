import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useKeyDown } from "../../hooks/useKeyDown"
import { regenerateBankinArchiveThunk } from "../../store/ducks/bankArchives.ducks"

interface RegenerateBankinArchiveModalProps extends Ct.ModalComponentsProps {
  bankinArchiveId: number
}

export const RegenerateBankinArchiveModal = ({
  isDisplayed,
  onClose,
  bankinArchiveId,
}: RegenerateBankinArchiveModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleDownload = () => {
    dispatch(
      regenerateBankinArchiveThunk({
        bankinArchiveId,
      })
    )
    onClose()
  }

  useKeyDown(() => {
    if (isDisplayed) handleDownload()
  }, ["Enter"])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"96rem"}>
        <Ct.Title
          text={intl.formatMessage(
            { id: "bank-management.archives.regenerate-archive-modal.title" },
            { bankinArchiveId: bankinArchiveId }
          )}
          size={3}
        />
        <Ct.Spacer height={3} />
        <Ct.Bar width={100} />
        <Ct.Spacer height={4} />

        <Ct.Text
          text={intl.formatMessage({
            id: "bank-management.archives.regenerate-archive-modal.content",
          })}
          textStyle={{ lineHeight: 3, textAlign: "center", fontSize: 2 }}
        />

        <Ct.Spacer height={4} />

        <Ct.FlexShrinkZero>
          <Ct.Button
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "bank-management.archives.regenerate-archive-modal.cancel",
            })}
            onClick={onClose}
          />
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: "bank-management.archives.regenerate-archive-modal.confirm",
            })}
            onClick={() => handleDownload()}
          />
        </Ct.FlexShrinkZero>
      </Ct.Card>
    </Ct.Modal>
  )
}
