import * as Ct from "ldlj"
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { sizes } from "../../styles/design.config"
import {
  getVatAccountsHistoryThunk,
  goodsOrServices,
} from "../../store/ducks/companySettings.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { buyOrSell, getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { sortAccountInstructionsByDate } from "../../utils/accounts"

interface VatAccountHistoryModalProps extends Ct.ModalComponentsProps {
  buyOrSell: buyOrSell | null
  vatType: "unique" | "per_rate" | null
  rate: string | null
  goodsOrServices: goodsOrServices | null
}

export const VatAccountHistoryModal = ({
  isDisplayed,
  onClose,
  buyOrSell,
  vatType,
  rate,
  goodsOrServices,
}: VatAccountHistoryModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const changesHistory = useRNBSelector(
    (state) => state.companySettings.userAccountInstructions
  )

  useEffect(() => {
    if (!selectedCompanyId || !buyOrSell || !vatType || !goodsOrServices) return
    dispatch(
      getVatAccountsHistoryThunk(
        selectedCompanyId,
        buyOrSell,
        vatType,
        rate,
        goodsOrServices
      )
    )
  }, [selectedCompanyId, buyOrSell, vatType, rate, goodsOrServices])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(30vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Title
          text={intl.formatMessage({
            id: "office-company-settings.vat-accounts.history-modal.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            {buyOrSell && vatType && goodsOrServices && (
              <Ct.Text
                text={intl.formatMessage(
                  {
                    id: `office-company-settings.vat-accounts.history-modal.subtitle`,
                  },
                  {
                    buyOrSell: buyOrSell === "buy" ? "Achat" : "Vente",
                    goodsOrServices:
                      goodsOrServices === "goods" ? "biens" : "services",
                    rate: rate ? `${rate} %` : "",
                  }
                )}
                textStyle={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              />
            )}
          </ChangeHeader>

          {changesHistory && changesHistory.length > 0 ? (
            <ChangesList>
              {sortAccountInstructionsByDate(changesHistory).map(
                (instruction, index) => (
                  <ChangeItem key={index}>
                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: `office-company-settings.vat-accounts.history-modal.${instruction.instruction_type}.entry`,
                        },
                        { account: instruction.account_number }
                      )}
                    />
                    <> par </>
                    <Mailto href={`mailto:${instruction.user}`}>
                      {instruction.user}
                    </Mailto>
                    <Ct.Text text={` (${instruction.created_at})`} />
                  </ChangeItem>
                )
              )}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `office-company-settings.vat-accounts.history-modal.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: "office-company-settings.vat-accounts-history.ok",
          })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
