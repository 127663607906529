import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import {
  displayKeyWordEvent,
  UserAccountingKeyWordEvent,
} from "../../utils/accountingKeyWords"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { sizes } from "../../styles/design.config"
import { DateTime } from "luxon"
/* eslint-disable camelcase */

interface AccountingKeyWordEventsProps extends Ct.ModalComponentsProps {
  events: UserAccountingKeyWordEvent[]
}

export const AccountingKeyWordEventsModal = ({
  isDisplayed,
  onClose,
  events,
}: AccountingKeyWordEventsProps) => {
  const intl = useIntl()

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top={`calc(30vh - 15rem)`}
    >
      <StyledCard width={"140rem"} height={`50vh`}>
        <Ct.CloseCross onClick={onClose} />

        <Header>
          <Ct.Text
            text={intl.formatMessage({
              id: "accounting-plan.key-words.table-header.events.title",
            })}
            textStyle={{
              fontSize: 5,
              color: "navy",
              fontWeight: 700,
            }}
          />
          <Ct.Spacer height={4} />
          <Ct.Separator size="full" />
          <Ct.Spacer height={4} />
        </Header>

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={intl.formatMessage({
                id: `accounting-plan.key-words.table-header.events.key-word`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
          </ChangeHeader>

          {events && events.length > 0 ? (
            <ChangesList>
              {events.map((instruction) => (
                <ChangeItem key={instruction.created_at}>
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: `accounting-plan.key-words.table-header.events.${instruction.event_type}`,
                      },
                      { key_word: instruction.key_word }
                    )}
                  />
                  <Mailto href={`mailto:${instruction.email}`}>
                    {instruction.email}
                  </Mailto>
                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(
                        new Date(instruction.created_at)
                      ).toFormat("dd/MM/yyyy HH:mm:ss") +
                      ")"
                    }
                  />
                  {instruction.event_type === "edit" && (
                    <Ct.Text
                      text={displayKeyWordEvent(instruction.metadata)}
                      textStyle={{
                        fontWeight: 700,
                      }}
                    />
                  )}
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `office-company-settings.autoliquidated-history.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: "office-company-settings.autoliquidated-history.ok",
          })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: space-around;
`
const Header = styled.div`
  width: 100%;
  text-align: center;
`
