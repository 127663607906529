import React, { SyntheticEvent, useRef, useState } from "react"
import styled from "styled-components/macro"
import * as Ct from "ldlj"

import { Suspense } from "react"
import { ReactComponent as CalendarCornflower } from "../../assets/calendarSearchCornflower.svg"
import { ReactComponent as Chevron } from "../../assets/chevron-down-bigger.svg"
import { ReactComponent as Cross } from "../../assets/circled-cross.svg"
import {
  locale,
  StyledCalendarGlobal,
  InputDateProps,
  formatDateFRdate,
} from "../../utils/date"
import { boxShadow, colors } from "../../styles/design.config"
import { DateRange } from "rsuite/DateRangePicker"
import { DateTime } from "luxon"

const DateRangePicker = React.lazy(() => import("rsuite/DateRangePicker"))
const CustomProvider = React.lazy(() => import("rsuite/CustomProvider"))

export const InputDateRange = (props: InputDateProps) => {
  const {
    value,
    onChange,
    onClose = () => {},
    minimum,
    maximum,
    placement = "bottomStart",
    editable = true,
    cleanable = false,
    closable = false,
    predefinedRanges = [],
    placeholder = "Sélectionner une période",
  } = props

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateRangePickerRef = useRef<any>(null)

  const [tmpValue, setTmpValue] = useState<DateRange | null>([
    new Date(),
    new Date(),
  ])
  const [invalidDate, setInvalidDate] = useState(false)
  const [calendarToogle, setCalendarToogle] = useState<boolean>(false)

  const disabledDate = (date: Date): boolean => {
    if (minimum && maximum) {
      const startDate = new Date(minimum)
      const endDate = new Date(maximum)
      return date < startDate || date > endDate
    }
    return false
  }

  const getLabel = (date: [Date, Date]): string => {
    const pr = predefinedRanges.find((pr) => pr.value === date)
    if (pr) return `${pr.label}`
    else {
      if (date === null) return placeholder
      else return `${formatDateFRdate(date[0])} - ${formatDateFRdate(date[1])}`
    }
  }

  const handleChange = (
    value: DateRange | null,
    event: SyntheticEvent<Element, Event>
  ) => {
    if (cleanable && value === null) onChange(value, event)
    else if (
      value &&
      DateTime.fromJSDate(value[0]).isValid &&
      DateTime.fromJSDate(value[1]).isValid
    ) {
      if (invalidDate) setInvalidDate(false)
      onChange(value, event)
    } else {
      setInvalidDate(true)
      setTmpValue(value)
    }
  }

  const handleIconClick = () => {
    if (calendarToogle) {
      setCalendarToogle(false)
      dateRangePickerRef.current.close()
    } else {
      setCalendarToogle(true)
      dateRangePickerRef.current.open()
    }
  }

  return (
    <StyledDiv>
      <StyledCalendarGlobal />
      <Suspense fallback={<Ct.SpinningLoader />}>
        <CustomProvider locale={locale}>
          <DateRangePicker
            ref={dateRangePickerRef}
            value={invalidDate ? tmpValue : value}
            editable={editable}
            renderValue={getLabel}
            placeholder={placeholder}
            onChange={handleChange}
            shouldDisableDate={disabledDate}
            ranges={predefinedRanges}
            appearance="subtle"
            format={"dd/MM/yyyy"}
            character={" - "}
            showHeader={false}
            isoWeek={true}
            cleanable={cleanable}
            placement={placement}
            onEntered={() => setCalendarToogle(true)}
            onExited={() => setCalendarToogle(false)}
            caretAs={StyledCalendar}
          />
        </CustomProvider>
      </Suspense>
      <Ct.Spacer width={1} />
      {closable && <StyledCross onClick={() => onClose()} />}
      {!closable && (
        <StyledChevronSquare
          onClick={handleIconClick}
          rotation={calendarToogle ? 1 : 0}
        />
      )}
      <Ct.Spacer width={1} />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  font-family: "Poppins", sans-serif;
  padding: 0 1rem 0 0;
  background-color: ${colors.white};
  font-weight: 600;
  font-size: 3rem;
  box-shadow: ${boxShadow};
`
const StyledChevronSquare = styled(Chevron)<{ rotation: number }>`
  width: 4rem;
  height: 3rem;
  transition-duration: 0.2s;
  transform: ${({ rotation }) => (rotation ? "rotate(180deg)" : ``)};
  fill: ${colors.cornflower};
`
const StyledCalendar = styled(CalendarCornflower)`
  width: 4rem;
  height: 3rem;
`
const StyledCross = styled(Cross)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`
