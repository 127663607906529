/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../store/rootReducer"
import { ReactComponent as Edit } from "../../assets/edit.svg"
import { ReactComponent as SecurityIllustration } from "../../assets/security-illustration.svg"

import { Text } from "../../components/Commons/Text"
import { Switch } from "../../components/Commons/Switch"

import { LockableInput } from "../../components/Commons/LockableInput"
import { forgottenPasswordThunk } from "../../store/ducks/login.ducks"
import { ConfigureOTP_Modal } from "./ConfigureOTP_Modal"
import { useState } from "react"

export const Security = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [displayConfigurationModal, setDisplayConfigurationModal] =
    useState(false)

  const { firstName, lastName, email, otpRequiredForLogin, forgottenPassword } =
    useRNBSelector((state) => ({
      firstName: state.user.firstName,
      lastName: state.user.lastName,
      email: state.user.email,
      otpRequiredForLogin: state.user.otp_required_for_login,
      forgottenPassword: state.login.forgottenPassword,
    }))

  return (
    <>
      <ConfigureOTP_Modal
        isDisplayed={displayConfigurationModal}
        onClose={() => {
          setDisplayConfigurationModal(false)
        }}
        action={otpRequiredForLogin ? "deactivate_OTP" : "activate_OTP"}
      />
      <StyledColumn>
        <Content>
          <ContentSizer>
            <Text
              text={intl.formatMessage({ id: "security.personal-info.title" })}
              textStyle={{
                fontFamily: "Poppins",
                fontSize: 2,
                fontWeight: 600,
              }}
            />
            <Ct.Spacer />
            <SpaceBetween>
              <LockableInput
                value={lastName}
                label={intl.formatMessage({
                  id: "security.personal-info.lastname",
                })}
                locked={true}
              />
              <Ct.Spacer />
              <LockableInput
                value={firstName}
                label={intl.formatMessage({
                  id: "security.personal-info.firstname",
                })}
                locked={true}
              />
            </SpaceBetween>
            <Ct.Spacer height={4} />
            <SpaceBetween>
              <Text
                text={intl.formatMessage({ id: "security.password.title" })}
                textStyle={{
                  fontFamily: "Poppins",
                  fontSize: 2,
                  fontWeight: 600,
                }}
              />

              <Align
                onClick={() => {
                  if (forgottenPassword === "UNFORGOTTEN") {
                    dispatch(forgottenPasswordThunk(email))
                  }
                }}
              >
                {forgottenPassword === "UNFORGOTTEN" && <Edit />}
                <Ct.Spacer width={0.5} />
                <Text
                  text={intl.formatMessage({
                    id:
                      forgottenPassword === "UNFORGOTTEN"
                        ? "login.password.edit"
                        : "login.password.edit-disabled",
                  })}
                />
              </Align>
            </SpaceBetween>
            <Ct.Spacer />
            <Text
              text={intl.formatMessage(
                { id: "security.password.description" },
                {
                  email,
                }
              )}
            />
            <Ct.Spacer height={4} />
            <Subcontent>
              <SpaceBetween>
                <Text
                  text={intl.formatMessage({ id: "security.2FA.title" })}
                  textStyle={{
                    fontFamily: "Poppins",
                    fontSize: 2,
                    fontWeight: 600,
                  }}
                />
                <Align>
                  <Switch
                    value={otpRequiredForLogin}
                    onToggle={() => {
                      setDisplayConfigurationModal(true)
                    }}
                    locked={false}
                  />
                  <Ct.Spacer width={1} />
                  <TextSpacer>
                    <Text
                      text={intl.formatMessage({
                        id: otpRequiredForLogin
                          ? "security.2FA.activated"
                          : "security.2FA.deactivated",
                      })}
                    />
                  </TextSpacer>
                </Align>
              </SpaceBetween>
              <Ct.Spacer />
              <Text
                text={intl.formatMessage({
                  id: "security.2FA.description",
                })}
              />

              <Ct.Spacer />
              <Text
                text={intl.formatMessage(
                  { id: "security.2FA.email-activated" },
                  {
                    email,
                  }
                )}
              />
            </Subcontent>

            <StyledSecurityIllustration width={340} />
          </ContentSizer>
        </Content>
      </StyledColumn>
    </>
  )
}

const StyledColumn = styled((props) => <Ct.Column {...props} />)`
  height: 100%;
  align-items: flex-end;
  overflow: hidden;
  padding: 4rem;
`

const Content = styled((props) => <Ct.Card {...props} />)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`

const Subcontent = styled((props) => <Ct.Card {...props} />)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
`

const ContentSizer = styled.div`
  max-width: 120rem;
  align-self: center;
  display: flex;
  flex-direction: column;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
const Align = styled.div`
  display: flex;
  align-items: center;
`
const TextSpacer = styled.div`
  width: 8.5rem;
`
const StyledSecurityIllustration = styled(SecurityIllustration)`
  align-self: center;
`
