import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { getIdFromParams } from "../../utils/company"
import {
  Account,
  AccountsVatRates,
  AccountVatRatesKey,
  activateOrDeactivateAutoliquidatedVatThunk,
  createAccountResetAction,
  getAutoliquidatedVatAccountSettingsThunk,
  getAutoliquidatedVatRatesThunk,
  getCompanyAutoliquidatedVatAccountsHistoryResetAction,
  VatType,
  vatTypeArray,
} from "../../store/ducks/companySettings.ducks"
import { emptyOptionValue } from "../Commons/Select"
import { useRNBSelector } from "../../store/rootReducer"
import {
  CreateAutoliquidatedVatModal,
  AutoliquidatedVatAccountPayload,
} from "./CreateAutoliquidatedVatModal"
import { AutoliquidatedHistoryModal } from "./AutoliquidatedHistoryModal"
import { CompanyStub } from "../../store/ducks/companies.ducks"
import ReactTooltip from "react-tooltip"
import { Strip } from "../Strip"
import { AccountStripSelectCreateHistory } from "../AccountStripSelectCreateHistory"

/* eslint-disable camelcase */
export interface AutoliquidatedVatAccountsState {
  constructionVatAccount: Account | null
  constructionReverseVatAccount: Account | null
  euVatAccount: Account | null
  euReverseVatAccount: Account | null
  euGoodsVatAccount: Account | null
  euGoodsReverseVatAccount: Account | null
  worldVatAccount: Account | null
  worldReverseVatAccount: Account | null
  worldGoodsVatAccount: Account | null
  worldGoodsReverseVatAccount: Account | null
}

interface AutoliquidatedVatAccountProps {
  onAccountsUpdated: ({
    disabled,
    autoliquidatedVatAccounts,
    autoliquidatedVatRates,
  }: {
    disabled: boolean
    autoliquidatedVatAccounts: AutoliquidatedVatAccountsState
    autoliquidatedVatRates: AccountsVatRates
  }) => void
}

export const AutoliquidatedVatAccounts = ({
  onAccountsUpdated,
}: AutoliquidatedVatAccountProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    createdAutoliquidatedVatAccount,
    selectedAccounts,
    construction_vat,
    construction_reverse_vat,
    eu_vat,
    eu_reverse_vat,
    eu_goods_vat,
    eu_goods_reverse_vat,
    world_vat,
    world_reverse_vat,
    world_goods_vat,
    world_goods_reverse_vat,
  } = useRNBSelector((state) => state.companySettings)
  const companySettings = useRNBSelector((state) => state.companySettings)

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || CompanyStub
  )
  const companyNotOnboarded =
    company.buy_third_party_account_id === null &&
    company.sell_third_party_account_id === null &&
    company.auxiliary === null

  useEffect(() => {
    dispatch(
      getAutoliquidatedVatAccountSettingsThunk({ companyId: selectedCompanyId })
    )
    dispatch(getAutoliquidatedVatRatesThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (createdAutoliquidatedVatAccount) {
      const vatType = createdAutoliquidatedVatAccount.vatType
      const newAutoliquidatedVatAccounts = {
        ...accounts,
        [`${vatType}Account`]: createdAutoliquidatedVatAccount,
      }
      setAccounts(newAutoliquidatedVatAccounts)
      dispatch(createAccountResetAction())
    }
  }, [createdAutoliquidatedVatAccount])

  const [accounts, setAccounts] = useState<AutoliquidatedVatAccountsState>({
    constructionVatAccount: null,
    constructionReverseVatAccount: null,
    euVatAccount: null,
    euReverseVatAccount: null,
    euGoodsVatAccount: null,
    euGoodsReverseVatAccount: null,
    worldVatAccount: null,
    worldReverseVatAccount: null,
    worldGoodsVatAccount: null,
    worldGoodsReverseVatAccount: null,
  })

  const [vatRates, setVatRates] = useState<AccountsVatRates>({
    selected_eu_vat_rate: null,
    selected_eu_goods_vat_rate: null,
    selected_construction_vat_rate: null,
    selected_world_vat_rate: null,
    selected_world_goods_vat_rate: null,
  })

  const [displayVatRateHistory, setDisplayVatRateHistory] = useState(false)

  const vatTypeMapping: Record<VatType, keyof typeof accounts> = {
    eu_vat: "euVatAccount",
    eu_reverse_vat: "euReverseVatAccount",
    construction_vat: "constructionVatAccount",
    construction_reverse_vat: "constructionReverseVatAccount",
    eu_goods_vat: "euGoodsVatAccount",
    eu_goods_reverse_vat: "euGoodsReverseVatAccount",
    world_vat: "worldVatAccount",
    world_reverse_vat: "worldReverseVatAccount",
    world_goods_vat: "worldGoodsVatAccount",
    world_goods_reverse_vat: "worldGoodsReverseVatAccount",
  }

  const vatRateMapping: Record<VatType, AccountVatRatesKey> = {
    eu_vat: "selected_eu_vat_rate",
    eu_reverse_vat: "selected_eu_vat_rate",
    construction_vat: "selected_construction_vat_rate",
    construction_reverse_vat: "selected_construction_vat_rate",
    eu_goods_vat: "selected_eu_goods_vat_rate",
    eu_goods_reverse_vat: "selected_eu_goods_vat_rate",
    world_vat: "selected_world_vat_rate",
    world_reverse_vat: "selected_world_vat_rate",
    world_goods_vat: "selected_world_goods_vat_rate",
    world_goods_reverse_vat: "selected_world_goods_vat_rate",
  }

  const [initialAccounts, setInitialAccounts] =
    useState<AutoliquidatedVatAccountsState>({
      constructionVatAccount: null,
      constructionReverseVatAccount: null,
      euVatAccount: null,
      euReverseVatAccount: null,
      euGoodsVatAccount: null,
      euGoodsReverseVatAccount: null,
      worldVatAccount: null,
      worldReverseVatAccount: null,
      worldGoodsVatAccount: null,
      worldGoodsReverseVatAccount: null,
    })

  const [initialVatRates, setInitialVatRates] = useState<AccountsVatRates>({
    selected_eu_vat_rate: null,
    selected_eu_goods_vat_rate: null,
    selected_construction_vat_rate: null,
    selected_world_vat_rate: null,
    selected_world_goods_vat_rate: null,
  })
  const accountsOptions = (vatType: VatType) => {
    return companySettings[vatType].possible_accounts
      .map((account) => ({
        value: account.id.toString(),
        label: `${account.number} ${account.details}`,
      }))
      .filter(
        (option) =>
          !selectedAccounts.some(
            (selectedAccount) =>
              selectedAccount.accountId === Number(option.value)
          )
      )
  }

  const vatRatesOptions = () => {
    return companySettings.rates.map((rate) => ({
      value: rate.id.toString(),
      label: `${rate.rate}`,
    }))
  }

  const findSelectedOptionValue = (vatType: VatType) => {
    const selectedAccount = accounts[vatTypeMapping[vatType]]

    return selectedAccount
      ? {
          value: selectedAccount.id.toString(),
          label: `${selectedAccount.number} ${selectedAccount.details}`,
        }
      : emptyOptionValue
  }

  const isAutoliquidatedVatDisalbed = (vatType: VatType) => {
    return companySettings[vatType].deactivated_at !== null
  }

  useEffect(() => {
    const accounts = {
      constructionVatAccount: construction_vat.selected_account,
      constructionReverseVatAccount: construction_reverse_vat.selected_account,
      euVatAccount: eu_vat.selected_account,
      euReverseVatAccount: eu_reverse_vat.selected_account,
      euGoodsVatAccount: eu_goods_vat.selected_account,
      euGoodsReverseVatAccount: eu_goods_reverse_vat.selected_account,
      worldVatAccount: world_vat.selected_account,
      worldReverseVatAccount: world_reverse_vat.selected_account,
      worldGoodsVatAccount: world_goods_vat.selected_account,
      worldGoodsReverseVatAccount: world_goods_reverse_vat.selected_account,
    }
    const vatRates = {
      selected_eu_vat_rate:
        companySettings.autoliquidated_accounts_vat_rates.selected_eu_vat_rate,
      selected_eu_goods_vat_rate:
        companySettings.autoliquidated_accounts_vat_rates
          .selected_eu_goods_vat_rate,
      selected_construction_vat_rate:
        companySettings.autoliquidated_accounts_vat_rates
          .selected_construction_vat_rate,
      selected_world_vat_rate:
        companySettings.autoliquidated_accounts_vat_rates
          .selected_world_vat_rate,
      selected_world_goods_vat_rate:
        companySettings.autoliquidated_accounts_vat_rates
          .selected_world_goods_vat_rate,
    }
    setAccounts(accounts)
    setVatRates(vatRates)
    setInitialAccounts(accounts)
    setInitialVatRates(vatRates)
  }, [
    construction_vat.selected_account,
    construction_reverse_vat.selected_account,
    eu_vat.selected_account,
    eu_reverse_vat.selected_account,
    eu_goods_vat.selected_account,
    eu_goods_reverse_vat.selected_account,
    world_vat.selected_account,
    world_reverse_vat.selected_account,
    world_goods_vat.selected_account,
    world_goods_reverse_vat.selected_account,
    companySettings.autoliquidated_accounts_vat_rates,
  ])

  const findSelectedVatRateValue = (vatType: VatType) => {
    const selectedVatRate = vatRates[vatRateMapping[vatType]]

    return selectedVatRate
      ? {
          value: selectedVatRate.id.toString(),
          label: `${selectedVatRate.rate}`,
        }
      : emptyOptionValue
  }

  const [isModalDisplayed, setIsModalDisplayed] = useState(false)
  const [
    autoliquidatedVatAccountToCreate,
    setAutoliquidatedVatAccountToCreate,
  ] = useState<AutoliquidatedVatAccountPayload | null>(null)
  const [historyDisplay, setHistoryDisplay] = useState<{
    isDisplayed: boolean
    vatType: VatType | null
  }>({
    isDisplayed: false,
    vatType: null,
  })

  const filteredVatTypes = vatTypeArray.filter(
    (item) => !item.includes("reverse")
  )

  const allAutoliquidatedFieldsFilled = () => {
    if (
      Object.values(accounts).every((entry) => entry !== null) &&
      Object.values(vatRates).every((entry) => entry !== null)
    )
      return true

    const isDisabled = filteredVatTypes.every((vat) => {
      return (
        companySettings[vat].deactivated_at ||
        (accounts[vatTypeMapping[vat]] &&
          accounts[
            vatTypeMapping[vat.replace("vat", "reverse_vat") as VatType]
          ] &&
          vatRates[vatRateMapping[vat]])
      )
    })
    return isDisabled
  }

  useEffect(() => {
    const accountsChanged =
      JSON.stringify(accounts) !== JSON.stringify(initialAccounts)
    const vatRatesChanged =
      JSON.stringify(vatRates) !== JSON.stringify(initialVatRates)

    const disabled =
      (!accountsChanged && !vatRatesChanged) || !allAutoliquidatedFieldsFilled()

    onAccountsUpdated({
      autoliquidatedVatAccounts: accounts,
      autoliquidatedVatRates: vatRates,
      disabled,
    })
  }, [accounts, vatRates])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  useEffect(() => {
    filteredVatTypes.forEach((e) => {
      if (companySettings[e].deactivated_at === null) {
        setToggles((prevToggles) =>
          prevToggles.map((item) =>
            item.index_name === e ? { ...item, toggle: false } : item
          )
        )
      }
    })
  }, [dispatch, companySettings])

  const [toggles, setToggles] = useState<
    { index_name: string; toggle: boolean }[]
  >([
    { index_name: "eu_vat", toggle: false },
    { index_name: "construction_vat", toggle: false },
    { index_name: "eu_goods_vat", toggle: false },
    { index_name: "world_vat", toggle: false },
    { index_name: "world_goods_vat", toggle: false },
  ])

  const handleToggle = (index: string) => {
    setToggles((prevToggles) =>
      prevToggles.map((item) =>
        item.index_name === index ? { ...item, toggle: !item.toggle } : item
      )
    )
  }

  return (
    <>
      <AutoliquidatedHistoryModal
        isDisplayed={historyDisplay.isDisplayed}
        onClose={() => {
          setHistoryDisplay({
            isDisplayed: false,
            vatType: null,
          })
          setDisplayVatRateHistory(false)
          dispatch(
            getCompanyAutoliquidatedVatAccountsHistoryResetAction(
              historyDisplay.vatType
            )
          )
        }}
        vatType={historyDisplay.vatType}
        rate={displayVatRateHistory}
      />
      <CreateAutoliquidatedVatModal
        isDisplayed={isModalDisplayed}
        onClose={() => {
          setIsModalDisplayed(false)
          setAutoliquidatedVatAccountToCreate(null)
        }}
        autoliquidatedVatAccountToCreate={
          autoliquidatedVatAccountToCreate || {
            vatType: null,
            prefix: [],
            forbid: [],
          }
        }
      />
      <Container>
        <Ct.Text
          text={intl.formatMessage({
            id: "office-company-settings.auto-vat-accounts-info",
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer height={2} />
        <>
          {filteredVatTypes.map((vatType) => {
            const reverseVatType = vatType.replace(
              "_vat",
              "_reverse_vat"
            ) as VatType
            return (
              <>
                <StyledCard>
                  <Strip
                    label={intl.formatMessage({
                      id: `office-company-settings.auto-vat-accounts-title.${vatType}`,
                    })}
                    itemType={{
                      type: "switch",
                      id: "ok",
                      value: !isAutoliquidatedVatDisalbed(vatType),
                      onToggleAction: () => {
                        setToggles((prevToggles) =>
                          prevToggles.map((item) =>
                            item.index_name === vatType
                              ? { ...item, toggle: false }
                              : item
                          )
                        )
                        dispatch(
                          activateOrDeactivateAutoliquidatedVatThunk(
                            selectedCompanyId,
                            vatType,
                            isAutoliquidatedVatDisalbed(vatType)
                              ? "activate"
                              : "deactivate"
                          )
                        )
                      },
                    }}
                    onSeeMoreAction={() =>
                      handleToggle(vatType.replace("_reverse", ""))
                    }
                    disabled={
                      companyNotOnboarded ||
                      accountsOptions(vatType)?.length === 0 ||
                      isAutoliquidatedVatDisalbed(vatType)
                    }
                    rotation={
                      toggles.find((e) => e.index_name === `${vatType}`)!.toggle
                    }
                    dataTip={intl.formatMessage({
                      id: `office-company-settings.auto-vat-accounts-tooltip.${
                        isAutoliquidatedVatDisalbed(vatType)
                          ? "activate"
                          : "deactivate"
                      }.${vatType}`,
                    })}
                  />
                  {toggles.find((e) => e.index_name === `${vatType}`)!
                    .toggle && (
                    <>
                      <AccountStripSelectCreateHistory
                        label={intl.formatMessage({
                          id: `office-company-settings.eu-construction-vat-accounts.${vatType}`,
                        })}
                        value={findSelectedOptionValue(vatType)}
                        options={accountsOptions(vatType) || []}
                        onChangeCallback={(selectedAccount) => {
                          const splittedLabel = selectedAccount.label.split(" ")
                          const number = splittedLabel[0]
                          const details = splittedLabel.slice(1).join(" ")
                          const account = {
                            id: Number(selectedAccount.value),
                            number: number,
                            details: details,
                          }

                          const accountToSet = vatTypeArray.includes(vatType)
                            ? account
                            : accounts[vatTypeMapping[vatType]]

                          setAccounts({
                            ...accounts,
                            [vatTypeMapping[vatType]]: accountToSet,
                          })
                        }}
                        error={false}
                        displayCreate={true}
                        displayHistory={true}
                        onClickCreate={() => {
                          setAutoliquidatedVatAccountToCreate({
                            vatType: vatType,
                            prefix: companySettings[vatType].prefix,
                            forbid: companySettings[vatType].forbid,
                          })
                          setIsModalDisplayed(true)
                        }}
                        onClickHistory={() => {
                          setHistoryDisplay({
                            isDisplayed: true,
                            vatType: vatType,
                          })
                          setDisplayVatRateHistory(false)
                        }}
                        disabled={
                          companyNotOnboarded ||
                          accountsOptions(vatType)?.length === 0 ||
                          isAutoliquidatedVatDisalbed(vatType)
                        }
                      />

                      <AccountStripSelectCreateHistory
                        label={intl.formatMessage({
                          id: `office-company-settings.eu-construction-vat-accounts.${reverseVatType}`,
                        })}
                        value={findSelectedOptionValue(reverseVatType)}
                        options={accountsOptions(reverseVatType) || []}
                        onChangeCallback={(selectedAccount) => {
                          const splittedLabel = selectedAccount.label.split(" ")
                          const number = splittedLabel[0]
                          const details = splittedLabel.slice(1).join(" ")
                          const account = {
                            id: Number(selectedAccount.value),
                            number: number,
                            details: details,
                          }

                          const accountToSet = vatTypeArray.includes(
                            reverseVatType
                          )
                            ? account
                            : accounts[vatTypeMapping[reverseVatType]]

                          setAccounts({
                            ...accounts,
                            [vatTypeMapping[reverseVatType]]: accountToSet,
                          })
                        }}
                        error={false}
                        displayCreate={true}
                        displayHistory={true}
                        onClickCreate={() => {
                          setAutoliquidatedVatAccountToCreate({
                            vatType: reverseVatType,
                            prefix: companySettings[reverseVatType].prefix,
                            forbid: companySettings[reverseVatType].forbid,
                          })
                          setIsModalDisplayed(true)
                        }}
                        onClickHistory={() => {
                          setHistoryDisplay({
                            isDisplayed: true,
                            vatType: vatType,
                          })
                          setDisplayVatRateHistory(false)
                        }}
                        disabled={
                          companyNotOnboarded ||
                          accountsOptions(reverseVatType)?.length === 0 ||
                          isAutoliquidatedVatDisalbed(reverseVatType)
                        }
                      />

                      <AccountStripSelectCreateHistory
                        label={intl.formatMessage({
                          id: `office-company-settings.auto-vat-accounts.rates.title.${vatType}`,
                        })}
                        value={findSelectedVatRateValue(vatType)}
                        options={vatRatesOptions()}
                        onChangeCallback={(selectedVatRate) => {
                          const vatRate = {
                            id: Number(selectedVatRate.value),
                            rate: selectedVatRate.label,
                          }

                          setVatRates({
                            ...vatRates,
                            [vatRateMapping[vatType]]: vatRate,
                          })
                        }}
                        error={false}
                        displayHistory={true}
                        onClickCreate={() => {}}
                        onClickHistory={() => {
                          setHistoryDisplay({
                            isDisplayed: true,
                            vatType: vatType,
                          })
                          setDisplayVatRateHistory(true)
                        }}
                        disabled={
                          companyNotOnboarded ||
                          accountsOptions(vatType)?.length === 0 ||
                          isAutoliquidatedVatDisalbed(vatType)
                        }
                      />
                    </>
                  )}
                </StyledCard>
                <Ct.Spacer />
              </>
            )
          })}
        </>
        <Ct.Spacer />
        <Ct.Spacer height={1} />
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 0 13rem;
`
const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  padding: 2.5rem;
`
