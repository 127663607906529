import { RootState } from "../rootReducer"
import { AnyAction } from "redux"
import axios, { AxiosError } from "axios"
import {
  ArchivesPayload,
  BankArchivesActionsEnum,
  BankArchivesActionsType,
  GenerationStatus,
  newOperationsWithoutArchive,
} from "./bankArchives.ducks"
import { Dispatch, RNBThunkAction } from "../store.config"
import {
  BatchDataAttached,
  FullDocumentToAttach,
  FullDocumentsActionsEnum,
  FullDocumentsActionsType,
  GetDocumentsToAttachToTransactionsThunk,
} from "./fullDocuments.ducks"
import { DropDocumentResultPayload, signRequest } from "./dropDocuments.ducks"
import { fileExtensionOfString } from "../../utils/string"
import { ThunkDispatch } from "redux-thunk"
import { acceptedBatchExtensions } from "../../utils/file"

const enum BankActionsEnum {
  GET_ACCOUNTS_FOR_COMPANY_ATTEMPT = "BANK/getAccountForCompanyAttempt",
  GET_ACCOUNTS_FOR_COMPANY_SUCCESS = "BANK/getAccountForCompanySuccess",
  GET_ACCOUNTS_FOR_COMPANY_FAILURE = "BANK/getAccountForCompanyFailure",
  GET_ACCOUNTS_BY_BANK_ATTEMPT = "BANK/getAccountsByBankAttempt",
  GET_ACCOUNTS_BY_BANK_SUCCESS = "BANK/getAccountsByBankSuccess",
  GET_ACCOUNTS_BY_BANK_FAILURE = "BANK/getAccountsByBankFailure",
  ADD_BANK_ATTEMPT = "BANK/addBankAttempt",
  ADD_BANK_SUCCESS_ACTION = "BANK/addBankSuccessAction",
  ADD_BANK_FAILURE_ACTION = "BANK/addBankFailureAction",
  SYNC_ERROR_ATTEMPT = "BANK/sync_errorAttemptAction",
  SYNC_ERROR_FAILURE = "BANK/sync_errorFailureAction",
  SYNC_ERROR_SUCCESS = "BANK/sync_errorSuccessAction",
  VALIDATE_PRO_ITEM_ATTEMPT = "BANK/validateProItemAttempt",
  VALIDATE_PRO_ITEM_SUCCESS = "BANK/validateProItemSuccess",
  VALIDATE_PRO_ITEM_FAILURE = "BANK/validateProItemFailure",
  DEACTIVATE_ITEM_ATTEMPT = "BANK/deactivateItemAttempt",
  DEACTIVATE_ITEM_SUCCESS = "BANK/deactivateItemSuccess",
  DEACTIVATE_ITEM_FAILURE = "BANK/deactivateItemFailure",
  DEACTIVATE_ITEM_RESET = "BANK/deactivateItemReset",
  DEACTIVATE_ACCOUNT_ATTEMPT = "BANK/deactivateAccountAttempt",
  DEACTIVATE_ACCOUNT_SUCCESS = "BANK/deactivateAccountSuccess",
  DEACTIVATE_ACCOUNT_FAILURE = "BANK/deactivateAccountFailure",
  DEACTIVATE_ACCOUNT_RESET = "BANK/deactivateAccountReset",
  UPDATE_ACCOUNT_SETTINGS_ATTEMPT = "BANK/updateAccountSettingsAttempt",
  UPDATE_ACCOUNT_SETTINGS_SUCCESS = "BANK/updateAccountSettingsSuccess",
  UPDATE_ACCOUNT_SETTINGS_FAILURE = "BANK/updateAccountSettingsFailure",
  UPDATE_ACCOUNT_SETTINGS_RESET = "BANK/updateAccountSettingsReset",
  UPDATE_ACCOUNT_NAME_ATTEMPT = "BANK/updateAcccountNameAttempt",
  UPDATE_ACCOUNT_NAME_SUCCESS = "BANK/updateAcccountNameSuccess",
  UPDATE_ACCOUNT_NAME_FAILURE = "BANK/updateAcccountNameFailure",
  CREATE_FIRST_IMPORT_DATE_ATTEMPT = "BANK/createFirstImportDateAttempt",
  CREATE_FIRST_IMPORT_DATE_SUCCESS = "BANK/createFirstImportDateSuccess",
  CREATE_FIRST_IMPORT_DATE_FAILURE = "BANK/createFirstImportDateFailure",
  GET_RULES_ATTEMPT = "BANK/GetRulesAttempt",
  GET_RULES_SUCCESS = "BANK/GetRulesSuccess",
  GET_RULES_FAILURE = "BANK/GetRulesFailure",
  GET_RULES_WITHOUT_DOCUMENT_ATTEMPT = "BANK/GetRulesWithoutDocumentAttempt",
  GET_RULES_WITHOUT_DOCUMENT_SUCCESS = "BANK/GetRulesWithoutDocumentSuccess",
  GET_RULES_WITHOUT_DOCUMENT_FAILURE = "BANK/GetRulesWithoutDocumentFailure",
  GET_DATA_TO_CREATE_RULE_ATTEMPT = "BANK/GetDataToCreateRuleAttempt",
  GET_DATA_TO_CREATE_RULE_SUCCESS = "BANK/GetDataToCreateRuleSuccess",
  GET_DATA_TO_CREATE_RULE_FAILURE = "BANK/GetDataToCreateRuleFailure",
  CREATE_RULE_ATTEMPT = "BANK/CreateRuleAttempt",
  CREATE_RULE_SUCCESS = "BANK/CreateRuleSuccess",
  CREATE_RULE_FAILURE = "BANK/CreateRuleFailure",
  CREATE_OR_EDIT_RULE_RESET = "BANK/CreateOrEditRuleReset",
  EDIT_RULE_ATTEMPT = "BANK/EditRuleAttempt",
  EDIT_RULE_SUCCESS = "BANK/EditRuleSuccess",
  EDIT_RULE_FAILURE = "BANK/EditRuleFailure",
  GET_RULES_EVENTS_ATTEMPT = "BANK/GetRuleEventsAttempt",
  GET_RULES_EVENTS_SUCCESS = "BANK/GetRuleEventsSuccess",
  GET_RULES_WITHOUT_DOCUMENTS_EVENTS_SUCCESS = "BANK/GetRuleWithoutDocumentEventsSuccess",
  GET_RULES_EVENTS_FAILURE = "BANK/GetRuleEventsFailure",
  DELETE_RULE_ATTEMPT = "BANK/DeleteRuleAttempt",
  DELETE_RULE_SUCCESS = "BANK/DeleteRuleSuccess",
  DELETE_RULE_FAILURE = "BANK/DeleteRuleFailure",
  GET_TRANSACTIONS_ATTEMPT = "BANK/GetTranscationsAttempt",
  GET_TRANSACTIONS_SUCCESS = "BANK/GetTranscationsSuccess",
  GET_TRANSACTIONS_FAILURE = "BANK/GetTranscationsFailure",
  GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_ATTEMPT = "BANK/GetAccountsOfItemsForCompanyAttempt",
  GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_SUCCESS = "BANK/GetAccountsOfItemsForCompanySuccess",
  GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_FAILURE = "BANK/GetAccountsOfItemsForCompanyFailure",
  TRANSFER_TO_ANOTHER_ACCOUNT_ATTEMPT = "BANK/TransferToAnotherAccountAttempt",
  TRANSFER_TO_ANOTHER_ACCOUNT_SUCCESS = "BANK/TransferToAnotherAccountSuccess",
  TRANSFER_TO_ANOTHER_ACCOUNT_FAILURE = "BANK/TransferToAnotherAccountFailure",
  CREATE_RULE_FOR_ONE_TRANSACTION_ATTEMPT = "BANK/CreateRuleForOneTransactionAttempt",
  CREATE_RULE_FOR_ONE_TRANSACTION_SUCCESS = "BANK/CreateRuleForOneTransactionSuccess",
  CREATE_RULE_FOR_ONE_TRANSACTION_FAILURE = "BANK/CreateRuleForOneTransactionFailure",
  MARK_WITHOUT_DOCUMENT_ATTEMPT = "BANK/MarkWithoutDocumentAttempt",
  MARK_WITHOUT_DOCUMENT_SUCCESS = "BANK/MarkWithoutDocumentSuccess",
  MARK_WITHOUT_DOCUMENT_FAILURE = "BANK/MarkWithoutDocumentFailure",
  GET_TRANSACTION_EVENTS_ATTEMPT = "BANK/GetTransactionEventsAttempt",
  GET_TRANSACTION_EVENTS_SUCCESS = "BANK/GetTransactionEventsSuccess",
  DROP_BATCH_FOR_TRANSACTION_ATTEMPT = "BANK/DropBatchForTransactionAttempt",
  DROP_BATCH_FOR_TRANSACTION_SUCCESS = "BANK/DropBatchForTransactionSuccess",
  DROP_BATCH_FOR_TRANSACTION_FAILURE = "BANK/DropBatchForTransactionFailure",
  ATTACH_BATCH_ON_TRANSACTION_ATTEMPT = "BANK/AttachBatchOnTransactionAttempt",
  ATTACH_BATCH_ON_TRANSACTION_SUCCESS = "BANK/AttachBatchOnTransactionSuccess",
  ATTACH_BATCH_ON_TRANSACTION_FAILURE = "BANK/AttachBatchOnTransactionFailure",
}

export const getBankAccountsForCompanyAttemptAction = () =>
  ({ type: BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_ATTEMPT } as const)
export const getBankAccountsForCompanySuccessAction = (
  payload: Array<ItemsResource>
) =>
  ({ type: BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_SUCCESS, payload } as const)
export const getBankAccountsForCompanyFailureAction = () =>
  ({ type: BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_FAILURE } as const)
export const getAccountsByBankAttemptAction = () =>
  ({ type: BankActionsEnum.GET_ACCOUNTS_BY_BANK_ATTEMPT } as const)
export const getAccountsByBankSuccessAction = (
  payload: Array<AccountsResource>
) => ({ type: BankActionsEnum.GET_ACCOUNTS_BY_BANK_SUCCESS, payload } as const)
export const getAccountsByBankFailureAction = () =>
  ({ type: BankActionsEnum.GET_ACCOUNTS_BY_BANK_FAILURE } as const)
export const addBankAttemptAction = () =>
  ({ type: BankActionsEnum.ADD_BANK_ATTEMPT } as const)
export const addBankSuccessAction = () =>
  ({ type: BankActionsEnum.ADD_BANK_SUCCESS_ACTION } as const)
export const addBankFailureAction = () =>
  ({
    type: BankActionsEnum.ADD_BANK_FAILURE_ACTION,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "Erreur",
      bodyKey: "Veuillez contacter l'équipe Chaintrust",
    },
  } as const)
export const syncErrorAttemptAction = () =>
  ({ type: BankActionsEnum.SYNC_ERROR_ATTEMPT } as const)
export const syncErrorFailureAction = () =>
  ({ type: BankActionsEnum.SYNC_ERROR_FAILURE } as const)
export const syncErrorSuccessAction = () =>
  ({ type: BankActionsEnum.SYNC_ERROR_SUCCESS } as const)

export const validateProItemAttemptAction = () =>
  ({ type: BankActionsEnum.VALIDATE_PRO_ITEM_ATTEMPT } as const)
export const validateProItemSuccessAction = () =>
  ({ type: BankActionsEnum.VALIDATE_PRO_ITEM_SUCCESS } as const)
export const validateProItemFailureAction = () =>
  ({ type: BankActionsEnum.VALIDATE_PRO_ITEM_FAILURE } as const)

export const deactivateItemAttempt = () =>
  ({ type: BankActionsEnum.DEACTIVATE_ITEM_ATTEMPT } as const)
export const deactivateItemSuccess = () =>
  ({
    type: BankActionsEnum.DEACTIVATE_ITEM_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.delete.toast.success.title`,
      titleValues: {
        accountOrBank: "Banque",
        gender: "supprimée",
      },
      bodyKey: `bank-management.delete.toast.success.body`,
      bodyValues: {
        accountOrBank: "Banque",
        gender: "supprimée",
      },
    },
  } as const)
export const deactivateItemFailure = () =>
  ({
    type: BankActionsEnum.DEACTIVATE_ITEM_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.delete.toast.error.title`,
      bodyKey: `bank-management.delete.toast.error.body`,
      bodyValues: {
        accountOrBank: "banque",
        gender: "supprimée",
      },
    },
  } as const)
export const deactivateItemReset = () =>
  ({ type: BankActionsEnum.DEACTIVATE_ITEM_RESET } as const)
export const deactivateAccountAttempt = () =>
  ({ type: BankActionsEnum.DEACTIVATE_ACCOUNT_ATTEMPT } as const)
export const deactivateAccountSuccess = () =>
  ({
    type: BankActionsEnum.DEACTIVATE_ACCOUNT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.delete.toast.success.title`,
      titleValues: {
        accountOrBank: "Compte",
        gender: "supprimé",
      },
      bodyKey: `bank-management.delete.toast.success.body`,
      bodyValues: {
        accountOrBank: "Compte",
        gender: "supprimé",
      },
    },
  } as const)
export const deactivateAccountFailure = () =>
  ({
    type: BankActionsEnum.DEACTIVATE_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.delete.toast.error.title`,
      bodyKey: `bank-management.delete.toast.error.body`,
      bodyValues: {
        accountOrBank: "compte",
        gender: "supprimé",
      },
    },
  } as const)
export const deactivateAccountReset = () =>
  ({ type: BankActionsEnum.DEACTIVATE_ACCOUNT_RESET } as const)
export const updateAccountSettingsAttempt = () =>
  ({ type: BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_ATTEMPT } as const)
export const updateAccountSettingsSuccess = () =>
  ({
    type: BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.account-modification.toast.success.title`,
      bodyKey: `bank-management.account-modification.toast.success.body`,
    },
  } as const)

export const updateAccountSettingsFailure = (errorMessage: string) =>
  ({
    type: BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.account-modification.toast.error.title`,
      bodyKey: "bank-management.name-modification.toast.error.body.point",
      bodyValues: {
        errorMessage: errorMessage,
      },
    },
  } as const)
export const updateAccountSettingsReset = () =>
  ({ type: BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_RESET } as const)
export const updateAcccountNameAttempt = () =>
  ({ type: BankActionsEnum.UPDATE_ACCOUNT_NAME_ATTEMPT } as const)
export const updateAcccountNameSuccess = (accountId: number, name: string) =>
  ({
    type: BankActionsEnum.UPDATE_ACCOUNT_NAME_SUCCESS,
    accountId,
    name,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.name-modification.toast.success.title`,
      bodyKey: `bank-management.name-modification.toast.success.body`,
    },
  } as const)
export const updateAcccountNameFailure = () =>
  ({
    type: BankActionsEnum.UPDATE_ACCOUNT_NAME_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.name-modification.toast.error.title`,
      bodyKey: `bank-management.name-modification.toast.error.body`,
    },
  } as const)
export const createFirstImportDateAttempt = () =>
  ({ type: BankActionsEnum.CREATE_FIRST_IMPORT_DATE_ATTEMPT } as const)
export const createFirstImportDateSuccess = () =>
  ({ type: BankActionsEnum.CREATE_FIRST_IMPORT_DATE_SUCCESS } as const)
export const createFirstImportDateFailure = (error: AxiosError) =>
  ({ type: BankActionsEnum.CREATE_FIRST_IMPORT_DATE_FAILURE, error } as const)

export const GetRulesAttempt = () =>
  ({ type: BankActionsEnum.GET_RULES_ATTEMPT } as const)
export const GetRulesSuccess = (accountId: number, rules: AccountRules[]) =>
  ({ type: BankActionsEnum.GET_RULES_SUCCESS, accountId, rules } as const)
export const GetRulesFailure = () =>
  ({ type: BankActionsEnum.GET_RULES_FAILURE } as const)

export const GetRulesWithoutDocumentAttempt = () =>
  ({ type: BankActionsEnum.GET_RULES_WITHOUT_DOCUMENT_ATTEMPT } as const)
export const GetRulesWithoutDocumentSuccess = (
  accountId: number,
  rules: AccountRulesWithoutDocument[]
) =>
  ({
    type: BankActionsEnum.GET_RULES_WITHOUT_DOCUMENT_SUCCESS,
    accountId,
    rules,
  } as const)
export const GetRulesWithoutDocumentFailure = () =>
  ({ type: BankActionsEnum.GET_RULES_WITHOUT_DOCUMENT_FAILURE } as const)

export const GetDataToCreateRuleAttempt = () =>
  ({ type: BankActionsEnum.GET_DATA_TO_CREATE_RULE_ATTEMPT } as const)
export const GetDataToCreateRuleSuccess = (data: DataToCreateRule) =>
  ({ type: BankActionsEnum.GET_DATA_TO_CREATE_RULE_SUCCESS, data } as const)
export const GetDataToCreateRuleFailure = () =>
  ({ type: BankActionsEnum.GET_DATA_TO_CREATE_RULE_FAILURE } as const)

export const CreateRuleAttempt = () =>
  ({ type: BankActionsEnum.CREATE_RULE_ATTEMPT } as const)
export const CreateRuleSuccess = () =>
  ({
    type: BankActionsEnum.CREATE_RULE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.add-rule.success.title`,
      bodyKey: `bank-management.rules.add-rule.success.message`,
    },
  } as const)
export const CreateRuleFailure = () =>
  ({
    type: BankActionsEnum.CREATE_RULE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.rules.add-rule.error.title`,
      bodyKey: "bank-management.rules.add-rule.error.message",
    },
  } as const)
export const CreateOrEditRuleReset = () =>
  ({
    type: BankActionsEnum.CREATE_OR_EDIT_RULE_RESET,
  } as const)

export const EditRuleAttempt = () =>
  ({
    type: BankActionsEnum.EDIT_RULE_ATTEMPT,
  } as const)
export const EditRuleSuccess = () =>
  ({
    type: BankActionsEnum.EDIT_RULE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.edit-rule.success.title`,
      bodyKey: `bank-management.rules.edit-rule.success.message`,
    },
  } as const)
export const EditRuleFailure = () =>
  ({
    type: BankActionsEnum.EDIT_RULE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.rules.edit-rule.error.title`,
      bodyKey: "bank-management.rules.add-rule.error.message",
    },
  } as const)

export const GetRuleEventsAttempt = () =>
  ({
    type: BankActionsEnum.GET_RULES_EVENTS_ATTEMPT,
  } as const)
export const GetRuleEventsSuccess = (
  events: RuleEvent[],
  accountId: number,
  ruleId: number
) =>
  ({
    type: BankActionsEnum.GET_RULES_EVENTS_SUCCESS,
    events,
    accountId,
    ruleId,
  } as const)
export const GetRuleWithoutDocumentEventsSuccess = (
  events: RuleEvent[],
  accountId: number,
  ruleId: number
) =>
  ({
    type: BankActionsEnum.GET_RULES_WITHOUT_DOCUMENTS_EVENTS_SUCCESS,
    events,
    accountId,
    ruleId,
  } as const)
export const GetRuleEventsFailure = () =>
  ({
    type: BankActionsEnum.GET_RULES_EVENTS_FAILURE,
  } as const)

export const DeleteRuleAttempt = () =>
  ({
    type: BankActionsEnum.DELETE_RULE_ATTEMPT,
  } as const)
export const DeleteRuleSuccess = (rule_without_document: boolean) =>
  ({
    type: BankActionsEnum.DELETE_RULE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.delete-rule.delete.success.tilte`,
      bodyKey: rule_without_document
        ? "bank-management.rules.delete-rule-without-document.delete.success.message"
        : "bank-management.rules.delete-rule.delete.success.message",
    },
  } as const)
export const DeleteRuleFailure = () =>
  ({
    type: BankActionsEnum.DELETE_RULE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.rules.delete-rule.delete.error.title`,
      bodyKey: "bank-management.rules.delete-rule.delete.error.message",
    },
  } as const)

export const GetTranscationsAttempt = (accountId: number) =>
  ({
    type: BankActionsEnum.GET_TRANSACTIONS_ATTEMPT,
    accountId,
  } as const)
export const GetTranscationsSuccess = (
  transactions: Transaction[],
  transations_need_action: { transaction_id: number; need_action: boolean }[],
  accountId: number
) =>
  ({
    type: BankActionsEnum.GET_TRANSACTIONS_SUCCESS,
    transactions,
    transations_need_action,
    accountId,
  } as const)
export const GetTranscationsFailure = () =>
  ({
    type: BankActionsEnum.GET_TRANSACTIONS_FAILURE,
  } as const)

export const GetAccountsOfItemsForCompanyAttempt = () =>
  ({
    type: BankActionsEnum.GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_ATTEMPT,
  } as const)
export const GetAccountsOfItemsForCompanySuccess = (
  companiesAccounts: CompaniesAccounts[]
) =>
  ({
    type: BankActionsEnum.GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_SUCCESS,
    companiesAccounts,
  } as const)
export const GetAccountsOfItemsForCompanyFailure = () =>
  ({
    type: BankActionsEnum.GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_FAILURE,
  } as const)

export const TransferToAnotherAccountAttempt = () =>
  ({
    type: BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_ATTEMPT,
  } as const)
export const TransferToAnotherAccountSuccess = () =>
  ({
    type: BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.transfer-rules.transfer.success-title`,
      bodyKey: "bank-management.rules.transfer-rules.transfer.success-message",
    },
  } as const)
export const TransferToAnotherAccountFailure = () =>
  ({
    type: BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.rules.transfer-rules.transfer.error-title`,
      bodyKey: "bank-management.rules.transfer-rules.transfer.error-message",
    },
  } as const)

export const CreateRuleForOneTransactionAttempt = () =>
  ({
    type: BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_ATTEMPT,
  } as const)
export const CreateRuleForOneTransactionSuccess = () =>
  ({
    type: BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.add-rule-on-transaction.success.title`,
      bodyKey: `bank-management.rules.add-rule-on-transaction.success.message`,
    },
  } as const)
export const CreateRuleForOneTransactionFailure = () =>
  ({
    type: BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_FAILURE,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules.add-rule-on-transaction.error.title`,
      bodyKey: `bank-management.rules.add-rule-on-transaction.error.message`,
    },
  } as const)

export const MarkWithoutDocumentAttempt = () =>
  ({
    type: BankActionsEnum.MARK_WITHOUT_DOCUMENT_ATTEMPT,
  } as const)
export const MarkWithoutDocumentSuccess = (marked_without_document: boolean) =>
  ({
    type: BankActionsEnum.MARK_WITHOUT_DOCUMENT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.rules-without-document.marked_without_document.success.title`,
      bodyKey: `bank-management.rules-without-document.marked_without_document.success.message`,
      bodyValues: {
        withOrWithout: marked_without_document ? "sans" : "avec",
      },
    },
  } as const)
export const MarkWithoutDocumentFailure = (message: string) =>
  ({
    type: BankActionsEnum.MARK_WITHOUT_DOCUMENT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.rules-without-document.marked_without_document.error.title`,
      bodyKey: message,
    },
  } as const)

export const GetTransactionEventsAttempt = () =>
  ({
    type: BankActionsEnum.GET_TRANSACTION_EVENTS_ATTEMPT,
  } as const)
export const GetTransactionEventsSuccess = (
  events: TransactionEvent[],
  accountID: number,
  transactionId: number
) =>
  ({
    type: BankActionsEnum.GET_TRANSACTION_EVENTS_SUCCESS,
    events,
    accountID,
    transactionId,
  } as const)

export const DropBatchForTransactionAttempt = () =>
  ({
    type: BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_ATTEMPT,
  } as const)
export const DropBatchForTransactionSuccess = (
  accountId: number,
  transactionId: number,
  batch_document_id: number
) =>
  ({
    type: BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_SUCCESS,
    accountId,
    transactionId,
    batch_document_id,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `bank-management.transaction.attach-modal.drop-batch-tooltip-success.title`,
      bodyKey: `bank-management.transaction.attach-modal.drop-batch-tooltip-success.message`,
    },
  } as const)
export const DropBatchForTransactionFailure = () =>
  ({
    type: BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `bank-management.transaction.attach-modal.drop-batch-tooltip-error.title`,
      bodyKey: `bank-management.transaction.attach-modal.drop-batch-tooltip-error.message`,
    },
  } as const)

export const AttachBatchOnTransactionAttempt = () =>
  ({
    type: BankActionsEnum.ATTACH_BATCH_ON_TRANSACTION_ATTEMPT,
  } as const)
export const AttachBatchOnTransactionSuccess = () =>
  ({
    type: BankActionsEnum.ATTACH_BATCH_ON_TRANSACTION_SUCCESS,
  } as const)
export const AttachBatchOnTransactionFailure = () =>
  ({
    type: BankActionsEnum.ATTACH_BATCH_ON_TRANSACTION_FAILURE,
  } as const)

type BankActionsType = ReturnType<
  | typeof getBankAccountsForCompanyAttemptAction
  | typeof getBankAccountsForCompanySuccessAction
  | typeof getAccountsByBankAttemptAction
  | typeof getAccountsByBankSuccessAction
  | typeof getAccountsByBankFailureAction
  | typeof addBankAttemptAction
  | typeof addBankSuccessAction
  | typeof addBankFailureAction
  | typeof getBankAccountsForCompanyFailureAction
  | typeof syncErrorAttemptAction
  | typeof syncErrorFailureAction
  | typeof syncErrorSuccessAction
  | typeof validateProItemAttemptAction
  | typeof validateProItemSuccessAction
  | typeof validateProItemFailureAction
  | typeof deactivateItemAttempt
  | typeof deactivateItemSuccess
  | typeof deactivateItemFailure
  | typeof deactivateItemReset
  | typeof deactivateAccountAttempt
  | typeof deactivateAccountSuccess
  | typeof deactivateAccountFailure
  | typeof deactivateAccountReset
  | typeof updateAccountSettingsAttempt
  | typeof updateAccountSettingsSuccess
  | typeof updateAccountSettingsFailure
  | typeof updateAccountSettingsReset
  | typeof updateAcccountNameAttempt
  | typeof updateAcccountNameSuccess
  | typeof updateAcccountNameFailure
  | typeof createFirstImportDateAttempt
  | typeof createFirstImportDateSuccess
  | typeof createFirstImportDateFailure
  | typeof GetRulesAttempt
  | typeof GetRulesSuccess
  | typeof GetRulesFailure
  | typeof GetRulesWithoutDocumentAttempt
  | typeof GetRulesWithoutDocumentSuccess
  | typeof GetRulesWithoutDocumentFailure
  | typeof GetDataToCreateRuleAttempt
  | typeof GetDataToCreateRuleSuccess
  | typeof GetDataToCreateRuleFailure
  | typeof CreateRuleAttempt
  | typeof CreateRuleSuccess
  | typeof CreateRuleFailure
  | typeof CreateOrEditRuleReset
  | typeof EditRuleAttempt
  | typeof EditRuleSuccess
  | typeof EditRuleFailure
  | typeof GetRuleEventsAttempt
  | typeof GetRuleEventsSuccess
  | typeof GetRuleWithoutDocumentEventsSuccess
  | typeof GetRuleEventsFailure
  | typeof DeleteRuleAttempt
  | typeof DeleteRuleSuccess
  | typeof DeleteRuleFailure
  | typeof GetTranscationsAttempt
  | typeof GetTranscationsSuccess
  | typeof GetTranscationsFailure
  | typeof GetAccountsOfItemsForCompanyAttempt
  | typeof GetAccountsOfItemsForCompanySuccess
  | typeof GetAccountsOfItemsForCompanyFailure
  | typeof TransferToAnotherAccountAttempt
  | typeof TransferToAnotherAccountSuccess
  | typeof TransferToAnotherAccountFailure
  | typeof CreateRuleForOneTransactionAttempt
  | typeof CreateRuleForOneTransactionSuccess
  | typeof CreateRuleForOneTransactionFailure
  | typeof MarkWithoutDocumentAttempt
  | typeof MarkWithoutDocumentSuccess
  | typeof MarkWithoutDocumentFailure
  | typeof GetTransactionEventsAttempt
  | typeof GetTransactionEventsSuccess
  | typeof DropBatchForTransactionAttempt
  | typeof DropBatchForTransactionSuccess
  | typeof DropBatchForTransactionFailure
  | typeof AttachBatchOnTransactionAttempt
  | typeof AttachBatchOnTransactionSuccess
  | typeof AttachBatchOnTransactionFailure
>

export interface BankState {
  activationState: "DEFAULT" | "REQUESTED" | "ACTIVATED" | null
  items: { [id: string]: ItemsResource }
  accounts: { [id: string]: AccountsResource }
  itemsLoading: "DEFAULT" | "LOADED"
  accountsLoading: "DEFAULT" | "LOADED"
  archives: ArchivesPayload[]
  getArchivesStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  downloadingStatus:
    | "UNSET"
    | "ATTEMPT"
    | "DOWNLOADING"
    | "DOWNLOADED"
    | "FAILURE"
  regenerateBankinArchiveStatus: "UNSET" | "LOADING" | "SUCCESS" | "ERROR"
  operationsStatus: "DEFAULT" | "LOADING" | "LOADED" | "ERROR"
  deactivateAccountStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  deactivateItemStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  updateAccountStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  udpateAccountsNameStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  createFirstImportStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  newOperationsWithoutArchive: newOperationsWithoutArchive[]
  dataToCreateRule: DataToCreateRule
  createOrEditRuleStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  getTransactionsStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  companiesAccounts: CompaniesAccounts[]
  transferToStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
  documentsToAttach: {
    full_documents: FullDocumentToAttach[]
    batch_data: BatchDataAttached | null
  }
  attachDocumentStatus: "UNSET" | "ATTEMPT" | "SUCCESS" | "FAILURE"
}

const dataToCreateRuleInitialState = {
  merchant_codes: [],
  accounts: [],
  vat_rates: [],
  company_data: {
    buy_auxiliary_prefix: null,
    sell_auxiliary_prefix: null,
    buy_third_party_account: null,
    sell_third_party_account: null,
  },
  rules: [],
  rules_without_document: [],
}

export const bankInitialState: BankState = {
  activationState: null,
  items: {},
  accounts: {},
  itemsLoading: "DEFAULT",
  accountsLoading: "DEFAULT",
  archives: [],
  getArchivesStatus: "UNSET",
  downloadingStatus: "UNSET",
  regenerateBankinArchiveStatus: "UNSET",
  operationsStatus: "DEFAULT",
  deactivateAccountStatus: "UNSET",
  deactivateItemStatus: "UNSET",
  updateAccountStatus: "UNSET",
  udpateAccountsNameStatus: "UNSET",
  newOperationsWithoutArchive: [],
  createFirstImportStatus: "UNSET",
  dataToCreateRule: dataToCreateRuleInitialState,
  createOrEditRuleStatus: "UNSET",
  getTransactionsStatus: "UNSET",
  companiesAccounts: [],
  transferToStatus: "UNSET",
  documentsToAttach: { full_documents: [], batch_data: null },
  attachDocumentStatus: "UNSET",
}

export function bankReducer(
  state = bankInitialState,
  action: BankActionsType | BankArchivesActionsType | FullDocumentsActionsType
): BankState {
  switch (action.type) {
    case BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_SUCCESS:
      return {
        ...state,
        items: action.payload.reduce(
          (item, resource) => ({ ...item, [resource.id]: resource }),
          {}
        ),
        itemsLoading: "LOADED",
      }
    case BankActionsEnum.GET_ACCOUNTS_BY_BANK_SUCCESS:
      return {
        ...state,
        accounts: action.payload.reduce(
          (account, resource) => ({ ...account, [resource.id]: resource }),
          { ...state.accounts }
        ),
        accountsLoading: "LOADED",
      }
    case BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_FAILURE:
      return {
        ...state,
        items: {},
      }
    case BankActionsEnum.GET_ACCOUNTS_FOR_COMPANY_ATTEMPT:
      return {
        ...state,
        items: {},
        itemsLoading: "DEFAULT",
      }
    case BankActionsEnum.GET_ACCOUNTS_BY_BANK_ATTEMPT:
      return {
        ...state,
        accounts: {},
        accountsLoading: "DEFAULT",
      }
    case BankActionsEnum.DEACTIVATE_ITEM_ATTEMPT:
      return { ...state, deactivateItemStatus: "ATTEMPT" }
    case BankActionsEnum.DEACTIVATE_ITEM_SUCCESS:
      return { ...state, deactivateItemStatus: "SUCCESS" }
    case BankActionsEnum.DEACTIVATE_ITEM_FAILURE:
      return { ...state, deactivateItemStatus: "FAILURE" }
    case BankActionsEnum.DEACTIVATE_ITEM_RESET:
      return { ...state, deactivateItemStatus: "UNSET" }
    case BankActionsEnum.DEACTIVATE_ACCOUNT_ATTEMPT:
      return { ...state, deactivateAccountStatus: "ATTEMPT" }
    case BankActionsEnum.DEACTIVATE_ACCOUNT_SUCCESS:
      return { ...state, deactivateAccountStatus: "SUCCESS" }
    case BankActionsEnum.DEACTIVATE_ACCOUNT_FAILURE:
      return { ...state, deactivateAccountStatus: "FAILURE" }
    case BankActionsEnum.DEACTIVATE_ACCOUNT_RESET:
      return { ...state, deactivateAccountStatus: "UNSET" }
    case BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_ATTEMPT:
      return { ...state, updateAccountStatus: "ATTEMPT" }
    case BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      return { ...state, updateAccountStatus: "SUCCESS" }
    case BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_FAILURE:
      return { ...state, updateAccountStatus: "FAILURE" }
    case BankActionsEnum.UPDATE_ACCOUNT_SETTINGS_RESET:
      return { ...state, updateAccountStatus: "UNSET" }
    case BankActionsEnum.UPDATE_ACCOUNT_NAME_ATTEMPT:
      return { ...state, udpateAccountsNameStatus: "ATTEMPT" }
    case BankActionsEnum.UPDATE_ACCOUNT_NAME_SUCCESS: {
      const account = { ...state.accounts[action.accountId], name: action.name }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        udpateAccountsNameStatus: "SUCCESS",
        accounts: accounts,
      }
    }
    case BankActionsEnum.UPDATE_ACCOUNT_NAME_FAILURE:
      return { ...state, udpateAccountsNameStatus: "FAILURE" }
    case BankArchivesActionsEnum.GET_ARCHIVES_ATTEMPT:
      return { ...state, getArchivesStatus: "ATTEMPT" }
    case BankArchivesActionsEnum.GET_ARCHIVES_SUCCESS: {
      return {
        ...state,
        getArchivesStatus: "SUCCESS",
        archives: action.payload,
      }
    }
    case BankArchivesActionsEnum.GET_ARCHIVES_FAILURE:
      return { ...state, getArchivesStatus: "FAILURE" }
    case BankArchivesActionsEnum.DOWNLOADING_STATUS_ATTEMPT:
      return { ...state, downloadingStatus: "ATTEMPT" }
    case BankArchivesActionsEnum.DOWNLOADING_STATUS_DOWNLOADING:
      return { ...state, downloadingStatus: "DOWNLOADING" }
    case BankArchivesActionsEnum.DOWNLOADING_STATUS_DOWNLOADED:
      return { ...state, downloadingStatus: "DOWNLOADED" }
    case BankArchivesActionsEnum.DOWNLOADING_STATUS_RESET:
      return { ...state, downloadingStatus: "UNSET" }
    case BankArchivesActionsEnum.DOWNLOADING_STATUS_FAILURE:
      return { ...state, downloadingStatus: "FAILURE" }
    case BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        operationsStatus: "LOADED",
      }
    case BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_ATTEMPT:
      return {
        ...state,
        operationsStatus: "LOADING",
      }
    case BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_FAILURE:
      return {
        ...state,
        operationsStatus: "ERROR",
      }
    case BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_RESET:
      return {
        ...state,
        operationsStatus: "DEFAULT",
      }
    case BankArchivesActionsEnum.GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_SUCCESS:
      return {
        ...state,
        newOperationsWithoutArchive: action.payload,
      }
    case BankArchivesActionsEnum.GENERATE_ARCHIVE_ATTEMPT: {
      const updatedOperations = state.newOperationsWithoutArchive.map(
        (operation) => {
          if (operation.fiscalYearId === action.fiscalYearId) {
            return { ...operation, status: "GENERATING" as GenerationStatus }
          }
          return operation
        }
      )

      return {
        ...state,
        newOperationsWithoutArchive: updatedOperations,
      }
    }
    case BankArchivesActionsEnum.GENERATE_ARCHIVE_SUCCESS: {
      const updatedOperations = state.newOperationsWithoutArchive.filter(
        (operation) => operation.fiscalYearId !== action.fiscalYearId
      )

      return {
        ...state,
        newOperationsWithoutArchive: updatedOperations,
      }
    }
    case BankArchivesActionsEnum.GENERATE_ARCHIVE_FAILURE: {
      const updatedOperations = state.newOperationsWithoutArchive.map(
        (operation) => {
          if (operation.fiscalYearId === action.fiscalYearId) {
            return { ...operation, status: "NOT_GENERATED" as GenerationStatus }
          }
          return operation
        }
      )

      return {
        ...state,
        newOperationsWithoutArchive: updatedOperations,
      }
    }
    case BankActionsEnum.CREATE_FIRST_IMPORT_DATE_ATTEMPT:
      return { ...state, createFirstImportStatus: "ATTEMPT" }
    case BankActionsEnum.CREATE_FIRST_IMPORT_DATE_SUCCESS:
      return { ...state, createFirstImportStatus: "SUCCESS" }
    case BankActionsEnum.CREATE_FIRST_IMPORT_DATE_FAILURE:
      return { ...state, createFirstImportStatus: "FAILURE" }
    case BankActionsEnum.GET_RULES_SUCCESS: {
      const account = {
        ...state.accounts[action.accountId],
        rules: action.rules,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
      }
    }
    case BankActionsEnum.GET_RULES_WITHOUT_DOCUMENT_SUCCESS: {
      const account = {
        ...state.accounts[action.accountId],
        rules_without_document: action.rules,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
      }
    }
    case BankActionsEnum.GET_DATA_TO_CREATE_RULE_ATTEMPT:
      return {
        ...state,
        dataToCreateRule: dataToCreateRuleInitialState,
      }
    case BankActionsEnum.GET_DATA_TO_CREATE_RULE_SUCCESS:
      return { ...state, dataToCreateRule: action.data }
    case BankActionsEnum.GET_DATA_TO_CREATE_RULE_FAILURE:
      return {
        ...state,
        dataToCreateRule: dataToCreateRuleInitialState,
      }
    case BankActionsEnum.CREATE_RULE_ATTEMPT:
      return { ...state, createOrEditRuleStatus: "ATTEMPT" }
    case BankActionsEnum.CREATE_RULE_SUCCESS:
      return { ...state, createOrEditRuleStatus: "SUCCESS" }
    case BankActionsEnum.CREATE_RULE_FAILURE:
      return { ...state, createOrEditRuleStatus: "FAILURE" }
    case BankActionsEnum.CREATE_OR_EDIT_RULE_RESET:
      return { ...state, createOrEditRuleStatus: "UNSET" }
    case BankActionsEnum.EDIT_RULE_ATTEMPT:
      return { ...state, createOrEditRuleStatus: "ATTEMPT" }
    case BankActionsEnum.EDIT_RULE_SUCCESS:
      return { ...state, createOrEditRuleStatus: "SUCCESS" }
    case BankActionsEnum.EDIT_RULE_FAILURE:
      return { ...state, createOrEditRuleStatus: "FAILURE" }
    case BankActionsEnum.GET_RULES_EVENTS_SUCCESS: {
      const rules = state.accounts[action.accountId].rules?.map((rule) => {
        if (rule.id === action.ruleId) {
          return { ...rule, events: action.events }
        }
        return rule
      })
      const account = {
        ...state.accounts[action.accountId],
        rules: rules,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
      }
    }
    case BankActionsEnum.GET_RULES_WITHOUT_DOCUMENTS_EVENTS_SUCCESS: {
      const rules = state.accounts[
        action.accountId
      ].rules_without_document?.map((rule) => {
        if (rule.id === action.ruleId) {
          return { ...rule, events: action.events }
        }
        return rule
      })
      const account = {
        ...state.accounts[action.accountId],
        rules: rules,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
      }
    }
    case BankActionsEnum.GET_TRANSACTIONS_ATTEMPT: {
      const account = {
        ...state.accounts[action.accountId],
        transactions: [],
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
        getTransactionsStatus: "ATTEMPT",
      }
    }
    case BankActionsEnum.GET_TRANSACTIONS_SUCCESS: {
      const mergedArray = action.transactions.reduce(
        (
          acc: { transaction_id: number; need_action: boolean }[],
          originalItem
        ) => {
          const matchingNeedActionsItem = action.transations_need_action.find(
            (item) => item.transaction_id === originalItem.transaction_id
          )
          acc.push({
            ...originalItem,
            need_action: matchingNeedActionsItem
              ? matchingNeedActionsItem.need_action
              : false,
          })
          return acc
        },
        []
      )

      const account = {
        ...state.accounts[action.accountId],
        transactions: mergedArray,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
        getTransactionsStatus: "SUCCESS",
      }
    }
    case BankActionsEnum.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        getTransactionsStatus: "FAILURE",
      }

    case BankActionsEnum.GET_ACCOUNTS_OF_ITEMS_FOR_COMPANY_SUCCESS:
      return {
        ...state,
        companiesAccounts: action.companiesAccounts,
      }

    case BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_ATTEMPT:
      return {
        ...state,
        transferToStatus: "ATTEMPT",
      }
    case BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_SUCCESS:
      return {
        ...state,
        transferToStatus: "SUCCESS",
      }
    case BankActionsEnum.TRANSFER_TO_ANOTHER_ACCOUNT_FAILURE:
      return {
        ...state,
        transferToStatus: "FAILURE",
      }
    case BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_ATTEMPT:
      return {
        ...state,
        createOrEditRuleStatus: "ATTEMPT",
      }
    case BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_SUCCESS:
      return {
        ...state,
        createOrEditRuleStatus: "SUCCESS",
      }
    case BankActionsEnum.CREATE_RULE_FOR_ONE_TRANSACTION_FAILURE:
      return {
        ...state,
        createOrEditRuleStatus: "FAILURE",
      }
    case FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_ATTEMPT:
      return {
        ...state,
        documentsToAttach: { full_documents: [], batch_data: null },
      }
    case FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        documentsToAttach: {
          full_documents: action.payload.full_documents,
          batch_data: action.payload.batch_data,
        },
      }
    case FullDocumentsActionsEnum.GET_DOCUMENTS_TO_ATTACH_TO_TRANSACTIONS_RESET:
      return {
        ...state,
        documentsToAttach: { full_documents: [], batch_data: null },
      }
    case FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_ATTEMPT:
      return { ...state, attachDocumentStatus: "ATTEMPT" }
    case FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_SUCCESS: {
      return {
        ...state,
        attachDocumentStatus: "SUCCESS",
      }
    }
    case FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_FAILURE:
      return { ...state, attachDocumentStatus: "FAILURE" }
    case FullDocumentsActionsEnum.ATTACH_FULL_DOCUMENT_TO_TRANSACTION_RESET:
      return { ...state, attachDocumentStatus: "UNSET" }

    case FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_ATTEMPT:
      return { ...state, attachDocumentStatus: "ATTEMPT" }
    case FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_SUCCESS: {
      const transactions = state.accounts[action.accountId].transactions?.map(
        (t) => {
          if (t.transaction_id === action.transactionId) {
            return { ...t, batch_document_id: null, full_document_id: null }
          }
          return t
        }
      )

      const account = {
        ...state.accounts[action.accountId],
        transactions: transactions,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
        attachDocumentStatus: "SUCCESS",
      }
    }
    case FullDocumentsActionsEnum.DETACH_FULL_DOCUMENT_OF_TRANSACTION_FAILURE:
      return { ...state, attachDocumentStatus: "ATTEMPT" }
    case BankActionsEnum.GET_TRANSACTION_EVENTS_SUCCESS:
      const transactions = state.accounts[action.accountID].transactions?.map(
        (t) => {
          if (t.transaction_id === action.transactionId) {
            return { ...t, events: action.events }
          }
          return t
        }
      )

      const account = {
        ...state.accounts[action.accountID],
        transactions: transactions,
      }
      const accounts = { ...state.accounts, [action.accountID]: account }
      return {
        ...state,
        accounts: accounts,
      }
    case BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_ATTEMPT: {
      return {
        ...state,
        attachDocumentStatus: "ATTEMPT",
      }
    }
    case BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_SUCCESS: {
      const transactions = state.accounts[action.accountId].transactions?.map(
        (t) => {
          if (t.transaction_id === action.transactionId) {
            return { ...t, batch_document_id: action.batch_document_id }
          }
          return t
        }
      )

      const account = {
        ...state.accounts[action.accountId],
        transactions: transactions,
      }
      const accounts = { ...state.accounts, [action.accountId]: account }
      return {
        ...state,
        accounts: accounts,
        attachDocumentStatus: "SUCCESS",
      }
    }
    case BankActionsEnum.DROP_BATCH_FOR_TRANSACTION_FAILURE: {
      return {
        ...state,
        attachDocumentStatus: "FAILURE",
      }
    }

    case BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_ATTEMPT:
      return {
        ...state,
        regenerateBankinArchiveStatus: "LOADING",
      }

    case BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_SUCCESS:
      return {
        ...state,
        regenerateBankinArchiveStatus: "SUCCESS",
      }

    case BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_FAILURE:
      return {
        ...state,
        regenerateBankinArchiveStatus: "ERROR",
      }

    case BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_RESET:
      return {
        ...state,
        regenerateBankinArchiveStatus: "UNSET",
      }

    default:
      return { ...state }
  }
}

export const getBankAccountsForCompanyThunk =
  (fiduciaryId: number, companyId: number) =>
  (dispatch: Dispatch<BankActionsType>) => {
    return axios
      .post<ItemsResponse>(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/list`
      )
      .then((response) => {
        const itemsFiltered = response.data.bankin_data.filter(
          (item) => !item.deactivated_at
        )
        dispatch(getBankAccountsForCompanySuccessAction(itemsFiltered))
      })
      .catch(() => {
        dispatch(getBankAccountsForCompanyFailureAction())
      })
  }

export const getAccountsByBankThunk =
  (fiduciaryId: number, companyId: number, bankAccountID: number) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(getAccountsByBankAttemptAction())
    return axios
      .post<AccountsResponse>(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${bankAccountID}/list_accounts`
      )
      .then((response) => {
        const accountsFiltered = response.data.list_accounts.filter(
          (account) => !account.deactivated_at
        )
        dispatch(getAccountsByBankSuccessAction(accountsFiltered))
      })
      .catch(() => {
        dispatch(getAccountsByBankFailureAction())
      })
  }

export const addBank =
  (fiduciaryID: number, companyId: number, fiduciaryName: string) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(addBankAttemptAction())
    return axios
      .get<RedirectedUrl>(
        `/fiduciaries/${fiduciaryID}/companies/${companyId}/redirected_url`
      )
      .then((response) => {
        const url = response.data.redirected_url
        axios
          .post("/users/set_redirect_params", {
            params: {
              readable_safe_url: fiduciaryName,
              id: companyId,
            },
            name: "BankAccounts",
          })
          .then(() => {
            window.open(url, "_blank")
            dispatch(addBankSuccessAction())
          })
      })
      .catch(() => {
        dispatch(addBankFailureAction())
      })
  }

export const syncErrorThunk =
  (fiduciaryId: number, companyId: number, id: number, fiduciaryName: string) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(syncErrorAttemptAction())
    return axios
      .post<RedirectedUrlObject>(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${id}/edit`
      )
      .then((response) => {
        const url = response.data.redirect_url.redirect_url
        axios
          .post("/users/set_redirect_params", {
            params: {
              readable_safe_url: fiduciaryName,
              id: companyId,
            },
            name: "BankAccounts",
          })
          .then(() => {
            window.open(url, "_blank")
            dispatch(syncErrorSuccessAction())
          })
      })
      .catch(() => {
        dispatch(syncErrorFailureAction())
      })
  }

export const validateProItemThunk =
  (fiduciaryId: number, companyId: number, id: number, fiduciaryName: string) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(validateProItemAttemptAction())
    return axios
      .post<RedirectedUrlObject>(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${id}/validate_pro_items`
      )
      .then((response) => {
        const url = response.data.redirect_url.redirect_url
        axios
          .post("/users/set_redirect_params", {
            params: {
              readable_safe_url: fiduciaryName,
              id: companyId,
            },
            name: "BankAccounts",
          })
          .then(() => {
            window.open(url, "_blank")
            dispatch(validateProItemSuccessAction())
          })
      })
      .catch(() => {
        dispatch(validateProItemFailureAction())
      })
  }

export const deactivateAccountThunk =
  ({ fiduciaryId, companyId, itemId, accountId }: deactivateAccountParams) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(deactivateAccountAttempt())
    return axios
      .post(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/deactivate`
      )
      .then(() => dispatch(deactivateAccountSuccess()))
      .catch(() => dispatch(deactivateAccountFailure()))
  }

export const deactivateItemThunk =
  ({ fiduciaryId, companyId, itemId }: deactivateItemParams) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(deactivateItemAttempt())
    return axios
      .post(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/destroy_item_data`
      )
      .then(() => dispatch(deactivateItemSuccess()))
      .catch(() => dispatch(deactivateItemFailure()))
  }

export const updateAccountThunk =
  ({
    fiduciaryId,
    companyId,
    itemId,
    accountId,
    journalCode,
    financialAccount,
    suspenseAccount,
  }: updateAccountParams) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(updateAccountSettingsAttempt())
    return axios
      .post(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/update_account_settings`,
        {
          bankin_journal_code: journalCode,
          bankin_financial_account: financialAccount,
          bankin_suspense_account: suspenseAccount,
        }
      )
      .then(() => {
        dispatch(updateAccountSettingsSuccess())
        if (companyId && itemId) {
          dispatch(getAccountsByBankThunk(fiduciaryId, companyId, itemId))
        }
      })
      .catch((response) => {
        let errorMessage = response.response.data.error

        errorMessage = errorMessage
          .replace(
            /Bankin journal code |Impossible de sauvegarder : |Bankin 512 suffix |Bankin 471 suffix /gi,
            ""
          )
          .replaceAll(",", ".")

        return dispatch(updateAccountSettingsFailure(errorMessage))
      })
  }

export const updateAccountNameThunk =
  ({
    fiduciaryId,
    companyId,
    itemId,
    accountId,
    name,
  }: updateAccountsNameParams) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(updateAcccountNameAttempt())
    return axios
      .put(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/update_account_name`,
        {
          account_id: accountId,
          name: name,
        }
      )
      .then(() => dispatch(updateAcccountNameSuccess(accountId, name)))
      .catch(() => {
        dispatch(updateAcccountNameFailure())
      })
  }

export const createFirstImportDateThunk =
  (
    companyId: number,
    itemId: number,
    accountId: number,
    firstImportDate: string
  ) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    const fiduciaryId = getState().fiduciary.id
    return axios
      .post(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/create_first_import_date`,
        { first_import_date: firstImportDate }
      )
      .then(() => {
        dispatch(createFirstImportDateSuccess())
        dispatch(getAccountsByBankThunk(fiduciaryId, companyId, itemId))
      })
      .catch((error) => {
        dispatch(createFirstImportDateFailure(error))
      })
  }

export const getRulesThunk =
  (accountId: number) => (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetRulesAttempt())
    return axios
      .get<AccountRules[]>(`/bankin_accounts/${accountId}/get_account_rules`)
      .then((response) => {
        dispatch(GetRulesSuccess(accountId, response.data))
      })
      .catch(() => {
        dispatch(GetRulesFailure())
      })
  }

export const getRulesWithoutDocumentThunk =
  (accountId: number) => (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetRulesWithoutDocumentAttempt())
    return axios
      .get<AccountRulesWithoutDocument[]>(
        `/bankin_accounts/${accountId}/get_account_rules_without_document`
      )
      .then((response) => {
        dispatch(GetRulesWithoutDocumentSuccess(accountId, response.data))
      })
      .catch(() => {
        dispatch(GetRulesWithoutDocumentFailure())
      })
  }

export const getDataToCreateRuleThunk =
  (accountId: number) => (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetDataToCreateRuleAttempt())
    return axios
      .get<DataToCreateRule>(
        `/bankin_accounts/${accountId}/get_data_to_create_rule`
      )
      .then((response) => {
        dispatch(GetDataToCreateRuleSuccess(response.data))
      })
      .catch(() => {
        dispatch(GetDataToCreateRuleFailure())
      })
  }

export const createRuleThunk =
  (
    newRule: NewRuleParams,
    accountId: number,
    from: "rules" | "transactions" | "rule_without_document"
  ) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(CreateRuleAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/create_rule`, {
        new_rule: newRule,
        rule_without_document: from === "rule_without_document",
      })
      .then(() => {
        dispatch(CreateRuleSuccess())
        if (from === "rules") {
          dispatch(getRulesThunk(accountId))
        } else if (from === "transactions") {
          dispatch(getDataToCreateRuleThunk(accountId))
          dispatch(GetTransactionsThunk(accountId))
        } else if (from === "rule_without_document") {
          dispatch(getRulesWithoutDocumentThunk(accountId))
        }
      })
      .catch(() => {
        dispatch(CreateRuleFailure())
      })
  }

export const EditRuleThunk =
  (
    editedRule: NewRuleParams,
    accountId: number,
    ruleId: number,
    rule_without_document: boolean
  ) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(EditRuleAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/edit_rule`, {
        edited_rule: editedRule,
        rule_id: ruleId,
        rule_without_document: rule_without_document,
      })
      .then(() => {
        dispatch(EditRuleSuccess())
        if (rule_without_document) {
          dispatch(getRulesWithoutDocumentThunk(accountId))
        } else {
          dispatch(getRulesThunk(accountId))
        }
      })
      .catch(() => {
        dispatch(EditRuleFailure())
      })
  }

export const GetRuleEventsThunk =
  (accountId: number, ruleId: number, rule_without_document: boolean) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetRuleEventsAttempt())
    return axios
      .get<RuleEvent[]>(`/bankin_accounts/${accountId}/get_rule_events`, {
        params: { rule_id: ruleId },
      })
      .then((response) => {
        if (rule_without_document) {
          dispatch(
            GetRuleWithoutDocumentEventsSuccess(
              response.data,
              accountId,
              ruleId
            )
          )
        } else {
          dispatch(GetRuleEventsSuccess(response.data, accountId, ruleId))
        }
      })
      .catch(() => {
        dispatch(GetRuleEventsFailure())
      })
  }

export const DeleteRuleThunk =
  (
    accountId: number,
    ruleId: number,
    from: "rules" | "transactions" | "rule_without_document"
  ) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(DeleteRuleAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/destroy_rule`, {
        rule_id: ruleId,
        rule_without_document: from === "rule_without_document",
      })
      .then(() => {
        dispatch(DeleteRuleSuccess(from === "rule_without_document"))
        if (from === "rules") {
          dispatch(getRulesThunk(accountId))
        } else if (from === "transactions") {
          dispatch(getDataToCreateRuleThunk(accountId))
        } else if (from === "rule_without_document") {
          dispatch(getRulesWithoutDocumentThunk(accountId))
        }
      })
      .catch(() => {
        dispatch(DeleteRuleFailure())
      })
  }

export const GetTransactionsThunk =
  (accountId: number) => (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetTranscationsAttempt(accountId))
    return axios
      .get<Transaction[]>(
        `/bankin_accounts/${accountId}/get_transactions_with_rules`
      )
      .then(({ data }) => {
        const transactions = data
        axios
          .get<{ transaction_id: number; need_action: boolean }[]>(
            `/bankin_accounts/${accountId}/get_batch_states_of_transactions`
          )
          .then((response) => {
            dispatch(
              GetTranscationsSuccess(transactions, response.data, accountId)
            )
          })
      })
      .catch(() => {
        dispatch(GetTranscationsFailure())
      })
  }

export const GetAccountsOfItemsForCompanyThunk =
  (companyId: number, accountId: number) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetAccountsOfItemsForCompanyAttempt())
    return axios
      .post<CompaniesAccounts[]>(`/companies/list_accounts_of_items`, {
        company_id: companyId,
        account_id: accountId,
      })
      .then((response) => {
        dispatch(GetAccountsOfItemsForCompanySuccess(response.data))
      })
      .catch(() => {
        dispatch(GetAccountsOfItemsForCompanyFailure())
      })
  }

export const TransferRuleToAnotherAccountThunk =
  (accountId: number, transfer_to: number, rule_without_document: boolean) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(TransferToAnotherAccountAttempt())
    const url = rule_without_document
      ? `/bankin_accounts/${accountId}/transfer_rules_without_document_to_another_account`
      : `/bankin_accounts/${accountId}/transfer_rules_to_another_account`
    return axios
      .post<CompaniesAccounts[]>(url, {
        transfer_to: transfer_to,
      })
      .then(() => {
        dispatch(TransferToAnotherAccountSuccess())
      })
      .catch(() => {
        dispatch(TransferToAnotherAccountFailure())
      })
  }

export const CreateRuleForOneTransactionThunk =
  (newRule: NewRuleParams, accountId: number, transactionId: number) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(CreateRuleForOneTransactionAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/create_rule_for_one_transaction`, {
        new_rule: newRule,
        transaction_id: transactionId,
      })
      .then(() => {
        dispatch(CreateRuleForOneTransactionSuccess())
        dispatch(getDataToCreateRuleThunk(accountId))
        dispatch(GetTransactionsThunk(accountId))
      })
      .catch(() => {
        dispatch(CreateRuleForOneTransactionFailure())
      })
  }

export const MarkWithoutDocumentThunk =
  (
    accountId: number,
    transactionId: number,
    marked_without_document: boolean
  ) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(MarkWithoutDocumentAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/mark_transaction_without_document`, {
        transaction_id: transactionId,
        marked_without_document: marked_without_document,
      })
      .then(() => {
        dispatch(MarkWithoutDocumentSuccess(marked_without_document))
        dispatch(GetTransactionsThunk(accountId))
      })
      .catch((error) => {
        let message =
          "bank-management.rules-without-document.marked_without_document.error.message"
        if (
          error.response.data.error.includes(
            "error transaction has a document attached"
          )
        ) {
          message += "-full-doc"
        } else if (
          error.response.data.error.includes(
            "error transaction is in a archive"
          )
        ) {
          message += "-archive"
        }
        dispatch(MarkWithoutDocumentFailure(message))
      })
  }

export const GetTransactionEventsThunk =
  (accountId: number, transaction_id: number) =>
  (dispatch: Dispatch<BankActionsType>) => {
    dispatch(GetTransactionEventsAttempt())
    return axios
      .get<TransactionEvent[]>(
        `/bankin_accounts/${accountId}/get_transaction_events`,
        {
          params: { transaction_id: transaction_id },
        }
      )
      .then((response) => {
        dispatch(
          GetTransactionEventsSuccess(response.data, accountId, transaction_id)
        )
      })
      .catch(() => {
        dispatch(GetTranscationsFailure())
      })
  }

export const DropBatchForTransactionThunk =
  (
    accountId: number,
    transactionId: number,
    originalFileName: string,
    originalFileSize: number,
    fingerprint: string,
    file: File,
    selectedCompanyId: number
  ) =>
  (dispatch: Dispatch<AnyAction> & ThunkDispatch<RootState, {}, AnyAction>) => {
    dispatch(DropBatchForTransactionAttempt())

    const config = (extension: string | null) => {
      const headers =
        extension && acceptedBatchExtensions.includes(extension)
          ? {
              headers: {
                "Content-type":
                  extension === "pdf"
                    ? "application/pdf"
                    : ["jpg", "jpeg"].includes(extension)
                    ? "image/jpeg"
                    : ["tiff", "tif"].includes(extension)
                    ? "image/tiff"
                    : "image/png",
              },
            }
          : {}

      return {
        withCredentials: false,
        ...headers,
      }
    }

    axios
      .post<DropDocumentResultPayload>(`/batch_documents`, {
        company_id: selectedCompanyId,
        fingerprint: fingerprint,
        original_file_name: originalFileName,
        original_file_size: originalFileSize,
      })
      .then(({ data }) => {
        let id = data.obj_id
        signRequest(id).then((res) => {
          axios
            .put(
              res.data.put_url,
              file,
              config(fileExtensionOfString(originalFileName))
            )
            .then(() => {
              axios.put(`/batch_documents/trigger_split`, {
                id: id,
              })
            })
            .then(() => {
              dispatch(
                AttachBatchOnTransactionThunk(
                  accountId,
                  transactionId,
                  id,
                  selectedCompanyId,
                  "drop"
                )
              )
            })
            .then(() => {
              setTimeout(() => {
                dispatch(
                  GetDocumentsToAttachToTransactionsThunk(
                    accountId,
                    selectedCompanyId,
                    transactionId,
                    true,
                    true
                  )
                )
                dispatch(
                  DropBatchForTransactionSuccess(accountId, transactionId, id)
                )
              }, 1000)
            })
        })
      })
      .catch(() => {
        dispatch(DropBatchForTransactionFailure())
      })
  }

export const AttachBatchOnTransactionThunk =
  (
    accountId: number,
    transactionId: number,
    batch_document_id: number,
    companyId: number,
    from: "drop" | "attachOriginal"
  ) =>
  (dispatch: Dispatch<BankActionsType | RNBThunkAction>) => {
    dispatch(AttachBatchOnTransactionAttempt())
    return axios
      .post(`/bankin_accounts/${accountId}/add_batch_document_on_transaction`, {
        transaction_id: transactionId,
        batch_document_id: batch_document_id,
      })
      .then(() => {
        dispatch(AttachBatchOnTransactionSuccess())
        if (from === "attachOriginal") {
          dispatch(GetTransactionsThunk(accountId))
          dispatch(
            GetDocumentsToAttachToTransactionsThunk(
              accountId,
              companyId,
              transactionId,
              true,
              true
            )
          )
        }
      })
      .catch(() => {
        dispatch(AttachBatchOnTransactionFailure())
      })
  }

export type BankLineType = "in" | "out"
export type CompareAmountType = "greater" | "lesser" | "equal" | null
export type PrefixType = "401" | "411" | "6" | "7"

export interface NewRuleParams {
  account_id: number | null
  amount?: number | null
  bank_line_type?: BankLineType
  compare_amount?: CompareAmountType
  merchant_code_id: number | null
  prefix: PrefixType | null
  priority_number?: number | null
  text_in_description: string | null
  vat_rate_id: number | null
  id?: number
}

export interface ItemsResource {
  id: number
  bankin_user_id: number
  bankin_bank_id: number
  bankin_id: number
  created_at: Date
  updated_at: Date
  status: number
  deactivated_at: string | null
  bank_name: string
}

interface ItemsResponse {
  bankin_data: ItemsResource[]
}

export interface AccountsResponse {
  list_accounts: AccountsResource[]
}

export interface AccountsResource {
  id: number
  bankin_item_id: number
  created_at: Date
  updated_at: Date
  name: string
  bankin_id: number
  first_import_date: Date | null
  bankin_journal_code: null | string
  bankin_financial_account: null | string
  bankin_suspense_account: null | string
  sync_in_progress: boolean
  deactivated_at: null | string
  rules?: AccountRules[]
  rules_without_document?: AccountRulesWithoutDocument[]
  transactions: Transaction[]
}

interface RedirectedUrl {
  redirected_url: string
}

interface RedirectedUrlObject {
  redirect_url: { redirect_url: string }
}

interface updateAccountParams {
  fiduciaryId: number
  companyId: number | null
  itemId: number | null
  accountId: number | null
  journalCode: number
  financialAccount: number
  suspenseAccount: number
}

interface deactivateItemParams {
  fiduciaryId: number
  companyId: number | null
  itemId: number | null
}

interface deactivateAccountParams {
  fiduciaryId: number
  companyId: number | null
  itemId: number | null
  accountId: number | null
}

interface updateAccountsNameParams {
  fiduciaryId: number
  companyId: number | null
  itemId: number | null
  accountId: number
  name: string
}

export interface AccountRules {
  bankin_account_id: number
  priority_number: number
  bank_line_type: BankLineType
  amount: number | null
  compare_amount: CompareAmountType
  text_in_description: string | null
  prefix: PrefixType
  value_account: string
  company_prefix: string
  third_party: string | null
  rate: string | null
  value_account_id: number | null
  id: number
  events: RuleEvent[]
  transaction_id: number | null
  rule_without_document: boolean
}

export interface AccountRulesWithoutDocument {
  bankin_account_id: number
  bank_line_type: BankLineType
  amount: number | null
  compare_amount: CompareAmountType
  text_in_description: string | null
  id: number
  events: RuleEvent[]
  transaction_id: number | null
  rule_without_document: boolean
  priority_number: null
}

export interface DataToCreateRule {
  merchant_codes: {
    id: number
    code: string
    auxiliary: boolean
  }[]
  accounts: AccountToCreateRule[]
  vat_rates: {
    id: number
    rate: number
  }[]
  company_data: {
    buy_auxiliary_prefix: string | null
    sell_auxiliary_prefix: string | null
    buy_third_party_account: string | null
    sell_third_party_account: string | null
  } | null
  rules: AccountRules[]
  rules_without_document: AccountRulesWithoutDocument[]
}

export interface AccountToCreateRule {
  id: number
  number: string
  details: string
}

export interface RuleEvent {
  email: string
  event_type: "create_rule" | "edit_rule"
  metadata: { [index: string]: string }
  created_at: string
}

export interface TransactionEvent {
  email: string
  event_type:
    | "marked_without_document"
    | "remove_marked_without_document"
    | "attach_document"
    | "detach_document"
    | "create_uniq_rule"
  metadata: { [index: string]: string }
  created_at: string
}

export interface Transaction {
  transaction_id: number
  date: string
  description: string
  possible_rules?: number[]
  lines: Line[]
  rule_id?: number | null
  full_document_id: number | null
  document_type?: "invoice" | "credit_note" | null
  amount_type: "positive" | "negative"
  fd_deactivated: boolean
  marked_without_document: boolean
  events: TransactionEvent[]
  rule_without_document_id: number | null
  batch_document_id: number | null
  need_action: boolean
  amount: number
}

export interface Line {
  amount: number
  vat_rate?: number | null
  assignment?: string
  auxiliary_assignment?: string
  direction?: "debit" | "credit"
}

interface CompaniesAccounts {
  bank_account_id: number
  bank_name: string
  account_name: string
}
