import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import * as Ct from "ldlj"
import { useEffect, useState } from "react"
import {
  VatAccountPayload,
  createAccountThunk,
} from "../../store/ducks/companySettings.ducks"
import { ReactComponent as CloseCrossSVG } from "../../assets/close-cross.svg"
import { isValidVatAccount } from "../../utils/accounts"

import styled from "styled-components/macro"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../store/rootReducer"

interface CreateVatAccountModalProps extends Ct.ModalComponentsProps {
  vatAccountToCreate: VatAccountPayload
  vatRateToCreate: string | null
  accountNumberLimit: number | null
}

export const CreateVatAccountModal = ({
  isDisplayed,
  onClose,
  vatAccountToCreate,
  vatRateToCreate,
  accountNumberLimit,
}: CreateVatAccountModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const createdAccount = useRNBSelector(
    (state) => state.companySettings.createdAccount
  )

  const cleanupAndClose = () => {
    setNumber("")
    setDetails("")
    onClose()
  }

  useEffect(() => {
    if (createdAccount) {
      cleanupAndClose()
    }
  }, [createdAccount])

  const [number, setNumber] = useState("")
  const [details, setDetails] = useState("")

  const buyOrSell = vatAccountToCreate.buy_or_sell
  const rateWithPercent = vatRateToCreate ? `, ${vatRateToCreate}%` : ""

  const buyOrSellText = intl.formatMessage({ id: `buy-or-sell.${buyOrSell}` })

  const titleText = intl.formatMessage(
    { id: `office-company-settings.vat-modal.title` },
    {
      buyOrSell: buyOrSellText,
      rateWithPercent: rateWithPercent,
    }
  )

  const accountNumberIsValid = isValidVatAccount(number, buyOrSell)
  const isCreationButtonDisabled = !accountNumberIsValid || details.length === 0

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        cleanupAndClose()
      }}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      {isDisplayed && (
        <Ct.Card>
          <CloseCrossWrapper
            onClick={() => {
              cleanupAndClose()
            }}
          >
            <CloseCrossSVG />
          </CloseCrossWrapper>

          <Ct.Title size={3} text={titleText} />
          <Ct.Spacer height={4} />

          <Ct.Row>
            <Ct.Input
              name="number"
              label={intl.formatMessage({
                id: "office-company-settings.vat-modal.number",
              })}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value)
              }}
              maxLength={accountNumberLimit || undefined}
            />
            <Ct.Spacer />
            <Ct.Input
              name="details"
              label={intl.formatMessage({
                id: "office-company-settings.vat-modal.details",
              })}
              type="text"
              value={details}
              onChange={(e) => {
                setDetails(e.target.value)
              }}
            />
          </Ct.Row>
          {!accountNumberIsValid && number.length > 3 && (
            <>
              <Ct.Spacer />
              <Ct.Text
                text={intl.formatMessage({
                  id: "office-company-settings.vat-modal.error",
                })}
                textStyle={{ color: "radicalRed" }}
              />
            </>
          )}
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: `office-company-settings.vat-modal.cta`,
            })}
            onClick={() => {
              dispatch(
                createAccountThunk({
                  companyId: selectedCompanyId,
                  number,
                  details,
                  buyOrSell,
                  vatRateId: vatAccountToCreate.vat_rate_id,
                  goodsOrServices: vatAccountToCreate.goods_or_services
                })
              )
            }}
            disabled={isCreationButtonDisabled}
          />
        </Ct.Card>
      )}
    </Ct.Modal>
  )
}

const CloseCrossWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
