/* eslint-disable camelcase */
import styled from "styled-components/macro"
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { useRNBSelector } from "../../store/rootReducer"
import { Modal } from "../../components/Commons/Modal"
import { colors } from "../../styles/design.config"
import { useEffect, useState } from "react"
import { resendOneTimePasswordThunk } from "../../store/ducks/login.ducks"
import { Input } from "../../components/Commons/Input"
import { Button } from "../../components/Commons/Button"
import {
  configureTwoFactorAuthenticationResetAction,
  configureTwoFactorAuthenticationThunk,
} from "../../store/ducks/user.ducks"
import { LockableInput } from "../../components/Commons/LockableInput"

interface ConfigureOTP_ModalProps extends Ct.ModalComponentsProps {
  action: "activate_OTP" | "deactivate_OTP"
}

export const ConfigureOTP_Modal = ({
  onClose,
  isDisplayed,
  action,
}: ConfigureOTP_ModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [oneTimePassword, setOneTimePassword] = useState("")

  const { email, configureTwoFactorAuthenticationStatus } = useRNBSelector(
    (state) => ({
      email: state.user.email,
      configureTwoFactorAuthenticationStatus:
        state.user.configureTwoFactorAuthenticationStatus,
    })
  )
  useEffect(() => {
    if (isDisplayed) {
      dispatch(resendOneTimePasswordThunk(email))
    }
  }, [isDisplayed])

  useEffect(() => {
    if (configureTwoFactorAuthenticationStatus === "SUCCESS") {
      dispatch(configureTwoFactorAuthenticationResetAction())
      onClose()
    }
  }, [configureTwoFactorAuthenticationStatus])

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="10%"
      right="10%"
      height={"40rem"}
    >
      <StyledCard width={"100%"} height={"100%"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Text
          text={intl.formatMessage({
            id: `security.2FA.modal.${action}.title`,
          })}
          textStyle={{
            fontSize: 3,
            fontWeight: 600,
          }}
        />
        <Ct.Spacer height={2} />
        <Line />
        <Ct.Spacer height={4} />
        <Ct.Text
          text={intl.formatMessage({
            id: `security.2FA.modal.${action}.explanation`,
          })}
          textStyle={{
            fontSize: 1.75,
          }}
        />
        <Ct.Spacer height={2} />

        <Row>
          <LockableInput
            label={intl.formatMessage({ id: "login.email" })}
            type="text"
            value={email}
            locked={true}
            maxWidth="32rem"
          />
          <Ct.Spacer height={2} />
          <Input
            label={intl.formatMessage({ id: "login.otp.code" })}
            type="text"
            value={oneTimePassword}
            showError={configureTwoFactorAuthenticationStatus === "ERROR"}
            onChange={(e) => {
              setOneTimePassword(e.target.value)
            }}
            maxWidth="32rem"
          />
        </Row>
        <Ct.Spacer height={6} />
        <Row>
          <Button
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              border: "mist",
              color: "cornflower",
            }}
            label={intl.formatMessage({
              id: `security.2FA.modal.back`,
            })}
            onClick={() => {
              onClose()
            }}
          />
          <Ct.Spacer height={2} />

          <Button
            label={intl.formatMessage({
              id: `security.2FA.modal.save`,
            })}
            disabled={oneTimePassword.length !== 6}
            onClick={() => {
              const activateTwoFactorAuthentication = action === "activate_OTP"
              dispatch(
                configureTwoFactorAuthenticationThunk(
                  activateTwoFactorAuthentication,
                  oneTimePassword
                )
              )
            }}
          />
        </Row>
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
  padding: 4rem 0;
`

const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
