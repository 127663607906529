export interface AccountingKeyWord {
  key_words: KeyWord[]
  events: UserAccountingKeyWordEvent[]
}

export interface KeyWord {
  id: number
  key_word: string
  buy_or_sell: string
  account_root: number
  company_id: number | null
  merchant_id: number | null
}

export interface UserAccountingKeyWordEvent {
  email: string
  created_at: string
  event_type: "create" | "edit" | "delete"
  metadata: { [index: string]: string }
  key_word: string
}

export interface CreateKeyWord {
  id: number | null
  buy_or_sell: { value: string; label: string }
  key_word: string
  account_root: number | null
  create_or_update: "create" | "update"
}

export const displayKeyWordEvent = (metadata: { [index: string]: string }) => {
  let val = ""

  for (let i = 0; i < Object.keys(metadata).length; i++) {
    const metadataKey = Object.keys(metadata)[i]
    const metadataValue = Object.values(metadata)[i]

    if (metadataKey === "key_word") {
      val = val + (val.length > 0 ? "," : "") + " mot clé: " + metadataValue
    }

    if (metadataKey === "account_root") {
      val = val + (val.length > 0 ? "," : "") + " racine: " + metadataValue
    }

    if (metadataKey === "buy_or_sell") {
      val =
        val + (val.length > 0 ? "," : "") + " achats/ventes: " + metadataValue
    }
  }

  return val
}

export const checkIfRowAlreadyExists = (
  row: CreateKeyWord,
  index: number,
  keyWords: CreateKeyWord[]
) => {
  const exists = keyWords
    .slice(0, -1)
    .filter((_, id) => id !== index)
    .some(
      (kw) =>
        kw.key_word === row.key_word &&
        kw.account_root === row.account_root &&
        kw.buy_or_sell.value === row.buy_or_sell.value
    )
  return exists ? index : null
}

export const hasKeyWordDuplicates = (keyWordsToDisplay: CreateKeyWord[]) => {
  return keyWordsToDisplay.some(
    (kw, index) =>
      keyWordsToDisplay.find(
        (word, id) =>
          kw.key_word === word.key_word &&
          kw.account_root === word.account_root &&
          kw.buy_or_sell.value === word.buy_or_sell.value &&
          id !== index
      ) !== undefined
  )
}

export const arraysHaveChanged = (
  arrDisplay: CreateKeyWord[],
  arrExisting: CreateKeyWord[]
): boolean => {
  const someValuesNull = arrDisplay.some(
    (d) =>
      d.key_word === "" || d.account_root === null || d.buy_or_sell.value === ""
  )
  if (someValuesNull) {
    return false
  }

  if (arrDisplay.length !== arrExisting.length) {
    return true
  }

  return arrDisplay.some(
    (value, index) =>
      JSON.stringify(value) !== JSON.stringify(arrExisting[index])
  )
}

export const getChangedRows = (
  arrDisplay: CreateKeyWord[],
  arrExisting: CreateKeyWord[]
): CreateKeyWord[] => {
  const existingMap = new Map(arrExisting.map((item) => [item.id, item]))

  return arrDisplay.filter((item) => {
    const existingItem = existingMap.get(item.id)
    return existingItem
      ? JSON.stringify(item) !== JSON.stringify(existingItem)
      : true
  })
}
