import * as Ct from "ldlj"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { buyOrSell, getIdFromParams } from "../../utils/company"
import {
  VatAccountPayload,
  getVatAccountSettingsThunk,
  VatAssignMode,
  createAccountResetAction,
  getVatAccountsHistoryResetAction,
  goodsOrServices,
} from "../../store/ducks/companySettings.ducks"
import { TabObject, TabsWithContent } from "../TabsWithContent"
import { CreateVatAccountModal } from "./CreateVatAccountModal"
import { useRNBSelector } from "../../store/rootReducer"
import { colors } from "../../styles/design.config"
import { Strip } from "../../components/Strip"
import { AccountStripSelectCreateHistory } from "../../components/AccountStripSelectCreateHistory"
import { VatAccountHistoryModal } from "./VatAccountsHistoryModal"

/* eslint-disable camelcase */
interface VatAccountsState {
  unique: VatAccountPayload[]
  per_rate: VatAccountPayload[]
}

interface VatAccountProps {
  onAccountsUpdated: ({
    disabled,
    accounts,
  }: {
    disabled: boolean
    accounts: VatAccountPayload[]
    tabSelected: VatAssignMode
  }) => void
}
export const VatAccounts = ({ onAccountsUpdated }: VatAccountProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    createdAccount,
    rates,
    possibleBuyAccounts,
    possibleSellAccounts,
    selectedAccounts,
    assignMode,
    accountNumberLimit,
    construction_vat,
    construction_reverse_vat,
    eu_vat,
    eu_reverse_vat,
    eu_goods_vat,
    eu_goods_reverse_vat,
    world_vat,
    world_reverse_vat,
    world_goods_vat,
    world_goods_reverse_vat,
  } = useRNBSelector((state) => state.companySettings)

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  useEffect(() => {
    dispatch(getVatAccountSettingsThunk({ companyId: selectedCompanyId }))
  }, [dispatch, selectedCompanyId])

  const [accounts, setAccounts] = useState<VatAccountsState>({
    unique: [],
    per_rate: [],
  })

  const [initialAccounts, setInitialAccounts] = useState<VatAccountsState>({
    unique: [],
    per_rate: [],
  })

  const uniqueAccountNumber = 4
  const perRateAccountNumber = rates.length * 4

  const selectedAccountsArray = [
    eu_reverse_vat?.selected_account?.id,
    eu_vat?.selected_account?.id,
    eu_goods_reverse_vat?.selected_account?.id,
    eu_goods_vat?.selected_account?.id,
    world_reverse_vat?.selected_account?.id,
    world_vat?.selected_account?.id,
    world_goods_reverse_vat?.selected_account?.id,
    world_goods_vat?.selected_account?.id,
    construction_vat?.selected_account?.id,
    construction_reverse_vat?.selected_account?.id,
  ]

  const sellOptions = possibleSellAccounts
    .filter((account) => !selectedAccountsArray.includes(account.id))
    .map((account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    }))

  const buyOptions = possibleBuyAccounts
    .filter((account) => !selectedAccountsArray.includes(account.id))
    .map((account) => ({
      value: account.id.toString(),
      label: `${account.number} ${account.details}`,
    }))

  const [isCreateModalDisplayed, setIsCreateModalDisplayed] = useState(false)
  const [vatAccountToCreate, setVatAccountToCreate] =
    useState<VatAccountPayload | null>(null)
  const [vatRateToCreate, setVatRateToCreate] = useState<string | null>(null)

  const [tabSelected, setTabSelected] = useState<VatAssignMode>("no_vat")
  const [initialAssignMode, setInitialAssignMode] =
    useState<VatAssignMode>("no_vat")

  const Tabs: TabObject[] = [
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.no-vat",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "no_vat",
      onClick: () => {
        setTabSelected("no_vat")
      },
    },
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.unique",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "unique",
      onClick: () => {
        setTabSelected("unique")
      },
    },
    {
      title: intl.formatMessage({
        id: "office-company-settings.vat-accounts.tabs.per-rate",
      }),
      iconIdle: null,
      iconSelected: null,
      isDisplayed: true,
      isSelected: tabSelected === "per_rate",
      onClick: () => {
        setTabSelected("per_rate")
      },
    },
  ]

  useEffect(() => {
    setTabSelected(assignMode)
    setInitialAssignMode(assignMode)
  }, [assignMode])

  useEffect(() => {
    if (createdAccount) {
      const type = createdAccount.vatRateId === null ? "unique" : "per_rate"

      setAccounts((prev) => {
        const index = prev[type].findIndex(
          (item) =>
            item.buy_or_sell === createdAccount.buyOrSell &&
            item.goods_or_services === createdAccount.goodsOrServices &&
            (!createdAccount.vatRateId ||
              createdAccount.vatRateId === item.vat_rate_id)
        )

        const updatedItems =
          index !== -1
            ? prev[type].map((item, i) =>
                i === index ? { ...item, account_id: createdAccount.id } : item
              )
            : [
                ...prev[type],
                {
                  buy_or_sell: createdAccount.buyOrSell,
                  goods_or_services: createdAccount.goodsOrServices,
                  vat_rate_id: createdAccount.vatRateId,
                  account_id: createdAccount.id,
                  vat_accounting: type,
                  company_id: selectedCompanyId,
                },
              ]

        return { ...prev, [type]: updatedItems }
      })

      dispatch(createAccountResetAction())
    }
  }, [createdAccount])

  useEffect(() => {
    if (selectedAccounts) {
      const uniqueAccounts: VatAccountPayload[] = selectedAccounts.reduce(
        (acc: VatAccountPayload[], curr) => {
          if (curr.vatAccounting === "unique" && curr.goodsOrServices) {
            acc.push({
              company_id: selectedCompanyId,
              account_id: Number(curr.accountId),
              vat_rate_id: null,
              buy_or_sell: curr.buyOrSell,
              vat_accounting: "unique",
              goods_or_services: curr.goodsOrServices,
            })
          }
          return acc
        },
        []
      )

      const perRateAccounts: VatAccountPayload[] = selectedAccounts.reduce(
        (acc: VatAccountPayload[], curr) => {
          if (
            curr.vatAccounting === "per_rate" &&
            curr.vatRateId &&
            curr.goodsOrServices
          ) {
            acc.push({
              company_id: selectedCompanyId,
              account_id: Number(curr.accountId),
              vat_rate_id: curr.vatRateId,
              buy_or_sell: curr.buyOrSell,
              vat_accounting: "per_rate",
              goods_or_services: curr.goodsOrServices,
            })
          }
          return acc
        },
        []
      )

      setInitialAccounts({
        unique: [...uniqueAccounts],
        per_rate: [...perRateAccounts],
      })

      setAccounts({
        unique: uniqueAccounts,
        per_rate: perRateAccounts,
      })
    }
  }, [selectedAccounts])

  useEffect(() => {
    const accountsChanged =
      JSON.stringify(accounts) !== JSON.stringify(initialAccounts)
    const tabChanged = tabSelected !== initialAssignMode

    const isUniqueUpdateDisabled = !(
      accounts.unique.length === uniqueAccountNumber &&
      Boolean(accounts.unique.every((acc) => acc.account_id))
    )
    const isPerRateUpdateDisabled = !(
      accounts.per_rate.length === perRateAccountNumber &&
      Boolean(accounts.per_rate.every((acc) => acc.account_id))
    )

    const disabled =
      (tabSelected === "per_rate" && isPerRateUpdateDisabled) ||
      (tabSelected === "unique" && isUniqueUpdateDisabled) ||
      (!accountsChanged && !tabChanged)

    const accountsToUpdate: VatAccountPayload[] =
      tabSelected === "no_vat"
        ? []
        : tabSelected === "unique"
        ? [
            ...accounts.unique.map((item) => ({
              ...item,
              vat_accounting: "unique" as "unique",
            })),
          ]
        : tabSelected === "per_rate"
        ? [
            ...accounts.per_rate.map((item) => ({
              ...item,
              vat_accounting: "per_rate" as "per_rate",
            })),
          ]
        : []

    onAccountsUpdated({
      accounts: accountsToUpdate,
      disabled,
      tabSelected,
    })
  }, [accounts, tabSelected, initialAssignMode, initialAccounts])

  const [toggles, setToggles] = useState<
    { index_name: string; toggle: boolean }[]
  >([
    { index_name: "unique_buy", toggle: false },
    { index_name: "unique_sell", toggle: false },
    { index_name: "per_rate_buy", toggle: false },
    { index_name: "per_rate_sell", toggle: false },
  ])

  const handleToggle = (index: string) => {
    setToggles((prevToggles) =>
      prevToggles.map((item) =>
        item.index_name === index ? { ...item, toggle: !item.toggle } : item
      )
    )
  }

  const buyOrSell = ["buy", "sell"]
  const goodsOrServices = ["goods", "services"]

  const svgMatch: { [key: string]: string } = {
    buy: "ShoppingTag",
    sell: "Receipt",
  }

  const getAccountData = (
    type: keyof VatAccountsState,
    buyOrSell: string,
    goodsOrServices: string,
    vatRateId: number | null
  ) => {
    const searchOption = buyOrSell === "buy" ? buyOptions : sellOptions
    const account = accounts[type].find(
      (item) =>
        item.buy_or_sell === buyOrSell &&
        item.goods_or_services === goodsOrServices &&
        (!vatRateId || vatRateId === item.vat_rate_id)
    )

    const value = account
      ? searchOption.find((e) => Number(e.value) === account.account_id)
      : null

    return {
      options: searchOption,
      value: value,
      setValue: (optionValue: string) => {
        setAccounts((prev) => {
          const index = prev[type].findIndex(
            (item) =>
              item.buy_or_sell === buyOrSell &&
              item.goods_or_services === goodsOrServices &&
              (!vatRateId || vatRateId === item.vat_rate_id)
          )

          const updatedItems =
            index !== -1
              ? prev[type].map((item, i) =>
                  i === index
                    ? { ...item, account_id: Number(optionValue) }
                    : item
                )
              : [
                  ...prev[type],
                  {
                    buy_or_sell: buyOrSell,
                    goods_or_services: goodsOrServices,
                    vat_rate_id: vatRateId,
                    account_id: Number(optionValue),
                    vat_accounting: type,
                    company_id: selectedCompanyId,
                  },
                ]

          return { ...prev, [type]: updatedItems }
        })
      },
      error: false,
    }
  }

  const [isHistoryModalDisplayed, setIsHistoryModalDisplayed] = useState(false)
  const [historyDisplay, setHistoryDisplay] = useState<{
    isDisplayed: boolean
    buyOrSell: buyOrSell | null
    vatType: "unique" | "per_rate" | null
    rate: string | null
    goodsOrServices: goodsOrServices | null
  }>({
    isDisplayed: false,
    buyOrSell: null,
    vatType: null,
    rate: null,
    goodsOrServices: null,
  })

  return (
    <>
      <CreateVatAccountModal
        isDisplayed={isCreateModalDisplayed && vatAccountToCreate !== null}
        onClose={() => {
          setIsCreateModalDisplayed(false)
          setVatAccountToCreate(null)
          setVatRateToCreate(null)
        }}
        vatAccountToCreate={
          vatAccountToCreate || {
            vat_accounting: "unique",
            buy_or_sell: "buy",
            vat_rate_id: null,
            company_id: selectedCompanyId,
            account_id: null,
            goods_or_services: "goods",
          }
        }
        vatRateToCreate={vatRateToCreate}
        accountNumberLimit={accountNumberLimit}
      />

      <VatAccountHistoryModal
        isDisplayed={isHistoryModalDisplayed}
        onClose={() => {
          setHistoryDisplay({
            isDisplayed: false,
            buyOrSell: null,
            vatType: null,
            rate: "",
            goodsOrServices: null,
          })
          setIsHistoryModalDisplayed(false)
          dispatch(getVatAccountsHistoryResetAction())
        }}
        buyOrSell={historyDisplay.buyOrSell}
        vatType={historyDisplay.vatType}
        rate={historyDisplay.rate}
        goodsOrServices={historyDisplay.goodsOrServices}
      />

      <Container>
        <Ct.Text
          text={intl.formatMessage({
            id: "office-company-settings.vat-accounts-info",
          })}
          textStyle={{ lineHeight: 3 }}
        />
        <Ct.Spacer height={3} />
        <TabsWithContent tabs={Tabs} />
        <Ct.Spacer height={3} />

        {tabSelected === "no_vat" && (
          <BorderedContainer>
            <Ct.Text
              text={intl.formatMessage({
                id: "office-company-settings.vat-modal.no-vat",
              })}
            />
          </BorderedContainer>
        )}
        {tabSelected === "unique" &&
          buyOrSell.map((bs) => (
            <>
              <StyledCard>
                <Strip
                  label={intl.formatMessage({
                    id: `office-company-settings.vat-accounts.${bs}`,
                  })}
                  itemType={{ type: "svg", svgName: svgMatch[bs] }}
                  onSeeMoreAction={() => handleToggle(`unique_${bs}`)}
                />

                {toggles.find((e) => e.index_name === `unique_${bs}`)!.toggle &&
                  goodsOrServices.map((gs) => {
                    const { options, value, setValue, error } = getAccountData(
                      "unique",
                      bs,
                      gs,
                      null
                    )
                    return (
                      <AccountStripSelectCreateHistory
                        key={`${bs}_${gs}`}
                        label={intl.formatMessage(
                          {
                            id: `office-company-settings.vat-accounts.${bs}.${gs}`,
                          },
                          { value: "" }
                        )}
                        value={value || { label: "", value: "" }}
                        options={options}
                        onChangeCallback={(e) => setValue(e.value)}
                        error={error}
                        displayCreate={true}
                        displayHistory={true}
                        onClickCreate={() => {
                          setVatAccountToCreate({
                            vat_accounting: "unique",
                            buy_or_sell: bs as buyOrSell,
                            vat_rate_id: null,
                            company_id: selectedCompanyId,
                            account_id: null,
                            goods_or_services: gs as goodsOrServices,
                          })
                          setIsCreateModalDisplayed(true)
                        }}
                        onClickHistory={() => {
                          setHistoryDisplay({
                            isDisplayed: true,
                            vatType: "unique",
                            buyOrSell: bs as buyOrSell,
                            rate: null,
                            goodsOrServices: gs as goodsOrServices,
                          })
                          setIsHistoryModalDisplayed(true)
                        }}
                      />
                    )
                  })}
              </StyledCard>
              <Ct.Spacer />
            </>
          ))}
        {tabSelected === "per_rate" &&
          buyOrSell.map((bs) => (
            <>
              <StyledCard>
                <Strip
                  label={intl.formatMessage({
                    id: `office-company-settings.vat-accounts.${bs}`,
                  })}
                  itemType={{ type: "svg", svgName: svgMatch[bs] }}
                  onSeeMoreAction={() => handleToggle(`per_rate_${bs}`)}
                />

                {toggles.find((e) => e.index_name === `per_rate_${bs}`)!
                  .toggle &&
                  rates
                    .sort((a, b) => Number(a.rate) - Number(b.rate))
                    .map((vr) =>
                      goodsOrServices.map((gs) => {
                        const { options, value, setValue, error } =
                          getAccountData("per_rate", bs, gs, vr.id)
                        return (
                          <AccountStripSelectCreateHistory
                            key={`${bs}_${gs}_${vr.id}`}
                            label={intl.formatMessage(
                              {
                                id: `office-company-settings.vat-accounts.${bs}.${gs}`,
                              },
                              { value: `${vr.rate}%` }
                            )}
                            value={value || { label: "", value: "" }}
                            options={options}
                            onChangeCallback={(e) => setValue(e.value)}
                            error={error}
                            displayCreate={true}
                            displayHistory={true}
                            onClickCreate={() => {
                              setVatAccountToCreate({
                                vat_accounting: "per_rate",
                                buy_or_sell: bs as buyOrSell,
                                vat_rate_id: vr.id,
                                company_id: selectedCompanyId,
                                account_id: null,
                                goods_or_services: gs as goodsOrServices,
                              })
                              setIsCreateModalDisplayed(true)
                            }}
                            onClickHistory={() => {
                              setHistoryDisplay({
                                isDisplayed: true,
                                vatType: "per_rate",
                                buyOrSell: bs as buyOrSell,
                                rate: vr.rate,
                                goodsOrServices: gs as goodsOrServices,
                              })
                              setIsHistoryModalDisplayed(true)
                            }}
                          />
                        )
                      })
                    )}
              </StyledCard>
              <Ct.Spacer />
            </>
          ))}

        <Ct.Spacer height={1} />
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 0 13rem;
`
const BorderedContainer = styled.div`
  border: 1px solid ${colors.cornflower};
  border-radius: 2.5rem;
  padding: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
`
const StyledCard = styled(Ct.Card)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  padding: 2.5rem;
`
