import * as Ct from "ldlj"
import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import { sizes } from "../../styles/design.config"
import {
  getAutoliquidatedVatAccountHistoryThunk,
  VatType,
} from "../../store/ducks/companySettings.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { getIdFromParams } from "../../utils/company"
import { useParams } from "react-router-dom"
import { sortAccountInstructionsByDate } from "../../utils/accounts"

interface AutoliquidatedHistoryProps extends Ct.ModalComponentsProps {
  vatType: VatType | null
  rate: boolean
}

export const AutoliquidatedHistoryModal = ({
  isDisplayed,
  onClose,
  vatType,
  rate,
}: AutoliquidatedHistoryProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const changesHistory = useRNBSelector((state) =>
    vatType ? state.companySettings[vatType].userAccountInstructions : []
  )

  const possibleAccounts = useRNBSelector((state) =>
    vatType ? state.companySettings[vatType].possible_accounts : []
  )

  useEffect(() => {
    if (!vatType) return
    dispatch(
      getAutoliquidatedVatAccountHistoryThunk({
        companyId: selectedCompanyId,
        vatType: vatType,
        rate: rate,
      })
    )
  }, [vatType])

  const findVatRate = (metadata: { [index: string]: string | null }) => {
    return metadata.previous_vat_rate
      ? ` : ${intl.formatMessage(
          {
            id: "office-company-settings.autoliquidated-history.previously_vat_rate",
          },
          {
            value: `${metadata.previous_vat_rate}`,
          }
        )}`
      : ""
  }

  const findAccounts = (metadata: { [index: string]: string | null }) => {
    for (let i = 0; i < Object.keys(metadata).length; i++) {

      const metadataKey = Object.keys(metadata)[i]
      const metadataValue = Object.values(metadata)[i]

      if (metadataKey === "previous_account") {
        const account = possibleAccounts.find(
          (account) => metadataValue && account.id === Number(metadataValue)
        )

        return account
          ? ` : ${intl.formatMessage(
              {
                id: "office-company-settings.autoliquidated-history.previously",
              },
              {
                value: `${account.number}`,
              }
            )}`
          : ""
      }

      return ""
    }
  }

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(30vh - 25rem)"
    >
      <Ct.Card width={"140rem"}>
        <Ct.CloseCross onClick={onClose} />
        <Ct.Title
          text={intl.formatMessage({
            id: "office-company-settings.autoliquidated-history.title",
          })}
          size={7}
        />

        <Ct.Spacer height={4} />

        <ChangeCardContent>
          <ChangeHeader>
            {vatType && (
              <Ct.Text
                text={intl.formatMessage({
                  id: `office-company-settings.eu-construction-vat-accounts.${vatType}`,
                })}
                textStyle={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                }}
              />
            )}
          </ChangeHeader>

          {changesHistory && changesHistory.length > 0 ? (
            <ChangesList>
              {sortAccountInstructionsByDate(changesHistory).map(
                (instruction) => (
                  <ChangeItem key={instruction.created_at}>
                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: instruction.instruction_type.includes("vat_rate")
                            ? `office-company-settings.autoliquidated-history.${instruction.instruction_type}`
                            : `office-company-settings.autoliquidated-history.${
                                instruction.instruction_type.split("_")[0]
                              }`,
                        },
                        {
                          value: instruction.instruction_type.includes(
                            "vat_rate"
                          )
                            ? `${instruction.metadata.current_vat_rate}`
                            : `${instruction.account_number}`,
                        }
                      )}
                    />
                    <> par </>
                    <Mailto href={`mailto:${instruction.user}`}>
                      {instruction.user}
                    </Mailto>
                    <Ct.Text
                      text={
                        " (" +
                        DateTime.fromJSDate(
                          new Date(instruction.created_at)
                        ).toFormat("dd/MM/yyyy HH:mm:ss") +
                        ")"
                      }
                    />
                    <Ct.Text
                      text={instruction.instruction_type.includes("create_account")
                          ? ""
                          : instruction.instruction_type.includes("vat_rate")
                          ? findVatRate(instruction.metadata)
                          : `${findAccounts(instruction.metadata)}`
                      }
                      textStyle={{
                        fontWeight: 700,
                      }}
                    />
                  </ChangeItem>
                )
              )}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `office-company-settings.autoliquidated-history.no-changes`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({
            id: "office-company-settings.autoliquidated-history.ok",
          })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Ct.Modal>
  )
}
