import { AnyAction } from "redux"
import { Dispatch, RNBThunkAction } from "../store.config"

import axios, { AxiosError } from "axios"
import {
  cleanSIREN,
  SirenCheckResponseType,
  SirenStatus,
} from "../../utils/siren"
import { Indexed } from "../../utils/asyncTools"
import { RootState } from "../rootReducer"
import { CompanyObjectType, getUserCompaniesThunk } from "./companies.ducks"
import {
  DocumentTypeParam,
  draftTypes,
} from "../../routes/office/company/invoicing/InvoiceIssuance"
import { includes } from "../../utils/array"

const enum InvoicingActionsEnum {
  GET_TYPOLOGIES_SUCCESS = "INVOICING/getTypologiesSuccess",
  GET_TYPOLOGIES_FAILURE = "INVOICING/getTypologiesFailure",

  GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_ATTEMPT = "INVOICING/getAllLegalEntitiesForCompanyAttempt",
  GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_SUCCESS = "INVOICING/getAllLegalEntitiesForCompanySuccess",

  CREATE_OR_MODIFY_CLIENT_ATTEMPT = "INVOICING/createOrModifyClientAttempt",
  CREATE_OR_MODIFY_CLIENT_SUCCESS = "INVOICING/createOrModifyClientSuccess",
  CREATE_OR_MODIFY_CLIENT_FAILURE = "INVOICING/createOrModifyClientFailure",
  CREATE_OR_MODIFY_CLIENT_RESET = "INVOICING/createOrModifyClientReset",

  INVOICING_SIREN_SUCCESS = "INVOICING/SIRENSuccess",
  INVOICING_SIREN_HIDDEN = "INVOICING/SIRENHiddenFailure",

  SIREN_RESET_STATUS = "INVOICING/invoicingSirenResetStatus",
  SIREN_FAILURE = "INVOICING/SIRENFailure",
  SIREN_ATTEMPT = "INVOICING/SIRENAttempt",

  GET_LEGAL_ENTITY_FROM_SIREN_SUCCESS = "INVOICING/getLegalEntityFromSirenSuccess",

  GET_PRODUCTS_ATTEMPT = "INVOICING/getProductsAttempt",
  GET_PRODUCTS_SUCCESS = "INVOICING/getProductsSuccess",
  GET_PRODUCTS_FAILURE = "INVOICING/getProductsFailure",

  GET_ADDITIONAL_LOGOS_ATTEMPT = "INVOICING/getAdditionalLogosAttempt",
  GET_ADDITIONAL_LOGOS_SUCCESS = "INVOICING/getAdditionalLogosSuccess",
  GET_ADDITIONAL_LOGOS_FAILURE = "INVOICING/getAdditionalLogosFailure",
  GET_ADDITIONAL_LOGOS_RESET = "INVOICING/getAdditionalLogosReset",

  ADD_ADDITIONAL_LOGO_ATTEMPT = "INVOICING/addAdditionalLogoAttempt",
  ADD_ADDITIONAL_LOGO_SUCCESS = "INVOICING/addAdditionalLogoSuccess",
  ADD_ADDITIONAL_LOGO_FAILURE = "INVOICING/addAdditionalLogoFailure",
  ADD_ADDITIONAL_LOGO_RESET = "INVOICING/addAdditionalLogoReset",

  DELETE_ADDITIONAL_LOGO_ATTEMPT = "INVOICING/deleteAdditionalLogoAttempt",
  DELETE_ADDITIONAL_LOGO_SUCCESS = "INVOICING/deleteAdditionalLogoSuccess",
  DELETE_ADDITIONAL_LOGO_FAILURE = "INVOICING/deleteAdditionalLogoFailure",
  DELETE_ADDITIONAL_LOGO_RESET = "INVOICING/deleteAdditionalLogoReset",

  UPDATE_ADDITIONAL_LOGO_ATTEMPT = "INVOICING/updateAdditionalLogoAttempt",
  UPDATE_ADDITIONAL_LOGO_SUCCESS = "INVOICING/updateAdditionalLogoSuccess",
  UPDATE_ADDITIONAL_LOGO_FAILURE = "INVOICING/updateAdditionalLogoFailure",
  UPDATE_ADDITIONAL_LOGO_RESET = "INVOICING/updateAdditionalLogoReset",

  HAS_DUPLICATE_ATTEMPT = "INVOICING/hasDuplicateAttempt",
  HAS_DUPLICATE_SUCCESS = "INVOICING/hasDuplicateSuccess",
  HAS_DUPLICATE_FAILURE = "INVOICING/hasDuplicateFailure",
  HAS_DUPLICATE_RESET = "INVOICING/hasDuplicateReset",

  ADD_PRODUCT_ATTEMPT = "INVOICING/addProductAttempt",
  ADD_PRODUCT_SUCCESS = "INVOICING/addProductSuccess",
  ADD_PRODUCT_FAILURE = "INVOICING/addProductFailure",
  ADD_PRODUCT_RESET = "INVOICING/addProductReset",

  ADD_PRODUCTS_ATTEMPT = "INVOICING/addProductsAttempt",
  ADD_PRODUCTS_SUCCESS = "INVOICING/addProductsSuccess",
  ADD_PRODUCTS_FAILURE = "INVOICING/addProductsFailure",
  ADD_PRODUCTS_RESET = "INVOICING/addProductsReset",
  ADD_PRODUCTS_TOO_MANY_LINES = "INVOICING/addProductsTooManyLines",

  MODIFY_PRODUCT_ATTEMPT = "INVOICING/modifyProductAttempt",
  MODIFY_PRODUCT_SUCCESS = "INVOICING/modifyProductSuccess",
  MODIFY_PRODUCT_FAILURE = "INVOICING/modifyProductFailure",
  MODIFY_PRODUCT_RESET = "INVOICING/modifyProductReset",

  GET_UNITS_ATTEMPT = "INVOICING/getUnitsAttempt",
  GET_UNITS_SUCCESS = "INVOICING/getUnitsSuccess",
  GET_UNITS_FAILURE = "INVOICING/getUnitsFailure",

  DELETE_PRODUCT_ATTEMPT = "INVOICING/deleteProductAttempt",
  DELETE_PRODUCT_SUCCESS = "INVOICING/deleteProductSuccess",
  DELETE_PRODUCT_FAILURE = "INVOICING/deleteProductFailure",
  DELETE_PRODUCT_RESET = "INVOICING/deleteProductReset",

  CREATE_INVOICE_ATTEMPT = "INVOICING/createInvoiceAttempt",
  CREATE_INVOICE_SUCCESS = "INVOICING/createInvoiceSuccess",
  CREATE_INVOICE_FAILURE = "INVOICING/createInvoiceFailure",
  RESET_BATCH_DOCUMENT_ID = "INVOICING/resetBatchDocumentId",

  PREVIEW_INVOICE_ATTEMPT = "INVOICING/previewInvoiceAttempt",
  PREVIEW_INVOICE_SUCCESS = "INVOICING/previewInvoiceSuccess",
  PREVIEW_INVOICE_FAILURE = "INVOICING/previewInvoiceFailure",
  RESET_PREVIEW_URL = "INVOICING/resetPreviewUrl",

  DRAFT_INVOICE_ATTEMPT = "INVOICING/draftInvoiceAttempt",
  DRAFT_INVOICE_SUCCESS = "INVOICING/draftInvoiceSuccess",
  DRAFT_INVOICE_FAILURE = "INVOICING/draftInvoiceFailure",
  DRAFT_INVOICE_RESET = "INVOICING/draftInvoiceReset",

  GET_URL_FAILURE = "INVOICING/getUrlFailureAction",

  GET_ALL_INVOICES_ATTEMPT = "INVOICING/getAllInvoicesAttempt",
  GET_ALL_INVOICES_SUCCESS = "INVOICING/getAllInvoicesSuccess",
  GET_ALL_INVOICES_FAILURE = "INVOICING/getAllInvoicesFailure",

  GET_DRAFT_INVOICES_ATTEMPT = "INVOICING/getDraftInvoicesAttempt",
  GET_DRAFT_INVOICES_SUCCESS = "INVOICING/getDraftInvoicesSuccess",
  GET_DRAFT_INVOICES_FAILURE = "INVOICING/getDraftInvoicesFailure",

  DELETE_DRAFT_INVOICE_ATTEMPT = "INVOICING/deleteDraftInvoicesAttempt",
  DELETE_DRAFT_INVOICE_SUCCESS = "INVOICING/deleteDraftInvoicesSuccess",
  DELETE_DRAFT_INVOICE_FAILURE = "INVOICING/deleteDraftInvoicesFailure",

  GET_INVOICING_DOCUMENT_HISTORY_ATTEMPT = "INVOICING/getInvoicingDocumentHistoryAttempt",
  GET_INVOICING_DOCUMENT_HISTORY_SUCCESS = "INVOICING/getInvoicingDocumentHistorySuccess",
  GET_INVOICING_DOCUMENT_HISTORY_FAILURE = "INVOICING/getInvoicingDocumentHistoryFailure",

  GET_CUSTOMER_HISTORY_ATTEMPT = "INVOICING/getCustmerHistoryAttempt",
  GET_CUSTOMER_HISTORY_SUCCESS = "INVOICING/getCustmerHistorySuccess",
  GET_CUSTOMER_HISTORY_FAILURE = "INVOICING/getCustmerHistoryFailure",

  SEND_REMINDER_ATTEMPT = "INVOICING/sendReminderAttempt",
  SEND_REMINDER_SUCCESS = "INVOICING/sendReminderSuccess",
  SEND_REMINDER_FAILURE = "INVOICING/sendReminderFailure",
  SEND_REMINDER_RESET = "INVOICING/sendReminderReset",

  GET_INVOICE_URL_ATTEMPT = "INVOICING/getInvoiceUrlAttempt",
  GET_INVOICE_URL_SUCCESS = "INVOICING/getInvoiceUrlSuccess",
  GET_INVOICE_URL_FAILURE = "INVOICING/getInvoiceUrlFailure",
  GET_INVOICE_FOR_OVERTAKING_ATTEMPT = "INVOICING/getInvoiceForOvertakingAttempt",
  GET_INVOICE_FOR_OVERTAKING_SUCCESS = "INVOICING/getInvoiceForOvertakingSuccess",
  GET_INVOICE_FOR_OVERTAKING_FAILURE = "INVOICING/getInvoiceForOvertakingFailure",

  OVERTAKING_RESET_PRODUCT_CLIENT = "INVOICING/overtakingResetClientAndProducts",

  URL_FAILURE = "INVOICING/urlFailure",
}

export const GetTypologiesSuccess = (payload: Typologies) =>
  ({
    type: InvoicingActionsEnum.GET_TYPOLOGIES_SUCCESS,
    payload,
  } as const)
export const GetTypologiesFailure = (error: AxiosError) =>
  ({
    type: InvoicingActionsEnum.GET_TYPOLOGIES_FAILURE,
    error,
  } as const)
export const getAllLegalEntitiesForCompanyAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_ATTEMPT,
  } as const)
export const getAllLegalEntitiesForCompanySuccess = (payload: {
  legal_entities: LegalEntityInfo[]
  legal_entities_without_infos: LegalEntityWithoutInfos[]
}) =>
  ({
    type: InvoicingActionsEnum.GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_SUCCESS,
    payload,
  } as const)
export const CreateOrModifyClientAttempt = () =>
  ({
    type: InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_ATTEMPT,
  } as const)
export const CreateOrModifyClientReset = () =>
  ({
    type: InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_RESET,
  } as const)
export const CreateOrModifyClientSuccess = (
  createOrModify: string,
  legalEntityId?: number
) =>
  ({
    legalEntityId,
    type: InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `invoicing.settings.create-client.form.create.success.title`,
      bodyKey: `invoicing.settings.${createOrModify}-client.form.create.success.body`,
    },
  } as const)
export const CreateOrModifyClientFailure = (createOrModify: string) =>
  ({
    type: InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "invoicing.settings.create-client.form.create.error.title",
      bodyKey: `invoicing.settings.${createOrModify}-client.form.create.error.body`,
    },
  } as const)
export const invoicingSirenResetStatus = () =>
  ({ type: InvoicingActionsEnum.SIREN_RESET_STATUS } as const)
export const invoicingSirenSuccessAction = (company: CompanyObjectType) =>
  ({
    type: InvoicingActionsEnum.INVOICING_SIREN_SUCCESS,
    payload: { company },
  } as const)
const invoicingSirenHiddenAction = () =>
  ({ type: InvoicingActionsEnum.INVOICING_SIREN_HIDDEN } as const)
export const invoicingSirenFailureAction = () =>
  ({ type: InvoicingActionsEnum.SIREN_FAILURE } as const)
export const invoicingSirenAttemptAction = () =>
  ({ type: InvoicingActionsEnum.SIREN_ATTEMPT } as const)
export const getLegalEntityFromSirenSuccess = (legalEntityId: number) =>
  ({
    type: InvoicingActionsEnum.GET_LEGAL_ENTITY_FROM_SIREN_SUCCESS,
    legalEntityId,
  } as const)
export const getProductsAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_PRODUCTS_ATTEMPT,
  } as const)
export const getProductsSuccess = (payload: Array<ProductFormatted>) =>
  ({
    type: InvoicingActionsEnum.GET_PRODUCTS_SUCCESS,
    payload,
  } as const)
export const getProductsFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_PRODUCTS_FAILURE,
  } as const)
export const getAdditionalLogosAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_ATTEMPT,
  } as const)
export const getAdditionalLogosSuccess = (payload: AdditionalLogo[]) =>
  ({
    type: InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_SUCCESS,
    payload,
  } as const)
export const getAdditionalLogosFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_FAILURE,
  } as const)
export const getAdditionalLogosReset = () =>
  ({
    type: InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_RESET,
  } as const)
export const addAdditionalLogoAttempt = () =>
  ({
    type: InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_ATTEMPT,
  } as const)
export const addAdditionalLogoSuccess = () =>
  ({
    type: InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.add-additional-logo.success.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.add-additional-logo.success.body",
    },
  } as const)
export const addAdditionalLogoFailure = () =>
  ({
    type: InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.add-additional-logo.error.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.add-additional-logo.error.body",
    },
  } as const)
export const addAdditionalLogoReset = () =>
  ({
    type: InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_RESET,
  } as const)
export const deleteAdditionalLogoAttempt = () =>
  ({
    type: InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_ATTEMPT,
  } as const)
export const deleteAdditionalLogoSuccess = () =>
  ({
    type: InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.delete-additional-logo.success.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.delete-additional-logo.success.body",
    },
  } as const)
export const deleteAdditionalLogoFailure = () =>
  ({
    type: InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.delete-additional-logo.error.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.delete-additional-logo.error.body",
    },
  } as const)
export const deleteAdditionalLogoReset = () =>
  ({
    type: InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_RESET,
  } as const)
export const updateAdditionalLogoAttempt = () =>
  ({
    type: InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_ATTEMPT,
  } as const)
export const updateAdditionalLogoSuccess = () =>
  ({
    type: InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.update-additional-logo.success.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.update-additional-logo.success.body",
    },
  } as const)
export const updateAdditionalLogoFailure = () =>
  ({
    type: InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "invoicing.settings.additional-logos.toast.update-additional-logo.error.title",
      bodyKey:
        "invoicing.settings.additional-logos.toast.update-additional-logo.error.body",
    },
  } as const)
export const updateAdditionalLogoReset = () =>
  ({
    type: InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_RESET,
  } as const)

export const hasDuplicateAttempt = () =>
  ({
    type: InvoicingActionsEnum.HAS_DUPLICATE_ATTEMPT,
  } as const)
export const hasDuplicateSuccess = (payload: Boolean) =>
  ({
    type: InvoicingActionsEnum.HAS_DUPLICATE_SUCCESS,
    payload,
  } as const)
export const hasDuplicateFailure = () =>
  ({
    type: InvoicingActionsEnum.HAS_DUPLICATE_FAILURE,
  } as const)
export const resetDuplicateStatusAction = () =>
  ({
    type: InvoicingActionsEnum.HAS_DUPLICATE_RESET,
  } as const)

export const addProductAttempt = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCT_ATTEMPT,
  } as const)
export const addProductSuccess = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "invoicing.settings.products.toast.add-product.success.title",
      bodyKey: "invoicing.settings.products.toast.add-product.success.body",
    },
  } as const)
export const addProductFailure = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "invoicing.settings.products.toast.add-product.error.title",
      bodyKey: "invoicing.settings.products.toast.add-product.error.body",
    },
  } as const)
export const addProductReset = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCT_RESET,
  } as const)

export const addProductsAttempt = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCTS_ATTEMPT,
  } as const)

export const addProductsSuccess = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "invoicing.settings.products.toast.add-product.success.title",
      bodyKey: "invoicing.settings.products.toast.add-product.success.body",
    },
  } as const)
export const addProductsFailure = (productName: string) =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCTS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "invoicing.settings.products.toast.add-products.error.title",
      bodyKey: "invoicing.settings.products.toast.add-products.error.body",
      bodyValues: { productName },
    },
  } as const)
export const addProductsReset = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCTS_RESET,
  } as const)

export const addProductsTooManyLines = () =>
  ({
    type: InvoicingActionsEnum.ADD_PRODUCTS_TOO_MANY_LINES,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey:
        "invoicing.settings.products.toast.add-products.too-many-lines.title",
      bodyKey:
        "invoicing.settings.products.toast.add-products.too-many-lines.body",
    },
  } as const)

export const modifyProductAttempt = () =>
  ({
    type: InvoicingActionsEnum.MODIFY_PRODUCT_ATTEMPT,
  } as const)
export const modifyProductSuccess = () =>
  ({
    type: InvoicingActionsEnum.MODIFY_PRODUCT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "invoicing.settings.products.toast.modify-product.success.title",
      bodyKey: "invoicing.settings.products.toast.modify-product.success.body",
    },
  } as const)
export const modifyProductFailure = () =>
  ({
    type: InvoicingActionsEnum.MODIFY_PRODUCT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "invoicing.settings.products.toast.modify-product.error.title",
      bodyKey: "invoicing.settings.products.toast.modify-product.error.body",
    },
  } as const)
export const modifyProductReset = () =>
  ({
    type: InvoicingActionsEnum.MODIFY_PRODUCT_RESET,
  } as const)
export const getUnitsAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_UNITS_ATTEMPT,
  } as const)
export const getUnitsSuccess = (payload: Units[]) =>
  ({
    type: InvoicingActionsEnum.GET_UNITS_SUCCESS,
    payload: payload,
  } as const)
export const getUnitsFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_UNITS_FAILURE,
  } as const)
export const deleteProductAttempt = () =>
  ({
    type: InvoicingActionsEnum.DELETE_PRODUCT_ATTEMPT,
  } as const)
export const deleteProductSuccess = () =>
  ({
    type: InvoicingActionsEnum.DELETE_PRODUCT_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey:
        "invoicing.settings.products.toast.delete-product.success.title",
      bodyKey: "invoicing.settings.products.toast.delete-product.success.body",
    },
  } as const)
export const deleteProductFailure = () =>
  ({
    type: InvoicingActionsEnum.DELETE_PRODUCT_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "invoicing.settings.products.toast.delete-product.error.title",
      bodyKey: "invoicing.settings.products.toast.delete-product.error.body",
    },
  } as const)
export const deleteProductReset = () =>
  ({
    type: InvoicingActionsEnum.DELETE_PRODUCT_RESET,
  } as const)
export const createInvoiceAttempt = () =>
  ({
    type: InvoicingActionsEnum.CREATE_INVOICE_ATTEMPT,
  } as const)
export const createInvoiceSuccess = (
  url: string,
  batchDocumentId: number,
  doc_type: string
) =>
  ({
    type: InvoicingActionsEnum.CREATE_INVOICE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `${doc_type}.issuance.toaster.success.title`,
      bodyKey: `${doc_type}.issuance.toaster.success.body`,
    },
    payload: { url, batchDocumentId },
  } as const)
export const createInvoiceFailure = (doc_type: string) =>
  ({
    type: InvoicingActionsEnum.CREATE_INVOICE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${doc_type}.issuance.toaster.error.title`,
      bodyKey: `${doc_type}.issuance.toaster.error.body`,
    },
  } as const)
export const resetBatchDocumentCreatedForInvoiceId = () =>
  ({
    type: InvoicingActionsEnum.RESET_BATCH_DOCUMENT_ID,
  } as const)

export const previewInvoiceAttempt = () =>
  ({
    type: InvoicingActionsEnum.PREVIEW_INVOICE_ATTEMPT,
  } as const)
export const previewInvoiceSuccess = (
  previewUrl: string,
  numberingPreview: string
) =>
  ({
    type: InvoicingActionsEnum.PREVIEW_INVOICE_SUCCESS,
    payload: { previewUrl, numberingPreview },
  } as const)
export const previewInvoiceFailure = (doc_type: string) =>
  ({
    type: InvoicingActionsEnum.PREVIEW_INVOICE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `${doc_type}.issuance.toaster-preview.error.title`,
      bodyKey: `${doc_type}.issuance.toaster-preview.error.body`,
    },
  } as const)

export const draftInvoiceAttempt = () =>
  ({
    type: InvoicingActionsEnum.DRAFT_INVOICE_ATTEMPT,
  } as const)
export const draftInvoiceReset = () =>
  ({
    type: InvoicingActionsEnum.DRAFT_INVOICE_RESET,
  } as const)

export const draftInvoiceSuccess = () =>
  ({
    type: InvoicingActionsEnum.DRAFT_INVOICE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `draft.issuance.toaster-preview.success.title`,
      bodyKey: `draft.issuance.toaster-preview.success.body`,
    },
  } as const)
export const draftInvoiceFailure = () =>
  ({
    type: InvoicingActionsEnum.DRAFT_INVOICE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `draft.issuance.toaster-preview.error.title`,
      bodyKey: `draft.issuance.toaster-preview.error.body`,
    },
  } as const)
export const resetPreviewUrl = () =>
  ({
    type: InvoicingActionsEnum.RESET_PREVIEW_URL,
  } as const)
export const getUrlFailureAction = () =>
  ({
    type: InvoicingActionsEnum.GET_URL_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `invoicing.url.error.title`,
      bodyKey: `invoicing.url.error.body`,
    },
  } as const)

export const getAllInvoicesAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_ALL_INVOICES_ATTEMPT,
  } as const)
export const getAllInvoicesSuccess = (payload: Indexed<InvoiceBlock>) =>
  ({
    type: InvoicingActionsEnum.GET_ALL_INVOICES_SUCCESS,
    payload,
  } as const)
export const getAllInvoicesFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_ALL_INVOICES_FAILURE,
  } as const)

export const getDraftInvoicesAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_DRAFT_INVOICES_ATTEMPT,
  } as const)
export const getDraftInvoicesSuccess = (payload: InvoiceDraftsPayload[]) =>
  ({
    type: InvoicingActionsEnum.GET_DRAFT_INVOICES_SUCCESS,
    payload,
  } as const)
export const getDraftInvoicesFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_DRAFT_INVOICES_FAILURE,
  } as const)

export const deleteDraftInvoiceAttempt = () =>
  ({
    type: InvoicingActionsEnum.DELETE_DRAFT_INVOICE_ATTEMPT,
  } as const)
export const deleteDraftInvoiceSuccess = (payload: number) =>
  ({
    type: InvoicingActionsEnum.DELETE_DRAFT_INVOICE_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `draft.destroy.toaster-preview.success.title`,
      bodyKey: `draft.destroy.toaster-preview.success.body`,
    },
    payload,
  } as const)
export const deleteDraftInvoiceFailure = () =>
  ({
    type: InvoicingActionsEnum.DELETE_DRAFT_INVOICE_FAILURE,
  } as const)

export const sendReminderAttempt = () =>
  ({
    type: InvoicingActionsEnum.SEND_REMINDER_ATTEMPT,
  } as const)
export const sendReminderReset = () =>
  ({
    type: InvoicingActionsEnum.SEND_REMINDER_RESET,
  } as const)
export const sendReminderSuccess = () =>
  ({
    type: InvoicingActionsEnum.SEND_REMINDER_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `invoicing.reminder.succes.title`,
      bodyKey: `invoicing.reminder.succes.body`,
    },
  } as const)

export const sendReminderFailure = () =>
  ({
    type: InvoicingActionsEnum.SEND_REMINDER_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `invoicing.reminder.error.title`,
      bodyKey: `invoicing.reminder.error.body`,
    },
  } as const)

export const getInvoicingDocumentHistoryAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICING_DOCUMENT_HISTORY_ATTEMPT,
  } as const)
export const getInvoicingDocumentHistoryFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICING_DOCUMENT_HISTORY_FAILURE,
  } as const)
export const getInvoicingDocumentHistorySuccess = (
  payload: InvoicingDocumentHistoryEvent[]
) =>
  ({
    type: InvoicingActionsEnum.GET_INVOICING_DOCUMENT_HISTORY_SUCCESS,
    payload,
  } as const)

export const getCustomerHistoryAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_CUSTOMER_HISTORY_ATTEMPT,
  } as const)
export const getCustomerHistoryFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_CUSTOMER_HISTORY_FAILURE,
  } as const)
export const getCustomerHistorySuccess = (payload: CustomerHistoryEvent[]) =>
  ({
    type: InvoicingActionsEnum.GET_CUSTOMER_HISTORY_SUCCESS,
    payload,
  } as const)

export const getInvoiceUrlAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_URL_ATTEMPT,
  } as const)
export const getInvoiceUrlSuccess = (invoiceUrl: string) =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_URL_SUCCESS,
    invoiceUrl,
  } as const)
export const getInvoiceUrlFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_URL_FAILURE,
  } as const)
export const getInvoiceForOvertakingAttempt = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_ATTEMPT,
  } as const)
export const getInvoiceForOvertakingSuccess = (payload: InvoiceForOvertaking) =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_SUCCESS,
    payload,
  } as const)
export const getInvoiceForOvertakingFailure = () =>
  ({
    type: InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `overtaking.issuance.toaster-preview.error.title`,
      bodyKey: `overtaking.issuance.toaster-preview.error.body`,
    },
  } as const)
export const overtakingResetClientAndProducts = () =>
  ({
    type: InvoicingActionsEnum.OVERTAKING_RESET_PRODUCT_CLIENT,
  } as const)
export const urlFailure = () =>
  ({
    type: InvoicingActionsEnum.URL_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `url.issuance.toaster-preview.error.title`,
      bodyKey: `url.issuance.toaster-preview.error.body`,
    },
  } as const)

type InvoicingActionsType = ReturnType<
  | typeof GetTypologiesSuccess
  | typeof GetTypologiesFailure
  | typeof getAllLegalEntitiesForCompanyAttempt
  | typeof getAllLegalEntitiesForCompanySuccess
  | typeof CreateOrModifyClientAttempt
  | typeof CreateOrModifyClientSuccess
  | typeof CreateOrModifyClientReset
  | typeof CreateOrModifyClientFailure
  | typeof invoicingSirenResetStatus
  | typeof invoicingSirenSuccessAction
  | typeof invoicingSirenHiddenAction
  | typeof invoicingSirenFailureAction
  | typeof invoicingSirenAttemptAction
  | typeof getLegalEntityFromSirenSuccess
  | typeof getProductsAttempt
  | typeof getProductsSuccess
  | typeof getProductsFailure
  | typeof getAdditionalLogosAttempt
  | typeof getAdditionalLogosSuccess
  | typeof getAdditionalLogosFailure
  | typeof getAdditionalLogosReset
  | typeof addAdditionalLogoAttempt
  | typeof addAdditionalLogoSuccess
  | typeof addAdditionalLogoFailure
  | typeof addAdditionalLogoReset
  | typeof deleteAdditionalLogoAttempt
  | typeof deleteAdditionalLogoSuccess
  | typeof deleteAdditionalLogoFailure
  | typeof deleteAdditionalLogoReset
  | typeof updateAdditionalLogoAttempt
  | typeof updateAdditionalLogoSuccess
  | typeof updateAdditionalLogoFailure
  | typeof updateAdditionalLogoReset
  | typeof hasDuplicateAttempt
  | typeof hasDuplicateSuccess
  | typeof hasDuplicateFailure
  | typeof resetDuplicateStatusAction
  | typeof addProductAttempt
  | typeof addProductSuccess
  | typeof addProductFailure
  | typeof addProductReset
  | typeof addProductsAttempt
  | typeof addProductsSuccess
  | typeof addProductsFailure
  | typeof addProductsReset
  | typeof modifyProductAttempt
  | typeof modifyProductSuccess
  | typeof modifyProductFailure
  | typeof modifyProductReset
  | typeof getUnitsAttempt
  | typeof getUnitsSuccess
  | typeof getUnitsFailure
  | typeof deleteProductAttempt
  | typeof deleteProductSuccess
  | typeof deleteProductFailure
  | typeof deleteProductReset
  | typeof createInvoiceAttempt
  | typeof createInvoiceSuccess
  | typeof createInvoiceFailure
  | typeof resetBatchDocumentCreatedForInvoiceId
  | typeof previewInvoiceAttempt
  | typeof previewInvoiceSuccess
  | typeof previewInvoiceFailure
  | typeof draftInvoiceAttempt
  | typeof draftInvoiceReset
  | typeof draftInvoiceSuccess
  | typeof draftInvoiceFailure
  | typeof getInvoiceForOvertakingAttempt
  | typeof getInvoiceForOvertakingSuccess
  | typeof getInvoiceForOvertakingFailure
  | typeof resetPreviewUrl
  | typeof getUrlFailureAction
  | typeof getAllInvoicesAttempt
  | typeof getAllInvoicesSuccess
  | typeof getAllInvoicesFailure
  | typeof getDraftInvoicesAttempt
  | typeof getDraftInvoicesSuccess
  | typeof getDraftInvoicesFailure
  | typeof deleteDraftInvoiceAttempt
  | typeof deleteDraftInvoiceSuccess
  | typeof deleteDraftInvoiceFailure
  | typeof sendReminderAttempt
  | typeof sendReminderReset
  | typeof sendReminderSuccess
  | typeof sendReminderFailure
  | typeof getInvoiceUrlAttempt
  | typeof getInvoiceUrlSuccess
  | typeof getInvoiceUrlFailure
  | typeof getInvoicingDocumentHistoryAttempt
  | typeof getInvoicingDocumentHistorySuccess
  | typeof getInvoicingDocumentHistoryFailure
  | typeof getCustomerHistoryAttempt
  | typeof getCustomerHistorySuccess
  | typeof getCustomerHistoryFailure
  | typeof overtakingResetClientAndProducts
  | typeof urlFailure
>

export const invoicingInitialState: InvoicingState = {
  clientStatus: "UNSET",
  typologies: {
    company: "",
    private_person: "",
  },
  legalEntityId: null,
  legalEntities: [],
  legalEntitiesWithoutInfos: [],
  siren: "",
  sirenCheck: "UNCHECKED",
  status: "UNREGISTERED",
  name: "",
  sirenState: null,
  code_ape: "",
  products: [],
  productStatus: "UNSET",
  addProductStatus: "UNSET",
  hasDuplicateStatus: "UNSET",
  deleteProductStatus: "UNSET",
  units: [],
  clientCreatedForInvoiceId: null,
  clientCreationStatus: "UNSET",
  createdInvoiceURL: "",
  batchDocumentCreatedForInvoiceId: null,
  invoicePreviewStatus: "UNSET",
  numberingPreview: "",
  invoicePreviewUrl: "",
  invoiceInfos: null,
  hasDuplicate: false,
  allInvoices: [],
  draftInvoices: [],
  invoiceUrl: "",
  modifyProductStatus: "UNSET",
  additionalLogos: [],
  getLogosStatus: "UNSET",
  addLogoStatus: "UNSET",
  deleteAdditionalLogoStatus: "UNSET",
  updateAdditionalLogoStatus: "UNSET",
  createDraftInvoiceStatus: "UNSET",
  address: "",
  city: "",
  postalCode: "",
  totalDiscount: 0,
  sendReminderStatus: "UNSET",
  addProductsStatus: "UNSET",
  invoicingDocumentHistoryEvents: [],
  ape_details: "",
  country: "",
  country_code: "",
  full_legal_form: "",
  legal_form: null,
  max_work_force: 0,
  min_work_force: 0,
  postal_code: "",
  share_capital: null,
  siege_siret: "",
  vat_number: "",
  work_force: "",
  customerHistoryEvents: [],
}

export interface InvoicingState {
  clientStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  typologies: Typologies
  legalEntityId: number | null
  legalEntities: LegalEntityInfo[]
  legalEntitiesWithoutInfos: LegalEntityWithoutInfos[]
  siren: string
  sirenCheck: "CHECKING" | "VALIDATED" | "INVALID" | "UNCHECKED"
  status: "UNREGISTERED" | "INVALID" | "SUCCESS"
  name: string
  sirenState: SirenStatus
  code_ape: string
  products: ProductFormatted[]
  productStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  addProductStatus: "UNSET" | "SUCCESS" | "FAILURE"
  hasDuplicateStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  deleteProductStatus: "UNSET" | "SUCCESS" | "FAILURE"
  units: Array<Units>
  clientCreatedForInvoiceId: number | null
  clientCreationStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  createdInvoiceURL: string
  batchDocumentCreatedForInvoiceId: number | null
  invoicePreviewStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  numberingPreview: string
  invoicePreviewUrl: string
  invoiceInfos: InvoiceForOvertaking | null
  hasDuplicate: Boolean
  allInvoices: Indexed<InvoiceBlock>
  draftInvoices: InvoiceDraftsPayload[]
  invoiceUrl: string
  modifyProductStatus: "UNSET" | "SUCCESS" | "FAILURE"
  additionalLogos: AdditionalLogo[]
  getLogosStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  addLogoStatus: "UNSET" | "SUCCESS" | "FAILURE"
  deleteAdditionalLogoStatus: "UNSET" | "SUCCESS" | "FAILURE"
  updateAdditionalLogoStatus: "UNSET" | "SUCCESS" | "FAILURE"
  createDraftInvoiceStatus: "UNSET" | "SUCCESS" | "FAILURE"
  address: string
  city: string
  postalCode: string
  totalDiscount: number
  sendReminderStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  addProductsStatus: "UNSET" | "LOADING" | "SUCCESS" | "FAILURE"
  invoicingDocumentHistoryEvents: InvoicingDocumentHistoryEvent[]
  ape_details: string
  country: string
  country_code: string
  full_legal_form: string
  legal_form: string | null
  max_work_force: number
  min_work_force: number
  postal_code: string
  share_capital: string | null
  siege_siret: string
  vat_number: string
  work_force: string
  customerHistoryEvents: CustomerHistoryEvent[]
}

export type DropAdditionalLogoStatus = "idle" | "loading" | "success" | "error"

export interface InvoiceBlock {
  status: "collapsed" | "extended"
  invoices: InvoicePayload[]
}

export function invoicingReducer(
  state = invoicingInitialState,
  action: InvoicingActionsType
): InvoicingState {
  switch (action.type) {
    case InvoicingActionsEnum.GET_TYPOLOGIES_SUCCESS:
      return { ...state, typologies: action.payload }

    case InvoicingActionsEnum.GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_ATTEMPT:
      return { ...state, clientStatus: "LOADING" }
    case InvoicingActionsEnum.GET_ALL_LEGAL_ENTITIES_FOR_COMPANY_SUCCESS:
      return {
        ...state,
        legalEntities: action.payload.legal_entities,
        legalEntitiesWithoutInfos: action.payload.legal_entities_without_infos,
        clientStatus: "SUCCESS",
      }

    case InvoicingActionsEnum.INVOICING_SIREN_SUCCESS:
      return {
        ...state,
        sirenCheck: "VALIDATED",
        siren: action.payload.company.siren || "",
        code_ape: action.payload.company.code_ape || "",
        name: action.payload.company.name,
        address: action.payload.company.address,
        city: action.payload.company.city,
        postalCode: action.payload.company.postal_code,
        sirenState: "ok",
      }
    case InvoicingActionsEnum.SIREN_FAILURE:
      return { ...state, sirenCheck: "INVALID", sirenState: "not found" }
    case InvoicingActionsEnum.INVOICING_SIREN_HIDDEN:
      return { ...state, sirenCheck: "INVALID", sirenState: "hidden" }
    case InvoicingActionsEnum.SIREN_ATTEMPT:
      return { ...state, sirenCheck: "CHECKING" }
    case InvoicingActionsEnum.SIREN_RESET_STATUS:
      return {
        ...state,
        code_ape: "",
        siren: "",
        sirenCheck: "UNCHECKED",
        status: "UNREGISTERED",
        name: "",
        sirenState: null,
        legalEntityId: null,
      }
    case InvoicingActionsEnum.GET_LEGAL_ENTITY_FROM_SIREN_SUCCESS:
      return { ...state, legalEntityId: action.legalEntityId }

    case InvoicingActionsEnum.GET_PRODUCTS_ATTEMPT:
      return { ...state, productStatus: "LOADING" }
    case InvoicingActionsEnum.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productStatus: "SUCCESS",
      }
    case InvoicingActionsEnum.GET_PRODUCTS_FAILURE:
      return { ...state, productStatus: "FAILURE" }

    case InvoicingActionsEnum.ADD_PRODUCT_ATTEMPT:
      return { ...state, addProductStatus: "UNSET" }
    case InvoicingActionsEnum.ADD_PRODUCT_SUCCESS:
      return { ...state, addProductStatus: "SUCCESS" }
    case InvoicingActionsEnum.ADD_PRODUCT_FAILURE:
      return { ...state, addProductStatus: "FAILURE" }
    case InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_ATTEMPT:
      return { ...state, getLogosStatus: "LOADING" }
    case InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_SUCCESS:
      return {
        ...state,
        additionalLogos: action.payload,
        getLogosStatus: "SUCCESS",
      }
    case InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_FAILURE:
      return { ...state, getLogosStatus: "FAILURE" }
    case InvoicingActionsEnum.GET_ADDITIONAL_LOGOS_RESET:
      return { ...state, getLogosStatus: "UNSET" }

    case InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_ATTEMPT:
      return { ...state, addLogoStatus: "UNSET" }
    case InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_SUCCESS:
      return { ...state, addLogoStatus: "SUCCESS" }
    case InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_FAILURE:
      return { ...state, addLogoStatus: "FAILURE" }
    case InvoicingActionsEnum.ADD_ADDITIONAL_LOGO_RESET:
      return { ...state, addLogoStatus: "UNSET" }
    case InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_ATTEMPT:
      return { ...state, deleteAdditionalLogoStatus: "UNSET" }
    case InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_SUCCESS:
      return { ...state, deleteAdditionalLogoStatus: "SUCCESS" }
    case InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_FAILURE:
      return { ...state, deleteAdditionalLogoStatus: "FAILURE" }
    case InvoicingActionsEnum.DELETE_ADDITIONAL_LOGO_RESET:
      return { ...state, deleteAdditionalLogoStatus: "UNSET" }
    case InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_ATTEMPT:
      return { ...state, updateAdditionalLogoStatus: "UNSET" }
    case InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_SUCCESS:
      return { ...state, updateAdditionalLogoStatus: "SUCCESS" }
    case InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_FAILURE:
      return { ...state, updateAdditionalLogoStatus: "FAILURE" }
    case InvoicingActionsEnum.UPDATE_ADDITIONAL_LOGO_RESET:
      return { ...state, updateAdditionalLogoStatus: "UNSET" }
    case InvoicingActionsEnum.HAS_DUPLICATE_ATTEMPT:
      return { ...state, hasDuplicateStatus: "LOADING" }
    case InvoicingActionsEnum.HAS_DUPLICATE_SUCCESS:
      return {
        ...state,
        hasDuplicate: action.payload,
        hasDuplicateStatus: "SUCCESS",
      }
    case InvoicingActionsEnum.HAS_DUPLICATE_FAILURE:
      return { ...state, hasDuplicateStatus: "FAILURE" }
    case InvoicingActionsEnum.HAS_DUPLICATE_RESET:
      return { ...state, hasDuplicateStatus: "UNSET" }
    case InvoicingActionsEnum.ADD_PRODUCT_RESET:
      return { ...state, addProductStatus: "UNSET" }
    case InvoicingActionsEnum.MODIFY_PRODUCT_ATTEMPT:
      return { ...state, modifyProductStatus: "UNSET" }
    case InvoicingActionsEnum.MODIFY_PRODUCT_SUCCESS:
      return { ...state, modifyProductStatus: "SUCCESS" }
    case InvoicingActionsEnum.MODIFY_PRODUCT_FAILURE:
      return { ...state, modifyProductStatus: "FAILURE" }
    case InvoicingActionsEnum.MODIFY_PRODUCT_RESET:
      return { ...state, modifyProductStatus: "UNSET" }
    case InvoicingActionsEnum.GET_UNITS_ATTEMPT:
      return { ...state }
    case InvoicingActionsEnum.GET_UNITS_SUCCESS:
      return { ...state, units: action.payload }
    case InvoicingActionsEnum.GET_UNITS_FAILURE:
      return { ...state }

    case InvoicingActionsEnum.DELETE_PRODUCT_ATTEMPT:
      return { ...state, deleteProductStatus: "UNSET" }
    case InvoicingActionsEnum.DELETE_PRODUCT_SUCCESS:
      return { ...state, deleteProductStatus: "SUCCESS" }
    case InvoicingActionsEnum.DELETE_PRODUCT_FAILURE:
      return { ...state, deleteProductStatus: "FAILURE" }
    case InvoicingActionsEnum.DELETE_PRODUCT_RESET:
      return { ...state, deleteProductStatus: "UNSET" }

    case InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_SUCCESS:
      return {
        ...state,
        clientCreatedForInvoiceId: action.legalEntityId || null,
        clientCreationStatus: "SUCCESS",
      }

    case InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_ATTEMPT:
      return {
        ...state,
        clientCreationStatus: "LOADING",
      }
    case InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_FAILURE:
      return {
        ...state,
        clientCreationStatus: "FAILURE",
      }
    case InvoicingActionsEnum.CREATE_OR_MODIFY_CLIENT_RESET:
      return {
        ...state,
        clientCreationStatus: "UNSET",
        clientCreatedForInvoiceId: null,
      }

    case InvoicingActionsEnum.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        clientCreatedForInvoiceId: null,
        createdInvoiceURL: action.payload.url,
        batchDocumentCreatedForInvoiceId: action.payload.batchDocumentId,
      }

    case InvoicingActionsEnum.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        clientCreatedForInvoiceId: null,
      }
    case InvoicingActionsEnum.RESET_BATCH_DOCUMENT_ID:
      return {
        ...state,
        batchDocumentCreatedForInvoiceId: null,
      }

    case InvoicingActionsEnum.PREVIEW_INVOICE_SUCCESS:
      return {
        ...state,
        invoicePreviewUrl: action.payload.previewUrl,
        invoicePreviewStatus: "SUCCESS",
        numberingPreview: action.payload.numberingPreview,
      }
    case InvoicingActionsEnum.PREVIEW_INVOICE_ATTEMPT:
      return {
        ...state,
        invoicePreviewUrl: "",
        invoicePreviewStatus: "LOADING",
      }
    case InvoicingActionsEnum.PREVIEW_INVOICE_FAILURE:
      return {
        ...state,
        invoicePreviewUrl: "",
        invoicePreviewStatus: "FAILURE",
      }
    case InvoicingActionsEnum.RESET_PREVIEW_URL:
      return {
        ...state,
        invoicePreviewUrl: "",
        invoicePreviewStatus: "UNSET",
      }
    case InvoicingActionsEnum.GET_ALL_INVOICES_ATTEMPT:
      return { ...state }
    case InvoicingActionsEnum.GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        allInvoices: action.payload,
      }
    case InvoicingActionsEnum.GET_DRAFT_INVOICES_SUCCESS:
      return {
        ...state,
        draftInvoices: action.payload,
      }
    case InvoicingActionsEnum.GET_ALL_INVOICES_FAILURE:
      return { ...state }
    case InvoicingActionsEnum.GET_INVOICE_URL_ATTEMPT:
      return { ...state }
    case InvoicingActionsEnum.GET_INVOICE_URL_SUCCESS:
      return {
        ...state,
        invoiceUrl: action.invoiceUrl,
      }
    case InvoicingActionsEnum.GET_INVOICE_URL_FAILURE:
      return { ...state }

    case InvoicingActionsEnum.GET_INVOICING_DOCUMENT_HISTORY_ATTEMPT:
      return { ...state, invoicingDocumentHistoryEvents: [] }
    case InvoicingActionsEnum.GET_INVOICING_DOCUMENT_HISTORY_SUCCESS:
      return { ...state, invoicingDocumentHistoryEvents: action.payload }

    case InvoicingActionsEnum.GET_CUSTOMER_HISTORY_ATTEMPT:
      return { ...state, customerHistoryEvents: [] }
    case InvoicingActionsEnum.GET_CUSTOMER_HISTORY_SUCCESS:
      return { ...state, customerHistoryEvents: action.payload }

    case InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_ATTEMPT:
      return { ...state }
    case InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_SUCCESS:
      return { ...state, invoiceInfos: action.payload }
    case InvoicingActionsEnum.GET_INVOICE_FOR_OVERTAKING_FAILURE:
      return { ...state }
    case InvoicingActionsEnum.OVERTAKING_RESET_PRODUCT_CLIENT:
      return { ...state, invoiceInfos: null }
    case InvoicingActionsEnum.URL_FAILURE:
      return { ...state }
    case InvoicingActionsEnum.DRAFT_INVOICE_SUCCESS:
      return { ...state, createDraftInvoiceStatus: "SUCCESS" }
    case InvoicingActionsEnum.DRAFT_INVOICE_RESET:
      return { ...state, createDraftInvoiceStatus: "UNSET" }
    case InvoicingActionsEnum.DELETE_DRAFT_INVOICE_SUCCESS:
      return {
        ...state,
        draftInvoices: state.draftInvoices.filter(
          (d) => d.id !== action.payload
        ),
      }

    case InvoicingActionsEnum.SEND_REMINDER_RESET:
      return { ...state, sendReminderStatus: "UNSET" }
    case InvoicingActionsEnum.SEND_REMINDER_ATTEMPT:
      return { ...state, sendReminderStatus: "LOADING" }
    case InvoicingActionsEnum.SEND_REMINDER_SUCCESS:
      return { ...state, sendReminderStatus: "SUCCESS" }

    case InvoicingActionsEnum.ADD_PRODUCTS_RESET:
      return { ...state, addProductsStatus: "UNSET" }
    case InvoicingActionsEnum.ADD_PRODUCTS_ATTEMPT:
      return { ...state, addProductsStatus: "LOADING" }
    case InvoicingActionsEnum.ADD_PRODUCTS_SUCCESS:
      return { ...state, addProductsStatus: "SUCCESS" }
    case InvoicingActionsEnum.ADD_PRODUCTS_FAILURE:
      return { ...state, addProductsStatus: "FAILURE" }

    default:
      return { ...state }
  }
}

export type Typology = Record<"company" | "private_person", string>

interface Typologies {
  [Typology: string]: string
}

export interface LegalEntityInfo {
  name: string
  adress: string
  city: string
  company_id: number
  created_at: string
  emails: string[]
  legal_entity_info_id: number
  legal_entity_id: number
  identification_number: number | null
  phone: string | null
  postal_code: string
  total_discount: number
  typology: "company" | "private_person"
  updated_at: string
}

export interface LegalEntityWithoutInfos {
  name: string
  legal_entity_id: number
  identification_number: string | null
}

export interface ClientForm {
  name: { name: string }
  typology: { typology: string; translation: string }
  adress: string
  postalCode: number
  city: string
  phone: number | null
  emails: string[]
  siren: number | null
  legalEntityId: number | null
  totalDiscount: number
}

export interface Products {
  [index: string]: ProductFormatted
}

interface Product {
  id: number
  unit_id: number
  vat_rate_id: number
  product_name: string
  description: string
  unit_price: number
  unit: string
  rate: number
  credit_note_only: boolean
}

export interface ProductFormatted {
  id: number
  unitId: number
  vatRateId: number
  productName: string
  productDescription: string
  unitPrice: string
  unit: string
  rate: number
  creditNoteOnly: boolean
}

export interface VatRates {
  id: number
  rate: string
}

export interface Units {
  id: number
  unit: string
}

export interface InvoiceEmailConfig {
  from: string
  to: string[]
  cc: string[]
  bcc: string[]
  object: string
  message: string
  numberingPreview: string // used to replace numbering in object/message at the time of preview by actual numbering of invoice in email
}

export interface CreateOrPreviewInvoice {
  company_id: string
  invoice_date: string
  due_date: string
  items: {
    id: number
    name: string
    description: string
    unit_price: string
    tva_rate: string
    ht_total: string
    tva_total: string
    ttc: string
    quantity: string
    unit: string
  }[]
  legal_notice: string
  legal_entity: number | null
  numbering_id: number | null
  address: string
  postal_code: string
  city: string
  free_text: string
  special_mentions: string
  special_discount: number
  total_discount: number
  document_type: string
  old_document_id: number | undefined
  current_document_to_edit_id: number | undefined
  additional_logos_id: number[] | undefined
  invoice_email_config: InvoiceEmailConfig | null
  documentTypeParam: DocumentTypeParam
}

export interface AdditionalLogo {
  id: number
  company_id: number
  original_file_name: string
  file_name: string
  deactivated_at: string
  deactivator_user_id: number
  is_default: boolean
}

export const GetTypologiesThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    return axios
      .get<Typologies>(`/invoicing_clients/${companyId}/get_typologies`)
      .then((response) => {
        dispatch(GetTypologiesSuccess(response.data))
      })
      .catch((e) => {
        dispatch(GetTypologiesFailure(e))
      })
  }

interface LegalEntityResponse {
  legal_entities: LegalEntityInfo[]
  legal_entities_without_infos: LegalEntityWithoutInfos[]
}

export const GetAllLegalEntitiesForCompanyThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    return axios
      .get<LegalEntityResponse>(`/invoicing_clients/get_all_legal_entities`, {
        params: { company_id: companyId },
      })
      .then((response) => {
        dispatch(getAllLegalEntitiesForCompanySuccess(response.data))
      })
  }

interface LegalEntityFromSiren {
  legal_entity_id: number
}

export const getLegalEntityFromSirenThunk =
  (companyId: number, siren: number, name: string) =>
  (dispatch: Dispatch<InvoicingActionsType>, getState: () => RootState) => {
    const informations = getState().invoicing

    const requestPayload = {
      name: informations.name,
      adress: informations.address,
      postal_code: informations.postalCode,
      city: informations.city,
      identification_number: informations.siren,
      company_id: companyId,
      code_ape: informations.code_ape,
      ape_details: informations.ape_details,
      country: informations.country,
      country_code: informations.country_code || "FR",
      full_legal_form: informations.full_legal_form,
      legal_form: informations.legal_form,
      max_work_force: informations.max_work_force,
      min_work_force: informations.min_work_force,
      share_capital: informations.share_capital,
      siege_siret: informations.siege_siret,
      vat_number: informations.vat_number,
      work_force: informations.work_force,
    }

    return axios
      .get<LegalEntityFromSiren>(
        `/invoicing_clients/${companyId}/exisiting_legal_entity`,
        { params: requestPayload }
      )
      .then((response) => {
        dispatch(getLegalEntityFromSirenSuccess(response.data.legal_entity_id))
      })
  }

export const InvoicingSirenValidationThunk =
  (siren: string) => (dispatch: Dispatch<InvoicingActionsType>) => {
    const sirenValidationURI = "/companies/siren_to_name"

    axios
      .get<SirenCheckResponseType>(`${sirenValidationURI}`, {
        params: {
          siren: cleanSIREN(siren),
          scope: "legal_entity",
        },
      })
      .then((response) => {
        const companyData: CompanyObjectType = response.data
        companyData["siren"] = cleanSIREN(siren)
        dispatch(invoicingSirenSuccessAction(response.data))
      })
      .catch((e) => {
        if (
          typeof e.response.data === "string" &&
          e.response.data.includes("Unité légale non diffusable")
        ) {
          return dispatch(invoicingSirenHiddenAction())
        }
        return dispatch(invoicingSirenFailureAction())
      })
  }

export const CreateClientThunk =
  (companyId: number, clientForm: ClientForm, createOrModify: string) =>
  (
    dispatch: Dispatch<AnyAction | RNBThunkAction>,
    getState: () => RootState
  ) => {
    CreateOrModifyClientAttempt()
    const informations = getState().invoicing
    const requestPayload = {
      name: clientForm.name,
      typology: clientForm.typology,
      adress: clientForm.adress,
      postal_code: clientForm.postalCode,
      phone: clientForm.phone,
      city: clientForm.city,
      emails: clientForm.emails.map((e) => e.replace(/\n/g, "")),
      identification_number: clientForm.siren,
      legal_entity_id: clientForm.legalEntityId,
      company_id: companyId,
      code_ape: informations.code_ape,
      ape_details: informations.ape_details,
      country: informations.country,
      country_code: informations.country_code || "FR",
      full_legal_form: informations.full_legal_form,
      legal_form: informations.legal_form,
      max_work_force: informations.max_work_force,
      min_work_force: informations.min_work_force,
      share_capital: informations.share_capital,
      siege_siret: informations.siege_siret,
      vat_number: informations.vat_number,
      work_force: informations.work_force,
      total_discount: clientForm.totalDiscount,
    }
    return axios
      .post<{ legal_entity_id: number }>(
        `/invoicing_clients/create_client`,
        requestPayload
      )
      .then((res) => {
        dispatch(
          CreateOrModifyClientSuccess(createOrModify, res.data.legal_entity_id)
        )
        dispatch(GetAllLegalEntitiesForCompanyThunk(companyId))
      })
      .catch(() => {
        dispatch(CreateOrModifyClientFailure(createOrModify))
      })
  }

export const ModifyClientThunk =
  (companyId: number, clientForm: ClientForm, createOrModify: string) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    return axios
      .post(`/invoicing_clients/modify_client`, {
        name: clientForm.name,
        typology: clientForm.typology,
        adress: clientForm.adress,
        postal_code: clientForm.postalCode,
        phone: clientForm.phone,
        city: clientForm.city,
        emails: clientForm.emails,
        siren: clientForm.siren,
        legal_entity_id: clientForm.legalEntityId,
        company_id: companyId,
        total_discount: clientForm.totalDiscount,
      })
      .then(() => {
        dispatch(CreateOrModifyClientSuccess(createOrModify))
        dispatch(GetAllLegalEntitiesForCompanyThunk(companyId))
      })
      .catch(() => {
        dispatch(CreateOrModifyClientFailure(createOrModify))
      })
  }

export const getProductsThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getProductsAttempt())
    return axios
      .get<Product[]>(`/invoicing_products/${companyId}/get_all_products`)
      .then(({ data }) => {
        const formattedProducts = data.map((product) => ({
          id: product.id,
          unitId: product.unit_id,
          vatRateId: product.vat_rate_id,
          productName: product.product_name,
          productDescription: product.description,
          unitPrice: product.unit_price.toString(),
          unit: product.unit,
          rate: product.rate,
          creditNoteOnly: product.credit_note_only,
        }))
        dispatch(getProductsSuccess(formattedProducts))
      })
      .catch(() => {
        dispatch(getProductsFailure())
      })
  }

export const addProductsThunk =
  (products: AddProductParams[], companyId: number) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(addProductsAttempt())
    const productsToCreate = products.map((p) => ({
      price: parseFloat(p.price),
      product_name: p.productName,
      description: p.productDescription,
      unit_id: p.unitId,
      vat_rate_id: p.vatRateId,
      credit_note_only: p.creditNoteOnly,
    }))

    return axios
      .put(`/invoicing_products/${companyId}/create_all_products`, {
        products_to_create: productsToCreate,
      })
      .then((r) => {
        dispatch(addProductsSuccess())
      })
      .catch((e) => {
        const error = e.response.data.error
        const productName = e.response.data.error.substring(
          "Un produit avec le même nom ".length,
          error.lastIndexOf(" existe")
        )
        dispatch(addProductsFailure(productName))
      })
  }

interface AddProductParams {
  price: string
  productName: string
  productDescription: string
  unitId: string
  vatRateId: string
  creditNoteOnly: boolean
}

export const addProductThunk =
  (
    {
      price,
      productName,
      productDescription,
      unitId,
      vatRateId,
      creditNoteOnly,
    }: AddProductParams,
    companyId: number
  ) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(addProductAttempt())
    return axios
      .put(`/invoicing_products/${companyId}/create_product`, {
        price: price,
        product_name: productName,
        description: productDescription,
        unit_id: unitId,
        vat_rate_id: vatRateId,
        credit_note_only: creditNoteOnly,
      })
      .then(() => {
        dispatch(addProductSuccess())
      })
      .catch(() => {
        dispatch(addProductFailure())
      })
  }

interface ModifyProductParams {
  oldPrice: string
  newPrice: string
  newProductName: string
  oldProductName: string
  oldUnitId: string
  newUnitId: string
  oldVatRateId: string
  newVatRateId: string
  oldCreditNoteOnly: boolean
  newCreditNoteOnly: boolean
  oldDescription: string
  newDescription: string
}

export const modifyProductThunk =
  (
    {
      oldPrice,
      newPrice,
      newProductName,
      oldProductName,
      oldUnitId,
      newUnitId,
      oldVatRateId,
      newVatRateId,
      oldCreditNoteOnly,
      newCreditNoteOnly,
      oldDescription,
      newDescription,
    }: ModifyProductParams,
    product_id: number,
    companyId: number
  ) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(modifyProductAttempt())
    return axios
      .put(`/invoicing_products/${companyId}`, {
        old_price: oldPrice,
        new_price: newPrice,
        old_product_name: oldProductName,
        new_product_name: newProductName,
        old_unit_id: oldUnitId,
        new_unit_id: newUnitId,
        old_vat_rate_id: oldVatRateId,
        new_vat_rate_id: newVatRateId,
        old_credit_note_only: oldCreditNoteOnly,
        new_credit_note_only: newCreditNoteOnly,
        old_description: oldDescription,
        new_description: newDescription,
        product_id: product_id,
      })
      .then(() => {
        dispatch(modifyProductSuccess())
      })
      .catch(() => {
        dispatch(modifyProductFailure())
      })
  }

export const getUnitsThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getUnitsAttempt())
    return axios
      .get<{ units: Array<Units> }>(`/invoicing_products/get_units`, {
        params: { company_id: companyId },
      })
      .then((response) => {
        return {
          units: response.data.units,
        }
      })
      .then((data) => {
        dispatch(getUnitsSuccess(data.units))
      })
      .catch(() => {
        dispatch(getUnitsFailure())
      })
  }

export const deleteProductThunk =
  (companyId: number, productId: number) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(deleteProductAttempt())
    return axios
      .post(`/invoicing_products/${companyId}/deactivate_product`, {
        product_id: productId,
      })
      .then(() => {
        dispatch(deleteProductSuccess())
      })
      .catch(() => deleteProductFailure())
  }

export const createFinalInvoiceThunk =
  (invoice: CreateOrPreviewInvoice) =>
  (dispatch: Dispatch<InvoicingActionsType | RNBThunkAction>) => {
    dispatch(createInvoiceAttempt())

    const isFromDraft = includes(draftTypes, invoice.documentTypeParam)
    const draftToDestroyId = isFromDraft ? invoice.old_document_id : null

    return axios
      .post<{ id: number; url: string; batch_document_id: number }>(
        `/invoicing_documents/create_final_invoice`,
        {
          ...invoice,
          draft_to_destroy_id: draftToDestroyId,
          old_document_id: isFromDraft ? null : invoice.old_document_id,
        }
      )
      .then((response) => {
        dispatch(
          createInvoiceSuccess(
            response.data.url,
            response.data.batch_document_id,
            invoice.document_type
          )
        )
        dispatch(getUserCompaniesThunk())
      })
      .catch(() => {
        dispatch(createInvoiceFailure(invoice.document_type))
      })
  }
export const createInvoicePreviewThunk =
  (invoice: CreateOrPreviewInvoice) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(previewInvoiceAttempt())
    return axios
      .post<{ url: string; potential_numbering: string }>(
        `/invoicing_documents/create_invoice_preview`,
        invoice
      )
      .then((response) => {
        let billUrl = response.data.url
        if (process.env.REACT_APP_ENV === "local") {
          billUrl = response.data.url.replace(
            "http://bill:5000",
            "http://localhost:5008"
          )
        }
        const numberingPreview = response.data.potential_numbering
        return dispatch(previewInvoiceSuccess(billUrl, numberingPreview))
      })
      .catch(() => {
        dispatch(previewInvoiceFailure(invoice.document_type))
      })
  }

export const createInvoiceDraftThunk =
  (invoice: CreateOrPreviewInvoice, currentDraftId: number | null) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(draftInvoiceAttempt())
    return axios
      .post(`/invoicing_documents/create_invoice_draft`, {
        ...invoice,
        current_draft_id: currentDraftId,
      })
      .then((response) => {
        return dispatch(draftInvoiceSuccess())
      })
      .catch(() => {
        dispatch(draftInvoiceFailure())
      })
  }

export const hasDuplicateInvoiceThunk =
  (invoice: CreateOrPreviewInvoice, companyId: number) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(hasDuplicateAttempt())
    return axios
      .post<{ exist: Boolean }>(
        `/invoicing_documents/check_invoicing_document_already_exist`,
        {
          company_id: companyId,
          legal_entity_id: invoice.legal_entity,
          invoice_date: invoice.invoice_date,
          due_date: invoice.due_date,
          items: invoice.items,
          document_type: invoice.document_type,
        }
      )
      .then((response) => {
        dispatch(hasDuplicateSuccess(response.data.exist))
      })
      .catch(() => {
        dispatch(hasDuplicateFailure())
      })
  }

export const selectClientList = (state: RootState) =>
  state.invoicing.legalEntities.map((c) => ({
    legalEntityId: String(c.legal_entity_id),
    name: c.name,
    clientTypology: c.typology,
    totalDiscount: c.total_discount,
  }))

export const getUrlForFullDocumentThunk =
  (fullDocumentId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    axios
      .get<string>("/full_documents/get_url_for_full_document", {
        params: { id: fullDocumentId },
      })
      .then((response) => {
        window.open(response.data, "_blank")
      })
      .catch(() => dispatch(getUrlFailureAction()))
  }

export interface InvoicePayload {
  id: number
  numbering: string
  date: string
  dueDate: string
  amount: string
  client: string
  issueBy: string
  emailedTo: string[]
  documentType: "invoice" | "quotation" | "creditNote" | ""
  overtakingId?: number
  linkedDocumentsNumber?: number
  batchDocumentId?: number | null
  isLastInvoiceOfGroup?: boolean
}

export interface InvoicingDocument {
  id: number
  invoice_number: 0
  created_at: string
  invoice_date: string
  due_date: string
  legal_notice: string
  bill_uuid: null
  company_id: number
  invoicing_numbering_id: number | null
  legal_entity_id: number | null
  invoicing_logo_id: number
  batch_document_id: null
  creator_id: number
  document_type: "creditNote" | "quotation" | "invoice"
  overtaking_id: null
  additional_logos: string
  emails: string
  draft: true
  total_discount: number | null
  free_text: string
}

export interface InvoiceDraftsPayload extends InvoicingDocument {
  amount: number | null
  client: string | null
  issue_by: string
}

const regroupInvoices = (invoices: InvoicePayload[]) => {
  let blocks: Indexed<InvoiceBlock> = {}
  for (let i = 0; i < invoices.length; i++) {
    let invoice = invoices[i]
    if (!invoice.overtakingId) {
      blocks[invoice.id] = {
        status: "collapsed",
        invoices: [invoice],
      }
    }
  }
  for (let i = 0; i < invoices.length; i++) {
    let invoice = invoices[i]
    if (invoice.overtakingId && invoice.documentType === "invoice") {
      blocks[invoice.overtakingId]["invoices"].push(invoice)
    }
  }
  for (let i = 0; i < invoices.length; i++) {
    let invoice = invoices[i]
    if (invoice.overtakingId && invoice.documentType === "quotation") {
      if (blocks[invoice.overtakingId]) {
        blocks[invoice.overtakingId]["invoices"].push(invoice)
      } else {
        const lastIndex = invoices.find(
          (line) => line.id === invoice.overtakingId
        )?.overtakingId
        if (lastIndex) blocks[lastIndex]["invoices"].push(invoice)
      }
    }
  }
  return blocks
}

export const getAllInvoicesThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getAllInvoicesAttempt())
    return axios
      .get<InvoicePayload[]>(`/invoicing_documents/get_all_invoices`, {
        params: { company_id: companyId },
      })
      .then((response) => {
        dispatch(getAllInvoicesSuccess(regroupInvoices(response.data)))
      })
      .catch(() => {
        dispatch(getAllInvoicesFailure())
      })
  }

export const getDraftInvoicesThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getDraftInvoicesAttempt())
    return axios
      .get<InvoiceDraftsPayload[]>(`/invoicing_documents/draft_invoices`, {
        params: { company_id: companyId },
      })
      .then((response) => {
        dispatch(getDraftInvoicesSuccess(response.data))
      })
      .catch(() => {
        dispatch(getDraftInvoicesFailure())
      })
  }

export const deleteDraftInvoiceThunk =
  ({
    companyId,
    draftInvoicingDocumentId,
  }: {
    companyId: number
    draftInvoicingDocumentId: number
  }) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(deleteDraftInvoiceAttempt())
    return axios
      .delete<InvoiceDraftsPayload[]>(`/invoicing_documents/delete_draft`, {
        params: {
          company_id: companyId,
          draft_invoicing_document_id: draftInvoicingDocumentId,
        },
      })
      .then(() => {
        dispatch(deleteDraftInvoiceSuccess(draftInvoicingDocumentId))
      })
      .catch(() => {
        dispatch(deleteDraftInvoiceFailure())
      })
  }

export const getInvoiceUrlThunk =
  (invoiceId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getInvoiceUrlAttempt())
    return axios
      .get<string>(`/invoicing_documents/display_invoice_for_client`, {
        params: { id: invoiceId },
      })
      .then((response) => {
        dispatch(getInvoiceUrlSuccess(response.data))
      })
      .catch(() => {
        dispatch(getInvoiceUrlFailure())
      })
  }

export interface ProductForInvoice {
  productId: number
  newDescription: string
  productDiscount: number
  quantity: number
}

export interface InvoiceForOvertaking {
  currentDraftId: number | null
  products: ProductForInvoice[]
  invoicingDocument: InvoicingDocument
}

export interface InvoiceForOvertakingPayload {
  current_draft_id: number | null
  products: {
    product_id: number
    quantity: number
    description: string
    product_discount: number
  }[]
  invoicing_document: InvoicingDocument
}

export const getInvoiceForOvertakingThunk =
  (invoiceId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getInvoiceForOvertakingAttempt())
    return axios
      .get<InvoiceForOvertakingPayload>(
        `/invoicing_documents/get_invoice_for_overtaking`,
        {
          params: { id: invoiceId },
        }
      )
      .then((response) => {
        const invoiceForOvertaking = {
          currentDraftId: response.data.current_draft_id,
          invoicingDocument: response.data.invoicing_document,
          products: response.data.products.map((p) => ({
            productId: p.product_id,
            quantity: Math.floor(p.quantity),
            newDescription: p.description,
            productDiscount: p.product_discount,
          })),
        }
        dispatch(getInvoiceForOvertakingSuccess(invoiceForOvertaking))
      })
      .catch(() => {
        dispatch(getInvoiceForOvertakingFailure())
      })
  }
export const getInvoicingAdditionalLogosThunk =
  (companyId: number) => (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getAdditionalLogosAttempt())
    return axios
      .get<AdditionalLogo[]>(
        `/invoicing_additional_logos/get_all_invoicing_additional_logos`,
        {
          params: { company_id: companyId },
        }
      )
      .then(({ data }) => {
        dispatch(getAdditionalLogosSuccess(data))
      })
      .catch(() => {
        dispatch(getAdditionalLogosFailure())
      })
  }

export const addInvoicingAdditionalLogoThunk =
  (companyId: number, originalFileName: string, file: File) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(addAdditionalLogoAttempt())
    return axios
      .post<AnyAction>(
        `/invoicing_additional_logos/create_invoicing_additional_logo`,
        {
          company_id: companyId,
          original_file_name: originalFileName,
        }
      )
      .then((response) => {
        UploadFileToS3Logo({
          putUrl: response.data["put_url"],
          file,
        }).then((response) => {
          if (!response.config?.url) {
            return
          }
          dispatch(addAdditionalLogoSuccess())
        })
      })
      .catch(() => {
        dispatch(addAdditionalLogoFailure())
      })
  }

const UploadFileToS3Logo = ({
  putUrl,
  file,
}: {
  putUrl: string
  file: File
}) => {
  let config = {
    withCredentials: false,
    headers: {
      "Cache-Control": "public, max-age=31536000",
    },
  }

  return axios.put(putUrl, file, config)
}

export const deleteInvoicingAdditionalLogoThunk =
  (companyId: number, file_name: string, logo_id: number) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(deleteAdditionalLogoAttempt())
    return axios
      .post(
        `/invoicing_additional_logos/deactivate_invoicing_additional_logo`,
        {
          company_id: companyId,
          file_name: file_name,
          logo_id: logo_id,
        }
      )
      .then(() => {
        dispatch(deleteAdditionalLogoSuccess())
      })
      .catch(() => deleteAdditionalLogoFailure())
  }

export const updateInvoicingAdditionalLogoThunk =
  (
    companyId: number,
    file_name: string,
    is_default: boolean,
    logo_id: number
  ) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(updateAdditionalLogoAttempt())
    return axios
      .post(`/invoicing_additional_logos/update_invoicing_additional_logo`, {
        company_id: companyId,
        file_name: file_name,
        is_default: is_default,
        logo_id: logo_id,
      })
      .then(() => {
        dispatch(updateAdditionalLogoSuccess())
      })
      .catch(() => updateAdditionalLogoFailure())
  }

interface ReminderContent {
  to: string[]
  bcc: string[]
  cc: string[]
  subject: string
  message: string
  from: string
  invoicing_document_id: number
}

export const sendReminderThunk =
  (reminderContent: ReminderContent) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(sendReminderAttempt())
    return axios
      .post(`/invoicing_documents/send_reminder`, reminderContent)
      .then((response) => {
        dispatch(sendReminderSuccess())
      })
      .catch(() => {
        dispatch(sendReminderFailure())
      })
  }

interface InvoicingDocumentHistoryEvent {
  email: string
  timestamp: string
  event_type: "reminder"
}

export const getInvoicingDocumentHistoryThunk =
  ({ invoicing_document_id }: { invoicing_document_id: number }) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getInvoicingDocumentHistoryAttempt())
    return axios
      .get<InvoicingDocumentHistoryEvent[]>(
        `/invoicing_documents/invoicing_document_user_event_history`,
        {
          params: { invoicing_document_id },
        }
      )
      .then((response) => {
        dispatch(getInvoicingDocumentHistorySuccess(response.data))
      })
      .catch(() => {
        dispatch(getInvoicingDocumentHistoryFailure())
      })
  }

interface CustomerHistoryEvent {
  email: string
  timestamp: string
  event_type: "created" | "changed_total_discount"
}

export const getCustomerHistoryThunk =
  ({ legal_entity_info_id }: { legal_entity_info_id: number }) =>
  (dispatch: Dispatch<InvoicingActionsType>) => {
    dispatch(getCustomerHistoryAttempt())
    return axios
      .get<CustomerHistoryEvent[]>(`/invoicing_clients/history`, {
        params: { legal_entity_info_id },
      })
      .then((response) => {
        dispatch(getCustomerHistorySuccess(response.data))
      })
      .catch(() => {
        dispatch(getCustomerHistoryFailure())
      })
  }
