import { DateRange, RangeType } from "rsuite/esm/DateRangePicker/types"
import { startOfMonth, endOfMonth, addMonths, addDays } from "date-fns"
import { boxShadow, colors } from "../styles/design.config"
import styled from "styled-components/macro"
import { ReactComponent as Warning } from "../assets/treasury-warning.svg"
import {
  TreasuryFullDocuments,
  TreasuryMerchants,
  UsersWithPermissions,
} from "../store/ducks/treasury.ducks"
import { sortByDate } from "./filesList"
import { HeaderTitleWrapper } from "../components/dropDocuments/StyledDropsComponents"

export const lateDate = addDays(new Date(), 0)
export const allTime: [Date, Date] = [
  new Date(new Date().getFullYear() - 10, 10, 10),
  new Date(),
]

export interface PaymentOptionType {
  label: string
  value: string
}

export enum BuySellSortOptionsValues {
  "_",
  "due_date",
  "date",
  "merchants",
  "file_name",
  "amount",
}

export const paymentOptions: PaymentOptionType[] = [
  { label: "Aucun", value: "none" },
  { label: "Jusqu'à 500e", value: "500" },
  { label: "Jusqu'à 2000e", value: "2000" },
  { label: "Jusqu'à 10000e", value: "10000" },
  { label: "Tous", value: "all" },
]

export const predefinedRanges: RangeType[] = [
  {
    label: "Selectionner jusqu'au :",
    value: [] as unknown as DateRange,
    placement: "left",
  },
  {
    label: "Mois en cours",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: "left",
  },
  {
    label: "30 prochains jours",
    value: [new Date(), addDays(new Date(), 30)],
    placement: "left",
  },
  {
    label: "90 prochains jours",
    value: [new Date(), addDays(new Date(), 90)],
    placement: "left",
  },
  {
    label: "6 prochains mois",
    value: [new Date(), addMonths(new Date(), 6)],
    placement: "left",
  },
  {
    label: "12 prochains mois",
    value: [new Date(), addMonths(new Date(), 12)],
    placement: "left",
  },
  {
    label: "18 prochains mois",
    value: [new Date(), addMonths(new Date(), 18)],
    placement: "left",
  },
  {
    label: "Tout le temps",
    value: allTime,
    placement: "left",
  },
]

export const filterDocuments = (
  search: string,
  fullDocuments: TreasuryFullDocuments[]
): TreasuryFullDocuments[] => {
  if (!fullDocuments) return []
  return fullDocuments.filter(
    (fullDocument) =>
      String(Math.abs(fullDocument?.amount))
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      String(Math.abs(fullDocument?.amount))
        .replace(".", ",")
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      fullDocument?.user_file_name
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      fullDocument?.file_name?.toLowerCase().includes(search.toLowerCase()) ||
      fullDocument?.merchant_name?.toLowerCase().includes(search.toLowerCase())
  )
}

export const filterUsers = (
  search: string,
  users: UsersWithPermissions[]
): UsersWithPermissions[] => {
  if (!users) return []
  return users.filter((user) =>
    user?.email?.toLowerCase().includes(search.toLowerCase())
  )
}

export const filterMerchants = (
  search: string,
  merchants: TreasuryMerchants[]
): TreasuryMerchants[] => {
  if (!merchants) return []
  return merchants.filter((merchant) =>
    merchant?.name?.toLowerCase().includes(search.toLowerCase())
  )
}

export const sorterFunction = (
  asc: boolean,
  option: BuySellSortOptionsValues,
  fullDocuments: TreasuryFullDocuments[]
) => {
  const fullDocumentsSorted = [...fullDocuments]
  if (option === BuySellSortOptionsValues?.due_date) {
    fullDocumentsSorted.sort(
      (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
        asc
          ? sortByDate(a.document_due_date, b.document_due_date)
          : sortByDate(b.document_due_date, a.document_due_date)
    )
  } else if (option === BuySellSortOptionsValues?.date) {
    fullDocumentsSorted.sort(
      (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
        asc
          ? sortByDate(a.document_date, b.document_date)
          : sortByDate(b.document_date, a.document_date)
    )
  } else if (option === BuySellSortOptionsValues?.merchants) {
    fullDocumentsSorted.sort(
      (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
        asc
          ? a?.merchant_name.localeCompare(b?.merchant_name)
          : b?.merchant_name.localeCompare(a?.merchant_name)
    )
  } else if (option === BuySellSortOptionsValues?.file_name) {
    fullDocumentsSorted.sort(
      (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
        asc
          ? a?.user_file_name.localeCompare(b?.user_file_name)
          : b?.user_file_name.localeCompare(a?.user_file_name)
    )
  } else if (option === BuySellSortOptionsValues?.amount) {
    fullDocumentsSorted.sort(
      (a: TreasuryFullDocuments, b: TreasuryFullDocuments) =>
        asc ? a.amount - b.amount : b.amount - a.amount
    )
  }
  return fullDocumentsSorted
}

export const sortFullDocumentsByDate = (
  fullDocuments: TreasuryFullDocuments[],
  asc: boolean
) => {
  return [...fullDocuments.sort((a, b) => (asc ? a.id - b.id : b.id - a.id))]
}

export const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`
export const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  border-radius: 2.5rem;
  margin: 0 2rem 4rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WrapperTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
`
export const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
export const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  touch-action: "auto";
`
export const StyledTotalAmount = styled.div`
  display: flex;
  min-width: 26rem;
  max-width: 42rem;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
`
export const StyledFullDocumentCount = styled.div`
  display: flex;
  min-width: 8rem;
  max-width: 20rem;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
`
export const WrapperCalendar = styled.div`
  display: flex;
  width: 400px;
  height: 100%;
`
export interface FisrItemProps {
  isFirstColumn?: boolean
  isLastColumn?: boolean
  marginTop?: string
}
export const StyledCenter = styled.div<FisrItemProps>`
  justify-content: ${(props) =>
    props.isFirstColumn
      ? "flex-start"
      : props.isLastColumn
      ? "flex-end"
      : "center"};
  align-items: center;
  display: flex;
  width: 90%;
  height: 5rem;
  margin-right: ${(props) => (props.isLastColumn ? "20%" : "")};
`
export const StyledWarning = styled(Warning)`
  & path {
    fill: ${colors.amaranth};
  }
`
export const HeadBand = styled.div`
  width: 100%;
  min-height: 6rem;
  background-color: ${colors.cornflower};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`
export const TreasuryHeaderWrapper = styled(HeaderTitleWrapper)`
  padding: 0;
`
