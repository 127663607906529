import { AnyAction } from "redux"
import axios from "axios"
import { sortByDate } from "../../utils/filesList"
import { Dispatch, RNBThunkAction } from "../store.config"
import { RowPayload } from "../../utils/bank"

export const enum BankArchivesActionsEnum {
  SYNC_BANK_TRANSACTIONS_ATTEMPT = "BANK_ARCHIVES/sync_bank_transactions_attempt",
  SYNC_BANK_TRANSACTIONS_SUCCESS = "BANK_ARCHIVES/sync_bank_transactions_success",
  SYNC_BANK_TRANSACTIONS_FAILURE = "BANK_ARCHIVES/sync_bank_transactions_failure",
  SYNC_BANK_TRANSACTIONS_RESET = "BANK_ARCHIVES/sync_bank_transactions_reset",
  GET_ARCHIVES_ATTEMPT = "BANK_ARCHIVES/getArchivesAttempt",
  GET_ARCHIVES_SUCCESS = "BANK_ARCHIVES/getArchivesSuccess",
  GET_ARCHIVES_FAILURE = "BANK_ARCHIVES/getArchivesFailure",
  DOWNLOADING_STATUS_ATTEMPT = "BANK_ARCHIVES/downloadingStatusAttempt",
  DOWNLOADING_STATUS_DOWNLOADING = "BANK_ARCHIVES/downloadingStatusDownloading",
  DOWNLOADING_STATUS_DOWNLOADED = "BANK_ARCHIVES/downloadingStatusDownloaded",
  DOWNLOADING_STATUS_RESET = "BANK_ARCHIVES/downloadingStatusReset",
  DOWNLOADING_STATUS_FAILURE = "BANK_ARCHIVES/downloadingStatusFailure",
  GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_ATTEMPT = "BANK_ARCHIVES/getNewOperationsWithoutArchiveAttempt",
  GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_SUCCESS = "BANK_ARCHIVES/getNewOperationsWithoutArchiveSuccess",
  GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_FAILURE = "BANK_ARCHIVES/getNewOperationsWithoutArchiveFailure",
  GENERATE_ARCHIVE_ATTEMPT = "BANK_ARCHIVES/generateArchiveAttempt",
  GENERATE_ARCHIVE_SUCCESS = "BANK_ARCHIVES/generateArchiveSuccess",
  GENERATE_ARCHIVE_FAILURE = "BANK_ARCHIVES/generateArchiveFailure",
  REGENERATE_BANKIN_ARCHIVE_ATTEMPT = "BANK_ARCHIVES/regenerateArchiveAttempt",
  REGENERATE_BANKIN_ARCHIVE_SUCCESS = "BANK_ARCHIVES/regenerateArchiveSuccess",
  REGENERATE_BANKIN_ARCHIVE_FAILURE = "BANK_ARCHIVES/regenerateArchiveFailure",
  REGENERATE_BANKIN_ARCHIVE_RESET = "BANK_ARCHIVES/regenerateArchiveReset",
}
export const syncBankTransactionsFailureAction = () =>
  ({
    type: BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "bank-account.get-new-operations.error.title",
      bodyKey: "bank-account.get-new-operations.error.body",
    },
  } as const)
export const syncBankTransactionsResetAction = () =>
  ({ type: BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_RESET } as const)
export const syncBankTransactionsAttemptAction = () =>
  ({ type: BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_ATTEMPT } as const)
export const syncBankTransactionsSuccessAction = () =>
  ({
    type: BankArchivesActionsEnum.SYNC_BANK_TRANSACTIONS_SUCCESS,
  } as const)
export const getArchivesAttempt = () =>
  ({ type: BankArchivesActionsEnum.GET_ARCHIVES_ATTEMPT } as const)
export const getArchivesSuccess = (payload: ArchivesPayload[]) =>
  ({ type: BankArchivesActionsEnum.GET_ARCHIVES_SUCCESS, payload } as const)
export const getArchivesFailure = () =>
  ({ type: BankArchivesActionsEnum.GET_ARCHIVES_FAILURE } as const)
export const downloadingStatusAttempt = () =>
  ({ type: BankArchivesActionsEnum.DOWNLOADING_STATUS_ATTEMPT } as const)
export const downloadingStatusDownloading = () =>
  ({ type: BankArchivesActionsEnum.DOWNLOADING_STATUS_DOWNLOADING } as const)
export const downloadingStatusDownloaded = () =>
  ({ type: BankArchivesActionsEnum.DOWNLOADING_STATUS_DOWNLOADED } as const)
export const downloadingStatusReset = () =>
  ({ type: BankArchivesActionsEnum.DOWNLOADING_STATUS_RESET } as const)
export const downloadingStatusFailure = () =>
  ({ type: BankArchivesActionsEnum.DOWNLOADING_STATUS_FAILURE } as const)
export const getNewOperationsWithoutArchivesAttemptAction = () =>
  ({
    type: BankArchivesActionsEnum.GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_ATTEMPT,
  } as const)
export const getNewOperationsWithoutArchivesFailureAction = () =>
  ({
    type: BankArchivesActionsEnum.GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_FAILURE,
  } as const)
export const getNewOperationsWithoutArchivesSuccessAction = (
  payload: newOperationsWithoutArchive[]
) =>
  ({
    type: BankArchivesActionsEnum.GET_NEW_OPERATIONS_WITHOUT_ARCHIVE_SUCCESS,
    payload,
  } as const)
export const generateArchiveAttemptAction = (fiscalYearId: number) =>
  ({
    type: BankArchivesActionsEnum.GENERATE_ARCHIVE_ATTEMPT,
    fiscalYearId,
  } as const)
export const generateArchiveSuccessAction = (fiscalYearId: number) =>
  ({
    type: BankArchivesActionsEnum.GENERATE_ARCHIVE_SUCCESS,
    fiscalYearId,
  } as const)
export const generateArchiveFailureAction = (
  fiscalYearId: number,
  message: string
) =>
  ({
    type: BankArchivesActionsEnum.GENERATE_ARCHIVE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "archives.table-content.archive.generating-failure.title",
      bodyKey: message,
    },
    fiscalYearId,
  } as const)

export const regenerateBankinArchiveAttempt = () =>
  ({
    type: BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_ATTEMPT,
  } as const)
export const regenerateBankinArchiveSuccess = () =>
  ({
    type: BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_SUCCESS,
  } as const)
export const regenerateBankinArchiveReset = () =>
  ({
    type: BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_RESET,
  } as const)
export const regenerateBankinArchiveFailure = () =>
  ({
    type: BankArchivesActionsEnum.REGENERATE_BANKIN_ARCHIVE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "bank-management.archives.regenerate-archive-modal.error.title",
      bodyKey:
        "bank-management.archives.regenerate-archive-modal.error.message",
    },
  } as const)

export type BankArchivesActionsType = ReturnType<
  | typeof syncBankTransactionsAttemptAction
  | typeof syncBankTransactionsSuccessAction
  | typeof syncBankTransactionsFailureAction
  | typeof syncBankTransactionsResetAction
  | typeof getArchivesAttempt
  | typeof getArchivesSuccess
  | typeof getArchivesFailure
  | typeof downloadingStatusAttempt
  | typeof downloadingStatusDownloading
  | typeof downloadingStatusDownloaded
  | typeof downloadingStatusReset
  | typeof downloadingStatusFailure
  | typeof getNewOperationsWithoutArchivesAttemptAction
  | typeof getNewOperationsWithoutArchivesSuccessAction
  | typeof getNewOperationsWithoutArchivesFailureAction
  | typeof generateArchiveAttemptAction
  | typeof generateArchiveSuccessAction
  | typeof generateArchiveFailureAction
  | typeof regenerateBankinArchiveAttempt
  | typeof regenerateBankinArchiveSuccess
  | typeof regenerateBankinArchiveFailure
  | typeof regenerateBankinArchiveReset
>

export const SyncBankinTransactionsThunk =
  (accountId: number, fiduciaryId: number, companyId: number, itemId: number) =>
  (dispatch: Dispatch<BankArchivesActionsType>) => {
    dispatch(syncBankTransactionsResetAction())
    dispatch(syncBankTransactionsAttemptAction())
    return axios
      .post(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/sync_bankin_transactions`
      )
      .then(() => {
        dispatch(syncBankTransactionsSuccessAction())
      })
      .catch(() => {
        dispatch(syncBankTransactionsFailureAction())
      })
  }

interface NewOperationsWithoutArchiveResponse {
  fiscal_year_id: number
  count: number
  begin_exercise: string
  end_exercise: string
}

export const getNewOperationsWithoutArchivesThunk =
  (accountId: number, timeOut?: number) =>
  (dispatch: Dispatch<BankArchivesActionsType>) => {
    dispatch(getNewOperationsWithoutArchivesAttemptAction())
    return axios
      .get<NewOperationsWithoutArchiveResponse[]>(
        `/bankin_accounts/${accountId}/count_new_transactions`
      )
      .then((response) => {
        if (response.data.length > 0) {
          const operations: newOperationsWithoutArchive[] = response.data.map(
            (o) => {
              return {
                fiscalYearId: o.fiscal_year_id,
                newTransactionsCount: o.count,
                begin_exercise: o.begin_exercise,
                end_exercise: o.end_exercise,
                status: "NOT_GENERATED",
              }
            }
          )
          setTimeout(() => {
            dispatch(getNewOperationsWithoutArchivesSuccessAction(operations))
          }, timeOut || 0)
        }
      })
      .catch(() => {
        dispatch(getNewOperationsWithoutArchivesFailureAction())
      })
  }

export const generateArchiveThunk =
  (
    fiscalYearId: number,
    {
      fiduciaryId,
      companyId,
      itemId,
      accountId,
      generate_choice,
    }: ArchivesParams
  ) =>
  (dispatch: Dispatch<BankArchivesActionsType | RNBThunkAction>) => {
    dispatch(generateArchiveAttemptAction(fiscalYearId))
    const timeOut = 1500
    return axios
      .post(`/bankin_accounts/${accountId}/list_transactions`, {
        fiscal_year_id: fiscalYearId,
        generate_choice: generate_choice,
      })
      .then(() => {
        dispatch(getNewOperationsWithoutArchivesThunk(accountId, timeOut))
        dispatch(
          getArchivesThunk(
            { fiduciaryId, companyId, itemId, accountId },
            timeOut
          )
        )
      })
      .then(() => {
        setTimeout(() => {
          dispatch(generateArchiveSuccessAction(fiscalYearId))
        }, timeOut)
      })
      .catch((error) => {
        let message = "archives.table-content.archive.generating-failure.body"
        if (error.response.data.error.includes("no transactions to generate")) {
          message += ".no_transaction"
        }
        setTimeout(() => {
          dispatch(generateArchiveFailureAction(fiscalYearId, message))
        }, timeOut)
      })
  }

export const regenerateBankinArchiveThunk =
  ({ bankinArchiveId }: { bankinArchiveId: number }) =>
  (dispatch: Dispatch<BankArchivesActionsType | RNBThunkAction>) => {
    dispatch(regenerateBankinArchiveAttempt())

    const timeOut = 2000

    return axios
      .post(`/bankin_archives/${bankinArchiveId}/regenerate`)
      .then(() => {
        setTimeout(() => {
          dispatch(regenerateBankinArchiveSuccess())
        }, timeOut)
      })
      .catch(() => {
        dispatch(regenerateBankinArchiveFailure())
      })
  }

export const getArchivesThunk =
  (
    { fiduciaryId, companyId, itemId, accountId }: ArchivesParams,
    timeOut?: number
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(getArchivesAttempt())
    return axios
      .get<ArchivesResponse>(
        `/fiduciaries/${fiduciaryId}/companies/${companyId}/bankin_items/${itemId}/bankin_accounts/${accountId}/list_archives`
      )
      .then(({ data }) => {
        const archivesFormatted = Object.values(data)[0].map(
          (archive: ArchivesResponse) => ({
            archiveId: archive.id,
            created_at: archive.created_at,
            downloadedAt: archive.downloaded_at,
            downloadUserId: archive.download_user_id,
            downloadFirstName: archive.first_name,
            downloadLastName: archive.last_name,
            fiscalYearId: archive.fiscal_year_id,
            fileName: archive.file_name,
            begin_exercise: archive.begin_exercise,
            end_exercise: archive.end_exercise,
            accountId: accountId,
            count_bankin_transaction: archive.count_bankin_transaction,
            status: archive.generated_at ? "GENERATED" : "GENERATING",
            generated_at: archive.generated_at,
          })
        )
        setTimeout(() => {
          dispatch(
            getArchivesSuccess(
              archivesFormatted.sort((a: RowPayload, b: RowPayload) =>
                sortByDate(a.created_at, b.created_at)
              )
            )
          )
        }, timeOut || 0)
      })
      .catch(() => {
        dispatch(getArchivesFailure())
      })
  }

export const getArchiveUrlThunk =
  ({
    id,
    companyName,
    accountSoftwareReference,
    accountSoftware,
  }: ArchiveUrlParams) =>
  (dispatch: Dispatch<AnyAction>) => {
    dispatch(downloadingStatusAttempt())
    dispatch(downloadingStatusDownloading())
    const requestURI = `/bankin_archives/${id}/download`
    const params = {}
    return axios
      .post<ArchiveRequestPayload>(requestURI, params, {
        withCredentials: false,
      })
      .then((response) => {
        generateDownloadFileName(
          response.data["s3_url"],
          companyName,
          id,
          accountSoftwareReference,
          accountSoftware
        ).then(() => {
          dispatch(downloadingStatusDownloaded())
          setTimeout(() => {
            dispatch(downloadingStatusReset())
          }, 7000)
        })
      })
      .catch(() => {
        dispatch(downloadingStatusFailure())
      })
  }

const generateDownloadFileName = (
  fileLink: string,
  companyName: string,
  archiveId: number,
  accountSoftwareReference: string,
  accountSoftware: string
) => {
  return axios
    .get<Blob>(fileLink, { responseType: "blob", withCredentials: false })
    .then((response) => {
      var blob = new Blob([response.data])
      var downloadElement = document.createElement("a")
      var href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      if (
        accountSoftwareReference &&
        (accountSoftware === "cegid" || accountSoftware === "quadratus")
      ) {
        downloadElement.download = `${accountSoftwareReference}_${archiveId}.zip`
      } else {
        downloadElement.download = `${companyName} Archive N${archiveId}.zip`
      }
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
}

interface ArchivesParams {
  fiduciaryId: number
  companyId: number
  itemId: number
  accountId: number
  generate_choice?: GenerateChoice
}
export type GenerateChoice =
  | "all"
  | "all_except_no_full_doc"
  | "all_except_no_assignment"
  | "all_except_no_full_doc_and_no_assignment"
interface ArchivesResponse {
  id: number
  created_at: string
  downloaded_at: string
  download_user_id: number
  first_name: string
  last_name: string
  fiscal_year_id: number
  file_name: string
  begin_exercise: string
  end_exercise: string
  count_bankin_transaction: number[]
  generated_at: string | null
}

interface ArchiveUrlParams {
  id: number
  companyName: string
  accountSoftwareReference: string
  accountSoftware: string
}

interface ArchiveRequestPayload {
  s3_url: string
  company_name: string
}
export interface ArchivesPayload {
  id: number
  creationDate: string
  downloadDate: string
  downloadUserId: number
  downloadFirstName: string
  downloadLastName: string
  fiscalYearId: number
  fileName: string
}

export interface newOperationsWithoutArchive {
  newTransactionsCount: number
  fiscalYearId: number
  begin_exercise: string
  end_exercise: string
  status: GenerationStatus
}

export type GenerationStatus =
  | "NOT_GENERATED"
  | "GENERATING"
  | "GENERATED"
  | "DOWNLOADING"
  | "DOWNLOADED"
