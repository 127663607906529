import styled from "styled-components/macro"
import { RowPermission } from "./RowPermission"
import { useIntl } from "react-intl"
import * as Ct from "ldlj"
import { boxShadow, colors } from "../styles/design.config"
import { useEffect, useState } from "react"

interface AuthorisationListProps {
  id: string
  isSelected: boolean
  disabled?: boolean
  content: string
  onClick: () => void
}

export const AuthorisationList = ({
  id,
  isSelected = false,
  disabled = true,
  content,
  onClick,
}: AuthorisationListProps) => {
  const intl = useIntl()

  const [borderColor, setBorderColor] = useState(colors.desertStorm)
  const [textColor, setTextColor] = useState(colors.moon)

  useEffect(() => {
    if (disabled) {
      setBorderColor(colors.desertStorm)
      setTextColor(colors.moon)
    } else {
      if (isSelected) {
        setBorderColor(colors.cornflower)
        setTextColor(colors.white)
      } else {
        setBorderColor(colors.hawkes)
        setTextColor(colors.navy)
      }
    }
  }, [isSelected, disabled])

  return (
    <TabContainer
      id={id}
      borderColor={borderColor}
      textColor={textColor}
      disabled={disabled}
      onClick={() => onClick()}
    >
      <TabHeader borderColor={borderColor} textColor={textColor}>
        {content}
      </TabHeader>
      <Ct.Spacer height={2} />
      <List>
        <RowPermission
          disabled={disabled}
          isAuthorized={
            !(
              id === "ClientInvitation" || id === "CustomerAccountantInvitation"
            )
          }
          text={intl.formatMessage({ id: "authorize-list.create-company" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={
            !(
              id === "ClientInvitation" ||
              id === "CollaboratorInvitation" ||
              id === "CustomerAccountantInvitation"
            )
          }
          text={intl.formatMessage({
            id: "authorize-list.deactivate-company",
          })}
        />
        <Ct.Spacer height={1} />

        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({
            id: "autorize-list.manage-bill-credit-quote",
          })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({ id: "authorize-list.sell-bill" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({ id: "autorize-list.issue-quote" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({ id: "autorize-list.issue-credit" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={!(id === "ClientInvitation")}
          text={intl.formatMessage({
            id: "authorize-list.accounting-entries",
          })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={!(id === "ClientInvitation")}
          text={intl.formatMessage({ id: "authorize-list.parameter" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({ id: "authorize-list.bank" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={!(id === "ClientInvitation")}
          text={intl.formatMessage({ id: "authorize-list.download-bank" })}
        />
        <Ct.Spacer height={2} />
        <PaddingBar>
          <Ct.Bar width={12.5} />
        </PaddingBar>
        <Ct.Spacer height={2} />
        <RowPermission
          disabled={disabled}
          isAuthorized={true}
          text={intl.formatMessage({ id: "authorize-list.bill-email" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={!(id === "ClientInvitation")}
          text={intl.formatMessage({ id: "authorize-list.email-chaintrust" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={
            id === "ClientInvitation" || id === "CustomerAccountantInvitation"
          }
          text={intl.formatMessage({
            id: "authorize-list.manage-paiement-traking",
          })}
        />
        <Ct.Spacer height={2} />
        <PaddingBar>
          <Ct.Bar width={12.5} />
        </PaddingBar>
        <Ct.Spacer height={2} />
        <RowPermission
          disabled={disabled}
          isAuthorized={
            !(
              id === "ClientInvitation" ||
              id === "CollaboratorInvitation" ||
              id === "CustomerAccountantInvitation"
            )
          }
          text={intl.formatMessage({ id: "authorize-list.display-team" })}
        />
        <Ct.Spacer height={1} />
        <RowPermission
          disabled={disabled}
          isAuthorized={
            !(
              id === "ClientInvitation" || id === "CustomerAccountantInvitation"
            )
          }
          text={intl.formatMessage({ id: "authorize-list.display-client" })}
        />
      </List>
      <Ct.Spacer height={2} />
    </TabContainer>
  )
}

interface Cliked {
  borderColor: string
  textColor: string
  disabled: boolean
}

interface Header {
  borderColor: string
  textColor: string
}

const TabContainer = styled.div<Cliked>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  background-color: ${colors.white};
  border-radius: 1.5rem;
  border: ${({ borderColor }) => `0.25rem solid ${borderColor}`};
  border-width: 0 0.25rem 0.25rem 0.25rem;
  box-sizing: border-box;
  box-shadow: ${boxShadow};
  cursor: ${({ disabled }) => (disabled ? `cursor` : `pointer`)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
`

const TabHeader = styled.div<Header>`
  position: relative;
  width: 100%;
  height: 6.5rem;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  background-color: ${({ borderColor }) => `${borderColor}`};
  color: ${({ textColor }) => `${textColor}`};
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 4rem;

  @media (max-width: 1401px) {
    padding: 0 1rem;
  }
`

const PaddingBar = styled.div`
  padding: 0 0 0 4rem;
`
