import { useIntl } from "react-intl"
import { Modal } from "../Commons/Modal"
import * as Ct from "ldlj"
import { Text } from "../Commons/Text"
import { colors, opacityColors, sizes } from "../../styles/design.config"
import styled from "styled-components/macro"
import { TableWrapper } from "../dropDocuments/StyledDropsComponents"
import { Table, TableBuilder } from "../Commons/Table"
import { Input } from "../Commons/Input"
import { useEffect, useState } from "react"
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg"
import { useRNBSelector } from "../../store/rootReducer"
import { Select } from "../Commons/Select"
import { CreateUnitLabel, UnitLabel } from "../../utils/unit"
import { useDispatch } from "react-redux"
import {
  createCompanyUnitLabelsThunk,
  createFiduciaryUnitLabelsThunk,
  createUnitLabelsReset,
} from "../../store/ducks/unitLabel.ducks"
import { Alert } from "../Commons/Alert"
/* eslint-disable camelcase */

interface AddUnitLabelProps extends Ct.ModalComponentsProps {
  companyId?: number
  unit_labels: UnitLabel[]
}

export const AddUnitLabelModal = ({
  onClose,
  isDisplayed,
  companyId,
  unit_labels,
}: AddUnitLabelProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const unit_label = useRNBSelector((state) => state.unitLabel)
  const fiduciary = useRNBSelector((state) => state.fiduciary)

  const [hoverId, setHoverId] = useState<number | null>(null)
  const [unitLabelToCreate, setUnitLabelToCreate] = useState<CreateUnitLabel[]>(
    [{ unit: { value: "", label: "" }, display_unit: "", display_symbol: "" }]
  )
  const [unitOptions, setUnitOptions] = useState<Ct.Option<string>[]>(
    unit_label.units.map((unit) => ({
      value: unit.id.toString(),
      label: unit.unit,
      disabled: unit_labels.some(
        (existing_label) => existing_label.unit_id === unit.id
      ),
    }))
  )

  const columns: TableBuilder<CreateUnitLabel>[] = [
    {
      headerText: "unit_labels.unit",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateUnitLabel) => {
        const index = unitLabelToCreate.indexOf(row)
        return (
          <Select
            intl={intl}
            options={unitOptions}
            value={row.unit}
            onChangeCallback={(e: Ct.Option<string>) => {
              const index = unitLabelToCreate.indexOf(row)
              const updatedUnitLabelToCreate = [...unitLabelToCreate]
              updatedUnitLabelToCreate[index] = {
                ...row,
                unit:
                  unitOptions.find((u) => u.value === e.value) ||
                  unitOptions[0],
              }
              setUnitLabelToCreate(updatedUnitLabelToCreate)
            }}
            label={""}
            domain={""}
            optionType={""}
            borderRadius={"0"}
            overrideBorderColor={"grey"}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "unit_labels.display-unit",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateUnitLabel) => {
        const index = unitLabelToCreate.indexOf(row)
        return (
          <Input
            label=""
            value={row.display_unit}
            onChange={(e) => {
              const index = unitLabelToCreate.indexOf(row)
              const updatedUnitLabelToCreate = [...unitLabelToCreate]
              updatedUnitLabelToCreate[index] = {
                ...row,
                display_unit: e.target.value,
              }
              setUnitLabelToCreate(updatedUnitLabelToCreate)
            }}
            borderRadius={0}
            borderColor={`${colors.grey}`}
            backgroundColor={
              hoverId === index ? opacityColors.amaranth : colors.white
            }
          />
        )
      },
    },
    {
      headerText: "unit_labels.display-symbol",
      width: "32%",
      flexGrow: "none",
      content: (row: CreateUnitLabel) => {
        const index = unitLabelToCreate.indexOf(row)
        return (
          <WrapperInput>
            <Input
              label=""
              value={row.display_symbol}
              onChange={(e) => {
                const updatedUnitLabelToCreate = [...unitLabelToCreate]
                updatedUnitLabelToCreate[index] = {
                  ...row,
                  display_symbol: e.target.value,
                }
                setUnitLabelToCreate(updatedUnitLabelToCreate)
              }}
              borderRadius={0}
              borderColor={`${colors.grey}`}
              backgroundColor={
                hoverId === index ? opacityColors.amaranth : colors.white
              }
            />
          </WrapperInput>
        )
      },
    },
    {
      headerText: "",
      width: "4%",
      flexGrow: "none",
      heightItem: "96%",
      content: (row: CreateUnitLabel) => {
        const index = unitLabelToCreate.indexOf(row)
        return (
          <TrashBlock
            onMouseEnter={() => {
              if (index === unitLabelToCreate.length - 1) return
              setHoverId(index)
            }}
            onMouseLeave={() => {
              if (index === unitLabelToCreate.length - 1) return
              setHoverId(null)
            }}
            onClick={() => {
              if (index === unitLabelToCreate.length - 1) return
              if (index > -1) {
                const updatedUnitLabelToCreate = [...unitLabelToCreate]
                updatedUnitLabelToCreate.splice(index, 1)
                setUnitLabelToCreate(updatedUnitLabelToCreate)
                setHoverId(null)
              }
            }}
            backgroundColor={
              hoverId === index ? "rgba(252, 90, 90, 0.2)" : colors.white
            }
          >
            <StyledTrashIcon
              disabled={index === unitLabelToCreate.length - 1}
            />
          </TrashBlock>
        )
      },
    },
  ]

  useEffect(() => {
    if (unit_label.units.length > 0) {
      setUnitOptions(
        unit_label.units.map((unit) => ({
          value: unit.id.toString(),
          label: unit.unit,
          disabled: unit_labels.some(
            (existing_label) => existing_label.unit_id === unit.id
          ),
        }))
      )
    }
  }, [unit_label.units])

  useEffect(() => {
    const lastItem = unitLabelToCreate[unitLabelToCreate.length - 1]
    if (lastItem.display_symbol !== "") {
      setUnitLabelToCreate([
        ...unitLabelToCreate,
        {
          unit: { value: "", label: "" },
          display_unit: "",
          display_symbol: "",
        },
      ])
    }

    setUnitOptions(
      unit_label.units.map((unit) => ({
        value: unit.id.toString(),
        label: unit.unit,
        disabled:
          unit_labels.some(
            (existing_label) => existing_label.unit_id === unit.id
          ) || unitLabelToCreate.some((u) => Number(u.unit.value) === unit.id),
      }))
    )
  }, [unitLabelToCreate])

  useEffect(() => {
    if (unit_label.creation_status === "success") {
      dispatch(createUnitLabelsReset())
      onClose()
    }
  }, [unit_label.creation_status])

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="20%"
    >
      <StyledCard width="70vw">
        <Ct.Spacer height={2} />

        <Ct.CloseCross onClick={onClose} />
        <Text
          text={intl.formatMessage({
            id: `unit_labels.add-unit.fiduciary.title`,
          })}
          textStyle={{ fontSize: 4, fontWeight: 600 }}
        />
        <Ct.Spacer height={2} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <WrapperBlock>
          <Text
            text={intl.formatMessage({
              id: `unit_labels.add-unit.fiduciary.subtitle`,
            })}
            textStyle={{
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          />

          <Ct.Spacer height={2} />
          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: `unit_labels.add-unit.fiduciary.alert`,
              })}
            />
          </Alert>
          <Ct.Spacer height={3} />

          <TableWrapper>
            <Table
              intl={intl}
              columns={columns}
              rows={unitLabelToCreate}
              alignItems={"center"}
              width={"100%"}
              height={"50rem"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              alertMessage={"unit_labels.empty"}
              sortableColumnsLength={0}
              customScrollBar={true}
              paddingHeader={"0"}
              centerFirstItem={true}
            />
          </TableWrapper>
        </WrapperBlock>

        <Ct.Spacer height={4} />

        <Text
          text={intl.formatMessage(
            {
              id:
                unitLabelToCreate.length - 1 <= 1
                  ? `unit_labels.add-unit.one-count-to-add`
                  : "unit_labels.add-unit.multiple-count-to-add",
            },
            { count: unitLabelToCreate.length - 1 }
          )}
        />
        <Ct.Spacer height={4} />

        <WrapperButtons>
          <Ct.Button
            label={intl.formatMessage({
              id: "unit_labels.add-unit.fiduciary.abort",
            })}
            onClick={onClose}
            width={sizes.button.standard}
            colorType="Tertiary"
            colorScheme={{
              border: "lavender",
              color: "cornflower",
              background: "lavender",
            }}
          />
          <Ct.Spacer height={2} />
          <Ct.Button
            label={intl.formatMessage({
              id: "unit_labels.add-unit.fiduciary.save",
            })}
            onClick={() => {
              if (unitLabelToCreate.length > 1) {
                if (companyId) {
                  dispatch(
                    createCompanyUnitLabelsThunk(
                      fiduciary.id,
                      unitLabelToCreate.slice(0, -1).map((unit) => {
                        return {
                          unit_id: Number(unit.unit.value),
                          display_symbol: unit.display_symbol,
                          display_unit: unit.display_unit,
                        }
                      }),
                      companyId
                    )
                  )
                } else {
                  dispatch(
                    createFiduciaryUnitLabelsThunk(
                      fiduciary.id,
                      unitLabelToCreate.slice(0, -1).map((unit) => {
                        return {
                          unit_id: Number(unit.unit.value),
                          display_symbol: unit.display_symbol,
                          display_unit: unit.display_unit,
                        }
                      })
                    )
                  )
                }
              }
            }}
            width={sizes.button.standard}
            disabled={
              !unitLabelToCreate
                .slice(0, -1)
                .every(
                  (unit) =>
                    unit.display_unit !== "" &&
                    unit.display_symbol !== "" &&
                    unit.unit.value !== ""
                )
            }
          />
        </WrapperButtons>
        <Ct.Spacer height={2} />
      </StyledCard>
    </Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  padding: 0;
`
const WrapperBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 80%;
`
const WrapperButtons = styled.div`
  display: flex;
`
const StyledTrashIcon = styled(TrashIcon)<{ disabled: boolean }>`
  fill: ${(props) => (props.disabled ? colors.moon : colors.rock)};
  padding-left: 0.6rem;
  padding-top: 0.2rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: 0.5s fill ease-in-out;

  :hover {
    fill: ${(props) => (props.disabled ? colors.moon : colors.amaranth)};
  }
`
const WrapperInput = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
  display: flex;
  width: 100%;
`
const TrashBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  border: 1px solid ${colors.grey};
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  transition: 0.5s background-color ease-in-out;
`
