import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../styles/design.config"
import { useIntl } from "react-intl"
import { Text } from "../../../components/Commons/Text"
import { Alert } from "../../../components/Commons/Alert"
import * as Ct from "ldlj"
import { TableWrapper } from "../../../components/Commons/TableStylesForSelect"
import { Table, TableBuilder } from "../../../components/Commons/Table"
import { UnitLabel, UnitLabelEvents } from "../../../utils/unit"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { useRNBSelector } from "../../../store/rootReducer"
import { getFiduciaryUnitLabelsThunk } from "../../../store/ducks/unitLabel.ducks"
import { ReactComponent as Eye } from "../../../assets/eyeFilled.svg"
import { ReactComponent as Trash } from "../../../assets/drop-documents/TrashCan.svg"
import { UnitLabelEventsModal } from "../../../components/fiduciary/UnitLabelEventsModal"
import { AddUnitLabelModal } from "../../../components/fiduciary/AddUnitLabelModal"
import { useNavigate } from "react-router-dom"
import { getUnitsThunk } from "../../../store/ducks/unitLabel.ducks"
import { destroyUnitLabelThunk } from "../../../store/ducks/unitLabel.ducks"
import { ReactComponent as Calculator } from "../../../assets/calculator.svg"

export const FiduciaryUnitLabels = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fiduciary = useRNBSelector((state) => state.fiduciary)

  const [displayEvents, setDisplayEvents] = useState<UnitLabelEvents[] | null>(
    null
  )
  const [addUnitLabel, setAddUnitLabel] = useState<boolean>(false)
  const [backgroundColors, setBackgroundColors] = useState<
    Array<keyof typeof colors>
  >([])

  if (
    fiduciary &&
    fiduciary.comptaSoftware !== "" &&
    fiduciary.comptaSoftware !== "isacompta"
  ) {
    navigate("/Unauthorized")
  }

  useEffect(() => {
    if (fiduciary.id) {
      dispatch(getFiduciaryUnitLabelsThunk(fiduciary.id))
    }
  }, [fiduciary.id, dispatch])

  useEffect(() => {
    if (fiduciary?.unit_labels && fiduciary?.unit_labels?.length > 0) {
      setBackgroundColors(
        fiduciary.unit_labels?.map((_, index) => {
          return index % 2 === 0 ? "white" : "clearBlue"
        })
      )
    }
  }, [fiduciary?.unit_labels])

  const columns: TableBuilder<UnitLabel>[] = [
    {
      headerText: "unit_labels.unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.unit}</>,
    },
    {
      headerText: "unit_labels.display-unit",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_unit}</>,
    },
    {
      headerText: "unit_labels.display-symbol",
      width: "30%",
      flexGrow: "none",
      content: (row: UnitLabel) => <>{row.display_symbol}</>,
    },
    {
      headerText: "",
      width: "10%",
      flexGrow: "none",
      content: (row: UnitLabel) => {
        const index = fiduciary?.unit_labels?.indexOf(row)

        return (
          <Actions>
            <StyledEye
              onClick={() => {
                setDisplayEvents(row.events)
              }}
            />
            <Ct.Spacer />
            <div
              onClick={() => {
                dispatch(destroyUnitLabelThunk(fiduciary.id, row.id))
              }}
              onMouseEnter={() => {
                if (
                  fiduciary?.unit_labels &&
                  fiduciary?.unit_labels?.length > 0
                ) {
                  setBackgroundColors(
                    fiduciary?.unit_labels?.map((_, id) => {
                      return id === index
                        ? "deleteBackground"
                        : id % 2 === 0
                        ? "white"
                        : "clearBlue"
                    })
                  )
                }
              }}
              onMouseLeave={() => {
                if (
                  fiduciary?.unit_labels &&
                  fiduciary?.unit_labels?.length > 0
                ) {
                  setBackgroundColors(
                    fiduciary?.unit_labels?.map((_, id) => {
                      return id % 2 === 0 ? "white" : "clearBlue"
                    })
                  )
                }
              }}
            >
              <StyledTrash />
            </div>
          </Actions>
        )
      },
    },
  ]

  return (
    <Wrapper>
      {displayEvents && (
        <UnitLabelEventsModal
          isDisplayed={!!displayEvents}
          onClose={() => {
            setDisplayEvents(null)
          }}
          events={displayEvents}
        />
      )}
      {addUnitLabel && (
        <AddUnitLabelModal
          isDisplayed={!!addUnitLabel}
          onClose={() => {
            setAddUnitLabel(false)
          }}
          unit_labels={fiduciary.unit_labels}
        />
      )}

      <StyledSection>
        <ButtonRight>
          <Ct.Button
            label={intl.formatMessage({
              id: "unit_labels.add-button",
            })}
            onClick={() => {
              dispatch(getUnitsThunk(fiduciary.id))
              setAddUnitLabel(true)
            }}
            width={35}
            prefix={<StyledCalculator />}
          />
        </ButtonRight>
        <StyledContent>
          <Ct.Spacer height={2} />

          <StyledText
            text={intl.formatMessage({
              id: "fiduciary.unit_labels.title",
            })}
            textStyle={{
              fontFamily: "Poppins",
              color: "navy",
              fontWeight: 600,
              fontSize: "2.25",
            }}
          />

          <Ct.Spacer height={2} />

          <Alert alertType={"bulb"}>
            <Text
              text={intl.formatMessage({
                id: "fiduciary.unit_labels.alert",
              })}
            />
          </Alert>

          <Ct.Spacer height={2} />

          {fiduciary.unit_labels.length === 0 ? (
            <CenteredDiv>
              <Alert alertType={"info"}>
                <Text
                  text={intl.formatMessage({
                    id: "fiduciary.unit_labels.empty",
                  })}
                />
              </Alert>
            </CenteredDiv>
          ) : (
            <TableWrapper>
              <Table
                intl={intl}
                columns={columns}
                rows={fiduciary.unit_labels}
                alignItems={"center"}
                width={"100%"}
                height={"100%"}
                padding={"0"}
                paddingRows={"1rem 2rem 1rem 4rem"}
                fontWeightTitle={600}
                alertMessage={"unit_labels.empty"}
                sortableColumnsLength={0}
                customScrollBar={true}
                rowBackgroundColors={backgroundColors}
              />
            </TableWrapper>
          )}
        </StyledContent>
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */
const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  box-sizing: border-box;
  height: 100%;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 8rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: auto;

  /* FIREFOX */
  scrollbar-color: ${colors.rock} transparent;
  scrollbar-width: thin !important;
  /* CHROME */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.rock};
    border-radius: 3px;
    width: 4px;
  }
`
const StyledContent = styled.div`
  width: 80%;
`
const StyledText = styled((props) => <Text {...props} />)`
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
`
const ButtonRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 95%;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: pointer;
`
const Actions = styled.div`
  display: flex;
  justify-content: end;
`
const StyledTrash = styled(({ ...props }) => <Trash {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.amaranth};
    }
  }
  cursor: pointer;
`
const StyledCalculator = styled(({ ...props }) => <Calculator {...props} />)`
  & path {
    fill: ${colors.white};
  }
`
const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
