import axios from "axios"
import { AutoliquidatedVatAccountsState } from "../../components/accountingPlan/AutoliquidatedVatAccounts"
import { buyOrSell } from "../../utils/company"
import { Dispatch, RNBThunkAction } from "../store.config"
import {
  AccountingKeyWord,
  CreateKeyWord,
} from "../../utils/accountingKeyWords"

export const enum CompanySettingsActionsEnum {
  SELECT_COMPANY = "COMPANY_SETTINGS/selectCompany",

  GET_COMPANY_VAT_ACCOUNTS_SETTINGS_ATTEMPT = "COMPANY_SETTINGS/getCompanyVatAccountsSettingsAttempt",
  GET_COMPANY_VAT_ACCOUNTS_SETTINGS_SUCCESS = "COMPANY_SETTINGS/getCompanyVatAccountsSettingsSuccess",
  GET_COMPANY_VAT_ACCOUNTS_SETTINGS_ERROR = "COMPANY_SETTINGS/getCompanyVatAccountsSettingsError",
  DISABLE_VAT_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/disableVatAccountsAttempt",
  DISABLE_VAT_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/disableVatAccountsSuccess",
  DISABLE_VAT_ACCOUNTS_ERROR = "COMPANY_SETTINGS/disableVatAccountsError",
  CREATE_VAT_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/createVatAccountsAttempt",
  CREATE_VAT_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/createVatAccountsSuccess",
  CREATE_VAT_ACCOUNTS_ERROR = "COMPANY_SETTINGS/createVatAccountsError",

  CREATE_ACCOUNT_ATTEMPT = "COMPANY_SETTINGS/createAccountAttempt",
  CREATE_ACCOUNT_SUCCESS = "COMPANY_SETTINGS/createAccountSuccess",
  CREATE_ACCOUNT_ERROR = "COMPANY_SETTINGS/createAccountError",
  CREATE_ACCOUNT_DUPLICATE_ERROR = "COMPANY_SETTINGS/createAccountDuplicateError",
  CREATE_ACCOUNT_RESET = "COMPANY_SETTINGS/createAccountReset",
  CREATE_ACCOUNT_SUCCESS_TOASTER = "COMPANY_SETTINGS/createAccountSuccessToaster",

  GET_MY_UNISOFT_TOKEN_ATTEMPT = "COMPANY_SETTINGS/getMyUnisoftTokenAttempt",
  GET_MY_UNISOFT_TOKEN_SUCCESS = "COMPANY_SETTINGS/getMyUnisoftTokenSuccess",
  GET_MY_UNISOFT_TOKEN_ERROR = "COMPANY_SETTINGS/getMyUnisoftTokenError",

  UPDATE_MY_UNISOFT_TOKEN_ATTEMPT = "COMPANY_SETTINGS/updateMyUnisoftTokenAttempt",
  UPDATE_MY_UNISOFT_TOKEN_SUCCESS = "COMPANY_SETTINGS/updateMyUnisoftTokenSuccess",
  UPDATE_MY_UNISOFT_TOKEN_ERROR = "COMPANY_SETTINGS/updateMyUnisoftTokenError",

  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_ATTEMPT = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsSettingsAttempt",
  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_SUCCESS = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsSettingsSuccess",
  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_ERROR = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsSettingsError",

  CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/createAutoliquidatedVatAccountsAttemptAction",
  CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/createAutoliquidatedVatAccountsSuccessAction",
  CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ERROR = "COMPANY_SETTINGS/createAutoliquidatedVatAccountsErrorAction",

  UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/updateAutoliquidatedVatAccountsAttemptAction",
  UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/updateAutoliquidatedVatAccountsSuccessAction",
  UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ERROR = "COMPANY_SETTINGS/updateAutoliquidatedVatAccountsErrorAction",

  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_ATTEMPT = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsHistoryAttempt",
  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_SUCCESS = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsHistorySuccess",
  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_ERROR = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsHistoryError",
  GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_RESET = "COMPANY_SETTINGS/getCompanyAutoliquidatedVatAccountsHistoryReset",

  GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/GetCompanyGoodsTransferAccountsAttempt",
  GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/GetCompanyGoodsTransferAccountsSuccess",
  GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_FAILURE = "COMPANY_SETTINGS/GetCompanyGoodsTransferAccountsFailure",

  UPDATE_GOODS_TRANSFER_ACCOUNTS_ATTEMPT = "COMPANY_SETTINGS/UpdateGoodsTransferAccountsAttempt",
  UPDATE_GOODS_TRANSFER_ACCOUNTS_SUCCESS = "COMPANY_SETTINGS/UpdateGoodsTransferAccountsSuccess",
  UPDATE_GOODS_TRANSFER_ACCOUNTS_FAILURE = "COMPANY_SETTINGS/UpdateGoodsTransferAccountsFailure",
  UPDATE_GOODS_TRANSFER_ACCOUNTS_RESET = "COMPANY_SETTINGS/UpdateGoodsTransferAccountsReset",

  CREATE_GOODS_TRANSFER_ACCOUNT_ATTEMPT = "COMPANY_SETTINGS/CreateGoodsTransferAccountAttempt",
  CREATE_GOODS_TRANSFER_ACCOUNT_SUCCESS = "COMPANY_SETTINGS/CreateGoodsTransferAccountSuccess",
  CREATE_GOODS_TRANSFER_ACCOUNT_FAILURE = "COMPANY_SETTINGS/CreateGoodsTransferAccountFailure",
  CREATE_GOODS_TRANSFER_ACCOUNT_RESET = "COMPANY_SETTINGS/CreateGoodsTransferAccountReset",

  CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_ATTEMPT = "COMPANY_SETTINGS/CreateGoodsTransferAccountWithVatAttempt",
  CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_SUCCESS = "COMPANY_SETTINGS/CreateGoodsTransferAccountWithVatSuccess",
  CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_FAILURE = "COMPANY_SETTINGS/CreateGoodsTransferAccountWithVatFailure",

  DESTROY_COMMISSION_ACCOUNT_VAT_RATE_ATTEMPT = "COMPANY_SETTINGS/DestroyCommissionAccountVatRateAttempt",
  DESTROY_COMMISSION_ACCOUNT_VAT_RATE_SUCCESS = "COMPANY_SETTINGS/DestroyCommissionAccountVatRateSuccess",
  DESTROY_COMMISSION_ACCOUNT_VAT_RATE_FAILURE = "COMPANY_SETTINGS/DestroyCommissionAccountVatRateFailure",

  GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_ATTEMPT = "COMPANY_SETTINGS/GetDataToUpdateAccountingSoftwareAttempt",
  GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_SUCCESS = "COMPANY_SETTINGS/GetDataToUpdateAccountingSoftwareSuccess",
  GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_FAILURE = "COMPANY_SETTINGS/GetDataToUpdateAccountingSoftwareFailure",

  CREATE_JOURNAL_ATTEMPT = "COMPANY/createJournalActionAttempt",
  CREATE_JOURNAL_SUCCESS = "COMPANY/createJournalActionSuccess",
  CREATE_JOURNAL_ERROR = "COMPANY/createJournalActionError",

  GET_JOURNALS_ATTEMPT = "COMPANY/getJournalsActionAttempt",
  GET_JOURNALS_SUCCESS = "COMPANY/getJournalsActionSuccess",
  GET_JOURNALS_ERROR = "COMPANY/getJournalsActionError",

  SET_JOURNALS_ATTEMPT = "COMPANY/setJournalsActionAttempt",
  SET_JOURNALS_SUCCESS = "COMPANY/setJournalsActionError",
  SET_JOURNALS_ERROR = "COMPANY/setJournalsActionError",

  GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_ATTEMPT = "COMPANY/getCompanyAutoliquidatedAccountsVatRatesAttempt",
  GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_SUCCESS = "COMPANY/getCompanyAutoliquidatedAccountsVatRatesSuccess",
  GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_FAILURE = "COMPANY/getCompanyAutoliquidatedAccountsVatRatesFailure",

  GET_IS_ONBOARDING_FINISHED_ATTEMPT_ACTION = "COMPANY/getIsOnboardingFinishedAttemptAction",
  GET_IS_ONBOARDING_FINISHED_SUCCESS_ACTION = "COMPANY/getIsOnboardingFinishedSuccessAction",
  GET_IS_ONBOARDING_FINISHED_FAILURE_ACTION = "COMPANY/getIsOnboardingFinishedFailureAction",

  ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_ATTEMPT_ACTION = "COMPANY/activateOrDeactivateAutoliquidatedVatAttemptAction",
  ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_SUCCESS_ACTION = "COMPANY/activateOrDeactivateAutoliquidatedVatSuccessAction",
  ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_FAILURE_ACTION = "COMPANY/activateOrDeactivateAutoliquidatedVatFailureAction",

  GET_VAT_ACCOUNTS_HISTORY_ATTEMPT = "COMPANY_SETTINGS/getVatAccountsHistoryAttempt",
  GET_VAT_ACCOUNTS_HISTORY_ERROR = "COMPANY_SETTINGS/getVatAccountsHistorySuccess",
  GET_VAT_ACCOUNTS_HISTORY_SUCCESS = "COMPANY_SETTINGS/getVatAccountsHistoryError",
  GET_VAT_ACCOUNTS_HISTORY_RESET = "COMPANY_SETTINGS/getVatAccountsHistoryReset",
  GET_COMPANY_ACCOUNTING_KEY_WORDS_ATTEMPT = "COMPANY/GetCompanyAccountingKeyWordsAttempt",
  GET_COMPANY_ACCOUNTING_KEY_WORDS_SUCCESS = "COMPANY/GetCompanyAccountingKeyWordsSuccess",
  GET_COMPANY_ACCOUNTING_KEY_WORDS_FAILURE = "COMPANY/GetCompanyAccountingKeyWordsFailure",

  CREATE_UPDATE_DELETE_KEY_WORDS_ATTEMPT = "COMPANY/CreateUpdateDeleteKeyWordAttempt",
  CREATE_UPDATE_DELETE_KEY_WORDS_SUCCESS = "COMPANY/CreateUpdateDeleteKeyWordSuccess",
  CREATE_UPDATE_DELETE_KEY_WORDS_FAILURE = "COMPANY/CreateUpdateDeleteKeyWordFailure",
}

export const createAccountDuplicateError = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_DUPLICATE_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office-company-settings.vat-accounts.account-creation-error-title`,
      bodyKey: `office-company-settings.vat-accounts.account-creation-error-body`,
    },
  } as const)

export const createAccountSuccessToaster = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_SUCCESS_TOASTER,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office-company-settings.vat-accounts.account-creation-success-title`,
      bodyKey: `office-company-settings.vat-accounts.account-creation-success-body`,
    },
  } as const)

export const getCompanyVatAccountsSettingsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_VAT_ACCOUNTS_SETTINGS_ATTEMPT,
  } as const)
export const getCompanyVatAccountsSettingsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_VAT_ACCOUNTS_SETTINGS_ERROR,
  } as const)

export const getCompanyVatAccountsSettingsSuccessAction = (
  vatAccounts: VatAccounts
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_VAT_ACCOUNTS_SETTINGS_SUCCESS,
    payload: vatAccounts,
  } as const)

export const disableVatAccountsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.DISABLE_VAT_ACCOUNTS_ATTEMPT,
  } as const)
export const disableVatAccountsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.DISABLE_VAT_ACCOUNTS_ERROR,
  } as const)
export const disableVatAccountsSuccessAction = () =>
  ({
    type: CompanySettingsActionsEnum.DISABLE_VAT_ACCOUNTS_SUCCESS,
  } as const)

export const createVatAccountsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_VAT_ACCOUNTS_ATTEMPT,
  } as const)
export const createVatAccountsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_VAT_ACCOUNTS_ERROR,
  } as const)
export const createVatAccountsSuccessAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_VAT_ACCOUNTS_SUCCESS,
  } as const)

export const createAccountAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_ATTEMPT,
  } as const)
export const createAccountResetAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_RESET,
  } as const)
export const createAccountErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_ERROR,
  } as const)
export const createAccountSuccessAction = (createdAccount: CreatedAccount) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_ACCOUNT_SUCCESS,
    payload: { createdAccount },
  } as const)

export const getMyUnisoftTokenAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_MY_UNISOFT_TOKEN_ATTEMPT,
  } as const)
export const getMyUnisoftTokenErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_MY_UNISOFT_TOKEN_ERROR,
  } as const)
export const getMyUnisoftTokenSuccessAction = (myUnisoftToken: string) =>
  ({
    type: CompanySettingsActionsEnum.GET_MY_UNISOFT_TOKEN_SUCCESS,
    payload: { myUnisoftToken },
  } as const)

export const updateMyUnisoftTokenAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_MY_UNISOFT_TOKEN_ATTEMPT,
  } as const)
export const updateMyUnisoftTokenErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_MY_UNISOFT_TOKEN_ERROR,
  } as const)
export const updateMyUnisoftTokenSuccessAction = (myUnisoftToken: string) =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_MY_UNISOFT_TOKEN_SUCCESS,
    payload: { myUnisoftToken },
  } as const)

export const getCompanyAutoliquidatedVatAccountsSettingsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_ATTEMPT,
  } as const)
export const getCompanyAutoliquidatedVatAccountsSettingsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_ERROR,
  } as const)

export const getCompanyAutoliquidatedVatAccountsSettingsSuccessAction = (
  autoliquidatedVatAccounts: AutoliquidatedVatAccountSettings
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_SUCCESS,
    payload: autoliquidatedVatAccounts,
  } as const)

export const createAutoliquidatedVatAccountsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ATTEMPT,
  } as const)
export const createAutoliquidatedVatAccountsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ERROR,
  } as const)
export const createAutoliquidatedVatAccountsSuccessAction = (
  createdAutoliquidatedVatAccount: CreatedAutoliquidatedVatAccount
) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_SUCCESS,
    payload: { createdAutoliquidatedVatAccount },
  } as const)

export const updateAutoliquidatedVatAccountsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ATTEMPT,
  } as const)
export const updateAutoliquidatedVatAccountsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office-company-settings.vat-accounts.account-modification.error.title`,
      bodyKey: `office-company-settings.vat-accounts.account-modification.error.body`,
    },
  } as const)
export const updateAutoliquidatedVatAccountsSuccessAction = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_AUTOLIQUIDATED_VAT_ACCOUNTS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office-company-settings.vat-accounts.account-modification.success.title`,
      bodyKey: `office-company-settings.vat-accounts.account-modification.success.body`,
    },
  } as const)

export const getCompanyAutoliquidatedVatAccountsHistoryAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_ATTEMPT,
  } as const)
export const getCompanyAutoliquidatedVatAccountsHistoryErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_ERROR,
  } as const)

export const getCompanyAutoliquidatedVatAccountsHistorySuccessAction = ({
  userAccountInstructions,
  vatType,
}: {
  userAccountInstructions: Instructions[]
  vatType: VatType
}) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_SUCCESS,
    payload: { userAccountInstructions, vatType },
  } as const)

export const getCompanyAutoliquidatedVatAccountsHistoryResetAction = (
  vatType: VatType | null
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_RESET,
    payload: { vatType },
  } as const)

export const selectCompanyAction = () =>
  ({
    type: CompanySettingsActionsEnum.SELECT_COMPANY,
  } as const)

export const GetCompanyGoodsTransferAccountsAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_ATTEMPT,
  } as const)
export const GetCompanyGoodsTransferAccountsSuccess = (
  goodsPayload: GoodsTransfer,
  accountNumberLimit: number | null,
  vat_rates: Rate[]
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_SUCCESS,
    goodsPayload,
    accountNumberLimit,
    vat_rates,
  } as const)
export const GetCompanyGoodsTransferAccountsFailure = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_FAILURE,
  } as const)

export const UpdateGoodsTransferAccountsAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_ATTEMPT,
  } as const)
export const UpdateGoodsTransferAccountsSuccess = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_SUCCESS,
  } as const)
export const UpdateGoodsTransferAccountsFailure = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_FAILURE,
  } as const)
export const UpdateGoodsTransferAccountsReset = () =>
  ({
    type: CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_RESET,
  } as const)

export const CreateGoodsTransferAccountAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_ATTEMPT,
  } as const)
export const CreateGoodsTransferAccountSuccess = (
  newAccount: AccountGoodsTransfer
) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_SUCCESS,
    newAccount,
    withToast: true,
    toasterType: "warning",
    message: {
      titleKey: `accounting-plan.goods-transfer.modal-create-success.title`,
      bodyKey: `accounting-plan.goods-transfer.modal-create-success.message`,
    },
  } as const)
export const CreateGoodsTransferAccountFailure = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_FAILURE,
  } as const)
export const CreateGoodsTransferAccountReset = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_RESET,
  } as const)

export const CreateGoodsTransferAccountWithVatAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_ATTEMPT,
  } as const)
export const CreateGoodsTransferAccountWithVatSuccess = (
  commissions: CommissionAccountVatRate[],
  goodsType: string
) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_SUCCESS,
    commissions,
    goodsType,
  } as const)
export const CreateGoodsTransferAccountWithVatFailure = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_FAILURE,
  } as const)

export const DestroyCommissionAccountVatRateAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.DESTROY_COMMISSION_ACCOUNT_VAT_RATE_ATTEMPT,
  } as const)
export const DestroyCommissionAccountVatRateSuccess = (
  commissions: CommissionAccountVatRate[],
  goodsType: string
) =>
  ({
    type: CompanySettingsActionsEnum.DESTROY_COMMISSION_ACCOUNT_VAT_RATE_SUCCESS,
    commissions,
    goodsType,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.goods-transfer.destroy-commission.success.title`,
      bodyKey: `accounting-plan.goods-transfer.destroy-commission.success.message`,
    },
  } as const)
export const DestroyCommissionAccountVatRateFailure = () =>
  ({
    type: CompanySettingsActionsEnum.DESTROY_COMMISSION_ACCOUNT_VAT_RATE_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.goods-transfer.destroy-commission.error.title`,
      bodyKey: `accounting-plan.goods-transfer.destroy-commission.error.message`,
    },
  } as const)

export const GetDataToUpdateAccountingSoftwareAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_ATTEMPT,
  } as const)
export const GetDataToUpdateAccountingSoftwareSuccess = (
  data: ResponseDataUpdateAccountingSoftware
) =>
  ({
    type: CompanySettingsActionsEnum.GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_SUCCESS,
    data,
  } as const)
export const GetDataToUpdateAccountingSoftwareFailure = () =>
  ({
    type: CompanySettingsActionsEnum.GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_FAILURE,
  } as const)
export const createJournalAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_JOURNAL_ATTEMPT,
  } as const)

export const createJournalSuccessAction = (
  journal_code: string,
  details: string
) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_JOURNAL_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.journals.create.success.title`,
      bodyKey: `accounting-plan.journals.create.success.body`,
      bodyValues: {
        journalCode: journal_code,
        details: details,
      },
    },
  } as const)

export const createJournalErrorAction = (error_msg: string) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_JOURNAL_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "Création du journal impossible",
      bodyKey: error_msg,
    },
  } as const)

export const getJournalsAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_JOURNALS_ATTEMPT,
  } as const)

export const getJournalsSuccessAction = (payload: getJournalResponse) =>
  ({
    type: CompanySettingsActionsEnum.GET_JOURNALS_SUCCESS,
    payload: payload,
  } as const)

export const getJournalsErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_JOURNALS_ERROR,
  } as const)

export const changeJournalAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.SET_JOURNALS_ATTEMPT,
  } as const)

export const changeJournalSuccessAction = () =>
  ({
    type: CompanySettingsActionsEnum.SET_JOURNALS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.journals.set_account.success.title`,
      bodyKey: `accounting-plan.journals.set_account.success.body`,
    },
  } as const)

export const changeJournalFailureAction = () =>
  ({
    type: CompanySettingsActionsEnum.SET_JOURNALS_ERROR,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.journals.set_account.failure.title`,
      bodyKey: `accounting-plan.journals.set_account.failure.title`,
    },
  } as const)

export const getCompanyAutoliquidatedAccountsVatRatesAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_ATTEMPT,
  } as const)
export const getCompanyAutoliquidatedAccountsVatRatesFailureAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_FAILURE,
  } as const)

export const getCompanyAutoliquidatedAccountsVatRatesSuccessAction = (
  payload: AccountsVatRates
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_SUCCESS,
    payload: payload,
  } as const)

export const getIsOnboardingFinishedAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_ATTEMPT_ACTION,
  } as const)
export const getIsOnboardingFinishedSuccessAction = (
  payload: IsOnboardingFinishedType
) =>
  ({
    type: CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_SUCCESS_ACTION,
    payload: payload,
  } as const)

export const getIsOnboardingFinishedFailureAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_FAILURE_ACTION,
  } as const)

export const activateOrDeactivateAutoliquidatedVatAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_ATTEMPT_ACTION,
  } as const)
export const activateOrDeactivateAutoliquidatedVatSuccessAction = () =>
  ({
    type: CompanySettingsActionsEnum.ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_SUCCESS_ACTION,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `office-company-settings.autoliquidated-vat.activate-or-deactivate.success.title`,
      bodyKey: `office-company-settings.autoliquidated-vat.activate-or-deactivate.success.body`,
    },
  } as const)

export const activateOrDeactivateAutoliquidatedVatFailureAction = () =>
  ({
    type: CompanySettingsActionsEnum.ACTIVATE_OR_DEACTIVATE_AUTOLIQUIDATED_VAT_FAILURE_ACTION,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `office-company-settings.autoliquidated-vat.activate-or-deactivate.error.title`,
      bodyKey: `office-company-settings.autoliquidated-vat.activate-or-deactivate.error.body`,
    },
  } as const)

export const getVatAccountsHistoryAttemptAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_ATTEMPT,
  } as const)
export const getVatAccountsHistoryErrorAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_ERROR,
  } as const)

export const getVatAccountsHistorySuccessAction = ({
  userAccountInstructions,
}: {
  userAccountInstructions: Instructions[]
}) =>
  ({
    type: CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_SUCCESS,
    payload: { userAccountInstructions },
  } as const)

export const getVatAccountsHistoryResetAction = () =>
  ({
    type: CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_RESET,
  } as const)

export const GetCompanyAccountingKeyWordsAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_ACCOUNTING_KEY_WORDS_ATTEMPT,
  } as const)
export const GetCompanyAccountingKeyWordsSuccess = (
  accounting_key_words: AccountingKeyWord
) =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_ACCOUNTING_KEY_WORDS_SUCCESS,
    accounting_key_words,
  } as const)
export const GetCompanyAccountingKeyWordsFailure = () =>
  ({
    type: CompanySettingsActionsEnum.GET_COMPANY_ACCOUNTING_KEY_WORDS_FAILURE,
  } as const)

export const CreateUpdateDeleteKeyWordAttempt = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_UPDATE_DELETE_KEY_WORDS_ATTEMPT,
  } as const)
export const CreateUpdateDeleteKeyWordSuccess = () =>
  ({
    type: CompanySettingsActionsEnum.CREATE_UPDATE_DELETE_KEY_WORDS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: `accounting-plan.key-words.table-header.tooltip.success.title`,
      bodyKey: `accounting-plan.key-words.table-header.tooltip.success.message`,
    },
  } as const)
export const CreateUpdateDeleteKeyWordFailure = (
  key_word_exists: string | null
) =>
  ({
    type: CompanySettingsActionsEnum.CREATE_UPDATE_DELETE_KEY_WORDS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: `accounting-plan.key-words.table-header.tooltip.error.title`,
      bodyKey: `accounting-plan.key-words.table-header.tooltip.error.message${
        key_word_exists ? ".exists" : ""
      }`,
      bodyValues: {
        key_word: key_word_exists,
      },
    },
  } as const)

export type CompanySettingsActionsType = ReturnType<
  | typeof getCompanyVatAccountsSettingsAttemptAction
  | typeof getCompanyVatAccountsSettingsSuccessAction
  | typeof getCompanyVatAccountsSettingsErrorAction
  | typeof disableVatAccountsAttemptAction
  | typeof disableVatAccountsSuccessAction
  | typeof disableVatAccountsErrorAction
  | typeof createVatAccountsAttemptAction
  | typeof createVatAccountsSuccessAction
  | typeof createVatAccountsErrorAction
  | typeof createAccountAttemptAction
  | typeof createAccountErrorAction
  | typeof createAccountSuccessAction
  | typeof createAccountResetAction
  | typeof createAccountDuplicateError
  | typeof getMyUnisoftTokenAttemptAction
  | typeof getMyUnisoftTokenErrorAction
  | typeof getMyUnisoftTokenSuccessAction
  | typeof updateMyUnisoftTokenAttemptAction
  | typeof updateMyUnisoftTokenErrorAction
  | typeof updateMyUnisoftTokenSuccessAction
  | typeof getCompanyAutoliquidatedVatAccountsSettingsAttemptAction
  | typeof getCompanyAutoliquidatedVatAccountsSettingsSuccessAction
  | typeof getCompanyAutoliquidatedVatAccountsSettingsErrorAction
  | typeof createAutoliquidatedVatAccountsAttemptAction
  | typeof createAutoliquidatedVatAccountsErrorAction
  | typeof createAutoliquidatedVatAccountsSuccessAction
  | typeof updateAutoliquidatedVatAccountsAttemptAction
  | typeof updateAutoliquidatedVatAccountsErrorAction
  | typeof updateAutoliquidatedVatAccountsSuccessAction
  | typeof getCompanyAutoliquidatedVatAccountsHistoryAttemptAction
  | typeof getCompanyAutoliquidatedVatAccountsHistorySuccessAction
  | typeof getCompanyAutoliquidatedVatAccountsHistoryErrorAction
  | typeof getCompanyAutoliquidatedVatAccountsHistoryResetAction
  | typeof createAccountSuccessToaster
  | typeof selectCompanyAction
  | typeof GetCompanyGoodsTransferAccountsAttempt
  | typeof GetCompanyGoodsTransferAccountsSuccess
  | typeof GetCompanyGoodsTransferAccountsFailure
  | typeof UpdateGoodsTransferAccountsAttempt
  | typeof UpdateGoodsTransferAccountsSuccess
  | typeof UpdateGoodsTransferAccountsFailure
  | typeof UpdateGoodsTransferAccountsReset
  | typeof CreateGoodsTransferAccountAttempt
  | typeof CreateGoodsTransferAccountSuccess
  | typeof CreateGoodsTransferAccountFailure
  | typeof CreateGoodsTransferAccountReset
  | typeof CreateGoodsTransferAccountWithVatAttempt
  | typeof CreateGoodsTransferAccountWithVatSuccess
  | typeof CreateGoodsTransferAccountWithVatFailure
  | typeof DestroyCommissionAccountVatRateAttempt
  | typeof DestroyCommissionAccountVatRateSuccess
  | typeof DestroyCommissionAccountVatRateFailure
  | typeof GetDataToUpdateAccountingSoftwareAttempt
  | typeof GetDataToUpdateAccountingSoftwareSuccess
  | typeof GetDataToUpdateAccountingSoftwareFailure
  | typeof createJournalAttemptAction
  | typeof createJournalSuccessAction
  | typeof createJournalErrorAction
  | typeof getJournalsAttemptAction
  | typeof getJournalsSuccessAction
  | typeof getJournalsErrorAction
  | typeof changeJournalAttemptAction
  | typeof changeJournalSuccessAction
  | typeof changeJournalFailureAction
  | typeof getCompanyAutoliquidatedAccountsVatRatesAttemptAction
  | typeof getCompanyAutoliquidatedAccountsVatRatesFailureAction
  | typeof getCompanyAutoliquidatedAccountsVatRatesSuccessAction
  | typeof getIsOnboardingFinishedAttemptAction
  | typeof getIsOnboardingFinishedSuccessAction
  | typeof getIsOnboardingFinishedFailureAction
  | typeof activateOrDeactivateAutoliquidatedVatAttemptAction
  | typeof activateOrDeactivateAutoliquidatedVatSuccessAction
  | typeof activateOrDeactivateAutoliquidatedVatFailureAction
  | typeof getVatAccountsHistoryAttemptAction
  | typeof getVatAccountsHistorySuccessAction
  | typeof getVatAccountsHistoryErrorAction
  | typeof getVatAccountsHistoryResetAction
  | typeof GetCompanyAccountingKeyWordsAttempt
  | typeof GetCompanyAccountingKeyWordsSuccess
  | typeof GetCompanyAccountingKeyWordsFailure
  | typeof CreateUpdateDeleteKeyWordAttempt
  | typeof CreateUpdateDeleteKeyWordSuccess
  | typeof CreateUpdateDeleteKeyWordFailure
>

interface VatAccounts {
  rates: Rate[]
  possibleBuyAccounts: Account[]
  possibleSellAccounts: Account[]
  selectedAccounts: SelectedAccount[]
  assignMode: VatAssignMode
  accountNumberLimit: number | null
}

export interface AutoliquidatedVatAccounts {
  selected_account: Account | null
  possible_accounts: Account[]
  prefix: string[]
  forbid: string[]
  deactivated_at: string | null
  userAccountInstructions?: Instructions[]
}

export type AccountVatRatesKey = keyof AccountsVatRates

export interface AccountsVatRates {
  selected_eu_vat_rate: Rate | null
  selected_eu_goods_vat_rate: Rate | null
  selected_construction_vat_rate: Rate | null
  selected_world_vat_rate: Rate | null
  selected_world_goods_vat_rate: Rate | null
}

export type JournalType = "buy" | "sell" | "misc"

export type IsOnboardingFinishedType = "finished" | "unfinished" | "idle"

interface Journal {
  id: number
  journal_code: string
  journal_details: string
  created_at: string
  updated_at: string
  journal_type: JournalType
  company_id: number
}

export const companySettingsInitialState: CompanySettingsState = {
  rates: [],
  possibleBuyAccounts: [],
  possibleSellAccounts: [],
  selectedAccounts: [],
  assignMode: "no_vat",
  accountNumberLimit: null,
  createdAccount: null,
  myUnisoftToken: "",
  createdAutoliquidatedVatAccount: null,
  construction_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  construction_reverse_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  eu_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  eu_reverse_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  eu_goods_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  eu_goods_reverse_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  world_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  world_reverse_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  world_goods_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  world_goods_reverse_vat: {
    selected_account: null,
    possible_accounts: [],
    prefix: [],
    forbid: [],
    deactivated_at: null,
  },
  goods_transfer: {
    commission_account_vat_rates: {},
    enum_translations: null,
    goods_transfer_commission_type_enum: [],
    goods_transfer_type_enum: [],
    possible_goods_transfer_accounts: [],
    possible_goods_transfer_commission_accounts: [],
    selected_accounts: null,
    goods_transfer_rules: [],
    goods_transfer_commission_rules: [],
  },
  autoliquidated_accounts_vat_rates: {
    selected_eu_vat_rate: null,
    selected_eu_goods_vat_rate: null,
    selected_construction_vat_rate: null,
    selected_world_vat_rate: null,
    selected_world_goods_vat_rate: null,
  },
  createdGoodsTransferAccount: null,
  updatedGoodsTransferStatus: "idle",
  dataToUpdateAccountingSoftware: {
    length_rules: {
      max_account_number_length: null,
      max_code_length: null,
    },
    codes_too_long: [],
    accounts_too_long: [],
    all_accounts: [],
    all_codes: [],
    accounting_software_prefix: null,
    auxiliary_or_principal: null,
    software_events: [],
  },
  createJournalStatus: "idle",
  selectedJournals: {
    buy: null,
    sell: null,
    misc: null,
  },
  possibleJournals: [],
  codeLimit: 0,
  isOnboardingFinished: "idle",
  userAccountInstructions: [],
  accounting_key_words: { key_words: [], events: [] },
}

export interface CompanySettingsState {
  rates: Rate[]
  possibleBuyAccounts: Account[]
  possibleSellAccounts: Account[]
  selectedAccounts: SelectedAccount[]
  assignMode: VatAssignMode
  accountNumberLimit: number | null
  createdAccount: CreatedAccount | null
  myUnisoftToken: string
  createdAutoliquidatedVatAccount: CreatedAutoliquidatedVatAccount | null
  construction_vat: AutoliquidatedVatAccounts
  construction_reverse_vat: AutoliquidatedVatAccounts
  eu_vat: AutoliquidatedVatAccounts
  eu_reverse_vat: AutoliquidatedVatAccounts
  eu_goods_vat: AutoliquidatedVatAccounts
  eu_goods_reverse_vat: AutoliquidatedVatAccounts
  world_vat: AutoliquidatedVatAccounts
  world_reverse_vat: AutoliquidatedVatAccounts
  world_goods_vat: AutoliquidatedVatAccounts
  world_goods_reverse_vat: AutoliquidatedVatAccounts
  autoliquidated_accounts_vat_rates: AccountsVatRates
  goods_transfer: GoodsTransfer
  createdGoodsTransferAccount: AccountGoodsTransfer | null
  updatedGoodsTransferStatus: "idle" | "loading" | "success" | "error"
  dataToUpdateAccountingSoftware: ResponseDataUpdateAccountingSoftware
  createJournalStatus: "idle" | "loading" | "success" | "error"
  selectedJournals: {
    buy: Journal | null
    sell: Journal | null
    misc: Journal | null
  }
  possibleJournals: Journal[]
  codeLimit: number
  isOnboardingFinished: IsOnboardingFinishedType
  userAccountInstructions: Instructions[]
  accounting_key_words: AccountingKeyWord
}

interface VatAccounts {
  rates: Rate[]
  possibleBuyAccounts: Account[]
  possibleSellAccounts: Account[]
  selectedAccounts: SelectedAccount[]
  assignMode: VatAssignMode
  accountNumberLimit: number | null
}

export function companySettingsReducer(
  state = companySettingsInitialState,
  action: CompanySettingsActionsType
): CompanySettingsState {
  switch (action.type) {
    case CompanySettingsActionsEnum.GET_COMPANY_VAT_ACCOUNTS_SETTINGS_ATTEMPT:
      return { ...state }
    case CompanySettingsActionsEnum.GET_COMPANY_VAT_ACCOUNTS_SETTINGS_SUCCESS:
      return { ...state, ...action.payload }

    case CompanySettingsActionsEnum.GET_MY_UNISOFT_TOKEN_SUCCESS:
      return { ...state, myUnisoftToken: action.payload.myUnisoftToken }

    case CompanySettingsActionsEnum.UPDATE_MY_UNISOFT_TOKEN_SUCCESS:
      return { ...state, myUnisoftToken: action.payload.myUnisoftToken }

    case CompanySettingsActionsEnum.CREATE_ACCOUNT_RESET:
      return {
        ...state,
        createdAccount: null,
        createdAutoliquidatedVatAccount: null,
      }
    case CompanySettingsActionsEnum.CREATE_ACCOUNT_SUCCESS: {
      const buyOrSell = action.payload.createdAccount.buyOrSell
      const newVatAccounts =
        buyOrSell === "buy"
          ? {
              ...state,
              possibleBuyAccounts: [
                action.payload.createdAccount,
                ...state.possibleBuyAccounts,
              ],
            }
          : {
              ...state,
              possibleSellAccounts: [
                action.payload.createdAccount,
                ...state.possibleSellAccounts,
              ],
            }
      return {
        ...newVatAccounts,
        createdAccount: action.payload.createdAccount,
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_ATTEMPT:
      return { ...state }
    case CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_SETTINGS_SUCCESS:
      return { ...state, ...action.payload }
    case CompanySettingsActionsEnum.CREATE_AUTOLIQUIDATED_VAT_ACCOUNTS_SUCCESS: {
      const vatType = action.payload.createdAutoliquidatedVatAccount.vatType

      if (vatType === null) return { ...state }

      const updatedVatState = {
        ...state,
        [vatType]: {
          ...state[vatType],
          possible_accounts: [
            ...state[vatType].possible_accounts,
            action.payload.createdAutoliquidatedVatAccount,
          ],
        },
      }

      const newAutoliquidatedVatAccounts = {
        ...state,
        ...updatedVatState,
      }

      return {
        ...newAutoliquidatedVatAccounts,
        createdAutoliquidatedVatAccount:
          action.payload.createdAutoliquidatedVatAccount,
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_SUCCESS: {
      const vatType = action.payload.vatType

      const newAutoliquidatedVatAccounts = {
        ...state,
        [vatType]: {
          ...state[vatType],
          userAccountInstructions: action.payload.userAccountInstructions,
        },
      }

      return {
        ...newAutoliquidatedVatAccounts,
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_VAT_ACCOUNTS_HISTORY_RESET: {
      const vatType = action.payload.vatType
      if (!vatType) return { ...state }
      return {
        ...state,
        [vatType]: {
          ...state[vatType],
          userAccountInstructions: {},
        },
      }
    }
    case CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_SUCCESS: {
      return {
        ...state,
        userAccountInstructions: action.payload.userAccountInstructions,
      }
    }
    case CompanySettingsActionsEnum.GET_VAT_ACCOUNTS_HISTORY_RESET: {
      return {
        ...state,
        userAccountInstructions: [],
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_GOODS_TRANSFER_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        goods_transfer: action.goodsPayload,
        accountNumberLimit: action.accountNumberLimit,
        rates: action.vat_rates,
      }
    }
    case CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        updatedGoodsTransferStatus: "loading",
      }
    case CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        updatedGoodsTransferStatus: "success",
      }
    case CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_FAILURE:
      return {
        ...state,
        updatedGoodsTransferStatus: "error",
      }
    case CompanySettingsActionsEnum.UPDATE_GOODS_TRANSFER_ACCOUNTS_RESET:
      return {
        ...state,
        updatedGoodsTransferStatus: "idle",
      }
    case CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        createdGoodsTransferAccount: action.newAccount,
      }
    }
    case CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_RESET: {
      return {
        ...state,
        createdGoodsTransferAccount: null,
      }
    }
    case CompanySettingsActionsEnum.CREATE_GOODS_TRANSFER_ACCOUNT_WITH_VAT_SUCCESS: {
      return {
        ...state,
        goods_transfer: {
          ...state.goods_transfer,
          commission_account_vat_rates: {
            ...state.goods_transfer.commission_account_vat_rates,
            [action.goodsType]: action.commissions,
          },
        },
      }
    }
    case CompanySettingsActionsEnum.DESTROY_COMMISSION_ACCOUNT_VAT_RATE_SUCCESS: {
      return {
        ...state,
        goods_transfer: {
          ...state.goods_transfer,
          commission_account_vat_rates: {
            ...state.goods_transfer.commission_account_vat_rates,
            [action.goodsType]: action.commissions,
          },
        },
      }
    }
    case CompanySettingsActionsEnum.GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_ATTEMPT: {
      return {
        ...state,
      }
    }
    case CompanySettingsActionsEnum.GET_DATA_TO_UPDATE_ACCOUNTING_SOFTWARE_SUCCESS: {
      return {
        ...state,
        dataToUpdateAccountingSoftware: action.data,
      }
    }
    case CompanySettingsActionsEnum.GET_JOURNALS_ATTEMPT: {
      return {
        ...state,
        selectedJournals: {
          buy: null,
          sell: null,
          misc: null,
        },
        possibleJournals: [],
      }
    }
    case CompanySettingsActionsEnum.GET_JOURNALS_SUCCESS: {
      return {
        ...state,
        selectedJournals: action.payload.active_journals,
        possibleJournals: action.payload.all_journals,
        codeLimit: action.payload.code_limit,
      }
    }
    case CompanySettingsActionsEnum.GET_JOURNALS_ERROR: {
      return {
        ...state,
        selectedJournals: {
          buy: null,
          sell: null,
          misc: null,
        },
        possibleJournals: [],
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_AUTOLIQUIDATED_ACCOUNTS_VAT_RATES_SUCCESS: {
      return {
        ...state,
        autoliquidated_accounts_vat_rates: action.payload,
      }
    }
    case CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_ATTEMPT_ACTION: {
      return {
        ...state,
        isOnboardingFinished: "idle",
      }
    }
    case CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_SUCCESS_ACTION: {
      return {
        ...state,
        isOnboardingFinished: action.payload,
      }
    }
    case CompanySettingsActionsEnum.GET_IS_ONBOARDING_FINISHED_FAILURE_ACTION: {
      return {
        ...state,
        isOnboardingFinished: "unfinished",
      }
    }
    case CompanySettingsActionsEnum.GET_COMPANY_ACCOUNTING_KEY_WORDS_SUCCESS: {
      return {
        ...state,
        accounting_key_words: action.accounting_key_words,
      }
    }

    default:
      return { ...state }
  }
}

export type goodsOrServices = "goods" | "services"

export interface Account {
  id: number
  details: string
  number: string
}

interface SelectedAccountPayload {
  vat_accounting: "per_rate" | "unique"
  buy_or_sell: "buy" | "sell"
  goods_or_services: goodsOrServices
  vat_rate_id: number | null
  account_id: number
}

export interface VatAccountPayload {
  vat_accounting: "per_rate" | "unique"
  buy_or_sell: "buy" | "sell"
  goods_or_services: goodsOrServices
  vat_rate_id: number | null
  company_id: number
  account_id: number | null
}

interface SelectedAccount {
  vatAccounting: "per_rate" | "unique"
  buyOrSell: "buy" | "sell"
  goodsOrServices: goodsOrServices
  vatRateId: number | null
  accountId: number
}

interface Rate {
  id: number
  rate: string
}

interface VatAccountSettings {
  disable_vat_accounts: boolean
  possible_buy_accounts: Account[]
  possible_sell_accounts: Account[]
  selected_vat_accounts: SelectedAccountPayload[]
  vat_rates: Rate[]
  account_number_limit: number | null
}

export interface AutoliquidatedVatAccountSettings {
  construction_vat: AutoliquidatedVatAccounts
  construction_reverse_vat: AutoliquidatedVatAccounts
  eu_vat: AutoliquidatedVatAccounts
  eu_reverse_vat: AutoliquidatedVatAccounts
  eu_goods_vat: AutoliquidatedVatAccounts
  eu_goods_reverse_vat: AutoliquidatedVatAccounts
  world_vat: AutoliquidatedVatAccounts
  world_reverse_vat: AutoliquidatedVatAccounts
  world_goods_vat: AutoliquidatedVatAccounts
  world_goods_reverse_vat: AutoliquidatedVatAccounts
}

interface VatAccountsHistoryResponse {
  user_account_instructions: Instructions[]
}

export interface Instructions {
  account_number: string
  created_at: string
  instruction_type: string
  user: string
  metadata: {
    [index: string]: string
  }
}

export type VatAssignMode = "no_vat" | "per_rate" | "unique"

export const getVatAccountSettingsThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(getCompanyVatAccountsSettingsAttemptAction())
    return axios
      .get<VatAccountSettings>(`/companies/vat_accounts`, {
        params: { id: companyId },
      })
      .then((response) => {
        const assignMode =
          response.data.disable_vat_accounts ||
          response.data.selected_vat_accounts.length === 0
            ? "no_vat"
            : response.data.selected_vat_accounts[0].vat_accounting

        dispatch(
          getCompanyVatAccountsSettingsSuccessAction({
            rates: response.data.vat_rates,
            possibleBuyAccounts: response.data.possible_buy_accounts,
            possibleSellAccounts: response.data.possible_sell_accounts,
            selectedAccounts: response.data.selected_vat_accounts.map(
              (acc) => ({
                accountId: acc.account_id,
                vatAccounting: acc.vat_accounting,
                buyOrSell: acc.buy_or_sell,
                vatRateId: acc.vat_rate_id,
                goodsOrServices: acc.goods_or_services,
              })
            ),
            assignMode,
            accountNumberLimit: response.data.account_number_limit,
          })
        )
      })
      .catch(() => {
        dispatch(getCompanyVatAccountsSettingsErrorAction())
      })
  }

export const getVatAccountsHistoryThunk =
  (
    companyId: number,
    buyOrSell: buyOrSell,
    vatType: "unique" | "per_rate",
    rate: string | null,
    goodsOrServices: goodsOrServices
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    return axios
      .get<VatAccountsHistoryResponse>(`/companies/vat_accounts_history`, {
        params: {
          id: companyId,
          buy_or_sell: buyOrSell,
          vat_type: vatType,
          goods_or_services: goodsOrServices,
          rate: rate,
        },
      })
      .then((response) => {
        dispatch(
          getVatAccountsHistorySuccessAction({
            userAccountInstructions: response.data.user_account_instructions,
          })
        )
      })
      .catch((e) => {
        dispatch(getVatAccountsHistoryErrorAction())
      })
  }

export const updateVatSettingsThunk =
  ({
    companyId,
    assignMode,
    accounts,
  }: {
    companyId: number
    assignMode: VatAssignMode
    accounts: VatAccountPayload[]
  }) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    if (assignMode === "no_vat") {
      dispatch(disableVatAccountsAttemptAction())
      return axios
        .post(`companies/disable_vat_accounts`, {
          id: companyId,
        })
        .then((response) => {
          dispatch(disableVatAccountsSuccessAction())
          dispatch(getVatAccountSettingsThunk({ companyId }))
        })
        .catch((e) => {
          dispatch(disableVatAccountsErrorAction())
        })
    }

    dispatch(createVatAccountsAttemptAction())
    return axios
      .post(`companies/create_vat_accounts`, {
        id: companyId,
        vat_accounts: accounts,
      })
      .then((response) => {
        dispatch(createVatAccountsSuccessAction())
        dispatch(getVatAccountSettingsThunk({ companyId }))
      })
      .catch((e) => {
        dispatch(createVatAccountsErrorAction())
      })
  }

export const vatTypeArray = [
  "construction_vat",
  "construction_reverse_vat",
  "eu_vat",
  "eu_reverse_vat",
  "eu_goods_vat",
  "eu_goods_reverse_vat",
  "world_vat",
  "world_reverse_vat",
  "world_goods_vat",
  "world_goods_reverse_vat",
] as const

export type VatType = (typeof vatTypeArray)[number]

interface CreatedAutoliquidatedVatAccount extends Account {
  vatType: VatType | null
}

export const getAutoliquidatedVatAccountSettingsThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(getCompanyAutoliquidatedVatAccountsSettingsAttemptAction())

    return axios
      .get<AutoliquidatedVatAccountSettings>(
        `/companies/autoliquidated_vat_accounts`,
        { params: { id: companyId } }
      )
      .then((response) => {
        const res = response.data

        const vatAccountsSettings = vatTypeArray.reduce((acc, type) => {
          const vatTypeSettings: AutoliquidatedVatAccounts = {
            selected_account: res[type].selected_account,
            possible_accounts: res[type].possible_accounts,
            prefix: res[type].prefix,
            forbid: res[type].forbid,
            deactivated_at: res[type].deactivated_at,
          }

          acc[type] = vatTypeSettings
          return acc
        }, {} as Record<keyof AutoliquidatedVatAccountSettings, AutoliquidatedVatAccounts>)

        dispatch(
          getCompanyAutoliquidatedVatAccountsSettingsSuccessAction(
            vatAccountsSettings as AutoliquidatedVatAccountSettings
          )
        )
      })
      .catch(() => {
        dispatch(getCompanyAutoliquidatedVatAccountsSettingsErrorAction())
      })
  }

export const activateOrDeactivateAutoliquidatedVatThunk =
  (
    companyId: number,
    vatType: VatType,
    typeAction: "activate" | "deactivate"
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(activateOrDeactivateAutoliquidatedVatAttemptAction())
    return axios
      .post(`companies/activate_or_deactivate_autoliquidated_vat`, {
        id: companyId,
        type_action: typeAction,
        vat_type: vatType,
      })
      .then(() => {
        dispatch(activateOrDeactivateAutoliquidatedVatSuccessAction())
        dispatch(
          getAutoliquidatedVatAccountSettingsThunk({ companyId: companyId })
        )
      })
      .catch(() => {
        dispatch(activateOrDeactivateAutoliquidatedVatFailureAction())
      })
  }

export const getAutoliquidatedVatRatesThunk =
  (companyId: number) => (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(getCompanyAutoliquidatedAccountsVatRatesAttemptAction())
    return axios
      .get<AccountsVatRates>(`/companies/autoliquidated_vat_rates`, {
        params: { id: companyId },
      })
      .then((response) => {
        dispatch(
          getCompanyAutoliquidatedAccountsVatRatesSuccessAction(response.data)
        )
      })
      .catch(() => {
        dispatch(getCompanyAutoliquidatedAccountsVatRatesFailureAction())
      })
  }

export const getAutoliquidatedVatAccountHistoryThunk =
  ({
    companyId,
    vatType,
    rate,
  }: {
    companyId: number
    vatType: VatType
    rate: boolean
  }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(getCompanyAutoliquidatedVatAccountsHistoryAttemptAction())

    return axios
      .get<VatAccountsHistoryResponse>(
        `/companies/autoliquidated_vat_accounts_history`,
        {
          params: { id: companyId, vat_type: vatType, rate: rate },
        }
      )
      .then((response) => {
        const res = response.data
        const parsed_response = res.user_account_instructions.map((curr) => ({
          ...curr,
          metadata: JSON.parse(String(curr.metadata)),
        }))

        dispatch(
          getCompanyAutoliquidatedVatAccountsHistorySuccessAction({
            userAccountInstructions: parsed_response,
            vatType: vatType,
          })
        )
      })
      .catch(() => {
        dispatch(getCompanyAutoliquidatedVatAccountsHistoryErrorAction())
      })
  }

export const updateAutoliquidatedVatSettingsThunk =
  ({
    companyId,
    accounts,
    vatRates,
  }: {
    companyId: number
    accounts: AutoliquidatedVatAccountsState
    vatRates: AccountsVatRates
  }) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(updateAutoliquidatedVatAccountsAttemptAction())

    return axios
      .post(`companies/update_autoliquidated_vat_accounts`, {
        id: companyId,
        eu_vat_account_id: accounts.euVatAccount?.id,
        eu_reverse_vat_account_id: accounts.euReverseVatAccount?.id,
        construction_vat_account_id: accounts.constructionVatAccount?.id,
        construction_reverse_vat_account_id:
          accounts.constructionReverseVatAccount?.id,
        eu_goods_vat_account_id: accounts.euGoodsVatAccount?.id,
        eu_goods_reverse_vat_account_id: accounts.euGoodsReverseVatAccount?.id,
        world_vat_account_id: accounts.worldVatAccount?.id,
        world_reverse_vat_account_id: accounts.worldReverseVatAccount?.id,
        world_goods_vat_account_id: accounts.worldGoodsVatAccount?.id,
        world_goods_reverse_vat_account_id:
          accounts.worldGoodsReverseVatAccount?.id,
        construction_vat_account_vat_rate_id:
          vatRates.selected_construction_vat_rate?.id,
        eu_vat_account_vat_rate_id: vatRates.selected_eu_vat_rate?.id,
        eu_goods_vat_account_vat_rate_id:
          vatRates.selected_eu_goods_vat_rate?.id,
        world_vat_account_vat_rate_id: vatRates.selected_world_vat_rate?.id,
        world_goods_vat_account_vat_rate_id:
          vatRates.selected_world_goods_vat_rate?.id,
      })
      .then(() => {
        dispatch(updateAutoliquidatedVatAccountsSuccessAction())
        dispatch(getAutoliquidatedVatAccountSettingsThunk({ companyId }))
        dispatch(getAutoliquidatedVatRatesThunk(companyId))
      })
      .catch((e) => {
        dispatch(updateAutoliquidatedVatAccountsErrorAction())
      })
  }

interface CreatedAccount extends Account {
  buyOrSell: buyOrSell
  vatRateId: number | null
  goodsOrServices: goodsOrServices
}

export const createAccountThunk =
  ({
    companyId,
    number,
    details,
    buyOrSell,
    vatRateId,
    goodsOrServices,
  }: {
    companyId: number
    number: string
    details: string
    buyOrSell: buyOrSell
    vatRateId: number | null
    goodsOrServices: goodsOrServices
  }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    return axios
      .post<Account | true>(`/companies/create_account`, {
        id: companyId,
        number,
        details,
        buy_or_sell: buyOrSell,
      })
      .then((response) => {
        if (response.data === true) {
          dispatch(createAccountDuplicateError())
          return
        }
        dispatch(
          createAccountSuccessAction({
            ...response.data,
            buyOrSell,
            vatRateId,
            goodsOrServices,
          })
        )
        dispatch(createAccountSuccessToaster())
      })
      .catch(() => {})
  }

export const createAutoliquidatedVatAccountThunk =
  ({
    companyId,
    number,
    details,
    vatType,
  }: {
    companyId: number
    number: string
    details: string
    vatType: VatType | null
  }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(createAutoliquidatedVatAccountsAttemptAction())
    return axios
      .post<Account | true>(`/companies/create_autoliquidated_vat_account`, {
        id: companyId,
        number,
        details,
        vat_type: vatType,
      })
      .then((response) => {
        if (response.data === true) {
          dispatch(createAccountDuplicateError())
          return
        }
        dispatch(
          createAutoliquidatedVatAccountsSuccessAction({
            ...response.data,
            vatType,
          })
        )
        dispatch(createAccountSuccessToaster())
      })
      .catch(() => {
        dispatch(createAutoliquidatedVatAccountsErrorAction())
      })
  }

interface MyUnisoftTokenRespone {
  my_unisoft_token: string | null
}

export const getMyUnisoftTokenThunk =
  ({ companyId }: { companyId: number }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(getMyUnisoftTokenAttemptAction())

    return axios
      .get<MyUnisoftTokenRespone>(`/companies/get_my_unisoft_token`, {
        params: {
          id: companyId,
        },
      })
      .then((response) => {
        const token = response.data.my_unisoft_token
        dispatch(getMyUnisoftTokenSuccessAction(token || ""))
      })
      .catch(() => {
        dispatch(getMyUnisoftTokenErrorAction())
      })
  }

export const updateMyUnisoftTokenThunk =
  ({ companyId, token }: { companyId: number; token: string }) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(updateMyUnisoftTokenAttemptAction())

    return axios
      .put<MyUnisoftTokenRespone>(`/companies/update_my_unisoft_token`, {
        id: companyId,
        token,
      })
      .then(() => {
        dispatch(updateMyUnisoftTokenSuccessAction(token))
      })
      .catch(() => {
        dispatch(updateMyUnisoftTokenErrorAction())
      })
  }

export const GetCompanyGoodsTransferAccountsThunk =
  (companyId: number) => (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(GetCompanyGoodsTransferAccountsAttempt())

    return axios
      .get<GoodsTransferResponse>(
        `/companies/get_goods_transfer_accounts_for_user`,
        {
          params: { id: companyId },
        }
      )
      .then((response) => {
        const goods = {
          commission_account_vat_rates:
            response.data.commission_account_vat_rates,
          enum_translations: response.data.enum_translations,
          goods_transfer_commission_type_enum:
            response.data.goods_transfer_commission_type_enum,
          goods_transfer_type_enum: response.data.goods_transfer_type_enum,
          possible_goods_transfer_accounts:
            response.data.possible_goods_transfer_accounts,
          possible_goods_transfer_commission_accounts:
            response.data.possible_goods_transfer_commission_accounts,
          selected_accounts: response.data.selected_accounts,
          goods_transfer_rules: response.data.goods_transfer_rules,
          goods_transfer_commission_rules:
            response.data.goods_transfer_commission_rules,
        }

        dispatch(
          GetCompanyGoodsTransferAccountsSuccess(
            goods,
            response.data.account_number_limit,
            response.data.vat_rates
          )
        )
      })
      .catch(() => {
        dispatch(GetCompanyGoodsTransferAccountsFailure())
      })
  }

interface GoodsTransfer {
  commission_account_vat_rates: { [index: string]: CommissionAccountVatRate[] }
  enum_translations: EnumTranslations | null
  goods_transfer_commission_type_enum: GoodsTransferType[]
  goods_transfer_type_enum: GoodsTransferType[]
  possible_goods_transfer_accounts: Account[]
  possible_goods_transfer_commission_accounts: Account[]
  selected_accounts: SelectedGoodsTransferAccounts | null
  goods_transfer_rules: string[]
  goods_transfer_commission_rules: string[]
}

interface GoodsTransferResponse extends GoodsTransfer {
  account_number_limit: number | null
  vat_rates: Rate[]
}

export interface CommissionAccountVatRate {
  id: number
  account_id: number
  number: string
  details: string
  rate: number
}

export type SelectedGoodsTransferAccounts = {
  [K in GoodsTransferType]: AccountGoodsTransfer | null
}

export interface AccountGoodsTransfer extends Account {
  instructions: AccountInstructions[] | null
  type: GoodsTransferType
}

export interface AccountInstructions {
  created_at: string
  instruction_type: string
  metadata: {}
  email: string
  account_number: number
}

export type EnumTranslations = {
  [K in GoodsTransferType]: string
}

export type GoodsTransferType =
  | "draw_games_goods_transfer"
  | "draw_games_goods_transfer_commission"
  | "draw_games_paid_lots_goods_transfer"
  | "metro_goods_transfer"
  | "metro_goods_transfer_commission"
  | "monetics_goods_transfer"
  | "monetics_goods_transfer_commission"
  | "other_goods_transfer"
  | "other_goods_transfer_commission"
  | "postage_stamps_goods_transfer"
  | "postage_stamps_goods_transfer_commission"
  | "press_goods_transfer"
  | "press_goods_transfer_commission"
  | "scratch_games_goods_transfer"
  | "scratch_games_goods_transfer_commission"
  | "scratch_games_paid_lots_goods_transfer"
  | "tax_stamps_goods_transfer"
  | "tax_stamps_goods_transfer_commission"
  | "tobacco_goods_transfer"
  | "tobacco_goods_transfer_commission"

export const UpdateGoodsTransferAccountsThunk =
  (companyId: number, accounts: { [index: string]: Account | null }) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(UpdateGoodsTransferAccountsAttempt())

    return axios
      .post(`/companies/set_goods_transfer_accounts`, {
        id: companyId,
        accounts: accounts,
      })
      .then(() => {
        dispatch(UpdateGoodsTransferAccountsSuccess())
        dispatch(GetCompanyGoodsTransferAccountsThunk(companyId))
      })
      .catch(() => {
        dispatch(UpdateGoodsTransferAccountsFailure())
      })
  }

export const CreateGoodsTransferAccountThunk =
  (
    companyId: number,
    account: { number: number; details: string },
    goods_type: string
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(CreateGoodsTransferAccountAttempt())

    return axios
      .post<AccountGoodsTransfer>(`/companies/create_goods_transfer_account`, {
        id: companyId,
        account: account,
        goods_type: goods_type,
      })
      .then((response) => {
        dispatch(GetCompanyGoodsTransferAccountsThunk(companyId))
        dispatch(CreateGoodsTransferAccountSuccess(response.data))
      })
      .catch(() => {
        dispatch(CreateGoodsTransferAccountFailure())
      })
  }

export const CreateGoodsTransferAccountWithVathunk =
  (
    companyId: number,
    account: { number: string; details: string },
    goods_type: string,
    vat_rate_id: string
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(CreateGoodsTransferAccountWithVatAttempt())

    return axios
      .post<CommissionAccountVatRate[]>(
        `/companies/create_commission_account_vat_rate`,
        {
          id: companyId,
          account: account,
          datum_type: goods_type,
          vat_rate_id: vat_rate_id,
        }
      )
      .then((response) => {
        dispatch(
          CreateGoodsTransferAccountWithVatSuccess(response.data, goods_type)
        )
      })
      .catch(() => {
        dispatch(CreateGoodsTransferAccountWithVatFailure())
      })
  }

export const DestroyCommissionAccountVatRateThunk =
  (
    companyId: number,
    goods_type: string,
    commission_account_vat_rate_id: number
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(DestroyCommissionAccountVatRateAttempt())

    return axios
      .post<CommissionAccountVatRate[]>(
        `/companies/destroy_commission_account_vat_rate`,
        {
          id: companyId,
          commission_account_vat_rate_id: commission_account_vat_rate_id,
          datum_type: goods_type,
        }
      )
      .then((response) => {
        dispatch(
          DestroyCommissionAccountVatRateSuccess(response.data, goods_type)
        )
      })
      .catch(() => {
        dispatch(DestroyCommissionAccountVatRateFailure())
      })
  }

export const GetDataToUpdateAccountingSoftwareThunk =
  (
    companyId: number,
    accounting_software: string,
    buy_auxiliary_prefix: string,
    sell_auxiliary_prefix: string
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(GetDataToUpdateAccountingSoftwareAttempt())
    return axios
      .get<ResponseDataUpdateAccountingSoftware>(
        `/companies/data_to_update_accounting_software`,
        {
          params: {
            id: companyId,
            accounting_software: accounting_software,
            buy_auxiliary_prefix: buy_auxiliary_prefix,
            sell_auxiliary_prefix: sell_auxiliary_prefix,
          },
        }
      )
      .then((response) => {
        dispatch(GetDataToUpdateAccountingSoftwareSuccess(response.data))
      })
      .catch(() => {
        dispatch(GetDataToUpdateAccountingSoftwareFailure())
      })
  }

interface ResponseDataUpdateAccountingSoftware {
  length_rules: {
    max_account_number_length: number | null
    max_code_length: number | null
  }
  codes_too_long: CodesToUpdate[] | []
  accounts_too_long: AccountsToUpdate[] | []
  all_accounts: AccountsToUpdate[] | []
  all_codes: CodesToUpdate[] | []
  accounting_software_prefix: { buy: string; sell: string } | null
  auxiliary_or_principal: AuxiliaryOrPrincipal
  software_events: SoftwareEvent[] | []
}

interface CodesToUpdate {
  id: number
  code: string
}
interface AccountsToUpdate {
  id: number
  number: string
}

export type AuxiliaryOrPrincipal =
  | ["auxiliary"]
  | ["principal"]
  | ["auxiliary", "principal"]
  | null

export interface SoftwareEvent {
  id: number
  date: string
  email: string
  metadata: { [index: string]: string }
}
export const createJournalThunk =
  (
    companyId: number,
    journal_code: string,
    details: string,
    journal_type: "buy" | "sell" | "misc"
  ) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(createJournalAttemptAction())
    return axios
      .post(`/companies/${companyId}/create_journal/`, {
        journal_code,
        details,
        journal_type,
      })
      .then(() => {
        dispatch(createJournalSuccessAction(journal_code, details))
        dispatch(getJournalsThunk(companyId))
      })
      .catch((e) => {
        let error_msg = ""
        if (e.response.data.error.includes("Must be unique per company"))
          error_msg = "Ce journal existe déjà"
        else error_msg = e.response.data.error
        dispatch(createJournalErrorAction(error_msg))
      })
  }

interface getJournalResponse {
  active_journals: {
    buy: Journal
    sell: Journal
    misc: Journal
  }
  all_journals: Journal[]
  code_limit: number
}

export const getJournalsThunk =
  (companyId: number) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(getJournalsAttemptAction())
    return axios
      .get<getJournalResponse>(`companies/${companyId}/get_journals`)
      .then((response) => {
        dispatch(getJournalsSuccessAction(response.data))
      })
      .catch(() => dispatch(getJournalsErrorAction()))
  }

export const changeJournalThunk =
  (companyId: number, journalType: JournalType, journalId: string) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(changeJournalAttemptAction())
    return axios
      .put(`companies/${companyId}/set_journal`, {
        journal_id: journalId,
        journal_type: journalType,
      })
      .then((response) => {
        dispatch(getJournalsThunk(companyId))
        dispatch(changeJournalSuccessAction())
      })
      .catch((e) => {
        dispatch(changeJournalFailureAction())
      })
  }

export const isOnboardingFinishedThunk =
  (companyId: number) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(getIsOnboardingFinishedAttemptAction())
    return axios
      .get<IsOnboardingFinishedType>(
        `companies/${companyId}/onboarding_unfinished`
      )
      .then((response) =>
        dispatch(getIsOnboardingFinishedSuccessAction(response.data))
      )
      .catch(() => dispatch(getIsOnboardingFinishedFailureAction()))
  }

export const GetCompanyAccountingKeyWordsThunk =
  (companyId: number) => (dispatch: Dispatch<CompanySettingsActionsType>) => {
    dispatch(GetCompanyAccountingKeyWordsAttempt())
    return axios
      .get<AccountingKeyWord>(`companies/${companyId}/get_company_key_words`)
      .then((response) => {
        dispatch(GetCompanyAccountingKeyWordsSuccess(response.data))
      })
      .catch(() => dispatch(GetCompanyAccountingKeyWordsFailure()))
  }

interface DataCreateKeyWord {
  companyId: number
  key_words: CreateKeyWord[]
  key_words_to_destroy: CreateKeyWord[]
}

export const CreateUpdateDeleteKeyWordsCompanyThunk =
  ({ companyId, key_words, key_words_to_destroy }: DataCreateKeyWord) =>
  (dispatch: Dispatch<CompanySettingsActionsType | RNBThunkAction>) => {
    dispatch(CreateUpdateDeleteKeyWordAttempt())
    return axios
      .put(`companies/${companyId}/create_update_delete_key_words`, {
        key_words: key_words,
        to_destroy: key_words_to_destroy,
      })
      .then((response) => {
        dispatch(GetCompanyAccountingKeyWordsThunk(companyId))
        dispatch(CreateUpdateDeleteKeyWordSuccess())
      })
      .catch((e) => {
        let key_word_exists = null
        if (e.response.data.error.includes("already exists")) {
          const split = e.response.data.error.split("=")
          key_word_exists = split[1]
        }
        dispatch(CreateUpdateDeleteKeyWordFailure(key_word_exists))
      })
  }
