import React from 'react';
import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from 'styled-components/macro';
import { Select } from "../components/Commons/Select"
import { ReactComponent as Plus } from "../assets/plus.svg"
import { ReactComponent as EyeFilled } from "../assets/eyeFilled.svg"
import { colors } from '../styles/design.config';


interface AccountStripSelectCreateHistoryProps {
    label?: string
    key?: string
    value: Ct.Option<string>
    options: Ct.Option<string>[]
    onChangeCallback: (option: Ct.Option<string>) => void
    error: boolean
    displayCreate?: boolean
    displayHistory?: boolean
    onClickCreate?: () => void
    onClickHistory?: () => void
    disabled?: boolean
}


export const AccountStripSelectCreateHistory = (props: AccountStripSelectCreateHistoryProps) => {

    const intl = useIntl()

    return (
        <VatAccountWrapper key={props.key && props.key}>
            <Ct.Spacer height={2} />
                <Ct.Text
                text={props.label || ""}
                textStyle={{
                    color: "cornflower",
                    fontSize: 1.75,
                    fontWeight: 700,
                }}
                />
                <Ct.Spacer />
                <AccountStripWrapper displayCreate displayHistory>
                <Ct.Row>
                    <Select
                        intl={intl}
                        disabled={props.disabled}
                        value={props.value}
                        options={props.options}
                        onChangeCallback={(e: Ct.Option<string>) => props.onChangeCallback(e)                              }
                        error={props.error}
                    />
                    <Ct.Spacer />

                    {props.displayCreate &&
                        <>
                            <Ct.Button
                                label={intl.formatMessage({
                                    id: "office-company-settings.vat-accounts.create",
                                })}
                                onClick={props.onClickCreate}
                                width={22}
                                prefix={<Plus />}
                                disabled={props.disabled}
                            />
                            <Ct.Spacer width={3} />
                        </>
                    }
                    {props.displayHistory &&
                        <>
                            <EyeWrapper onClick={props.disabled ? () => {} : props.onClickHistory}>
                                <StyledEyeFilled disabled={props.disabled} />
                            </EyeWrapper>
                        </>
                    }
                </Ct.Row>
            </AccountStripWrapper>
        </VatAccountWrapper>

    )
}

interface displayOptions {
    displayCreate?: boolean
    displayHistory?: boolean
}

interface EyeFilledProps {
    disabled?: boolean
}


const VatAccountWrapper = styled.div`
  width: 97%;
  padding-left: 4rem;
  overflow: hidden;
`

const AccountStripWrapper = styled.div<displayOptions>`
    padding-right: ${({ displayCreate }) => (!displayCreate ? '28rem' : 'auto')};
`

const EyeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledEyeFilled = styled(EyeFilled)<EyeFilledProps>`
    & path {
        fill: ${({ disabled }) => disabled ? colors.lightGrey : colors.cornflower};
    }
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

`