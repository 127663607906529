import styled from "styled-components/macro"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import * as Ct from "ldlj"
import { ReactComponent as Logout } from "../assets/logout.svg"
import { ReactComponent as QuestionMark } from "../assets/question-mark.svg"
import { ReactComponent as Security } from "../assets/security.svg"
import { useRNBSelector } from "../store/rootReducer"
import { useNavigate } from "react-router-dom"
import { attemptLogoutThunk } from "../store/ducks/login.ducks"
import { useEffect } from "react"

interface UserMenuProps {
  showMenu: boolean
}

export const UserMenu = ({ showMenu }: UserMenuProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userFirstName = useRNBSelector((state) => state.user.firstName)
  const loginStatus = useRNBSelector((state) => state.login.status)
  const userTypology = useRNBSelector((state) => state.user.typology)

  const redirectHome = () => {
    dispatch(attemptLogoutThunk())
  }

  const redirectHelpCenter = () => {
    const newTab = window.open("https://aide.chaintrust.io/hc/fr", "_blank")
    newTab?.focus()
  }

  useEffect(() => {
    if (loginStatus === "DISCONNECTED") {
      return navigate("/user/login")
    }
  }, [loginStatus, navigate])

  return (
    <StyledCard overridePosition width={"20rem"} showMenu={showMenu}>
      <UserName
        text={intl.formatMessage(
          {
            id: "logout.greeting",
          },
          { userFirstName }
        )}
      />

      <Ct.Spacer height={1.5} />
      <Ct.Separator size="half" />
      <Ct.Spacer height={1.5} />
      <div>
        {userTypology === "customer" ||
        userTypology === "customer_accountant" ? (
          <StyledDiv onClick={redirectHome} id="logout">
            <Logout />
            <Ct.Spacer width={1} />
            <Ct.Text
              text={intl.formatMessage({
                id: "logout.cta",
              })}
              textStyle={{ cursor: "pointer" }}
            />
          </StyledDiv>
        ) : (
          <div>
            <StyledDiv
              onClick={() => {
                navigate("/office/security")
              }}
            >
              <Security width={"16px"} height={"16px"} />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "security",
                })}
                textStyle={{ cursor: "pointer" }}
              />
            </StyledDiv>
            <Ct.Spacer height={1} />
            <StyledDiv onClick={redirectHelpCenter}>
              <QuestionMark width={"16px"} height={"16px"} />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "centre.aide",
                })}
                textStyle={{ cursor: "pointer" }}
              />
            </StyledDiv>
            <Ct.Spacer height={1} />
            <StyledDiv onClick={redirectHome} id="logout">
              <Logout />
              <Ct.Spacer width={1} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "logout.cta",
                })}
                textStyle={{ cursor: "pointer" }}
              />
            </StyledDiv>
          </div>
        )}
      </div>
    </StyledCard>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)<UserMenuProps>`
  position: relative;
  align-self: flex-end;
  z-index: 10;
  transition: opacity 0.5s ease-in-out;
  padding: 2.5rem;
  opacity: ${({ showMenu }) => (showMenu ? "1" : "0")};
  display: ${({ showMenu }) => (showMenu ? "block" : "none")};
  text-align: center;
`

const StyledDiv = styled.div`
  display: flex;
  cursor: pointer;
`

const UserName = styled((props) => <Ct.Text {...props} />)`
  text-transform: capitalize;
`
