import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { ReactComponent as Chevron } from "../assets/thin-right-chevron.svg"
import { colors } from "../styles/design.config"
import { DynamicSvg, svgComponents } from "./DynamicSVG"
import ReactTooltip from "react-tooltip"

interface SwitchType {
  type: "switch"
  value: boolean
  id: string
  onToggleAction: () => void
}

interface SVGProps {
  type: "svg"
  svgName: string
}

interface StripProps {
  itemType: SwitchType | SVGProps
  label: string
  disabled?: boolean
  rotation?: boolean
  dataTip?: string
  onSeeMoreAction?: () => void
}

export const Strip = (props: StripProps) => {
  const intl = useIntl()

  return (
    <StripContainer>
      <LabelWrapper>
        {props.itemType.type === "switch" ? (
          <>
            <div data-tip={props.dataTip}>
              <Ct.Switch
                value={props.itemType.value}
                id={props.itemType.id}
                onToggle={props.itemType.onToggleAction}
              />
            </div>
            <ReactTooltip
              delayHide={500}
              effect={"solid"}
              eventOff="mouseleave scroll mousewheel blur"
              borderColor={colors.navy}
            />
          </>
        ) : (
          <DynamicSvg
            name={props.itemType.svgName as keyof typeof svgComponents}
          />
        )}
        <Ct.Spacer width={1} />
        <Ct.Text
          text={props.label}
          textStyle={{
            color: "navy",
            fontWeight: 700,
            lineHeight: 3,
          }}
        />
      </LabelWrapper>
      <SeeMoreWrapper
        onClick={props.disabled ? () => {} : props.onSeeMoreAction}
      >
        <Ct.Text
          text={intl.formatMessage({
            id: `office-company-settings.vat-accounts.see-more`,
          })}
          textStyle={{
            color: props.disabled ? "disabledGrey" : "cornflower",
            underline: props.disabled ? false : true,
            lineHeight: 3,
          }}
        />
        <Ct.Spacer width={1} />
        <ChevronWrapper
          disabled={props.disabled || false}
          rotation={props.rotation || false}
        >
          <Chevron />
        </ChevronWrapper>
      </SeeMoreWrapper>
    </StripContainer>
  )
}

const StripContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SeeMoreWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface RotationProps {
  rotation?: boolean
  disabled?: boolean
}

const ChevronWrapper = styled.span<RotationProps>`
  display: flex;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  && svg {
    & path {
      fill: ${({ disabled }) =>
        disabled ? colors.lightGrey : colors.cornflower};
    }
    transition-duration: 0.2s;
    transform: ${({ rotation }) => (rotation ? "rotate(90deg)" : ``)};
  }
`
