import * as Ct from "ldlj"
import AutoSizer from "react-virtualized-auto-sizer"
import React, { useEffect, useState } from "react"
import styled, { CSSProperties } from "styled-components/macro"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { useDispatch } from "react-redux"
import { VariableSizeGrid } from "react-window"
import { boxShadow, colors } from "../../../../../styles/design.config"
import { ReactComponent as FolderArchiveSVG } from "../../../../../assets/folder-archive.svg"
import { ReactComponent as Reload } from "../../../../../assets/reload.svg"
import { getIdFromParams } from "../../../../../utils/company"
import {
  RowPayload,
  formattedFiscalYearDate,
  formattedTime,
  sortArchiveByDate,
  sortBankArchiveById,
  sortByDownloadUser,
  sortByFiscalYear,
} from "../../../../../utils/bank"
import {
  getArchiveUrlThunk,
  getArchivesThunk,
  SyncBankinTransactionsThunk,
  getNewOperationsWithoutArchivesThunk,
  regenerateBankinArchiveReset,
} from "../../../../../store/ducks/bankArchives.ducks"
import { GenerateArchiveChoicesModal } from "../../../../../components/bank/GenerateArchiveChoicesModal"
import { TableWrapper } from "../../../../../components/Commons/TableStylesForSelect"
import { Button } from "../../../../../components/Commons/Button"
import { Alert } from "../../../../../components/Commons/Alert"
import { Text } from "../../../../../components/Commons/Text"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
} from "../../../../../components/dropDocuments/StyledDropsComponents"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../../components/Commons/Table"
import { RegenerateBankinArchiveModal } from "../../../../../components/bank/RegenerateBankinArchiveModal"

export const Archives = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const itemId = getIdFromParams(useParams())("bank_id")
  const accountId = getIdFromParams(useParams())("account_id")

  const {
    company,
    fiduciaryId,
    newOperationsWithoutArchive,
    archives,
    regenerateBankinArchiveStatus,
  } = useRNBSelector((state) => ({
    company: state.companies.companies[selectedCompanyId || 0],
    fiduciaryId: state.fiduciary.id,
    newOperationsWithoutArchive: state.bank.newOperationsWithoutArchive,
    archives: state.bank.archives,
    regenerateBankinArchiveStatus: state.bank.regenerateBankinArchiveStatus,
  }))

  const [displayGenerateModal, setDisplayGenerateModal] = useState<{
    display: boolean
    fiscalYearId: number | null
  }>({ display: false, fiscalYearId: null })

  const [displayRegenerateModal, setDisplayRegenerateModal] = useState<{
    display: boolean
    bankinArchiveId: number
  }>({ display: false, bankinArchiveId: 0 })
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const listRef = React.createRef<VariableSizeGrid>()
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [rowData, setRowData] = useState<RowPayload[]>([])
  const companyName = company?.user_company_name
    ? company?.user_company_name
    : company?.name
    ? company?.name
    : ""

  const loadData = () => {
    if (selectedCompanyId && itemId && accountId) {
      dispatch(
        SyncBankinTransactionsThunk(
          accountId,
          fiduciaryId,
          selectedCompanyId,
          itemId
        )
      )
      dispatch(
        getArchivesThunk({
          fiduciaryId: fiduciaryId,
          companyId: selectedCompanyId,
          itemId: itemId,
          accountId: accountId,
        })
      )
      dispatch(getNewOperationsWithoutArchivesThunk(accountId))
    }
  }
  useEffect(() => {
    loadData()
  }, [dispatch, selectedCompanyId, itemId, accountId, fiduciaryId])

  useEffect(() => {
    if (newOperationsWithoutArchive.length > 0 || archives.length > 0) {
      setRowData([...newOperationsWithoutArchive, ...archives])
    }
  }, [newOperationsWithoutArchive, archives])

  useEffect(() => {
    if (["SUCCESS", "ERROR"].includes(regenerateBankinArchiveStatus)) {
      dispatch(regenerateBankinArchiveReset())
      loadData()
    }
  }, [regenerateBankinArchiveStatus])

  const columns = [
    {
      headerText: "archives.table-header.archive",
      content: (row: RowPayload) => (
        <RowContent
          onClick={() => {
            if (
              !row.archiveId &&
              !companyName &&
              !company.accounting_software_reference &&
              !company.accounting_software
            )
              return
            dispatch(
              getArchiveUrlThunk({
                id: row.archiveId || 0,
                companyName,
                accountSoftwareReference:
                  company.accounting_software_reference || "",
                accountSoftware: company.accounting_software || "",
              })
            )
          }}
        >
          <Ct.Spacer width={4} />
          <FolderArchiveSVG />
          <Ct.Spacer width={1.75} />
          {row.status === "NOT_GENERATED" ? (
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "archives.table-content.archive.ready-to-generate",
                },
                { count: row.newTransactionsCount }
              )}
              textStyle={{
                fontFamily: "Roboto",
                color: "cornflower",
                fontWeight: 500,
                lineHeight: 2,
              }}
            />
          ) : row.status === "GENERATING" ? (
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "archives.table-content.archive.generating",
                },
                { count: row.newTransactionsCount }
              )}
              textStyle={{
                underline: true,
                fontFamily: "Roboto",
                color: "cornflower",
                fontWeight: 500,
                lineHeight: 2,
              }}
            />
          ) : row.status === "GENERATED" && row.count_bankin_transaction ? (
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: "archives.table-content.archive.generated",
                },
                { count: String(row.count_bankin_transaction || 0) }
              )}
              textStyle={{
                underline: true,
                fontFamily: "Roboto",
                color: "cornflower",
                fontWeight: 500,
                lineHeight: 2,
              }}
            />
          ) : (
            <></>
          )}
        </RowContent>
      ),
    },
    {
      headerText: "archives.table-header.fiscal-year",
      content: (row: RowPayload) => (
        <Row
          text={formattedFiscalYearDate(
            row.begin_exercise || "",
            row.end_exercise || ""
          )}
        />
      ),
    },
    {
      headerText: "archives.table-header.download-date",
      content: (row: RowPayload) => (
        <Row text={row.created_at ? formattedTime(row.created_at) : "-"} />
      ),
      flexGrow: "25rem",
    },
    {
      headerText: "archives.table-header.download-by",
      content: (row: RowPayload) => (
        <>
          {row.downloadLastName && row.downloadedAt ? (
            <RowColumn>
              <Row
                text={row.downloadFirstName + " " + row.downloadLastName}
                textStyle={{ lineHeight: 4 }}
              />
              <Row
                text={"le " + formattedTime(row.downloadedAt)}
                textStyle={{ color: "slateGrey" }}
              />
            </RowColumn>
          ) : (
            <Row text={"-"} />
          )}
        </>
      ),
    },
    {
      headerText: "archives.table-header.download",
      content: (row: RowPayload) => (
        <Ct.RowCenter>
          {row.status === "NOT_GENERATED" ? (
            <DisableOverflow>
              <Ct.Button
                width={26}
                height={4}
                label={intl.formatMessage({
                  id: "archives.table-content.generate-archive-button",
                })}
                onClick={() => {
                  row.fiscalYearId &&
                    setDisplayGenerateModal({
                      display: true,
                      fiscalYearId: row.fiscalYearId,
                    })
                }}
              />
            </DisableOverflow>
          ) : row.status === "GENERATING" ||
            regenerateBankinArchiveStatus === "LOADING" ? (
            <DisableOverflow>
              <Button label={""} loadingStatus={"loading"} />
            </DisableOverflow>
          ) : row.status === "GENERATED" && row.archiveId ? (
            <DisableOverflow>
              <Ct.Button
                colorType={row.downloadUserId !== null ? "Tertiary" : "Primary"}
                colorScheme={
                  row.downloadUserId !== null
                    ? {
                        background: "mist",
                        color: "cornflower",
                        border: "mist",
                      }
                    : undefined
                }
                width={26}
                height={4}
                label={intl.formatMessage(
                  {
                    id: `archives.table-content.download-achive-button`,
                  },
                  { id: row.archiveId }
                )}
                onClick={() => {
                  dispatch(
                    getArchiveUrlThunk({
                      id: row.archiveId || 0,
                      companyName,
                      accountSoftwareReference:
                        company.accounting_software_reference || "",
                      accountSoftware: company.accounting_software || "",
                    })
                  )
                }}
              />

              <Regenerate
                onClick={() => {
                  setDisplayRegenerateModal({
                    display: true,
                    bankinArchiveId: row.archiveId || 0,
                  })
                }}
              >
                <StyledReload />
                <Ct.Spacer width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: `archives.table-content.regenerate-achive-button`,
                  })}
                  textStyle={{
                    fontSize: 1.75,
                    fontWeight: 600,
                    underline: true,
                  }}
                />
              </Regenerate>
            </DisableOverflow>
          ) : (
            <></>
          )}
        </Ct.RowCenter>
      ),
    },
  ]

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const archive: RowPayload = rowData[rowIndex]

    return (
      <>
        <div
          style={{
            ...style,
            backgroundColor: rowIndex % 2 === 0 ? "white" : "#F4F8FF",
          }}
          key={columnIndex}
        >
          {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
          <StyledCell
            style={{ height: "100%", width: "100%" }}
            alignItems={columnIndex === 0 ? "flex-start" : "center"}
          >
            <Ct.Row
              style={{
                width: "100%",
                justifyContent: columnIndex === 0 ? "flex-start" : "center",
                height: "100%",
              }}
            >
              {columns[columnIndex].content(archive)}
            </Ct.Row>
          </StyledCell>
        </div>
      </>
    )
  }

  enum SortOptionsValues {
    "_",
    "fiscalYear",
    "date",
    "dowloadedUser",
    "archiveId",
  }

  const [sortType, setSortType] = useState<
    { type: SortOptionsValues; asc: boolean } | undefined
  >({ type: SortOptionsValues.archiveId, asc: true })

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    setSortType({ type: option, asc: asc })
    if (option === SortOptionsValues.fiscalYear) {
      setRowData(sortByFiscalYear(rowData, asc))
    } else if (option === SortOptionsValues.date) {
      setRowData(sortArchiveByDate(rowData, asc))
    } else if (option === SortOptionsValues.dowloadedUser) {
      setRowData(sortByDownloadUser(rowData, asc))
    } else if (option === SortOptionsValues.archiveId) {
      setRowData(sortBankArchiveById(rowData, asc))
    }
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])

      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setRowData([...newOperationsWithoutArchive, ...archives])
      }
    }
  }, [columnToSort])

  return (
    <Content>
      <StyledSection>
        <Ct.Spacer height={5} />
        {!rowData.length ? (
          <TextWrapper>
            <Alert alertType="info">
              <Ct.Text
                text={intl.formatMessage({
                  id: "bank.archives.no-archives-available.1",
                })}
                textStyle={{ lineHeight: 3 }}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: "bank.archives.no-archives-available.2",
                })}
                textStyle={{ lineHeight: 3 }}
              />
            </Alert>
          </TextWrapper>
        ) : (
          <StyledTable>
            <AutoSizer>
              {({ height, width }: { height: number; width: number }) => {
                const columnWidths = columns.map((_, index) => {
                  if (index === 0) {
                    return (25 / 100) * width
                  }
                  if (index === 1) {
                    return (20 / 100) * width
                  }
                  if (index === 2) {
                    return (20 / 100) * width
                  }
                  if (index === 3) {
                    return (15 / 100) * width
                  }
                  if (index === 4) {
                    return (20 / 100) * width
                  }

                  return (6 / 100) * width
                })

                if (
                  listRef?.current &&
                  (width !== listWidth || height !== listHeight)
                ) {
                  listRef.current.resetAfterColumnIndex(0, true)
                }
                setListWidth(width)
                setListHeight(height)

                const rowHeights = new Array(rowData.length)
                  .fill(true)
                  .map(() => 80)

                return (
                  <>
                    <HeaderWrapper totalWidth={width} paddingHeader={"0 1rem"}>
                      {columns.map((column, index) => (
                        <HeaderTitleWrapper
                          calculatedWidth={columnWidths[index]}
                          key={column.headerText}
                        >
                          {index > 0 ? (
                            <ClickableTitleSort
                              tid={column.headerText}
                              intl={intl}
                              index={index}
                              sortToReturn={(column: SortToReturn) => {
                                setColumnToSort(column)
                                if (rowData.length > 0 && sortType) {
                                  sorter(sortType.asc)(sortType.type)
                                }
                              }}
                              currentChevron={currentChevron}
                            />
                          ) : (
                            <TitleTable tid={column.headerText} intl={intl} />
                          )}
                        </HeaderTitleWrapper>
                      ))}
                    </HeaderWrapper>

                    {[...newOperationsWithoutArchive, ...archives].length ===
                    0 ? (
                      <LoaderWrapper totalWidth={width} height={height - 56}>
                        <Alert alertType="info">
                          <Text
                            text={intl.formatMessage({
                              id:
                                Object.values(rowData).length > 0
                                  ? "bank.archives.no-archives-available.search"
                                  : "bank.archives.no-archives-available.1",
                            })}
                          />
                        </Alert>
                      </LoaderWrapper>
                    ) : (
                      <GridWrapper totalWidth={width}>
                        <VariableSizeGrid
                          ref={listRef}
                          height={height - 56}
                          rowCount={rowData.length}
                          width={width}
                          columnWidth={(index) => columnWidths[index]}
                          rowHeight={(index) => rowHeights[index]}
                          columnCount={columns.length}
                        >
                          {Cell}
                        </VariableSizeGrid>
                      </GridWrapper>
                    )}
                  </>
                )
              }}
            </AutoSizer>
          </StyledTable>
        )}

        {displayGenerateModal.display && displayGenerateModal.fiscalYearId && (
          <GenerateArchiveChoicesModal
            isDisplayed={displayGenerateModal.display}
            onClose={() => {
              setDisplayGenerateModal({ display: false, fiscalYearId: null })
            }}
            fiscalYearId={displayGenerateModal.fiscalYearId}
          />
        )}

        {displayRegenerateModal.display &&
          displayRegenerateModal.bankinArchiveId && (
            <RegenerateBankinArchiveModal
              isDisplayed={displayRegenerateModal.display}
              onClose={() => {
                setDisplayRegenerateModal({
                  display: false,
                  bankinArchiveId: 0,
                })
              }}
              bankinArchiveId={displayRegenerateModal.bankinArchiveId}
            />
          )}
      </StyledSection>
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Row = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  align-items: center;
`
const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`
const DisableOverflow = styled.div`
  overflow: hidden;
`
const RowColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTable = styled(TableWrapper)`
  border: none;
`
interface StyledCellProps {
  width: string
  alignItems: string
}
const StyledCell = styled(Ct.ColumnCenterCenter)<StyledCellProps>`
  width: ${(props) => props.width};
  align-items: ${(props) => props.alignItems};
`
const Regenerate = styled.div`
  display: flex;
  cursor: pointer;
  padding-top: 1rem;
  align-items: center;
  justify-content: center;
`

const StyledReload = styled((props) => <Reload {...props} />)`
  width: 1.5rem;
  height: 1.5rem;
`
