import { useEffect, useState } from "react"
import { ReactComponent as File } from "../assets/fileSimple.svg"
import styled from "styled-components/macro"
import { colors } from "../styles/design.config"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useRNBSelector } from "../store/rootReducer"
import {
  FullDocument,
  fullDocumentUpdateThunk,
  getUrlForDeactivatedThunk,
  renameUserFullDocumentNameThunk,
} from "../store/ducks/fullDocuments.ducks"
import { truncateFileName } from "../utils/string"
import * as Ct from "ldlj"
import { RenameBatchDocumentModal } from "./dropDocuments/RenameBatchDocumentModal"
import { ReactComponent as More } from "../assets/more.svg"
import { MoreActions } from "./MoreActions"
import { setActionId } from "../store/ducks/actions.ducks"
import { Select } from "./Commons/Select"
import { getUrlForFullDocumentThunk } from "../store/ducks/invoicing.duck"
import { formatDateDate } from "../utils/date"
import { IconActions } from "./IconActions"
import { ArchiveDownLink } from "./ArchiveDownLink"
import { ResetDeactivatedUrlPreview } from "../store/ducks/companies.ducks"
import { ModalFullDocumentViewer } from "./Commons/ModalFullDocumentViewer"
import { Checkbox } from "./Commons/Checkbox"

interface OtherFilesRowsProps {
  fullDoc: FullDocument
  selectedCompanyId: number
  deactivatedReasonsOptions: Ct.Option<string>[]
  fullDocIdListToDelete?: number[]
  columnsConfig: { title: string; width: number }[]
  onSelectFullDocToDelete: (fullDocId: number, uniqueDelete: boolean) => void
}

interface displayPreviewParams {
  isDisplayed: boolean
  fullDocumentId: number | null
  elementName: string
}
export const OtherFilesRows = ({
  fullDoc,
  selectedCompanyId,
  deactivatedReasonsOptions,
  fullDocIdListToDelete = [],
  columnsConfig,
  onSelectFullDocToDelete,
}: OtherFilesRowsProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const userTypology = useRNBSelector((state) => state.user.typology)
  const actionId = useRNBSelector((state) => state.actions.actionId)
  const urlPreview = useRNBSelector(
    (state) =>
      state.companies.companies[selectedCompanyId].deactivatedDocuments
        ?.urlDeactivatedPreview
  )

  const [qualification, setQualification] = useState<Ct.Option<string>>({
    value: "",
    label: "",
  })
  const [renamedFullDocumentId, setRenamedFullDocumentId] = useState<
    number | null
  >(null)
  const [displayPreview, setDisplayPreview] = useState<displayPreviewParams>({
    isDisplayed: false,
    fullDocumentId: null,
    elementName: "",
  })
  const [showActions, setShowActions] = useState(false)

  const updateDeactivatedBecause = (
    selectedOption: Ct.Option<string>,
    documentId: number
  ) => {
    dispatch(
      fullDocumentUpdateThunk(
        documentId,
        selectedCompanyId,
        selectedOption.value
      )
    )
  }

  const toggleAction = () => {
    setShowActions(actionId !== fullDoc.id ? true : !showActions)
    dispatch(setActionId(fullDoc.id))
  }

  const getColumnWidth = (title: string): number => {
    const column = columnsConfig.find((e) => e.title === title)
    return column?.width || 20
  }

  useEffect(() => {
    if (
      !displayPreview.isDisplayed &&
      urlPreview &&
      urlPreview.fullDocId === fullDoc.id
    ) {
      setDisplayPreview({
        isDisplayed: true,
        fullDocumentId: fullDoc.id,
        elementName: fullDoc.user_file_name
          ? fullDoc.user_file_name
          : fullDoc.original_file_name,
      })
    }
  }, [urlPreview])

  useEffect(() => {
    if (actionId !== 0) {
      dispatch(setActionId(0))
    }
  }, [])

  useEffect(() => {
    dispatch(ResetDeactivatedUrlPreview(selectedCompanyId))
    const formattedString: string =
      fullDoc.deactivated_because.toLowerCase().charAt(0).toUpperCase() +
      fullDoc.deactivated_because.toLowerCase().slice(1)

    setQualification({
      value: formattedString,
      label: formattedString,
    })
  }, [setQualification, fullDoc])

  return (
    <>
      {displayPreview.isDisplayed &&
        urlPreview?.url &&
        urlPreview.fullDocId === fullDoc.id && (
          <>
            <ModalFullDocumentViewer
              isDisplayed={displayPreview.isDisplayed}
              onClose={() => {
                setDisplayPreview({
                  isDisplayed: false,
                  fullDocumentId: null,
                  elementName: "",
                })
              }}
              elementName={displayPreview.elementName}
              url={urlPreview.url}
            />
          </>
        )}

      <Ct.FlexStart>
        {!["customer", "customer_accountant"].includes(userTypology) && (
          <StyledCellCheckbox>
            <Checkbox
              label=""
              isChecked={fullDocIdListToDelete.includes(fullDoc.id)}
              value={fullDocIdListToDelete.includes(fullDoc.id)}
              name=""
              onChange={() => {
                onSelectFullDocToDelete(fullDoc.id, false)
              }}
              colorChecked={colors.amaranth}
              checkBoxSize={2.5}
            />
          </StyledCellCheckbox>
        )}

        <StyledCellStart calculatedWidth={getColumnWidth("deposit-name")}>
          <Ct.RowCenter>
            <Ct.FlexShrinkZero>
              <File />
            </Ct.FlexShrinkZero>
            <Ct.Spacer width={1} />

            <StyledLink
              onClick={() => {
                dispatch(
                  getUrlForDeactivatedThunk(fullDoc.id, selectedCompanyId)
                )
              }}
            >
              {Object(fullDoc)?.user_batch_name
                ? truncateFileName(Object(fullDoc)?.user_batch_name, 20)
                : fullDoc.original_file_name
                ? truncateFileName(fullDoc.original_file_name, 20)
                : ""}
            </StyledLink>
          </Ct.RowCenter>
        </StyledCellStart>

        <StyledCell calculatedWidth={getColumnWidth("file-name")}>
          <Ct.Text
            text={
              Object(fullDoc)?.user_full_document_name
                ? truncateFileName(Object(fullDoc)?.user_full_document_name, 20)
                : fullDoc.user_file_name
                ? truncateFileName(fullDoc.user_file_name, 20)
                : ""
            }
            textStyle={{
              textAlign: "center",
            }}
          />
        </StyledCell>

        <StyledCell calculatedWidth={getColumnWidth("qualification")}>
          <ItemSelect id="select">
            <Select
              intl={intl}
              options={deactivatedReasonsOptions}
              value={qualification}
              onChangeCallback={(selectedOption: Ct.Option<string>) => {
                setQualification(selectedOption)
                updateDeactivatedBecause(selectedOption, fullDoc.id)
              }}
              label={fullDoc.deactivated_because}
              domain={"ged.other-files"}
              optionType={"qualification"}
              disabled={userTypology === "customer"}
            />
          </ItemSelect>
        </StyledCell>

        <StyledCell calculatedWidth={getColumnWidth("date")}>
          {formatDateDate(fullDoc.created_at)}
        </StyledCell>

        <StyledCell calculatedWidth={getColumnWidth("actions")}>
          <>
            {userTypology !== "customer" ? (
              <DropActionButton onClick={toggleAction}>
                <More />
              </DropActionButton>
            ) : (
              <IconActions
                actionsToDisplay={["download"]}
                onSetDownload={() => {
                  dispatch(getUrlForFullDocumentThunk(fullDoc.id))
                }}
              />
            )}
          </>
          <MoreActions
            onBlur={() => {
              setShowActions(!showActions)
            }}
            showActions={showActions && actionId === fullDoc.id}
            actionsToDisplay={["rename", "download"]}
            onSetDisplayPreview={() => {
              setDisplayPreview({
                isDisplayed: true,
                fullDocumentId: fullDoc.id,
                elementName: fullDoc.user_file_name
                  ? fullDoc.user_file_name
                  : fullDoc.original_file_name,
              })
              setShowActions(!showActions)
            }}
            onRename={() => {
              setRenamedFullDocumentId(fullDoc.id)
            }}
            onDelete={() => {
              onSelectFullDocToDelete(fullDoc.id, true)
              setShowActions(!showActions)
            }}
            deleteAction={
              ["customer", "customer_accountant"].includes(userTypology)
                ? "hide"
                : "enable"
            }
            onSetDownload={() => {
              dispatch(getUrlForFullDocumentThunk(fullDoc.id))
              setShowActions(!showActions)
            }}
          />
        </StyledCell>

        <StyledCell calculatedWidth={getColumnWidth("archive")}>
          <Ct.RowCenter>
            <ArchiveDownLink
              userTypology={userTypology}
              selectedCompanyId={selectedCompanyId}
              archiveId={fullDoc.archive_id}
            />
          </Ct.RowCenter>
        </StyledCell>

        <RenameBatchDocumentModal
          isDisplayed={renamedFullDocumentId !== null}
          onClose={() => {
            setRenamedFullDocumentId(null)
          }}
          onClick={(newName: string) => {
            dispatch(
              renameUserFullDocumentNameThunk(
                fullDoc.id,
                newName,
                selectedCompanyId || 0
              )
            )
          }}
          inputLabelId="full-documents.rename.label"
        />
      </Ct.FlexStart>
    </>
  )
}

const ItemSelect = styled.div`
  display: flex;
  flex: 1 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 3rem 1rem;
`
const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;
  margin-top: 1rem;
  :hover {
    color: ${colors.cornflower};
  }
`
const DropActionButton = styled.div`
  cursor: pointer;
`
const StyledCellCheckbox = styled.div`
  height: 11rem;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledCellStart = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  height: 11rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-all;
  margin: auto;
`
const StyledCell = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}rem`};
  height: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
`
