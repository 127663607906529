import * as Ct from "ldlj"
import { CSSProperties, useEffect, useState } from "react"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { useIntl } from "react-intl"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../../components/Commons/Table"
import { useDispatch } from "react-redux"
import { getIdFromParams } from "../../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import {
  getCurrentUserPermissionsThunk,
  getTreasuryFullDocumentHistoryThunk,
  getUsersWithPermissions,
  setDateRange,
  TreasuryFullDocuments,
  updateFullDocumentPaymentStatusThunk,
} from "../../../../../store/ducks/treasury.ducks"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { Checkbox } from "../../../../../components/Commons/Checkbox"
import { truncateFileName } from "../../../../../utils/string"
import { getUrlForFullDocumentThunk } from "../../../../../store/ducks/invoicing.duck"
import {
  StyledSection,
  StyledTotalAmount,
  Wrapper,
  WrapperCalendar,
  WrapperTopBar,
  predefinedRanges,
  StyledFullDocumentCount,
  StyledLink,
  filterDocuments,
  StyledCenter,
  sorterFunction,
  BuySellSortOptionsValues,
  HeadBand,
  TreasuryHeaderWrapper,
  sortFullDocumentsByDate,
  allTime,
} from "../../../../../utils/treasury"
import { ReactComponent as EyeFilled } from "../../../../../assets/eyeFilled.svg"
import { ReactComponent as HourGlass } from "../../../../../assets/treasury-hourglass.svg"
import styled from "styled-components/macro"
import { colors } from "../../../../../styles/design.config"
import { Button } from "../../../../../components/Commons/Button"
import { FullDocumentTreasuryHistoryModal } from "../../../../../components/treasury/FullDocumentTreasuryHistoryModal"
import React from "react"
import { VariableSizeGrid } from "react-window"
import {
  GridWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../../components/dropDocuments/StyledDropsComponents"
import AutoSizer from "react-virtualized-auto-sizer"
import { Alert } from "../../../../../components/Commons/Alert"
import { InputDateRange } from "../../../../../components/Commons/InputDateRange"

export const Received = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const companyId = getIdFromParams(useParams())("company_id") || 0
  const currentUserId = useRNBSelector((state) => state.user.id)
  const [search, setSearch] = useState("")
  const [fullDocumentsToDisplay, setfullDocumentsToDisplay] = useState<
    TreasuryFullDocuments[]
  >([])
  const fullDocuments = useRNBSelector((state) => {
    return state.treasury.dashboard.received
  })
  const rowBackgroundColors = fullDocumentsToDisplay.map((fullDoc, index) => {
    return index % 2 === 0 ? "white" : "clearBlue"
  })
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [fullDocumentChecked, setFullDocumentChecked] = useState<number[]>([])
  const [allFullDocumentsChecked, setAllFullDocumentsChecked] =
    useState<boolean>(false)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [fullDocumentSelectedCount, setFullDocumentSelectedCount] =
    useState<number>(0)
  const range = useRNBSelector((state) => state.treasury.range)

  const [displayCalendar, setDisplayCalendar] = useState<boolean>(false)

  const [calendarLabel, setCalendarLabel] = useState("")
  const handleCalendarChange = (value: [Date, Date] | null) => {
    dispatch(setDateRange(value))
    const finded = predefinedRanges.find((range) => range.value === value)
    setCalendarLabel(String(finded?.label))
  }
  const updateCheckBoxList = (id: number, amount: number) => {
    if (fullDocumentChecked.some((f) => f === id)) {
      setFullDocumentChecked((previous) =>
        previous.filter((elem) => elem !== id)
      )
      setTotalAmount((previousAmount) =>
        Math.abs(Number(previousAmount) - Number(amount))
      )
      setFullDocumentSelectedCount((previousCount) => Number(previousCount) - 1)
    } else {
      setFullDocumentChecked((previous) => [...previous, id])
      setTotalAmount((previousAmount) =>
        Math.abs(Number(previousAmount) + Number(amount))
      )
      setFullDocumentSelectedCount((previousCount) => Number(previousCount) + 1)
    }
  }
  const [highlightValues, setHighlightValues] = useState<{
    highlight: boolean
    searchString: string
  }>({ highlight: false, searchString: "" })
  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight || !text) {
      return text
    }

    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            style={
              part.toLowerCase().includes(highlight.toLowerCase()) ||
              highlight.toLowerCase().includes(part.toLowerCase())
                ? {
                    background: "rgba(255, 197, 66, 0.3)",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </>
    )
  }
  const setFullDocumentsFilteredByRangeAndHighlight = (
    fullDocumentsArray: TreasuryFullDocuments[]
  ) => {
    setfullDocumentsToDisplay(
      sortFullDocumentsByDate(
        filterDocuments(search, fullDocumentsArray) || [],
        false
      )
    )
    setHighlightValues({ highlight: true, searchString: search })
  }
  const currentUserTypology = useRNBSelector((state) => state.user.typology)
  const currentUserPermissions = useRNBSelector(
    (state) => state.treasury.current_user_permissions
  )
  const navigate = useNavigate()
  const resetTopBarData = () => {
    setFullDocumentChecked([])
    setTotalAmount(0)
    setFullDocumentSelectedCount(0)
  }
  const updateTopBarData = (fullDocumentId: number, amount: number) => {
    if (fullDocumentChecked.length !== 0) {
      setFullDocumentChecked(
        fullDocumentChecked.filter((fd) => fd !== fullDocumentId)
      )
      setTotalAmount(Math.abs(totalAmount - amount))
      setFullDocumentSelectedCount(fullDocumentSelectedCount - 1)
    }
  }
  const [fullDocumentSelected, setFullDocumentSelected] =
    useState<TreasuryFullDocuments | null>(null)
  const fullDocumentHistory = useRNBSelector(
    (state) => state.treasury.full_document_history
  )
  const [displayHistory, setDisplayHistory] = useState(false)
  const historyAction = (fullDocument: TreasuryFullDocuments) => {
    dispatch(getTreasuryFullDocumentHistoryThunk(fullDocument.id))
    setFullDocumentSelected(fullDocument)
    setDisplayHistory(true)
  }
  const noSearchDisplayFullDocuments = (
    fullDocuments: TreasuryFullDocuments[]
  ) => {
    setfullDocumentsToDisplay(sortFullDocumentsByDate(fullDocuments, false))
    setHighlightValues({ highlight: false, searchString: "" })
  }

  const sorter = (asc: boolean, option: BuySellSortOptionsValues) => {
    setfullDocumentsToDisplay(
      sorterFunction(asc, option, fullDocumentsToDisplay)
    )
  }

  useEffect(() => {
    if (columnToSort) {
      const currentSort: BuySellSortOptionsValues = Object.values(
        BuySellSortOptionsValues
      ).indexOf(BuySellSortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc, currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc, currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (
      currentUserPermissions === null &&
      currentUserTypology !== "" &&
      currentUserTypology !== "customer" &&
      currentUserTypology !== "customer_accountant"
    )
      navigate("/unauthorized")
  }, [currentUserPermissions, currentUserTypology])

  useEffect(() => {
    if (!fullDocuments || fullDocuments.length === 0)
      return setFullDocumentsFilteredByRangeAndHighlight([])

    if (!range || calendarLabel === "Tout le temps")
      return search && search !== ""
        ? setFullDocumentsFilteredByRangeAndHighlight(fullDocuments)
        : noSearchDisplayFullDocuments(fullDocuments)

    const fullDocumentsDate =
      range === allTime
        ? fullDocuments
        : fullDocuments.filter((fd) =>
            fd.document_due_date
              ? fd.document_due_date <= range[1].toISOString().slice(0, 10)
              : fd.document_date <= range[1].toISOString().slice(0, 10)
          )
    return search && search !== ""
      ? setFullDocumentsFilteredByRangeAndHighlight(fullDocumentsDate)
      : noSearchDisplayFullDocuments(fullDocumentsDate)
  }, [fullDocuments, range, search])

  useEffect(() => {
    dispatch(getUsersWithPermissions(companyId))
  }, [dispatch])

  useEffect(() => {
    if (fullDocumentChecked.length === 0) setAllFullDocumentsChecked(false)
  }, [fullDocumentChecked])

  useEffect(() => {
    if (currentUserId && companyId && companyId !== 0)
      dispatch(getCurrentUserPermissionsThunk(companyId))
  }, [companyId, currentUserId])

  const listRef = React.createRef<VariableSizeGrid>()
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const onResize = () => {
    if (listRef.current !== null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    return (
      <div
        style={{
          ...style,
          backgroundColor: colors[rowBackgroundColors[rowIndex]],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        key={columnIndex}
      >
        {columns[columnIndex].content(fullDocumentsToDisplay[rowIndex])}
      </div>
    )
  }

  const columns = [
    {
      flexGrow: "5rem",
      headerIcon: (
        <div style={{ paddingLeft: "3.2rem" }}>
          <Checkbox
            label=""
            isChecked={
              allFullDocumentsChecked && fullDocumentChecked.length !== 0
            }
            value={allFullDocumentsChecked}
            onChange={() => {
              setAllFullDocumentsChecked(!allFullDocumentsChecked)

              if (allFullDocumentsChecked) {
                setFullDocumentChecked([])
                setTotalAmount(0)
                setFullDocumentSelectedCount(0)
              } else {
                setFullDocumentChecked(
                  fullDocumentsToDisplay.map((fullDocument) => fullDocument.id)
                )
                setTotalAmount(
                  Math.abs(
                    fullDocumentsToDisplay.reduce((acc, curr) => {
                      return Number(acc) + Number(curr.amount)
                    }, 0)
                  )
                )
                setFullDocumentSelectedCount(fullDocumentsToDisplay.length)
              }
            }}
          />
        </div>
      ),
      content: (fullDocument: TreasuryFullDocuments) => (
        <div style={{ paddingLeft: "4rem" }}>
          <Checkbox
            id={String(fullDocument.id)}
            label={""}
            isChecked={fullDocumentChecked.some((f) => f === fullDocument.id)}
            value={fullDocumentChecked.some((f) => f === fullDocument.id)}
            onChange={() => {
              updateCheckBoxList(fullDocument.id, fullDocument.amount)
            }}
          />
        </div>
      ),
    },
    {
      headerText: "treasury.sell.received.due-date",
      flexGrow: "20rem",
      content: (fullDocument: TreasuryFullDocuments) => (
        <Ct.Text
          text={fullDocument.document_due_date}
          textStyle={{
            fontFamily: "Poppins",
          }}
        />
      ),
    },
    {
      headerText: "treasury.sell.received.date",
      flexGrow: "20rem",
      content: (fullDocument: TreasuryFullDocuments) => (
        <Ct.Text
          text={fullDocument.document_date}
          textStyle={{
            fontFamily: "Poppins",
          }}
        />
      ),
    },
    {
      headerText: "treasury.sell.received.merchant",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          {getHighlightedText(
            fullDocument.merchant_name,
            highlightValues.searchString
          )}
        </StyledCenter>
      ),
    },
    {
      headerText: "treasury.sell.received.file_name",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          <StyledLink
            onClick={() => {
              dispatch(getUrlForFullDocumentThunk(fullDocument.id))
            }}
          >
            {(fullDocument.user_file_name &&
              getHighlightedText(
                truncateFileName(fullDocument.user_file_name, 30),
                highlightValues.searchString
              )) ||
              ""}
          </StyledLink>
        </StyledCenter>
      ),
    },
    {
      headerText: "treasury.sell.received.amount",
      content: (fullDocument: TreasuryFullDocuments) => (
        <StyledCenter marginTop={"2%"}>
          {getHighlightedText(
            String(fullDocument.amount.toFixed(2)).replace(".", ","),
            highlightValues.searchString
          )}
        </StyledCenter>
      ),
    },
    {
      headerText: "treasury.sell.received.action",
      content: (fullDocument: TreasuryFullDocuments) => (
        <div
          style={{
            width: "40rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ButtonWrapper>
            <Ct.Button
              label={
                <Ct.Text
                  text={intl.formatMessage({
                    id: "treasury.sell.received.mark_as_to_receive",
                  })}
                  textStyle={{
                    fontFamily: "Roboto",
                    fontSize: 1.75,
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              }
              prefix={<StyledHourGlass />}
              onClick={() =>
                dispatch(
                  updateFullDocumentPaymentStatusThunk(
                    companyId,
                    [fullDocument.id],
                    "mark_as_to_receive",
                    "sell",
                    fullDocument.amount
                  )
                ) && updateTopBarData(fullDocument.id, fullDocument.amount)
              }
              width={35}
              height={5}
              colorType="Tertiary"
              colorScheme={{
                background: "cornflower",
                color: "white",
                border: "cornflower",
              }}
              disabled={
                currentUserTypology !== "customer" &&
                currentUserTypology !== "customer_accountant" &&
                (currentUserPermissions === null ||
                  currentUserPermissions.id === 0)
              }
            />
            <Ct.Spacer width={1} />
            <EyeFilled
              onClick={() => {
                historyAction(fullDocument)
              }}
            />
          </ButtonWrapper>
        </div>
      ),
    },
  ]

  return (
    <Wrapper>
      <StyledSection>
        <Ct.Spacer height={1} />
        <HeadBand>
          <Ct.Text text={"🏷️"} />
          <Ct.Spacer width={1} />
          <Ct.Text
            text={intl.formatMessage({
              id: "treasury.try_access.headband",
            })}
            textStyle={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: 2,
              fontStyle: "italic",
              color: "white",
            }}
          />
        </HeadBand>
        <Ct.Spacer height={4} />

        <WrapperTopBar>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            shadowed={true}
            noBorder={true}
            isSearch={true}
          />
          <Ct.Spacer width={1} />
          <WrapperCalendar
            onClick={() => {
              setDisplayCalendar(!displayCalendar)
            }}
          >
            <InputDateRange
              value={range}
              onChange={(value) => {
                handleCalendarChange(value)
              }}
              predefinedRanges={predefinedRanges}
            />
          </WrapperCalendar>
          <Ct.FlexEnd>
            <StyledTotalAmount>
              <Ct.Text
                text={intl.formatMessage({
                  id: "treasury.sell.received.total_amount",
                })}
                textStyle={{
                  fontFamily: "Poppins",
                  textTransform: "uppercase",
                  fontWeight: 600,
                  fontSize: 2,
                }}
              />
              <Ct.Spacer width={0.5} />
              <Ct.Text
                text={`${totalAmount.toFixed(2)} €`}
                textStyle={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "orange",
                  fontSize: 2,
                }}
              />
            </StyledTotalAmount>
            <Ct.Spacer width={0.5} />
            <StyledFullDocumentCount>
              <Checkbox
                label={""}
                isChecked={fullDocumentSelectedCount !== 0}
                value={fullDocumentSelectedCount !== 0}
                disabled={fullDocumentSelectedCount === 0}
              />
              <Ct.Text
                text={`: ${fullDocumentSelectedCount.toString()}`}
                textStyle={{
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: fullDocumentSelectedCount === 0 ? "moon" : "navy",
                  fontSize: 2,
                }}
              />
            </StyledFullDocumentCount>
            <Button
              label={
                <Ct.Text
                  text={intl.formatMessage({
                    id: "treasury.sell.received.mark_as_to_receive",
                  })}
                  textStyle={{
                    fontFamily: "Roboto",
                    fontSize: 1.75,
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              }
              prefix={<StyledHourGlass />}
              onClick={() =>
                dispatch(
                  updateFullDocumentPaymentStatusThunk(
                    companyId,
                    fullDocumentChecked,
                    "mark_as_to_receive",
                    "sell",
                    totalAmount
                  )
                ) && resetTopBarData()
              }
              width={35}
              height={4.75}
              colorType="Tertiary"
              colorScheme={{
                background: "cornflower",
                color: "white",
                border: "cornflower",
              }}
              disabled={
                fullDocumentChecked.length === 0 ||
                (currentUserTypology !== "customer" &&
                  currentUserTypology !== "customer_accountant" &&
                  (currentUserPermissions === null ||
                    currentUserPermissions.id === 0))
              }
            />
          </Ct.FlexEnd>
        </WrapperTopBar>

        <FullDocumentTreasuryHistoryModal
          file_name={
            fullDocumentSelected?.user_file_name ||
            fullDocumentSelected?.file_name ||
            ""
          }
          fullDocumentHistory={fullDocumentHistory}
          isDisplayed={displayHistory ? true : false}
          onClose={() => setDisplayHistory(false)}
        />

        <Ct.Spacer height={3} />

        <TableWrapper>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                if (index === 0) return 40
                if (index === 6) return 320

                return (width - 360 - 10) / (columns.length - 2)
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 0rem"}>
                    {columns.map((column, index) => (
                      <TreasuryHeaderWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                      >
                        {column.headerIcon}
                        {index !== 0 && index !== columns.length - 1 ? (
                          <ClickableTitleSort
                            tid={column.headerText || ""}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable
                            tid={column.headerText || ""}
                            intl={intl}
                          />
                        )}
                      </TreasuryHeaderWrapper>
                    ))}
                  </HeaderWrapper>

                  {fullDocumentsToDisplay.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Ct.Text
                          text={intl.formatMessage({
                            id: "treasury.buy.to-pay.filter.empty",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <GridWrapper totalWidth={width}>
                      <VariableSizeGrid
                        ref={listRef}
                        height={height - 56}
                        rowCount={fullDocumentsToDisplay.length}
                        width={width}
                        columnWidth={(index) => columnWidths[index]}
                        rowHeight={(index) => 60}
                        columnCount={columns.length}
                      >
                        {Cell}
                      </VariableSizeGrid>
                    </GridWrapper>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}

const StyledHourGlass = styled(HourGlass)`
  width: 3rem;
  height: 3rem;
  & path {
    fill: ${colors.white};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
