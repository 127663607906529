import { AnyAction, CombinedState, combineReducers } from "redux"
import { userInitialState, userReducer, UserState } from "./ducks/user.ducks"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import {
  fiduciaryInitialState,
  fiduciaryReducer,
  FiduciaryState,
} from "./ducks/fiduciary.ducks"
import { RootActionsEnum } from "./rootActions"
import {
  companiesInitialState,
  companiesReducer,
  CompaniesState,
} from "./ducks/companies.ducks"
import {
  loginInitialState,
  loginReducer,
  LoginState,
} from "./ducks/login.ducks"
import { teamInitialState, teamReducer, TeamState } from "./ducks/team.ducks"
import { KPIsInitialState, KPIsReducer, KPIsState } from "./ducks/kpis.ducks"
import {
  fiscalYearsInitialState,
  fiscalYearsReducer,
  FiscalYearsState,
} from "./ducks/fiscalYears.ducks"
import {
  merchantsInitialState,
  merchantsReducer,
  MerchantsState,
} from "./ducks/merchants.ducks"

import {
  standardLedgerInitialState,
  standardLedgerReducer,
  standardLedgerState,
} from "./ducks/standardLedger.ducks"
import {
  accountsInitialState,
  accountsReducer,
  AccountsState,
} from "./ducks/accounts.ducks"

import {
  dropDocumentsInitialState,
  dropDocumentsReducer,
  DropDocumentsState,
} from "./ducks/dropDocuments.ducks"
import {
  batchDocumentsInitialState,
  batchDocumentsReducer,
  BatchesForCompanyState,
} from "./ducks/batchDocuments.ducks"
import {
  fullDocumentsInitialState,
  fullDocumentsReducer,
  FullDocumentsState,
} from "./ducks/fullDocuments.ducks"
import { bankInitialState, bankReducer, BankState } from "./ducks/bank.ducks"
import {
  merchantCodesInitialState,
  merchantCodesReducer,
  MerchantCodesState,
} from "./ducks/merchantCodes.ducks"
import {
  captureInitialState,
  captureReducer,
  CaptureState,
} from "./ducks/capture.ducks"
import {
  writingsInitialState,
  writingsReducer,
  WritingsState,
} from "./ducks/writings.ducks"
import {
  softwareRulesInitialState,
  softwareRulesReducer,
  SoftwareRulesState,
} from "./ducks/softwareRules.ducks"
import {
  invoicingInitialState,
  invoicingReducer,
  InvoicingState,
} from "./ducks/invoicing.duck"
import {
  numberingsInitialState,
  numberingsReducer,
  NumberingsState,
} from "./ducks/numberings.ducks"
import {
  actionsInitialState,
  actionsReducer,
  ActionsState,
} from "./ducks/actions.ducks"

import {
  InputMaskInitialState,
  InputMaskReducer,
  InputMaskState,
} from "./ducks/inputMask.ducks"

import {
  previewFullDocumentInitialState,
  previewFullDocumentReducer,
  PreviewFullDocumentState,
} from "./ducks/previewFullDocument.ducks"

import {
  CompanySettingsActionsEnum,
  companySettingsInitialState,
  companySettingsReducer,
  CompanySettingsState,
} from "./ducks/companySettings.ducks"

import {
  TreasuryInitialState,
  TreasuryReducer,
  TreasuryState,
} from "./ducks/treasury.ducks"

import {
  accountingSoftwareInitialState,
  accountingSoftwareReducer,
  AccountingSoftwareState,
} from "./ducks/accountingSoftware.ducks"

import {
  writingLabelsInitialState,
  writingLabelsReducer,
  WritingLabelsState,
} from "./ducks/writingLabels.ducks"

import {
  vatRatesInitialState,
  vatRatesReducer,
  VatRatesState,
} from "./ducks/vatRates.ducks"

import {
  unitLabelInitialState,
  unitLabelReducer,
  unitLabelState,
} from "./ducks/unitLabel.ducks"

export type RootState = typeof INITIAL_ROOTSTATE

export const INITIAL_ROOTSTATE = {
  login: loginInitialState,
  user: userInitialState,
  fiduciary: fiduciaryInitialState,
  companies: companiesInitialState,
  team: teamInitialState,
  fiscalYears: fiscalYearsInitialState,
  kpis: KPIsInitialState,
  standardLedger: standardLedgerInitialState,
  merchants: merchantsInitialState,
  merchantCodes: merchantCodesInitialState,
  accounts: accountsInitialState,
  dropDocuments: dropDocumentsInitialState,
  batchDocuments: batchDocumentsInitialState,
  fullDocuments: fullDocumentsInitialState,
  bank: bankInitialState,
  capture: captureInitialState,
  writings: writingsInitialState,
  softwareRules: softwareRulesInitialState,
  invoicing: invoicingInitialState,
  numberings: numberingsInitialState,
  actions: actionsInitialState,
  inputMask: InputMaskInitialState,
  previewFullDocument: previewFullDocumentInitialState,
  companySettings: companySettingsInitialState,
  treasury: TreasuryInitialState,
  accountingSoftware: accountingSoftwareInitialState,
  writingLabels: writingLabelsInitialState,
  vatRates: vatRatesInitialState,
  unitLabel: unitLabelInitialState,
}

const RNBReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  fiduciary: fiduciaryReducer,
  companies: companiesReducer,
  team: teamReducer,
  fiscalYears: fiscalYearsReducer,
  kpis: KPIsReducer,
  standardLedger: standardLedgerReducer,
  merchants: merchantsReducer,
  merchantCodes: merchantCodesReducer,
  accounts: accountsReducer,
  dropDocuments: dropDocumentsReducer,
  batchDocuments: batchDocumentsReducer,
  fullDocuments: fullDocumentsReducer,
  bank: bankReducer,
  capture: captureReducer,
  writings: writingsReducer,
  softwareRules: softwareRulesReducer,
  invoicing: invoicingReducer,
  numberings: numberingsReducer,
  actions: actionsReducer,
  inputMask: InputMaskReducer,
  previewFullDocument: previewFullDocumentReducer,
  companySettings: companySettingsReducer,
  treasury: TreasuryReducer,
  accountingSoftware: accountingSoftwareReducer,
  writingLabels: writingLabelsReducer,
  vatRates: vatRatesReducer,
  unitLabel: unitLabelReducer,
})

const rootReducer = (
  state:
    | CombinedState<{
        login: LoginState
        user: UserState
        fiduciary: FiduciaryState
        companies: CompaniesState
        team: TeamState
        kpis: KPIsState
        fiscalYears: FiscalYearsState
        standardLedger: standardLedgerState
        merchants: MerchantsState
        merchantCodes: MerchantCodesState
        accounts: AccountsState
        dropDocuments: DropDocumentsState
        batchDocuments: BatchesForCompanyState
        fullDocuments: FullDocumentsState
        bank: BankState
        capture: CaptureState
        writings: WritingsState
        softwareRules: SoftwareRulesState
        invoicing: InvoicingState
        numberings: NumberingsState
        actions: ActionsState
        inputMask: InputMaskState
        previewFullDocument: PreviewFullDocumentState
        companySettings: CompanySettingsState
        treasury: TreasuryState
        accountingSoftware: AccountingSoftwareState
        writingLabels: WritingLabelsState
        vatRates: VatRatesState
        unitLabel: unitLabelState
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === RootActionsEnum.LOGOUT) {
    // Undefined is used to reset the store to inital state, see :
    // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
    return RNBReducer(undefined, action)
  }

  if (action.type === CompanySettingsActionsEnum.SELECT_COMPANY) {
    if (!state) {
      return RNBReducer(undefined, action)
    }

    return RNBReducer(
      {
        ...INITIAL_ROOTSTATE,
        companies: state.companies,
        fiduciary: state.fiduciary,
        user: state.user,
      },
      action
    )
  }

  return RNBReducer(state, action)
}
export default rootReducer

export const useRNBSelector: TypedUseSelectorHook<RootState> = useSelector
