import axios from "axios"
import { Dispatch, RNBThunkAction } from "../store.config"
import { AnyAction } from "redux"
import { CreateUnitLabelQuery, Unit, UnitLabel } from "../../utils/unit"

export const enum UnitLabelActionEnum {
  GET_FIDUCIARY_UNIT_LABELS_ATTEMPT = "UNIT_LABEL/getFiduciaryUnitLabelsAttempt",
  GET_FIDUCIARY_UNIT_LABELS_SUCCESS = "UNIT_LABEL/getFiduciaryUnitLabelsSuccess",
  GET_FIDUCIARY_UNIT_LABELS_FAILURE = "UNIT_LABEL/getFiduciaryUnitLabelsFailure",

  GET_UNITS_ATTEMPT = "UNIT_LABEL/getUnitsAttempt",
  GET_UNITS_SUCCESS = "UNIT_LABEL/getUnitsSuccess",
  GET_UNITS_FAILURE = "UNIT_LABEL/getUnitsFailure",

  CREATE_UNIT_LABELS_ATTEMPT = "UNIT_LABEL/createUnitLabelsAttempt",
  CREATE_UNIT_LABELS_RESET = "UNIT_LABEL/createUnitLabelsReset",
  CREATE_UNIT_LABELS_SUCCESS = "UNIT_LABEL/createUnitLabelsSuccess",
  CREATE_UNIT_LABELS_FAILURE = "UNIT_LABEL/createUnitLabelsFailure",
  CREATE_UNIT_LABELS_FAILURE_DUPLICATE = "UNIT_LABEL/createUnitLabelsFailureDuplicate",
  CREATE_UNIT_LABELS_FAILURE_TOO_LONG = "UNIT_LABEL/createUnitLabelsFailureTooLong",

  DESTROY_UNIT_LABEL_ATTEMPT = "UNIT_LABEL/destroyUnitLabelAttempt",
  DESTROY_UNIT_LABEL_SUCCESS = "UNIT_LABEL/destroyUnitLabelSuccess",
  DESTROY_UNIT_LABEL_FAILURE = "UNIT_LABEL/destroyUnitLabelFailure",

  GET_COMPANY_UNIT_LABELS_ATTEMPT = "UNIT_LABEL/getCompanyUnitLabelsAttempt",
  GET_COMPANY_UNIT_LABELS_SUCCESS = "UNIT_LABEL/getCompanyUnitLabelsSuccess",
  GET_COMPANY_UNIT_LABELS_FAILURE = "UNIT_LABEL/getCompanyUnitLabelsFailure",
}

export const getFiduciaryUnitLabelsAttempt = () =>
  ({ type: UnitLabelActionEnum.GET_FIDUCIARY_UNIT_LABELS_ATTEMPT } as const)
export const getFiduciaryUnitLabelsSuccess = (unitLabels: UnitLabel[]) =>
  ({
    type: UnitLabelActionEnum.GET_FIDUCIARY_UNIT_LABELS_SUCCESS,
    unitLabels,
  } as const)
export const getFiduciaryUnitLabelsFailure = () =>
  ({ type: UnitLabelActionEnum.GET_FIDUCIARY_UNIT_LABELS_FAILURE } as const)

export const getUnitsAttempt = () =>
  ({ type: UnitLabelActionEnum.GET_UNITS_ATTEMPT } as const)
export const getUnitsSuccess = (units: Unit[]) =>
  ({ type: UnitLabelActionEnum.GET_UNITS_SUCCESS, units } as const)
export const getUnitsFailure = () =>
  ({ type: UnitLabelActionEnum.GET_UNITS_FAILURE } as const)

export const createUnitLabelsAttempt = () =>
  ({ type: UnitLabelActionEnum.CREATE_UNIT_LABELS_ATTEMPT } as const)
export const createUnitLabelsReset = () =>
  ({ type: UnitLabelActionEnum.CREATE_UNIT_LABELS_RESET } as const)
export const createUnitLabelsSuccess = () =>
  ({
    type: UnitLabelActionEnum.CREATE_UNIT_LABELS_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "unit_labels.add-unit.tooltip.success.title",
      bodyKey: "unit_labels.add-unit.tooltip.success.message",
    },
  } as const)
export const createUnitLabelsFailure = () =>
  ({
    type: UnitLabelActionEnum.CREATE_UNIT_LABELS_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "unit_labels.add-unit.tooltip.error.title",
      bodyKey: "unit_labels.add-unit.tooltip.error.message",
    },
  } as const)
export const createUnitLabelsFailureDuplicate = (fiduciaryOrCompany: string) =>
  ({
    type: UnitLabelActionEnum.CREATE_UNIT_LABELS_FAILURE_DUPLICATE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "unit_labels.add-unit.tooltip.error.title.existing",
      bodyKey: "unit_labels.add-unit.tooltip.error.message.existing",
      bodyValues: { fiduciaryOrCompany: fiduciaryOrCompany },
    },
  } as const)
export const createUnitLabelsFailureTooLong = (error: string) =>
  ({
    type: UnitLabelActionEnum.CREATE_UNIT_LABELS_FAILURE_TOO_LONG,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "unit_labels.add-unit.tooltip.error.title",
      bodyKey: error,
    },
  } as const)

export const destroyUnitLabelAttempt = () =>
  ({
    type: UnitLabelActionEnum.DESTROY_UNIT_LABEL_ATTEMPT,
  } as const)
export const destroyUnitLabelSuccess = () =>
  ({
    type: UnitLabelActionEnum.DESTROY_UNIT_LABEL_SUCCESS,
    withToast: true,
    toasterType: "success",
    message: {
      titleKey: "unit_labels.destroy.tooltip.success.title",
      bodyKey: "unit_labels.destroy.tooltip.success.message",
    },
  } as const)
export const destroyUnitLabelFailure = () =>
  ({
    type: UnitLabelActionEnum.DESTROY_UNIT_LABEL_FAILURE,
    withToast: true,
    toasterType: "error",
    message: {
      titleKey: "unit_labels.destroy.tooltip.error.title",
      bodyKey: "unit_labels.destroy.tooltip.error.message",
    },
  } as const)

export const getCompanyUnitLabelsAttempt = () =>
  ({ type: UnitLabelActionEnum.GET_COMPANY_UNIT_LABELS_ATTEMPT } as const)
export const getCompanyUnitLabelsSuccess = (
  unitLabels: UnitLabel[],
  companyId: number
) =>
  ({
    type: UnitLabelActionEnum.GET_COMPANY_UNIT_LABELS_SUCCESS,
    unitLabels,
    companyId,
  } as const)
export const getCompanyUnitLabelsFailure = () =>
  ({ type: UnitLabelActionEnum.GET_COMPANY_UNIT_LABELS_FAILURE } as const)

export type UnitLabelActionType = ReturnType<
  | typeof getFiduciaryUnitLabelsAttempt
  | typeof getFiduciaryUnitLabelsSuccess
  | typeof getFiduciaryUnitLabelsFailure
  | typeof getUnitsAttempt
  | typeof getUnitsSuccess
  | typeof getUnitsFailure
  | typeof createUnitLabelsAttempt
  | typeof createUnitLabelsReset
  | typeof createUnitLabelsSuccess
  | typeof createUnitLabelsFailure
  | typeof createUnitLabelsFailureDuplicate
  | typeof destroyUnitLabelAttempt
  | typeof destroyUnitLabelSuccess
  | typeof destroyUnitLabelFailure
  | typeof getCompanyUnitLabelsAttempt
  | typeof getCompanyUnitLabelsSuccess
  | typeof getCompanyUnitLabelsFailure
>

export interface unitLabelState {
  units: Unit[]
  creation_status: "idle" | "success" | "error"
}

export const unitLabelInitialState: unitLabelState = {
  units: [],
  creation_status: "idle",
}

export function unitLabelReducer(
  state = unitLabelInitialState,
  action: UnitLabelActionType
): unitLabelState {
  switch (action.type) {
    case UnitLabelActionEnum.GET_UNITS_SUCCESS: {
      return {
        ...state,
        units: action.units,
      }
    }
    case UnitLabelActionEnum.CREATE_UNIT_LABELS_ATTEMPT: {
      return {
        ...state,
        creation_status: "idle",
      }
    }
    case UnitLabelActionEnum.CREATE_UNIT_LABELS_RESET: {
      return {
        ...state,
        creation_status: "idle",
      }
    }
    case UnitLabelActionEnum.CREATE_UNIT_LABELS_SUCCESS: {
      return {
        ...state,
        creation_status: "success",
      }
    }
    case UnitLabelActionEnum.CREATE_UNIT_LABELS_FAILURE: {
      return {
        ...state,
        creation_status: "error",
      }
    }
    case UnitLabelActionEnum.CREATE_UNIT_LABELS_FAILURE_DUPLICATE: {
      return {
        ...state,
        creation_status: "error",
      }
    }

    default:
      return state
  }
}

export const getFiduciaryUnitLabelsThunk =
  (fiduciaryId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getFiduciaryUnitLabelsAttempt())
    return axios
      .get<UnitLabel[]>("fiduciaries/get_fiduciary_unit_labels", {
        params: { fiduciary_id: fiduciaryId },
      })
      .then((response) => {
        dispatch(getFiduciaryUnitLabelsSuccess(response.data))
      })
      .catch((e) => {
        dispatch(getFiduciaryUnitLabelsFailure())
      })
  }

export const getUnitsThunk =
  (fiduciaryId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getUnitsAttempt())
    return axios
      .get<UnitLabel[]>("fiduciaries/get_units", {
        params: { fiduciary_id: fiduciaryId },
      })
      .then((response) => {
        dispatch(getUnitsSuccess(response.data))
      })
      .catch((e) => {
        dispatch(getUnitsFailure())
      })
  }

export const createFiduciaryUnitLabelsThunk =
  (fiduciaryId: number, units: CreateUnitLabelQuery[]) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(createUnitLabelsAttempt())
    return axios
      .post(`fiduciaries/create_fiduciary_unit_labels`, {
        fiduciary_id: fiduciaryId,
        units: units,
      })
      .then(() => {
        dispatch(createUnitLabelsSuccess())
        dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
      })
      .catch((e) => {
        if (e.response.data.error.includes("already exists")) {
          dispatch(createUnitLabelsFailureDuplicate("fiduciaire"))
        } else if (e.response.data.error.includes("longueur supérieure")) {
          const split = e.response.data.error.split("Votre")
          dispatch(createUnitLabelsFailureTooLong("Votre " + split[1]))
        } else {
          dispatch(createUnitLabelsFailure())
          dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
        }
      })
  }

export const createCompanyUnitLabelsThunk =
  (fiduciaryId: number, units: CreateUnitLabelQuery[], companyId: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(createUnitLabelsAttempt())
    return axios
      .post(`companies/${companyId}/create_company_unit_labels`, {
        units: units,
        id: companyId,
      })
      .then(() => {
        dispatch(createUnitLabelsSuccess())
        dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
        dispatch(getCompanyUnitLabelsThunk(companyId))
      })
      .catch((e) => {
        if (e.response.data.error.includes("already exists")) {
          dispatch(createUnitLabelsFailureDuplicate("société"))
        } else if (e.response.data.error.includes("longueur supérieure")) {
          const split = e.response.data.error.split("Votre")
          dispatch(createUnitLabelsFailureTooLong("Votre " + split[1]))
        } else {
          dispatch(createUnitLabelsFailure())
          dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
          dispatch(getCompanyUnitLabelsThunk(companyId))
        }
      })
  }

export const destroyUnitLabelThunk =
  (fiduciaryId: number, unit_id: number, companyId?: number) =>
  (dispatch: Dispatch<AnyAction | RNBThunkAction>) => {
    dispatch(destroyUnitLabelAttempt())
    return axios
      .post(`fiduciaries/destroy_unit_label`, {
        fiduciary_id: fiduciaryId,
        unit_label_id: unit_id,
      })
      .then(() => {
        dispatch(destroyUnitLabelSuccess())
        dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
        if (companyId) {
          dispatch(getCompanyUnitLabelsThunk(companyId))
        }
      })
      .catch((e) => {
        dispatch(destroyUnitLabelFailure())
        dispatch(getFiduciaryUnitLabelsThunk(fiduciaryId))
        if (companyId) {
          dispatch(getCompanyUnitLabelsThunk(companyId))
        }
      })
  }

export const getCompanyUnitLabelsThunk =
  (companyId: number) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(getCompanyUnitLabelsAttempt())
    return axios
      .get<UnitLabel[]>(`companies/${companyId}/get_company_unit_labels`)
      .then((response) => {
        dispatch(getCompanyUnitLabelsSuccess(response.data, companyId))
      })
      .catch((e) => {
        dispatch(getCompanyUnitLabelsFailure())
      })
  }
