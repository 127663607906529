import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import React, { CSSProperties, useEffect, useState } from "react"
import AutoSizer from "react-virtualized-auto-sizer"

import { boxShadow, colors } from "../../../../../styles/design.config"
import { getIdFromParams } from "../../../../../utils/company"
import {
  CreateOrEditRuleReset,
  GetTransactionEventsThunk,
  GetTransactionsThunk,
  MarkWithoutDocumentThunk,
  Transaction,
  getDataToCreateRuleThunk,
} from "../../../../../store/ducks/bank.ducks"
import * as Ct from "ldlj"
import { useRNBSelector } from "../../../../../store/rootReducer"
import { VariableSizeGrid } from "react-window"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../../components/Commons/Table"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
  TableWrapper,
} from "../../../../../components/dropDocuments/StyledDropsComponents"
import { Alert } from "../../../../../components/Commons/Alert"
import { useIntl } from "react-intl"
import { Text } from "../../../../../components/Commons/Text"
import { Button } from "../../../../../components/Commons/Button"
import { DisplayTransactionModal } from "../../../../../components/bank/DisplayTransactionModal"
import { DateTime } from "luxon"
import { ReactComponent as Search } from "../../../../../assets/search.svg"
import { ReactComponent as FileFilled } from "../../../../../assets/file-filled.svg"
import { ReactComponent as Eye } from "../../../../../assets/eye.svg"
import { ReactComponent as FilleOff } from "../../../../../assets/file-off.svg"
import {
  searchTransactions,
  sortTransactionsByDate,
  sortTransactionsByDebit,
  sortTransactionsByDescription,
  sortTransactionsByDocumentAttached,
  sortTransactionsByStatus,
} from "../../../../../utils/bank"
import { FullDocumentModal } from "../../../../../components/bank/FullDocumentModal"
import { TransactionEventsModal } from "../../../../../components/bank/TransactionEventsModal"
import { GetDocumentsToAttachToTransactionsThunk } from "../../../../../store/ducks/fullDocuments.ducks"
import { Switch } from "../../../../../components/Commons/Switch"
import { SpotNotifBottom } from "../../../../../components/SpotNotification"
import { usePopperTooltip } from "react-popper-tooltip"
import "react-popper-tooltip/dist/styles.css"

type SortOptionsType =
  | "date"
  | "description"
  | "debit"
  | "credit"
  | "assignment"
  | "status"
  | "documentAttached"

export type ExtendedSortToReturn = SortToReturn & {
  sortType: SortOptionsType
}

export const Transactions = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const accountID = getIdFromParams(useParams())("account_id")
  const accounts = useRNBSelector((state) => state.bank.accounts)
  const currentAccount = Object.values(accounts).find((a) => a.id === accountID)
  const getTransactionsStatus = useRNBSelector(
    (state) => state.bank.getTransactionsStatus
  )
  const createOrEditRuleStatus = useRNBSelector(
    (state) => state.bank.createOrEditRuleStatus
  )
  const userTypology = useRNBSelector((state) => state.user.typology)

  const [rowData, setRowData] = useState<Transaction[]>([])
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const listRef = React.createRef<VariableSizeGrid>()
  const [columnToSort, setColumnToSort] = useState<ExtendedSortToReturn | null>(
    null
  )
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })
  const [rowHeigths, setRowHeights] = useState<number[]>([])
  const [search, setSearch] = useState<string>("")
  const [displayTransaction, setDisplayTransaction] =
    useState<Transaction | null>(null)
  const [showTransactionEvents, setShowTransactionEvents] =
    useState<Transaction | null>(null)
  const [goToFullDocAttached, setGoToFullDocAttached] = useState<boolean>(false)
  const [attachOrImport, setAttachOrImport] = useState<
    "attach" | "import" | null
  >(null)
  const [attachFullDocTransaction, setAttachFullDocTransaction] =
    useState<Transaction | null>(null)

  useEffect(() => {
    if (
      Object.values(accounts).length > 0 &&
      currentAccount &&
      currentAccount.transactions
    ) {
      setRowData(searchTransactions(search, currentAccount.transactions))
      if (currentAccount.transactions.length > 0) {
        setHeights(currentAccount.transactions)
      }
    }
  }, [Object.values(accounts).length, currentAccount?.transactions, search])

  useEffect(() => {
    if (accountID && Object.values(accounts).length > 0) {
      dispatch(GetTransactionsThunk(accountID))
    }
  }, [accountID, dispatch, Object.values(accounts).length])

  type ColumnType = { id: string; sortType: SortOptionsType }

  const firstColumns: ColumnType[] = [
    { id: "bank-management.transaction.table-header.date", sortType: "date" },
    {
      id: "bank-management.transaction.table-header.label",
      sortType: "description",
    },
  ]
  const customerColumns: ColumnType[] = [
    {
      id: "bank-management.transaction.table-header.amount",
      sortType: "debit",
    },
  ]
  const accountingColumns: ColumnType[] = [
    { id: "bank-management.transaction.table-header.debit", sortType: "debit" },
    {
      id: "bank-management.transaction.table-header.credit",
      sortType: "credit",
    },
    {
      id: "bank-management.transaction.table-header.assignment",
      sortType: "assignment",
    },
    {
      id: "bank-management.transaction.table-header.status",
      sortType: "status",
    },
  ]
  const lastColumns: ColumnType[] = [
    {
      id: "bank-management.transaction.table-header.full-doc",
      sortType: "documentAttached",
    },
  ]
  const columns: ColumnType[] =
    userTypology !== "customer"
      ? [...firstColumns, ...accountingColumns, ...lastColumns]
      : [...firstColumns, ...customerColumns, ...lastColumns]

  const [hoveredId, setHoveredId] = useState<number>(0)

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const transaction: Transaction = rowData[rowIndex]

    const firstComponents = [
      <TransactionDateCell key="0" transaction={transaction} />,
      <TransactionDescriptionCell key="1" transaction={transaction} />,
    ]
    const customerComponents = [
      <TransactionAmountCellCustomer key="2" transaction={transaction} />,
    ]
    const accountingComponents = [
      <TransactionDebitCell key="2" transaction={transaction} />,
      <TransactionCreditCell key="3" transaction={transaction} />,
      <TransactionAssignmentCell key="4" transaction={transaction} />,
      <TransactionStatusCell key="5" transaction={transaction} />,
    ]
    const lastComponents = [
      <TransactionDocumentCell
        key={userTypology === "customer" ? "3" : "6"}
        transaction={transaction}
        setAttachFullDocTransaction={(transaction: Transaction) => {
          setAttachFullDocTransaction(transaction)
        }}
        setAttachOrImport={(attachOrImport: "attach" | "import") => {
          setAttachOrImport(attachOrImport)
        }}
        setGoToFullDocAttached={(goTo: boolean) => {
          setGoToFullDocAttached(goTo)
        }}
        setShowTransactionEvents={(transaction: Transaction) => {
          setShowTransactionEvents(transaction)
        }}
      />,
    ]

    const allComponents =
      userTypology !== "customer"
        ? [...firstComponents, ...accountingComponents, ...lastComponents]
        : [...firstComponents, ...customerComponents, ...lastComponents]

    const cellComponent = allComponents[columnIndex]

    const isAffected = !transaction.lines.some((l) =>
      l.assignment?.startsWith("471")
    )

    return (
      <div
        style={{
          ...style,
        }}
        key={columnIndex + transaction.transaction_id}
        onClick={() => {
          if (userTypology === "customer" || columnIndex === 6) {
            return
          }
          setDisplayTransaction(transaction)
          if (accountID && selectedCompanyId) {
            dispatch(getDataToCreateRuleThunk(accountID))
            dispatch(
              GetDocumentsToAttachToTransactionsThunk(
                accountID,
                selectedCompanyId,
                transaction.transaction_id,
                true,
                transaction.need_action
              )
            )
          }
        }}
        onMouseEnter={() => {
          setHoveredId(transaction.transaction_id)
        }}
      >
        {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
        <StyledCell
          style={{ height: "100%", width: "100%" }}
          alignItems={"flex-start"}
          backgroundColor={
            transaction.transaction_id === hoveredId &&
            userTypology !== "customer"
              ? colors.blueIsh
              : rowIndex % 2 === 0
              ? colors.white
              : colors.clearBlue
          }
          color={
            columnIndex === 5 && isAffected
              ? colors.cornflower
              : columnIndex === 5 && !isAffected
              ? colors.amaranth
              : colors.navy
          }
          clickable={userTypology !== "customer" && columnIndex !== 5}
        >
          <Ct.Row
            style={{
              width: "100%",
              justifyContent:
                columnIndex === columns.length - 1 ? "center" : "flex-start",
              height: "100%",
            }}
          >
            {columnIndex === 1 && <Ct.Spacer width={2} height={0} />}

            {cellComponent}
          </Ct.Row>
        </StyledCell>
      </div>
    )
  }

  const [sortType, setSortType] = useState<
    { type: SortOptionsType; asc: boolean } | undefined
  >({ type: "date", asc: true })

  const sorter = (asc: boolean) => (option: SortOptionsType) => {
    let sortedData: Transaction[] = []
    setSortType({ type: option, asc: asc })
    if (option === "date") {
      sortedData = sortTransactionsByDate(rowData, asc)
      setRowData(sortedData)
    } else if (option === "description") {
      sortedData = sortTransactionsByDescription(rowData, asc)
      setRowData(sortedData)
    } else if (option === "status") {
      sortedData = sortTransactionsByStatus(rowData, asc)
      setRowData(sortedData)
    } else if (option === "debit") {
      sortedData = sortTransactionsByDebit(
        rowData,
        asc,
        userTypology === "customer"
      )
      setRowData(sortedData)
    } else if (option === "documentAttached") {
      sortedData = sortTransactionsByDocumentAttached(rowData, asc)
      setRowData(sortedData)
    }

    setHeights(sortedData)
  }

  useEffect(() => {
    if (columnToSort) {
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(columnToSort.sortType)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(columnToSort.sortType)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        if (
          Object.values(accounts).length > 0 &&
          currentAccount &&
          currentAccount.transactions
        ) {
          setRowData(currentAccount.transactions.slice())
          setHeights(currentAccount.transactions)
        }
      }
    }
  }, [columnToSort])

  const setHeights = (transactions: Transaction[]) => {
    const heightToAdd = window.innerHeight > 960 ? 39 : 29

    const newRows = transactions.map((r) => {
      return userTypology === "customer"
        ? 50
        : r.lines.length === 2
        ? window.innerHeight > 960
          ? 80
          : 60
        : r.lines.length * heightToAdd
    })
    setRowHeights(newRows)

    if (listRef.current != null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  return (
    <Content>
      <StyledSection>
        <Ct.Spacer height={3} />

        <StyledInput
          name={"searchRule"}
          id={"searchRule"}
          label="Rechercher"
          value={search}
          suffix={<Search />}
          maxWidth={30}
          onChange={(event: { target: HTMLInputElement }) => {
            const value = event.target.value.trimStart().replace("  ", " ")
            setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
          }}
          shadowed={true}
          noBorder={true}
        />
        <Ct.Spacer height={3} />

        <StyledTable>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => {
              const columnSizeByTypology = {
                manager: [10, 30, 8, 8, 15, 9, 20],
                customer: [15, 45, 20, 20],
              }

              const columnWidths = columns.map((_, index) => {
                return userTypology !== "customer"
                  ? (columnSizeByTypology.manager[index] / 100) * width
                  : (columnSizeByTypology.customer[index] / 100) * width
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)
              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 1rem"}>
                    {columns.map((column, index) => (
                      <HeaderTitleWrapper
                        justifyContent={
                          index === columns.length - 1 ? "center" : "flex-start"
                        }
                        calculatedWidth={columnWidths[index] - 10}
                        key={column.id || ""}
                        padding={"0 1rem"}
                      >
                        {!["credit", "assignment"].includes(column.sortType) ? (
                          <ClickableTitleSort
                            tid={column.id || ""}
                            intl={intl}
                            index={index}
                            sortToReturn={(str: SortToReturn) => {
                              setColumnToSort({
                                ...str,
                                sortType: column.sortType,
                              })
                              if (rowData.length > 0 && sortType) {
                                sorter(sortType.asc)(sortType.type)
                              }
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable tid={column.id || ""} intl={intl} />
                        )}
                      </HeaderTitleWrapper>
                    ))}
                  </HeaderWrapper>

                  {rowData.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      {getTransactionsStatus === "ATTEMPT" ? (
                        <div>
                          <Button label={""} loadingStatus={"loading"} />
                        </div>
                      ) : (
                        <Alert alertType="info">
                          <Text
                            text={intl.formatMessage({
                              id:
                                currentAccount?.transactions &&
                                currentAccount?.transactions?.length > 0
                                  ? "bank-management.transaction.table-body.empty-transactions-search"
                                  : "bank-management.transaction.table-body.empty-transactions",
                            })}
                          />
                        </Alert>
                      )}
                    </LoaderWrapper>
                  ) : (
                    <GridWrapper totalWidth={width}>
                      <VariableSizeGrid
                        ref={listRef}
                        height={height - 56}
                        rowCount={rowData.length}
                        width={width}
                        columnWidth={(index) => columnWidths[index]}
                        rowHeight={(index) => rowHeigths[index]}
                        columnCount={columns.length}
                      >
                        {Cell}
                      </VariableSizeGrid>
                    </GridWrapper>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </StyledTable>

        {displayTransaction && userTypology !== "customer" && (
          <DisplayTransactionModal
            isDisplayed={!!displayTransaction}
            onClose={() => {
              setDisplayTransaction(null)
              setAttachOrImport(null)
              if (createOrEditRuleStatus === "SUCCESS") {
                dispatch(CreateOrEditRuleReset())
                if (accountID && Object.values(accounts).length > 0) {
                  dispatch(GetTransactionsThunk(accountID))
                }
              }
            }}
            transaction={displayTransaction}
            goToFullDoc={(attachOrImport) => {
              setGoToFullDocAttached(goToFullDocAttached)
              setAttachFullDocTransaction(displayTransaction)
              setAttachOrImport(attachOrImport)
              if (selectedCompanyId && accountID) {
                dispatch(
                  GetDocumentsToAttachToTransactionsThunk(
                    accountID,
                    selectedCompanyId,
                    displayTransaction.transaction_id,
                    attachOrImport && attachOrImport === "import",
                    attachOrImport && attachOrImport === "import"
                  )
                )
              }
              setDisplayTransaction(null)
            }}
          />
        )}

        {attachFullDocTransaction && (
          <FullDocumentModal
            isDisplayed={!!attachFullDocTransaction}
            onClose={() => {
              setAttachFullDocTransaction(null)
              setGoToFullDocAttached(false)
              setAttachOrImport(null)
            }}
            transaction={attachFullDocTransaction}
            goToFullDocAttached={goToFullDocAttached}
            attachOrImport={attachOrImport}
          />
        )}

        {showTransactionEvents && accountID && (
          <TransactionEventsModal
            isDisplayed={!!showTransactionEvents}
            onClose={() => {
              setShowTransactionEvents(null)
            }}
            transaction={showTransactionEvents}
            bankAccountId={accountID}
          />
        )}
      </StyledSection>
    </Content>
  )
}

const TransactionDateCell = ({ transaction }: { transaction: Transaction }) => {
  const userTypology = useRNBSelector((state) => state.user.typology)

  return (
    <>
      <Ct.Spacer width={2} height={0} />
      <ColumnRow clickable={userTypology !== "customer"}>
        {transaction.lines.map((r) => (
          <Row
            text={DateTime.fromJSDate(new Date(transaction.date)).toFormat(
              "dd/MM/yyyy"
            )}
            notClickable={userTypology === "customer"}
            key={transaction.transaction_id + transaction.date + r.assignment}
          />
        ))}
      </ColumnRow>
    </>
  )
}
const TransactionDescriptionCell = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const userTypology = useRNBSelector((state) => state.user.typology)

  return (
    <ColumnRow clickable={userTypology !== "customer"}>
      {transaction.lines.map((r) => (
        <Row
          text={transaction.description}
          notClickable={userTypology === "customer"}
          key={
            transaction.transaction_id + transaction.description + r.assignment
          }
        />
      ))}
    </ColumnRow>
  )
}
const TransactionAmountCellCustomer = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  return (
    <ColumnRow clickable={false}>
      <Row text={transaction.lines[0].amount} notClickable={true} />
    </ColumnRow>
  )
}
const TransactionDebitCell = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const intl = useIntl()
  const empty = intl.formatMessage({
    id: "bank-management.transaction.table-body.empty-space",
  })

  return (
    <ColumnRow clickable={true}>
      {transaction.lines.map((l) => (
        <Row
          text={l.direction === "debit" ? l.amount : empty}
          key={
            "debit" +
            transaction.transaction_id +
            (l?.direction || "") +
            l.amount +
            l.assignment
          }
        />
      ))}
    </ColumnRow>
  )
}
const TransactionCreditCell = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const intl = useIntl()
  const empty = intl.formatMessage({
    id: "bank-management.transaction.table-body.empty-space",
  })

  return (
    <ColumnRow clickable={true}>
      {transaction.lines.map((l) => (
        <Row
          text={l.direction === "credit" ? l.amount : empty}
          key={
            "credit" +
            transaction.transaction_id +
            (l?.direction || "") +
            l.amount +
            l.assignment
          }
        />
      ))}
    </ColumnRow>
  )
}
const TransactionAssignmentCell = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  return (
    <ColumnRow clickable={true}>
      {transaction.lines.map((l) => (
        <Row
          text={
            l.assignment +
            (l.auxiliary_assignment ? ", " + l.auxiliary_assignment : "")
          }
          key={transaction.transaction_id + (l?.assignment || "")}
        />
      ))}
    </ColumnRow>
  )
}
const TransactionStatusCell = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const intl = useIntl()
  const isAffected = !transaction.lines.some((l) =>
    l.assignment?.startsWith("471")
  )

  return (
    <ColumnRow clickable={false}>
      <Affected affected={isAffected}>
        <RowColored
          affected={isAffected}
          text={intl.formatMessage({
            id: isAffected
              ? "bank-management.transaction.table-header.status-affected"
              : "bank-management.transaction.table-header.status-not-affected",
          })}
          textStyle={{
            fontWeight: 600,
          }}
        />
      </Affected>
    </ColumnRow>
  )
}
const TransactionDocumentCell = ({
  transaction,
  setAttachFullDocTransaction,
  setAttachOrImport,
  setGoToFullDocAttached,
  setShowTransactionEvents,
}: {
  transaction: Transaction
  setAttachFullDocTransaction: (transaction: Transaction) => void
  setAttachOrImport: (attachOrImport: "attach" | "import") => void
  setGoToFullDocAttached: (goToFullDocAttached: boolean) => void
  setShowTransactionEvents: (transaction: Transaction) => void
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const accountID = getIdFromParams(useParams())("account_id")
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const popperTooltipStatus = usePopperTooltip()
  const popperTooltipMarked = usePopperTooltip()

  return (
    <ColumnRow clickable={false}>
      <WrapperFiles>
        <WrapperDocument
          onClick={(event) => {
            if (transaction.marked_without_document) return
            event.stopPropagation()
            setAttachFullDocTransaction(transaction)
            setAttachOrImport(transaction.need_action ? "import" : "attach")
            setGoToFullDocAttached(!!transaction.full_document_id)
            if (selectedCompanyId && accountID) {
              dispatch(
                GetDocumentsToAttachToTransactionsThunk(
                  accountID,
                  selectedCompanyId,
                  transaction.transaction_id,
                  transaction.need_action,
                  transaction.need_action
                )
              )
            }
          }}
          ref={popperTooltipStatus.setTriggerRef}
          disabled={transaction.marked_without_document}
          svgColor={
            transaction.marked_without_document
              ? colors.moon
              : transaction.batch_document_id
              ? colors.orange
              : !transaction.full_document_id
              ? colors.amaranth
              : colors.cornflower
          }
        >
          {popperTooltipStatus.visible && (
            <div
              ref={popperTooltipStatus.setTooltipRef}
              {...popperTooltipStatus.getTooltipProps({
                className: "tooltip-container",
              })}
            >
              <div
                {...popperTooltipStatus.getArrowProps({
                  className: "tooltip-arrow",
                })}
              />
              <div key={"123"}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: `bank-management.transaction.tooltip.document.${
                      transaction.full_document_id
                        ? "attached"
                        : transaction.need_action
                        ? "need_action"
                        : transaction.batch_document_id
                        ? "to-treat"
                        : transaction.marked_without_document
                        ? "marked-without-document"
                        : "nothing-attached"
                    }`,
                  })}
                />
              </div>
            </div>
          )}
          {transaction.need_action && <SpotNotifBottom>1</SpotNotifBottom>}
          <StyledFileFilled />
        </WrapperDocument>

        <Ct.Spacer width={2} />

        <span ref={popperTooltipMarked.setTriggerRef}>
          <Switch
            value={transaction.marked_without_document}
            onToggle={() => {
              if (
                accountID &&
                !transaction.full_document_id &&
                !transaction.batch_document_id
              ) {
                dispatch(
                  MarkWithoutDocumentThunk(
                    accountID,
                    transaction.transaction_id,
                    !transaction.marked_without_document
                  )
                )
              }
            }}
            disabled={
              !!transaction.full_document_id || !!transaction.batch_document_id
            }
            icon={<StyledFileOff />}
          />
        </span>

        {popperTooltipMarked.visible && (
          <div
            ref={popperTooltipMarked.setTooltipRef}
            {...popperTooltipMarked.getTooltipProps({
              className: "tooltip-container",
            })}
          >
            <div
              {...popperTooltipMarked.getArrowProps({
                className: "tooltip-arrow",
              })}
            />
            <div key={"123"}>
              <Ct.Text
                text={intl.formatMessage({
                  id: `bank-management.transaction.tooltip.toggle-${
                    transaction.marked_without_document
                      ? "activated"
                      : "deactivated"
                  }`,
                })}
              />
            </div>
          </div>
        )}

        <Ct.Spacer width={2} />

        <span
          onClick={() => {
            dispatch(
              GetTransactionEventsThunk(
                accountID || 0,
                transaction.transaction_id
              )
            )
            setShowTransactionEvents(transaction)
          }}
        >
          <StyledEye />
        </span>
      </WrapperFiles>
    </ColumnRow>
  )
}

const Content = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

interface StyledCellProps {
  width: string
  alignItems: string
  backgroundColor: string
  color: string
  clickable: boolean
}

const StyledCell = styled(Ct.ColumnCenterCenter)<StyledCellProps>`
  width: ${(props) => props.width};
  align-items: ${(props) => props.alignItems};
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  background-color: ${(props) => props.backgroundColor};
  & span {
    color: ${(props) => props.color};
  }
`
const StyledTable = styled(TableWrapper)`
  border: none;
`

const ColumnRow = styled.div<{ clickable: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
`
const Row = styled((props) => <Ct.Text {...props} />)`
  display: flex;
  align-items: center;
  padding-top: 2rem;
`
const StyledInput = styled((props) => <Ct.Input {...props} />)<{
  notClickable?: boolean
}>`
  box-shadow: ${boxShadow};
  cursor: ${(props) => (props.notClickable ? "initial" : "pointer")};
`
const StyledFileFilled = styled(({ ...props }) => <FileFilled {...props} />)`
  width: 3rem;
  height: 3rem;
`
const WrapperFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`
const Affected = styled.span<{ affected: boolean }>`
  padding-top: 0;
  margin: 2rem 0;
`

const RowColored = styled(Ct.Text)<{ affected: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => (props.affected ? "#d8e3ff" : "#febdbd")};
  border-radius: 0.5rem;
`
const WrapperDocument = styled.span<{ disabled: boolean; svgColor: string }>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  & svg > path {
    fill: ${(props) => props.svgColor};
  }
`
const StyledEye = styled(Eye)`
  width: 3rem;
  height: 3rem;
  & svg > path {
    fill: ${colors.cornflower}!important;
  }
`

const StyledFileOff = styled(FilleOff)`
  width: 2.5rem;
  height: 2.5rem;
`
