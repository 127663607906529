import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { colors, sizes } from "../../styles/design.config"
import { Input } from "../Commons/Input"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { emptyOptionValue, Select } from "../Commons/Select"
import { ReactComponent as Trash } from "../../assets/drop-documents/TrashCan.svg"
import { Button } from "../Commons/Button"
import { unitValuesAction } from "../../store/ducks/inputMask.ducks"
import { disableUnitButton, WritingLinePayload } from "../../utils/inputMask"
/* eslint-disable camelcase */

interface UnitModalProps extends Ct.ModalComponentsProps {
  writingLine: WritingLinePayload
}

export const UnitModal = ({
  isDisplayed,
  onClose,
  writingLine,
}: UnitModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const units = useRNBSelector((state) => state.invoicing.units)

  const [unitOptions, setUnitOptions] = useState<Ct.Option<string>[]>(
    units.map((unit) => ({
      value: unit.id.toString(),
      label: unit.unit,
    }))
  )

  const [description1, setDescription1] = useState<string>("")
  const [description2, setDescription2] = useState<string>("")
  const [unit1, setUnit1] = useState<{
    value: string
    label: string
  }>({
    value: "",
    label: "",
  })
  const [unit2, setUnit2] = useState<{
    value: string
    label: string
  }>({
    value: "",
    label: "",
  })
  const [quantity1, setQuantity1] = useState<string>("")
  const [quantity2, setQuantity2] = useState<string>("")

  useEffect(() => {
    if (units.length > 0) {
      setUnitOptions(
        units.map((unit) => ({
          value: unit.id.toString(),
          label: unit.unit,
        }))
      )
    }
  }, [units])

  useEffect(() => {
    if (writingLine.unit.id) {
      const selectedUnit = unitOptions.find(
        (u) => Number(u.value) === writingLine.unit.id
      )
      if (selectedUnit) setUnit1(selectedUnit)
      setDescription1(writingLine.unit_description || "")
      setQuantity1(String(writingLine.unit_quantity) || "")
    }
    if (writingLine.unit_2.id) {
      const selectedUnit = unitOptions.find(
        (u) => Number(u.value) === writingLine.unit_2.id
      )
      if (selectedUnit) setUnit2(selectedUnit)
      setDescription2(writingLine.unit_2_description || "")
      setQuantity2(String(writingLine.unit_2_quantity) || "")
    }
  }, [writingLine, unitOptions])

  const isFirstUnitFilled =
    description1 !== "" && unit1.value !== "" && quantity1 !== ""

  const isButtonDisabled = disableUnitButton({
    writingLine,
    unit1: {
      description: description1,
      quantity: Number(quantity1) || null,
      unit: Number(unit1.value) || null,
    },
    unit2: {
      description: description2,
      quantity: Number(quantity2) || null,
      unit: Number(unit2.value) || null,
    },
  })

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(25vh - 25rem)"
    >
      <StyledCard width={"160rem"}>
        <Ct.Spacer height={2} />
        <Ct.CloseCross onClick={onClose} />

        <Ct.Title
          text={`${intl.formatMessage({
            id: `input-mask.unit.modal.title`,
          })}`}
          size={7}
        />

        <Ct.Spacer height={2} />
        <Line />
        <Ct.Spacer height={4} />

        <Ct.Text
          text={intl.formatMessage({
            id: `input-mask.unit.modal.message`,
          })}
        />

        <Ct.Spacer height={4} />

        <Wrapper>
          <WrapperInput>
            <Input
              value={description1}
              label={intl.formatMessage({
                id: `input-mask.unit.modal.input-description`,
              })}
              onChange={(e) => {
                setDescription1(e.target.value)
              }}
            />

            <Ct.Spacer width={3} />

            <Select
              intl={intl}
              options={unitOptions}
              value={unit1}
              onChangeCallback={(e: { value: string; label: string }) => {
                setUnit1(e)
              }}
              label={""}
              domain={"input-mask.unit.modal"}
              optionType={"input-unit1"}
              customWidth={"50rem"}
            />

            <Ct.Spacer width={3} />

            <Input
              value={quantity1}
              label={intl.formatMessage({
                id: `input-mask.unit.modal.input-quantity`,
              })}
              onChange={(e) => {
                if (e.target.value === "0") return
                setQuantity1(e.target.value.replace(/[^0-9.]/g, ""))
              }}
              min="1"
              maxWidth={"40rem"}
            />
            <Ct.Spacer width={2} />

            <StyledTrash
              disabled={
                description1 === "" && unit1.value === "" && quantity1 === ""
              }
              onClick={() => {
                setDescription1("")
                setUnit1(emptyOptionValue)
                setQuantity1("")
              }}
            />
          </WrapperInput>

          <Ct.Spacer height={2} />

          <WrapperInput>
            <Input
              value={description2}
              label={intl.formatMessage({
                id: `input-mask.unit.modal.input-description`,
              })}
              onChange={(e) => {
                setDescription2(e.target.value)
              }}
              disabled={!isFirstUnitFilled}
            />

            <Ct.Spacer width={3} />

            <Select
              intl={intl}
              options={unitOptions}
              value={unit2}
              onChangeCallback={(e: { value: string; label: string }) => {
                setUnit2(e)
              }}
              label={""}
              domain={"input-mask.unit.modal"}
              optionType={"input-unit2"}
              customWidth={"50rem"}
              disabled={!isFirstUnitFilled}
            />

            <Ct.Spacer width={3} />

            <Input
              value={quantity2}
              label={intl.formatMessage({
                id: `input-mask.unit.modal.input-quantity`,
              })}
              onChange={(e) => {
                if (e.target.value === "0") return
                setQuantity2(e.target.value.replace(/[^0-9.]/g, ""))
              }}
              min="1"
              maxWidth={"40rem"}
              disabled={!isFirstUnitFilled}
            />

            <Ct.Spacer width={2} />

            <StyledTrash
              disabled={
                description2 === "" &&
                unit2.value === "" &&
                quantity2 === "" &&
                !isFirstUnitFilled
              }
              onClick={() => {
                setDescription2("")
                setUnit2(emptyOptionValue)
                setQuantity2("")
              }}
            />
          </WrapperInput>

          <Ct.Spacer height={4} />

          <WrapperButton>
            <Button
              label={intl.formatMessage({
                id: `input-mask.unit.modal.cancel`,
              })}
              width={sizes.button.standard}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              onClick={onClose}
            />
            <Ct.Spacer width={2} />

            <Button
              label={intl.formatMessage({
                id: `input-mask.unit.modal.save`,
              })}
              width={sizes.button.standard}
              disabled={isButtonDisabled}
              onClick={() => {
                dispatch(
                  unitValuesAction({
                    writingLineUuid: writingLine.writing_line_uuid,
                    unit_values: {
                      unit: { id: Number(unit1.value) || null },
                      unit_quantity: Number(quantity1) || null,
                      unit_description: description1 || null,
                      unit_2: { id: Number(unit2.value) || null || null },
                      unit_2_quantity: Number(quantity2) || null,
                      unit_2_description: description2 || null,
                    },
                  })
                )
                onClose()
              }}
            />
          </WrapperButton>
        </Wrapper>
        <Ct.Spacer height={4} />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled(Ct.Card)`
  padding: 0;
`
const Line = styled.div`
  height: 1px;
  background: ${colors.hawkes};
  width: 100%;
`
const Wrapper = styled.div`
  width: 80%;
`
const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const StyledTrash = styled(Trash)<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 7rem;
  height: 7rem;
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${(props) => (props.disabled ? colors.moon : colors.cornflower)};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${(props) => (props.disabled ? colors.moon : colors.amaranth)};
    }
  }
`
const WrapperButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
