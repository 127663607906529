import { useEffect } from "react"
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useParams,
  useResolvedPath,
} from "react-router-dom"
import { ReactComponent as Folder } from "../../../../assets/folderUser.svg"
import { ReactComponent as Products } from "../../../../assets/products.svg"
import { ReactComponent as Vat } from "../../../../assets/settings-adjust.svg"
import { ReactComponent as Users } from "../../../../assets/users.svg"
import { ReactComponent as ViewList } from "../../../../assets/viewList.svg"
import { ReactComponent as Ruler } from "../../../../assets/ruler.svg"
import { ReactComponent as At } from "../../../../assets/at.svg"
import { ScrollableOutlet } from "../../../../components/Commons/ScrollableOutlet"
import { Tabs } from "../../../../components/Commons/Tabs"
import { useRNBSelector } from "../../../../store/rootReducer"
import { isRouteSelected } from "../../../../utils/routes"
import styled from "styled-components/macro"
import { getIdFromParams } from "../../../../utils/company"
import { CompanyStub } from "../../../../store/ducks/companies.ducks"
import { useDispatch } from "react-redux"
import { isOnboardingFinishedThunk } from "../../../../store/ducks/companySettings.ducks"

export const AccountingPlanMenu = () => {
  const location = useLocation()
  const routePattern = "\\/company\\/\\d+\\/accounting_plan"
  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId] || CompanyStub
  )
  const companyOnboarding = useRNBSelector(
    (state) => state.companySettings.isOnboardingFinished
  )

  useEffect(() => {
    if (selectedCompanyId)
      dispatch(isOnboardingFinishedThunk(selectedCompanyId))
  }, [dispatch, selectedCompanyId])

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company.id !== 0) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company.id,
  ])

  let linkTabs = [
    {
      to: "buy",
      title: "Fournisseurs",
      isDisplayed: true,
      icon: <Products />,
      isSelected: isRouteSelected(location, routePattern, "buy"),
      tooltip: "",
    },
    {
      to: "sell",
      title: "Clients",
      isDisplayed: true,
      icon: <Users />,
      isSelected: isRouteSelected(location, routePattern, "sell"),
      tooltip: "",
    },
    {
      to: "accounts",
      title: "Gestion des comptes",
      isDisplayed: true,
      icon: <Folder />,
      isSelected: isRouteSelected(location, routePattern, "accounts"),
      disabled: companyOnboarding === "unfinished",
      tooltip: "",
    },
    {
      to: "codes",
      title: "Codes Tiers",
      isDisplayed: true,
      icon: <ViewList />,
      isSelected: isRouteSelected(location, routePattern, "codes"),
      disabled: companyOnboarding === "unfinished",
      tooltip: "",
    },
    {
      to: "vat",
      title: "TVA",
      isDisplayed: true,
      icon: <StyledVat />,
      isSelected: isRouteSelected(location, routePattern, "vat"),
      disabled: companyOnboarding === "unfinished",
      tooltip: "",
    },
    {
      to: "journals",
      title: "Journaux",
      isDisplayed: true,
      icon: <StyledVat />,
      isSelected: isRouteSelected(location, routePattern, "journals"),
      disabled: companyOnboarding === "unfinished",
      tooltip: "",
    },
    {
      to: "lcr",
      title: "LCR",
      isDisplayed: true,
      icon: <Ruler />,
      isSelected: isRouteSelected(location, routePattern, "lcr"),
      disabled: companyOnboarding === "unfinished",
      tooltip: "",
    },
    {
      to: "accounting_key_words",
      title: "Mots clés",
      isDisplayed: ["administrator", "manager", "collaborator"].includes(
        userInformations.typology
      ),
      icon: <At />,
      isSelected: isRouteSelected(
        location,
        routePattern,
        "accounting_key_words"
      ),
      tooltip: "",
    },
  ]

  if (companyOnboarding !== "finished") {
    linkTabs = linkTabs
      .slice(0, 2)
      .concat(linkTabs.slice(2).map((tab) => ({ ...tab, disabled: true })))
  }

  if (
    company &&
    company.scratch_games_paid_lots_goods_transfer_account_id &&
    linkTabs[linkTabs.length - 2].to !== "goods_transfer"
  ) {
    const lastTab = linkTabs.slice(linkTabs.length - 1)
    linkTabs = [
      ...linkTabs.slice(0, linkTabs.length - 1),
      {
        to: "goods_transfer",
        title: "Marchandises",
        isDisplayed: true,
        icon: <StyledVat />,
        isSelected: isRouteSelected(location, routePattern, "goods_transfer"),
        disabled: companyOnboarding === "unfinished",
        tooltip: "accounting-plan-menu.tooltip.onboarding-unfinished",
      },
      ...lastTab,
    ]
  }

  return (
    <>
      <Tabs
        tabs={linkTabs}
        Link={Link}
        useMatch={useMatch}
        useResolvedPath={useResolvedPath}
      />
      <ScrollableOutlet padding={"0"} Outlet={Outlet} />
    </>
  )
}

const StyledVat = styled(Vat)`
  transform: rotate(90deg);
`
