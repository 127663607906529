import React, { CSSProperties, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeGrid } from "react-window"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Info } from "../../../../assets/info-full-little.svg"
import { ReactComponent as Check } from "../../../../assets/drop-documents/Check.svg"
import { ReactComponent as DocumentsCheck } from "../../../../assets/check.svg"
import { ReactComponent as Duplicate } from "../../../../assets/red-duplicate.svg"
import { ReactComponent as UploadFile } from "../../../../assets/file-upload.svg"
import { ReactComponent as Calendar } from "../../../../assets/calendarSearch.svg"
import { ReactComponent as NotTreatable } from "../../../../assets/circle-cross-full.svg"

import * as Ct from "ldlj"
import {
  dropDocumentEmptyAction,
  DropDocumentStatus,
  dropDocumentThunk,
  getAcceptedFormatsThunk,
  resetUploadStatusAction,
  resetUploadsTotalAction,
  setDuplicateDetailBatchDocumentIdAction,
} from "../../../../store/ducks/dropDocuments.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { base64ArrayBuffer } from "../../../../utils/base64ArrayBuffer"
import { getIdFromParams } from "../../../../utils/company"

import {
  BatchDocument,
  getBatchesForCompanyThunk,
  getUrlForBatchDocumentThunk,
  deactivateBatchResetAction,
} from "../../../../store/ducks/batchDocuments.ducks"
import {
  filterBatches,
  getDocumentTypeDisplayed,
  getListWithoutDuplicates,
  getListOfOfficeDocuments,
  isExchange,
  sortBatchesByState,
  sortBatchesAlphabetically,
  sortBatchesByDate,
  sortBatchesByType,
  sortBatchesDepositBy,
} from "../../../../utils/batchDocuments"
import { croppedString, truncateString } from "../../../../utils/string"
import { DateTime } from "luxon"
import { resetBatchDocumentCreatedForInvoiceId } from "../../../../store/ducks/invoicing.duck"
import { usePrompt } from "../../../../utils/usePrompt.hook"
import { setActionId } from "../../../../store/ducks/actions.ducks"
import { DropFileUpload, UploadStatus } from "../../../../components/DropFile"
import { getByBatchThunk } from "../../../../store/ducks/fullDocuments.ducks"
import { Modals } from "../../../../components/dropDocuments/Modals"
import { displayPreviewParams } from "../../../../components/dropDocuments/BatchDocumentPreviewModal"
import {
  Content,
  ControlBar,
  DropHeader,
  GridWrapper,
  LoaderWrapper,
  StyledCell,
  TableWrapper,
  UploadIndicator,
  Wrapper,
} from "../../../../components/dropDocuments/StyledDropsComponents"
import { Alert } from "../../../../components/Commons/Alert"
import { InputDateRange } from "../../../../components/Commons/InputDateRange"

import styled from "styled-components/macro"
import { colors } from "../../../../styles/design.config"

import { useLocation } from "react-router-dom"
import { Type } from "react-tooltip"
import {
  TitleTable,
  SortToReturn,
  ClickableTitleSort,
  ChevronProps,
} from "../../../../components/Commons/Table"
import { IconActions } from "../../../../components/IconActions"
import { Checkbox } from "../../../../components/Commons/Checkbox"

const CompanyDropDocuments = () => {
  const location = useLocation()
  const state = location.state as Type
  const titleName =
    String(state) === String("quotation")
      ? "Votre devis"
      : String(state) === String("creditNote")
      ? "Votre avoir"
      : String(state) === String("invoicing")
      ? "Votre facture"
      : "Votre document"

  const intl = useIntl()
  const dispatch = useDispatch()
  const listRef = React.createRef<VariableSizeGrid>()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")

  const [cells, setCells] = useState<Ct.TableBuilder<BatchDocument>[]>([])
  const [search, setSearch] = useState("")
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const [hideDuplicates, setHideDuplicates] = useState(false)
  const [originalBatches, setOriginalBatches] = useState<BatchDocument[]>([])
  const [displayedBatches, setDisplayedBatches] = useState<BatchDocument[]>([])
  const [displayAcceptedTooltip, setDisplayAcceptedTooltip] = useState(false)
  const [displayOfficeAccepted, setDisplayOfficeAccepted] = useState(false)
  const [renamedBatchDocumentId, setRenamedBatchDocumentId] = useState<
    number | null
  >(null)

  const [, setShiftHeld] = useState<boolean>(false)
  const [deactivatedBatchDocumentIdList, setDeactivatedBatchDocumentIdList] =
    useState<number[]>([])
  const [displayDeactivateModal, setDisplayDeactivateModal] = useState(false)
  const [displayDetails, setDisplayDetails] = useState<string | undefined>(
    undefined
  )
  const [displayDuplicateDetails, setDisplayDuplicateDetails] = useState(false)
  const [displayPreview, setDisplayPreview] = useState<displayPreviewParams>({
    isDisplayed: false,
    batchDocumentId: null,
    companyId: selectedCompanyId,
    elementName: "",
    displayInvoice: false,
  })
  const [showActions, setShowActions] = useState(false)
  const [displayCalendar, setDisplayCalendar] = useState(false)
  const [displayOfficeDocuments, setDisplayOfficeDocuments] = useState(false)
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId ?? 0]
  )
  const batchesFetchStatus = useRNBSelector(
    (state) => state.batchDocuments.batchesFetchStatus
  )
  const batchesDeactivateStatus = useRNBSelector(
    (state) => state.batchDocuments.batchesDeactivateStatus
  )
  const acceptedFormats = useRNBSelector(
    (state) => state.dropDocuments.acceptedBatchesFormats
  )
  const uploadStatus = useRNBSelector(
    (state) => state.dropDocuments.allDroppedDocumentsStatus
  )
  const uploadProgress = useRNBSelector(
    (state) => state.dropDocuments.allDroppedDocumentsProgress
  )
  const documentsCount = useRNBSelector(
    (state) => state.dropDocuments.documentsCount
  )
  const rejectedDocumentsNames = useRNBSelector(
    (state) => state.dropDocuments.rejectedDocumentsNames
  )
  const emptyDocumentsNames = useRNBSelector(
    (state) => state.dropDocuments.emptyDocumentsNames
  )
  const recentUploads = useRNBSelector(
    (state) => state.dropDocuments.recentUploads
  )
  const batchDocumentCreatedForInvoiceId = useRNBSelector(
    (state) => state.invoicing.batchDocumentCreatedForInvoiceId
  )
  const userTypology = useRNBSelector((state) => state.user.typology)
  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)

  usePrompt(
    intl.formatMessage({ id: "company-drop-document.prompt" }),
    uploadStatus === "loading"
  )

  const batches = JSON.stringify(
    useRNBSelector((state) =>
      Object.values(
        state.batchDocuments.batchesByCompany[
          selectedCompanyId ? selectedCompanyId.toString() : ""
        ] || {}
      )
    )
  )

  const today = DateTime.now().toJSDate().toISOString().substring(0, 10)
  const [range, setRange] = useState<[Date, Date] | null>([
    DateTime.now().set({ day: 1 }).toJSDate(),
    DateTime.now().toJSDate(),
  ])

  const dropFileStatusByUploadStatus: Record<DropDocumentStatus, UploadStatus> =
    {
      idle: "start",
      loading: "uploading",
      success: "success",
      error: "error",
    }

  async function uploadFile(file: File) {
    if (!selectedCompanyId) {
      throw new Error("no company selected")
    }

    if (!file.size) {
      dispatch(
        dropDocumentEmptyAction({
          fileName: file.name,
        })
      )
      return
    }
    crypto.subtle
      .digest("SHA-512", await file.arrayBuffer())
      .then(function (h) {
        let fingerprint = base64ArrayBuffer(h)

        dispatch(
          dropDocumentThunk({
            selectedCompanyId,
            fingerprint,
            originalFileName: file.name,
            originalFileSize: file.size,
            file,
          })
        )
      })
  }

  const resetCheckbox = () => {
    setDeactivatedBatchDocumentIdList([])
    const tmpBatches = displayedBatches.map((e) => {
      return { ...e, toDeactivate: false }
    })
    setDisplayedBatches(tmpBatches)
  }

  const handleHeaderCheckboxChange = () => {
    if (
      deactivatedBatchDocumentIdList.length === displayedBatches.length &&
      displayedBatches
    ) {
      resetCheckbox()
    } else {
      const ids = displayedBatches.map((e) => e.id)
      const tmpBatches = displayedBatches.map((e) => {
        return { ...e, toDeactivate: true }
      })
      setDeactivatedBatchDocumentIdList(ids)
      setDisplayedBatches(tmpBatches)
    }
  }

  const handleCheckboxChange = (batch: BatchDocument) => {
    // On utilise les fonctions set[...] afin de récupérer les state à jour
    // Si on ne le fait pas on a accès au state qu'a leur état initial
    // Ce comportement est présent car les lignes du tableau et donc l'appel de cette fonction sont déclarés dans une constante
    setDisplayedBatches((displayedBatchesState) => {
      setShiftHeld((shiftState) => {
        setDeactivatedBatchDocumentIdList((deactivateListState: number[]) => {
          if (deactivateListState.includes(batch.id)) {
            batch.toDeactivate = false
            return [...deactivateListState.filter((id) => id !== batch.id)]
          } else {
            if (shiftState && deactivateListState.length >= 1) {
              const firstIndex = displayedBatchesState.findIndex(
                (b) => deactivateListState.includes(b.id) || batch.id === b.id
              )
              const lastElem = displayedBatchesState
                .slice()
                .reverse()
                .find(
                  (b) => deactivateListState.includes(b.id) || batch.id === b.id
                )
              const lastIndex = displayedBatchesState.findIndex(
                (b) => b.id === lastElem?.id || 0
              )

              if (firstIndex !== -1 && lastIndex !== -1) {
                const idsToDeactivate: number[] = []
                displayedBatchesState = displayedBatchesState.map(
                  (b, index) => {
                    if (index >= firstIndex && index <= lastIndex) {
                      if (!deactivateListState.includes(b.id))
                        idsToDeactivate.push(b.id)
                      return { ...b, toDeactivate: true }
                    } else return b
                  }
                )
                return [...deactivateListState, ...idsToDeactivate]
              } else {
                batch.toDeactivate = true
                return [...deactivateListState, batch.id]
              }
            } else {
              batch.toDeactivate = true
              return [...deactivateListState, batch.id]
            }
          }
        })
        return shiftState
      })
      return displayedBatchesState
    })
  }

  const handleDeactivatedModalClose = () => {
    setDisplayDeactivateModal(false)
  }

  const downHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(true)
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === "Shift") {
      setShiftHeld(false)
    }
  }

  const handleUniqueDeletion = (batchId: number) => {
    setDisplayedBatches((prevValue) => {
      const tmpBatches = prevValue.map((e) => {
        return { ...e, toDeactivate: e.id === batchId ? true : false }
      })
      setDeactivatedBatchDocumentIdList([batchId])
      setDisplayDeactivateModal(true)
      return tmpBatches
    })
  }

  const initializeCells = () => {
    const multiDelActive = !["customer", "customer_accountant"].includes(
      userTypology
    )
    const multiDelActiveCell: Ct.TableBuilder<BatchDocument>[] = [
      {
        headerText: "",
        content: (batch: BatchDocument) => (
          <Checkbox
            label=""
            isChecked={batch.toDeactivate || false}
            value={batch.toDeactivate || false}
            name=""
            onChange={() => handleCheckboxChange(batch)}
            colorChecked={colors.amaranth}
            checkBoxSize={2.5}
          />
        ),
      },
    ]
    const defaultCells: Ct.TableBuilder<BatchDocument>[] = [
      {
        headerText: "company-drop-document.header.state",
        content: (batch: BatchDocument) => (
          <div>{batch.duplicate ? <Duplicate /> : <Check />}</div>
        ),
      },
      {
        headerText: "company-drop-document.header.type",
        content: (batch: BatchDocument) => (
          <div>
            <TypeWrapper isOffice={batch.exchange}>
              {getDocumentTypeDisplayed(batch)}
            </TypeWrapper>
            {userTypology !== "customer" &&
              (batch.exchange ? (
                <>
                  <Ct.Spacer height={0.5} width={0} />
                  <Ct.Text
                    text="* Document office non traité"
                    textStyle={{
                      color: "slateGrey",
                      fontSize: 1.5,
                      fontStyle: "italic",
                      fontFamily: "Roboto",
                    }}
                  />
                </>
              ) : (
                void 0
              ))}
          </div>
        ),
      },
      {
        headerText: "company-drop-document.header.name",
        content: (batch: BatchDocument) => (
          <DocumentNameWrapper>
            <StyledLink
              onClick={() => {
                if (
                  ["PDF", "PNG", "JPG", "JPEG"].indexOf(
                    getDocumentTypeDisplayed(batch).toUpperCase()
                  ) !== -1
                ) {
                  setDisplayPreview({
                    isDisplayed: true,
                    batchDocumentId: batch.id,
                    companyId: selectedCompanyId,
                    elementName: batch.originalFileName,
                  })
                } else {
                  dispatch(getUrlForBatchDocumentThunk(batch.id))
                }
              }}
            >
              {croppedString(batch.userBatchName || batch.originalFileName, 30)}
            </StyledLink>
            <Ct.Spacer width={2} />
            <IconActions
              actionsToDisplay={["rename"]}
              onRename={() => {
                setRenamedBatchDocumentId(batch.id)
              }}
            />
          </DocumentNameWrapper>
        ),
      },
      {
        headerText: "company-drop-document.header.email",
        content: (batch: BatchDocument) => (
          <Ct.Text
            text={
              batch.email
                ? batch.email
                : batch.captureEmail
                ? batch.captureEmail
                : ""
            }
            textStyle={{
              textAlign: "center",
            }}
          />
        ),
      },
      {
        headerText: "company-drop-document.header.date",
        content: (batch: BatchDocument) => (
          <Ct.Text
            text={new Date(batch.createdAt).toLocaleDateString()}
            textStyle={{
              textAlign: "center",
            }}
          />
        ),
      },
      {
        headerText: "company-drop-document.header.actions",
        content: (batch: BatchDocument) => (
          <Ct.Row>
            <IconActions
              actionsToDisplay={
                !["customer", "customer_accountant"].includes(userTypology)
                  ? ["seeDetails", "download", "delete"]
                  : ["download", "seeDetails"]
              }
              hideDetails={
                batch.exchange
                  ? "exchange"
                  : !batch.writing_validated_at && !batch?.duplicate
                  ? "writing_not_validated"
                  : null
              }
              onSetDownload={() => {
                dispatch(getUrlForBatchDocumentThunk(batch.id))
              }}
              onDelete={() => {
                handleUniqueDeletion(batch.id)
              }}
              deleteAction={
                ["customer", "customer_accountant"].includes(userTypology)
                  ? "hide"
                  : "enable"
              }
              onSetDisplayDetails={() => {
                if (batch?.duplicate) {
                  setDisplayDuplicateDetails(true)
                  dispatch(setDuplicateDetailBatchDocumentIdAction(batch.id))
                } else {
                  dispatch(getByBatchThunk(batch.id))
                  setDisplayDetails(batch.originalFileName)
                }
              }}
            />
          </Ct.Row>
        ),
      },
    ]
    if (multiDelActive) setCells([...multiDelActiveCell, ...defaultCells])
    else setCells([...defaultCells])
  }

  const getColumnsWidth = (width: number): number[] => {
    const multiDelActive = !["customer", "customer_accountant"].includes(
      userTypology
    )

    if (multiDelActive) {
      return cells.map((_, index) => {
        // fixed size for first 3 columns
        if (index === 0) return 70
        if (index === 1) return 96
        if (index === 2) return 185
        if (index === 3)
          return (width - (70 + 96 + 185)) / (cells.length - 3) + 60 - 8
        // split remaining space evenly for other columns
        // -8 to prevent horizontal scroll due to vertical scrollbar
        return (width - (70 + 96 + 185 + 60)) / (cells.length - 3) - 8
      })
    } else {
      return cells.map((_, index) => {
        // fixed size for first 2 columns
        if (index === 0) return 96
        if (index === 1) return 185
        if (index === 2)
          return (width - (96 + 185)) / (cells.length - 2) + 60 - 8
        // split remaining space evenly for other columns
        // -5 to prevent horizontal scroll due to vertical scrollbar
        return (width - (96 + 185 + 60)) / (cells.length - 2) - 8
      })
    }
  }

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const rowWithoutEmptyMail: BatchDocument =
      displayedBatches.length > 0
        ? displayedBatches[rowIndex]?.email
          ? displayedBatches[rowIndex]
          : { ...displayedBatches[rowIndex], email: "Dépôt par API" }
        : originalBatches[rowIndex]?.email
        ? originalBatches[rowIndex]
        : { ...originalBatches[rowIndex], email: "Dépôt par API" }
    return (
      <div style={style}>
        {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
        <StyledCell
          isGray={
            displayedBatches.length > 0
              ? displayedBatches[rowIndex]?.duplicate
              : originalBatches[rowIndex]?.duplicate
          }
          isRecent={Object.entries(recentUploads).some(
            ([key, value]) =>
              key ===
                String(
                  displayedBatches.length > 0
                    ? displayedBatches[rowIndex]?.id
                    : originalBatches[rowIndex]?.id
                ) &&
              // After 30mns, the upload is not considered recent anymore
              DateTime.fromISO(value).diffNow("minutes").minutes > -30
          )}
        >
          <Ct.Row>
            {columnIndex === cells.length && <Ct.Spacer width={4} />}
            <Ct.ColumnCenterCenter>
              {cells[columnIndex].content(rowWithoutEmptyMail)}
            </Ct.ColumnCenterCenter>
          </Ct.Row>
        </StyledCell>
      </div>
    )
  }

  const batchOnRange = (range: [Date, Date] | null) => {
    let total = 0
    let duplicate = 0
    let office = 0
    let filterbatches = originalBatches
    if (displayCalendar && range) {
      filterbatches = originalBatches.filter(
        (batch) =>
          batch.createdAt >= range[0].toISOString().slice(0, 10) &&
          batch.createdAt <= range[1].toISOString().slice(0, 10)
      )
    }
    filterbatches.forEach((batch) => {
      total = total + 1
      if (batch.duplicate) {
        duplicate = duplicate + 1
      }
      if (isExchange(batch.originalFileName)) {
        office = office + 1
      }
    })
    return { total: total, duplicate: duplicate, office: office }
  }

  enum SortOptionsValues {
    "state",
    "document_type",
    "batch_name",
    "deposit_by",
    "creation_date",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.state) {
      setDisplayedBatches(sortBatchesByState(displayedBatches, asc))
    } else if (option === SortOptionsValues?.batch_name) {
      setDisplayedBatches(sortBatchesAlphabetically(displayedBatches, asc))
    } else if (option === SortOptionsValues?.creation_date) {
      setDisplayedBatches(sortBatchesByDate(displayedBatches, asc))
    } else if (option === SortOptionsValues?.document_type) {
      setDisplayedBatches(sortBatchesByType(displayedBatches, asc))
    } else if (option === SortOptionsValues?.deposit_by) {
      setDisplayedBatches(sortBatchesDepositBy(displayedBatches, asc))
    }
  }

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  useEffect(() => {
    if (batchDocumentCreatedForInvoiceId) {
      setDisplayPreview({
        isDisplayed: true,
        batchDocumentId: batchDocumentCreatedForInvoiceId,
        companyId: selectedCompanyId,
        elementName: titleName,
        displayInvoice: true,
      })
      dispatch(resetBatchDocumentCreatedForInvoiceId())
    }
  }, [batchDocumentCreatedForInvoiceId])

  useEffect(() => {
    dispatch(setActionId(0))
  }, [])

  useEffect(() => {
    dispatch(getAcceptedFormatsThunk())
  }, [dispatch])

  useEffect(() => {
    const formattedBatches = hideDuplicates
      ? getListWithoutDuplicates(JSON.parse(batches))
      : JSON.parse(batches)

    const formattedOfficeBatches = displayOfficeDocuments
      ? getListOfOfficeDocuments(formattedBatches)
      : formattedBatches

    const filteredBatches = filterBatches(formattedOfficeBatches, search)

    if (range && displayCalendar) {
      const filteredBatchesDate = filteredBatches.filter(
        (batch) =>
          new Date(batch.createdAt) >= range[0] &&
          new Date(batch.createdAt) <= range[1]
      )
      setOriginalBatches(sortBatchesByDate(filteredBatchesDate, false))
    } else {
      setOriginalBatches(sortBatchesByDate(filteredBatches, false))
    }
    setDeactivatedBatchDocumentIdList([])
  }, [
    batches,
    search,
    hideDuplicates,
    range,
    displayCalendar,
    displayOfficeDocuments,
  ])

  useEffect(() => {
    dispatch(resetUploadsTotalAction()) // reset to 0 on company change or page refresh
    if (selectedCompanyId) {
      dispatch(
        getBatchesForCompanyThunk({
          companyId: selectedCompanyId,
        })
      )
    }
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (uploadStatus === "success" || uploadStatus === "error") {
      setTimeout(() => {
        // reset on success or error
        dispatch(resetUploadStatusAction())
      }, 3000)
    }
  }, [dispatch, uploadStatus, rejectedDocumentsNames, emptyDocumentsNames])

  useEffect(() => {
    dispatch(resetUploadStatusAction())
  }, [dispatch, selectedCompanyId])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setDisplayedBatches([...originalBatches])
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (
      originalBatches &&
      currentChevron.direction === "none" &&
      currentChevron.index === 0
    ) {
      setDisplayedBatches([...originalBatches])
    }
  }, [originalBatches])

  useEffect(() => {
    if (userTypology) initializeCells()
  }, [userTypology])

  useEffect(() => {
    if (["success", "error"].includes(batchesDeactivateStatus)) {
      dispatch(deactivateBatchResetAction())
      setDeactivatedBatchDocumentIdList([])
      resetCheckbox()
    }
  }, [batchesDeactivateStatus])

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  window.onclick = function (event) {
    const element = event.target as Element
    const value = element.id

    if (showActions && value !== "openActions") {
      setShowActions(false)
    }
  }

  const parametersOfModals = {
    selectedCompanyId,
    displayPreview,
    displayDetails,
    displayDuplicateDetails,
    deactivatedBatchDocumentIdList,
    displayDeactivateModal,
    renamedBatchDocumentId,
    displayOfficeAccepted,
    displayAcceptedTooltip,
    acceptedFormats,
    setDisplayAcceptedTooltip,
    setDisplayOfficeAccepted,
    setRenamedBatchDocumentId,
    handleDeactivatedModalClose,
    setDisplayDeactivateModal,
    setDisplayDetails,
    setDisplayPreview,
    setDisplayDuplicateDetails,
  }

  return (
    <>
      <Modals {...parametersOfModals} />
      <Wrapper>
        <Content>
          <DropHeader>
            <DropFileUpload
              uploadFile={uploadFile}
              dropText="FileDrop"
              uploadStatus={dropFileStatusByUploadStatus[uploadStatus]}
              uploadProgress={uploadProgress}
              height={"25rem"}
              allowMultiple={true}
              errorMessage={truncateString(
                rejectedDocumentsNames.join(", "),
                400
              )}
              secondErrorMessage={
                emptyDocumentsNames.length > 0
                  ? "Fichiers vides : " +
                    truncateString(emptyDocumentsNames.join(", "), 400)
                  : ""
              }
            />
            <Ct.Spacer />
            <UploadIndicator>
              <Ct.ColumnCenterCenter>
                <UploadWrapper>
                  {documentsCount.accepted > 0 ? (
                    <StyledCheck />
                  ) : (
                    <UploadFile />
                  )}
                  <Ct.Spacer width={1} />
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: `company-drop-document.accepted`,
                      },
                      { accepted: documentsCount.accepted }
                    )}
                    textStyle={{
                      fontSize: 2.25,
                      fontWeight: 600,
                      color: "cornflower",
                    }}
                  />
                </UploadWrapper>

                <Ct.Spacer height={2} />

                {(documentsCount.officeAccepted > 0 ||
                  documentsCount.rejected > 0) && (
                  <UploadWrapper>
                    <NotTreatable />
                    <Ct.Spacer width={1} />
                    <Ct.Text
                      text={intl.formatMessage(
                        {
                          id: `company-drop-document.totalRejected`,
                        },
                        {
                          totalRejected:
                            documentsCount.rejected +
                            documentsCount.officeAccepted,
                        }
                      )}
                      textStyle={{
                        fontSize: 2.25,
                        fontWeight: 700,
                        color: "steelBlue",
                      }}
                    />
                  </UploadWrapper>
                )}

                <Ct.Spacer height={1} />

                {documentsCount.officeAccepted > 0 && (
                  <>
                    <Ct.RowCenter>
                      <Ct.Text
                        text={intl.formatMessage(
                          {
                            id: `company-drop-document.officeAccepted`,
                          },
                          { officeAccepted: documentsCount.officeAccepted }
                        )}
                        textStyle={{
                          fontSize: 2,
                          fontWeight: 400,
                          color: "steelBlue",
                        }}
                      />
                      <Ct.Spacer />
                      <Info
                        onMouseLeave={() => {
                          setDisplayOfficeAccepted(false)
                        }}
                        onMouseEnter={() => {
                          setDisplayOfficeAccepted(true)
                        }}
                      />
                    </Ct.RowCenter>
                    <Ct.Spacer height={1} />
                  </>
                )}
                {documentsCount.rejected > 0 && (
                  <>
                    <Ct.RowCenter>
                      <Ct.Text
                        text={intl.formatMessage(
                          {
                            id: `company-drop-document.rejected`,
                          },
                          { rejected: documentsCount.rejected }
                        )}
                        textStyle={{
                          fontSize: 2,
                          fontWeight: 400,
                          color: "steelBlue",
                        }}
                      />
                      <Ct.Spacer />

                      <Info
                        onMouseLeave={() => {
                          setDisplayAcceptedTooltip(false)
                        }}
                        onMouseEnter={() => {
                          setDisplayAcceptedTooltip(true)
                        }}
                      />
                    </Ct.RowCenter>

                    <PaddingText
                      text={truncateString(
                        rejectedDocumentsNames.join(", "),
                        150
                      )}
                      textStyle={{
                        color: "amaranth",
                        fontWeight: 700,
                        textAlign: "center",
                        lineHeight: 3,
                      }}
                    />
                  </>
                )}
                <Ct.Spacer height={1} />
              </Ct.ColumnCenterCenter>
            </UploadIndicator>
          </DropHeader>

          <Ct.Spacer height={4} />

          <ControlBar>
            <Ct.Row>
              <Ct.Input
                label="Rechercher"
                value={search}
                suffix={<Search />}
                maxWidth={30}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                shadowed={true}
                noBorder={true}
              />
              <Ct.Spacer width={3} />
              {displayCalendar ? (
                <InputDateRange
                  value={range}
                  onChange={(value) => setRange(value)}
                  onClose={() => [setDisplayCalendar(false)]}
                  closable={true}
                  maximum={today}
                  minimum={DateTime.fromFormat(today, "yyyy-MM-dd")
                    .minus({ year: 1 })
                    .toString()
                    .substring(0, 10)}
                />
              ) : (
                <Ct.Button
                  label={<Calendar />}
                  onClick={() => {
                    setDisplayCalendar(true)
                  }}
                  width={6}
                  height={6}
                />
              )}
            </Ct.Row>
            <Ct.Spacer width={3} />
            <PaddingRow>
              <Ct.Checkbox
                label="Afficher uniquement les documents offices"
                name={`officeDocumentCheckbox`}
                isChecked={displayOfficeDocuments}
                onChange={() => {
                  setDisplayOfficeDocuments(!displayOfficeDocuments)
                }}
              />
            </PaddingRow>
            <Ct.Row>
              <Ct.Button
                label={
                  hideDuplicates
                    ? "Afficher les doublons"
                    : "Masquer les doublons"
                }
                onClick={() => {
                  setHideDuplicates(!hideDuplicates)
                }}
                width={25}
              />
            </Ct.Row>
            {deactivatedBatchDocumentIdList.length > 0 &&
              (displayedBatches.find((e) => e.toDeactivate) || false) && (
                <Ct.Row>
                  <Ct.Spacer width={3} />
                  <Ct.Button
                    label={
                      batchesDeactivateStatus === "loading" ? (
                        <Ct.SpinningLoader />
                      ) : (
                        `Supprimer la sélection (${deactivatedBatchDocumentIdList.length})`
                      )
                    }
                    onClick={() => {
                      setDisplayDeactivateModal(true)
                    }}
                    width={25}
                    colorType="Tertiary"
                    colorScheme={{
                      background: "amaranth",
                      color: "white",
                      border: "amaranth",
                    }}
                  />
                </Ct.Row>
              )}
          </ControlBar>

          <Ct.Spacer height={3} />

          <MessageWrapper>
            <UploadFile />
            <Ct.Spacer width={1} />
            <Ct.Text
              text={intl.formatMessage(
                {
                  id: `company-drop-document.filter-date`,
                },
                {
                  total: batchOnRange(range).total,
                  duplicate: batchOnRange(range).duplicate,
                  office: batchOnRange(range).office,
                }
              )}
              textStyle={{
                fontSize: 2.25,
                fontWeight: 500,
                color: "steelBlue",
              }}
            />
          </MessageWrapper>

          <Ct.Spacer height={2} />

          <TableWrapper>
            {userTypology && cells.length && (
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => {
                  const columnWidths = getColumnsWidth(width)
                  const rowHeights = new Array(originalBatches.length)
                    .fill(true)
                    .map(() => 50)
                  if (
                    listRef?.current &&
                    (width !== listWidth || height !== listHeight)
                  ) {
                    listRef.current.resetAfterColumnIndex(0, true)
                  }
                  setListWidth(width)
                  setListHeight(height)

                  return (
                    <>
                      <HeaderContainer totalWidth={width}>
                        {cells.map((column, index) => {
                          const multiDelActive = ![
                            "customer",
                            "customer_accountant",
                          ].includes(userTypology)
                          if (index === 0 && multiDelActive) {
                            return (
                              <HeaderItem
                                calculatedWidth={columnWidths[index]}
                                key={`header-${index}`}
                              >
                                <Checkbox
                                  label=""
                                  isChecked={
                                    deactivatedBatchDocumentIdList.length ===
                                      originalBatches.length &&
                                    originalBatches.length > 0
                                  }
                                  value={
                                    deactivatedBatchDocumentIdList.length ===
                                      originalBatches.length &&
                                    originalBatches.length > 0
                                  }
                                  name=""
                                  onChange={() => handleHeaderCheckboxChange()}
                                  colorChecked={colors.amaranth}
                                  checkBoxSize={2.5}
                                />
                              </HeaderItem>
                            )
                          } else {
                            return (
                              <HeaderItem
                                calculatedWidth={columnWidths[index]}
                                key={`header-${index}`}
                              >
                                {index < cells.length - 1 ? (
                                  <ClickableTitleSort
                                    tid={column.headerText}
                                    intl={intl}
                                    index={index}
                                    sortToReturn={(column: SortToReturn) => {
                                      setColumnToSort(column)
                                    }}
                                    currentChevron={currentChevron}
                                  />
                                ) : (
                                  <TitleTable
                                    tid={column.headerText}
                                    intl={intl}
                                  />
                                )}
                              </HeaderItem>
                            )
                          }
                        })}
                      </HeaderContainer>

                      {batchesFetchStatus === "loading" ? (
                        <LoaderWrapper totalWidth={width} height={height - 56}>
                          <Ct.SpinningLoader spinnersize={5} />
                        </LoaderWrapper>
                      ) : batchesFetchStatus === "success" &&
                        originalBatches.length === 0 ? (
                        <LoaderWrapper totalWidth={width} height={height - 56}>
                          <Alert alertType="info">
                            <Ct.Text
                              text={intl.formatMessage({
                                id: "company-drop-document.no-documents",
                              })}
                            />
                          </Alert>
                        </LoaderWrapper>
                      ) : (
                        <GridWrapper totalWidth={width}>
                          <VariableSizeGrid
                            ref={listRef}
                            height={height - 56} // header size
                            rowCount={originalBatches.length}
                            width={width} //scrollbar
                            columnWidth={(index) => columnWidths[index]}
                            rowHeight={(index) => rowHeights[index]}
                            columnCount={cells.length}
                          >
                            {Cell}
                          </VariableSizeGrid>
                        </GridWrapper>
                      )}
                    </>
                  )
                }}
              </AutoSizer>
            )}
          </TableWrapper>
        </Content>
      </Wrapper>
    </>
  )
}

export default CompanyDropDocuments

const MessageWrapper = styled.div`
  display: flex;
  margin-left: 1.5rem;
  align-items: center;
`

const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`
const StyledLink = styled.a`
  color: ${colors.cornflower};
  font-size: 1.75rem;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;

  :hover {
    color: ${colors.cornflower};
  }
`

const StyledCheck = styled(DocumentsCheck)`
  width: 24px;
  height: 24px;
`

const PaddingText = styled(Ct.Text)`
  padding: 0 1rem;
`
const TypeWrapper = styled.div<{ isOffice: boolean }>`
  background: ${(props) =>
    props.isOffice ? colors.amaranthLight : colors.greenLight};
  color: ${(props) => (props.isOffice ? colors.amaranth : colors.shamrock)};
  border-radius: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
  padding: 0 1rem 0 1rem;
  height: 3rem;
  width: 10rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`
const PaddingRow = styled(Ct.Row)`
  justify-content: flex-start;
  flex-grow: 1;
`

const DocumentNameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HeaderContainer = styled.div<{
  totalWidth: number
}>`
  width: ${({ totalWidth }) => `${totalWidth}px`};
  height: 7rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  border-top-right-radius: 2.6rem;
  border-top-left-radius: 2.6rem;
  background-color: ${colors.lavender};
`

const HeaderItem = styled.div<{
  calculatedWidth: number
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}px`};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
`
