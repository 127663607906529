import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { useKeyDown } from "../hooks/useKeyDown"
import { useRNBSelector } from "../store/rootReducer"
import { Merchant } from "../store/ducks/merchants.ducks"
import { formatDateFRdate } from "../utils/date"
import { capitalizeFirstLetter } from "../utils/string"
import {
  getMerchantFullDocumentsArchive,
  getMerchantFullDocumentsArchiveReset,
} from "../store/ducks/fullDocuments.ducks"

interface DownloadMerchantGedModalProps extends Ct.ModalComponentsProps {
  merchant: Merchant | null
  dateStart: Date
  dateEnd: Date
}

export const DownloadMerchantGedModal = ({
  isDisplayed,
  onClose,
  merchant,
  dateStart,
  dateEnd,
}: DownloadMerchantGedModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const getMerchantFullDocumentsArchiveStatus = useRNBSelector(
    (state) => state.fullDocuments.getMerchantFullDocumentsArchiveStatus
  )

  const [idsFullDocToDdl, setIdsFullDocToDdl] = useState<number[]>([])

  const handleDownload = () => {
    if (merchant) {
      const merchantName = capitalizeFirstLetter(merchant.name).replaceAll(
        " ",
        "_"
      )
      const formatedDateStart = formatDateFRdate(dateStart).replaceAll("/", "-")
      const formatedDateEnd = formatDateFRdate(dateEnd).replaceAll("/", "-")
      dispatch(
        getMerchantFullDocumentsArchive({
          idsFullDocToDdl,
          zipFileName: `${merchantName}_factures_du_${formatedDateStart}_au_${formatedDateEnd}.zip`,
        })
      )
    }
  }

  useEffect(() => {
    if (["success", "error"].includes(getMerchantFullDocumentsArchiveStatus)) {
      dispatch(getMerchantFullDocumentsArchiveReset())
      onClose()
    }
  }, [getMerchantFullDocumentsArchiveStatus])

  useEffect(() => {
    if (merchant !== null) {
      setIdsFullDocToDdl(
        merchant.documents
          .filter(
            (d) =>
              new Date(d.document_date) >= dateStart &&
              new Date(d.document_date) <= dateEnd
          )
          .map((d) => d.id)
      )
    } else setIdsFullDocToDdl([])
  }, [merchant, dateEnd, dateStart])

  useKeyDown(() => {
    if (isDisplayed) handleDownload()
  }, ["Enter"])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed && merchant !== null}
      onClose={onClose}
      left="50%"
      right="50%"
      top="calc(50vh - 25rem)"
    >
      <Ct.Card width={"96rem"}>
        <Ct.Title
          text={intl.formatMessage({ id: "ged.download-modal.title" })}
          size={3}
        />
        <Ct.Spacer height={3} />
        <Ct.Bar width={100} />
        <Ct.Spacer height={4} />
        {getMerchantFullDocumentsArchiveStatus !== "loading" && (
          <Ct.Text
            text={intl.formatMessage(
              { id: "ged.download-modal.content" },
              {
                merchantName: capitalizeFirstLetter(merchant?.name || ""),
                dateStart: formatDateFRdate(dateStart),
                dateEnd: formatDateFRdate(dateEnd),
              }
            )}
            textStyle={{ lineHeight: 3, textAlign: "center", fontSize: 2 }}
          />
        )}

        {getMerchantFullDocumentsArchiveStatus === "loading" && (
          <>
            <Ct.Text
              text={intl.formatMessage({ id: "ged.download-modal.loading" })}
              textStyle={{ lineHeight: 3, textAlign: "center", fontSize: 2 }}
            />
            <Ct.Spacer />
            <SpinningLoaderStyled />
          </>
        )}

        <Ct.Spacer height={4} />

        <Ct.FlexShrinkZero>
          <Ct.Button
            colorType={"Tertiary"}
            colorScheme={{
              background: "mist",
              color: "cornflower",
              border: "mist",
            }}
            label={intl.formatMessage({
              id: "ged.download-modal.cancel",
            })}
            onClick={onClose}
          />
          <Ct.Spacer />
          <Ct.Button
            label={intl.formatMessage({
              id: "ged.download-modal.validate",
            })}
            onClick={() => handleDownload()}
            disabled={getMerchantFullDocumentsArchiveStatus === "loading"}
          />
        </Ct.FlexShrinkZero>
      </Ct.Card>
    </Ct.Modal>
  )
}

const SpinningLoaderStyled = styled(Ct.SpinningLoader)`
  width: 25px;
  height: 25px;
`
