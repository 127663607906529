import React from 'react';
import { ReactComponent as ThinRightChevron } from '../assets/thin-right-chevron.svg';
import { ReactComponent as ShoppingTag } from "../assets/shopping-tag.svg"
import { ReactComponent as Receipt } from "../assets/receipt.svg"


export const svgComponents = {
    ThinRightChevron,
    ShoppingTag,
    Receipt,
};

interface SVGProps {
    name: keyof typeof svgComponents;
}

export const DynamicSvg: React.FC<SVGProps> = ( props: SVGProps ) => {
  const SvgComponent = svgComponents[props.name];
  return <SvgComponent />
};