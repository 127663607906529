import { OptionList } from "ldlj"
import {
  Instructions,
  LemInstructions,
  Merchant,
} from "../store/ducks/merchants.ducks"
import { MerchantCode } from "../store/ducks/merchantCodes.ducks"
import { Account } from "../store/ducks/companySettings.ducks"
import { Account as Account2 } from "../store/ducks/accounts.ducks"

import { buyOrSell } from "./company"

export const filterMerchantsAndDocs =
  (
    search: string,
    customDateActive?: boolean,
    customDateRange?: [Date, Date] | null
  ) =>
  (merchant: Merchant) => {
    const searchLower = search.toLowerCase()
    if (!merchant) return

    const dateDefined = customDateActive && customDateRange
    const matchingDate = merchant.documents?.find((d) => {
      const isInRange =
        dateDefined &&
        new Date(d.document_date) >= customDateRange[0] &&
        new Date(d.document_date) <= customDateRange[1]
      return dateDefined ? isInRange : true
    })
    if (dateDefined && !matchingDate) return false

    return (
      merchant?.name?.toLowerCase().includes(searchLower) ||
      searchLower.includes(merchant?.name?.toLowerCase()) ||
      merchant?.documents?.find(
        (d) =>
          d.document_reference?.toLowerCase().includes(searchLower) ||
          d.user_file_name?.toLowerCase().includes(searchLower) ||
          d.original_file_name?.toLowerCase().includes(searchLower) ||
          d.amount?.toLowerCase().includes(searchLower) ||
          searchLower.includes(d.document_reference?.toLowerCase()) ||
          searchLower.includes(d.user_file_name?.toLowerCase()) ||
          searchLower.includes(d.original_file_name?.toLowerCase()) ||
          searchLower.includes(d.amount?.toLowerCase())
      )
    )
  }

export const sortMerchants = (asc: boolean) => (a: Merchant, b: Merchant) => {
  return asc ? a?.name?.localeCompare(b?.name) : b?.name?.localeCompare(a?.name)
}

export const sortMerchantsByName = (merchant: Merchant[], asc: boolean) => {
  return [
    ...merchant.sort((a: Merchant, b: Merchant) =>
      asc ? a?.name?.localeCompare(b?.name) : b?.name?.localeCompare(a?.name)
    ),
  ]
}

export const sortMerchantsByDocumentsCount = (
  merchant: Merchant[],
  asc: boolean
) => {
  return [
    ...merchant.sort((a: Merchant, b: Merchant) =>
      asc
        ? (a?.full_document_count || 0) - (b?.full_document_count || 0)
        : (b?.full_document_count || 0) - (a?.full_document_count || 0)
    ),
  ]
}

export const sortMerchantsByIsFuel = (merchant: Merchant[], asc: boolean) => {
  const sorted = merchant.sort((a: Merchant, b: Merchant) => {
    if (asc) {
      return a?.is_fuel_vat === b?.is_fuel_vat ? 0 : a?.is_fuel_vat ? -1 : 1
    }
    return a?.is_fuel_vat === b?.is_fuel_vat ? 0 : a?.is_fuel_vat ? 1 : -1
  })
  return sorted
}

export const sortMerchantChangesByDate = (
  allInstructions: Array<Instructions | LemInstructions>
) => {
  return [
    ...allInstructions.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  ]
}

export const getHistoryValues = (
  instruction: Instructions | LemInstructions,
  merchantCodes: MerchantCode[],
  availableAccounts: {
    [index: number]: Account2
  },
  VATAccounts: Account[],
  buyOrSell: buyOrSell
) => {
  if (instruction.instruction_type === "change_merchant") {
    const merchant = buyOrSell === "buy" ? "client" : "fournisseur"
    return `Entité légale ${instruction.identification_number} ${instruction.metadata.previous_legal_entity_name} anciennement sur le ${merchant} ${instruction.metadata.previous_merchant_name}`
  }
  const account = findAccounts(
    instruction.metadata,
    merchantCodes,
    availableAccounts,
    VATAccounts
  )

  return instruction.metadata.code
    ? " Nouveau : " + account
    : Object.keys(instruction.metadata)[0] === "vat_account"
    ? " Nouveau : " + account
    : Object.keys(instruction.metadata)[0] !== "account_id"
    ? " Anciennement : " + account
    : " Sur : " + account
}

const findAccounts = (
  metadata: { [index: string]: string },
  merchantCodes: MerchantCode[],
  availableAccounts: {
    [index: number]: Account2
  },
  VATAccounts: Account[]
) => {
  for (let i = 0; i < Object.keys(metadata).length; i++) {
    const metadataKey = Object.keys(metadata)[i]
    const metadataValue = Object.values(metadata)[i]
    const lockVatAccounts: { [key: string]: string } = {
      /* eslint-disable camelcase */
      lock_without_vat: "Sans TVA",
      lock_eu_vat: "Autoliquidation TVA",
      lock_construction_vat: "Autoliquidation BTP",
    }

    switch (metadataKey) {
      case "previous_name":
        return metadataValue ? metadataValue : "non défini"
      case "previous_merchant_code":
        return Number(metadataValue)
          ? merchantCodes.find((mc) => mc.id === Number(metadataValue))?.code
          : "non défini"
      case "previous_account":
        return Number(metadataValue)
          ? availableAccounts[Number(metadataValue)]?.number +
              " - " +
              availableAccounts[Number(metadataValue)]?.details
          : "non défini"
      case "account_id":
        return Number(metadataValue)
          ? availableAccounts[Number(metadataValue)]?.details
          : "non défini"
      case "code":
        return metadataValue ? metadataValue : "non défini"
      case "previous_buy_vat_account":
        const buyVatAccount = VATAccounts.find(
          (a) => a.id === Number(metadataValue)
        )
        return metadataValue
          ? buyVatAccount
            ? `${buyVatAccount.number} - ${buyVatAccount.details}`
            : Object.keys(lockVatAccounts).includes(metadataValue)
            ? lockVatAccounts[metadataValue]
            : metadataValue
          : "non défini"
      case "previous_sell_vat_account":
        const sellVatAccount = VATAccounts.find(
          (a) => a.id === Number(metadataValue)
        )
        return metadataValue
          ? sellVatAccount
            ? `${sellVatAccount.number} - ${sellVatAccount.details}`
            : Object.keys(lockVatAccounts).includes(metadataValue)
            ? lockVatAccounts[metadataValue]
            : metadataValue
          : "non défini"
      case "vat_account":
        const vatAccount = VATAccounts.find(
          (a) => a.id === Number(metadataValue)
        )
        return metadataValue
          ? vatAccount
            ? `${vatAccount.number} - ${vatAccount.details}`
            : metadataValue
          : "non défini"
    }
  }
}

export const sortMerchantByAccount = (
  merchant: Merchant[],
  asc: boolean,
  buyOrSell: "buy" | "sell"
) => {
  if (buyOrSell === "sell") {
    const sorted = merchant.sort((a: Merchant, b: Merchant) =>
      asc
        ? (a.sell_default_account?.number || "").localeCompare(
            b.sell_default_account?.number || ""
          )
        : (b.sell_default_account?.number || "").localeCompare(
            a.sell_default_account?.number || ""
          )
    )
    return [...sorted]
  } else {
    const sorted = merchant.sort((a: Merchant, b: Merchant) =>
      asc
        ? (a.buy_default_account?.number || "").localeCompare(
            b.buy_default_account?.number || ""
          )
        : (b.buy_default_account?.number || "").localeCompare(
            a.buy_default_account?.number || ""
          )
    )
    return [...sorted]
  }
}

export const sortMerchantByBannedAccounts = (
  merchants: Merchant[],
  asc: boolean
) => {
  return [
    ...merchants.sort((a: Merchant, b: Merchant) => {
      const aBanned =
        a.banned_accounts.length > 0 ? a.banned_accounts[0].number : "99999"
      const bBanned =
        b.banned_accounts.length > 0 ? b.banned_accounts[0].number : "99999"
      return asc
        ? aBanned.localeCompare(bBanned)
        : bBanned.localeCompare(aBanned)
    }),
  ]
}

export const sortMerchantByVatAccount = (
  merchant: Merchant[],
  asc: boolean,
  buyOrSell: "buy" | "sell",
  defaultVATAccountOptions: OptionList<string>
) => {
  let filteredMerchantsNotNull: Merchant[] = merchant.filter((a: Merchant) =>
    buyOrSell === "buy"
      ? a.buy_vat_default_account_id
      : a.sell_vat_default_account_id
  )

  let filteredMerchantsNull: Merchant[] = merchant.filter((a: Merchant) =>
    buyOrSell === "buy"
      ? a.buy_vat_default_account_id === null
      : a.sell_vat_default_account_id === null
  )

  filteredMerchantsNotNull.sort((a: Merchant, b: Merchant) => {
    const valA = defaultVATAccountOptions
      .find(
        (elem) =>
          Number(elem.value) ===
          (buyOrSell === "buy"
            ? a.buy_vat_default_account_id
            : a.sell_vat_default_account_id)
      )
      ?.label.split(" ")[0]

    const valB = defaultVATAccountOptions
      .find(
        (elem) =>
          Number(elem.value) ===
          (buyOrSell === "buy"
            ? b.buy_vat_default_account_id
            : b.sell_vat_default_account_id)
      )
      ?.label.split(" ")[0]

    return (valA || "").localeCompare(valB || "")
  })

  let sorted: Merchant[] = [
    ...filteredMerchantsNull,
    ...filteredMerchantsNotNull,
  ]

  return asc ? sorted : sorted.reverse()
}

export const optionOfAccount = (account: Account2) => ({
  value: `${account.id}`,
  label: `${account.number} - ${account.details}`,
})
