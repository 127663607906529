import { Fragment, useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { Navigate } from "react-router-dom"
import { useIntl } from "react-intl"

import * as Ct from "ldlj"
import { ReactComponent as AddUsers } from "../../assets/add-users.svg"
import {
  attemptToLoadUsersThunk,
  selectUserToEditAction,
} from "../../store/ducks/team.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { colors } from "../../styles/design.config"
import { UserRow } from "../../components/UserRow"
import {
  searchUsers,
  sortByEmail,
  sortByTypology,
  TeamMember,
  UserTypes,
} from "../../model/users"
import UserInvitation from "./UserInvitation"
import { UserEditModal } from "../../components/UserEditModal"
import { userInvitationUninvited } from "../../store/ducks/user.ducks"
import { ReactComponent as Search } from "../../assets/search.svg"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
} from "../../components/Commons/Table"
import { Checkbox } from "../../components/Commons/Checkbox"
import { Alert } from "../../components/Commons/Alert"
import { Text } from "../../components/Commons/Text"

export const Management = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { invitationState, editedUserId, users, userIsAuthorized } =
    useRNBSelector((state) => ({
      invitationState: state.user.userInvitationStatus,
      editedUserId: state.team.editedUserId,
      users: state.team.team,
      userIsAuthorized:
        state.user.typology !== "customer" &&
        state.user.typology !== "customer_accountant",
    }))

  const [search, setSearch] = useState("")
  const [selectedUserTypes, setSelectedUserTypes] = useState<UserTypes[]>([
    "administrator",
    "manager",
    "collaborator",
  ])
  const [searchedUsers, setSearchedUsers] = useState<TeamMember[]>([])
  const [sortedUsers, setSortedUsers] = useState<TeamMember[]>([])
  const [displayedUsers, setDisplayedUsers] = useState<TeamMember[]>([])
  const [displayModalUserInvitation, setDisplayModalUserInvitation] =
    useState(false)
  const [displayModalUserEdit, setDisplayModalUserEdit] = useState(false)
  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  const userTypesList: UserTypes[] = [
    "administrator",
    "manager",
    "collaborator",
    "customer_accountant",
    "customer",
  ]
  enum SortOptionsValues {
    "userName",
    "role",
  }
  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.userName) {
      setSortedUsers(sortByEmail(asc, Object.values(users)))
    } else if (option === SortOptionsValues?.role) {
      setSortedUsers(sortByTypology(asc, Object.values(users)))
    }
  }
  const handleSelectCheckbox = (type: UserTypes) => {
    if (selectedUserTypes.includes(type))
      setSelectedUserTypes((prevState) => prevState.filter((t) => t !== type))
    else setSelectedUserTypes((prevState) => [...prevState, type])
  }

  useEffect((): void => {
    dispatch(attemptToLoadUsersThunk())
  }, [dispatch])

  useEffect(() => {
    if (
      invitationState === "CUSTOMER_SUCCESS" ||
      invitationState === "COLLAB_SUCCESS" ||
      (invitationState === "REACTIVATED_SUCCESS" && editedUserId !== null)
    ) {
      setDisplayModalUserEdit(true)
    }
  }, [invitationState, editedUserId])

  useEffect(() => {
    setSortedUsers(Object.values(users))
    setSearchedUsers(Object.values(users))
    setDisplayedUsers(Object.values(users))
  }, [users])

  useEffect(() => {
    let sortedMatchingSearch = sortedUsers.filter((sortedUser) =>
      searchedUsers.some((searchedUser) => searchedUser.id === sortedUser.id)
    )
    sortedMatchingSearch = sortedMatchingSearch.filter((e) =>
      selectedUserTypes.includes(e.typology)
    )
    setDisplayedUsers(sortedMatchingSearch)
  }, [sortedUsers, searchedUsers, selectedUserTypes])

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedUsers(searchUsers(Object.values(users), search))
      }
    }
  }, [columnToSort])

  if (!userIsAuthorized) {
    return <Navigate to={"/unauthorized"} />
  }

  return (
    <StyledColumn>
      <Ct.SpacedBetween>
        <FilterWrapper>
          <Ct.Input
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(e) => {
              setSearch(e.target.value)
              setSearchedUsers(
                searchUsers(Object.values(users), e.target.value)
              )
            }}
            shadowed={true}
            noBorder={true}
          />
          <Ct.Spacer width={4} />
          {userTypesList.map((type) => (
            <CheckboxWrapper key={type}>
              <Checkbox
                label={intl.formatMessage({
                  id: `office.${type}`,
                })}
                isChecked={selectedUserTypes.includes(type)}
                value={selectedUserTypes.includes(type)}
                onChange={() => handleSelectCheckbox(type)}
              />
            </CheckboxWrapper>
          ))}
        </FilterWrapper>
        <Controls>
          <Ct.Button
            height={5}
            width={13}
            textTransform={"capitalize"}
            prefix={<AddUsers />}
            label={intl.formatMessage({
              id: "office.invite",
            })}
            onClick={() => {
              setDisplayModalUserInvitation(!displayModalUserInvitation)
              if (
                invitationState === "SUCCESS" ||
                invitationState === "REACTIVATED_SUCCESS" ||
                invitationState === "CUSTOMER_SUCCESS"
              ) {
                dispatch(userInvitationUninvited())
              }
            }}
          />
          <UserInvitation
            isDisplayed={displayModalUserInvitation}
            onClose={() => {
              setDisplayModalUserInvitation(false)
            }}
          />
        </Controls>
      </Ct.SpacedBetween>
      <Ct.Spacer />
      <>
        <UserEditModal
          isDisplayed={displayModalUserEdit}
          onClose={() => {
            setDisplayModalUserEdit(!displayModalUserEdit)
          }}
        />
        <StickyHead>
          <StyledTextRow>
            <IDTitle>
              <HeaderTitleWrapper>
                <ClickableTitleSort
                  tid={"office.users"}
                  intl={intl}
                  index={0}
                  sortToReturn={(column: SortToReturn) => {
                    setColumnToSort(column)
                  }}
                  currentChevron={currentChevron}
                />
              </HeaderTitleWrapper>
            </IDTitle>
            <SettingsTitles>
              <Ct.Column>
                <HeaderTitleWrapper>
                  <ClickableTitleSort
                    tid={"office.role"}
                    intl={intl}
                    index={1}
                    sortToReturn={(column: SortToReturn) => {
                      setColumnToSort(column)
                    }}
                    currentChevron={currentChevron}
                  />
                </HeaderTitleWrapper>
              </Ct.Column>
              <Ct.Column>
                <Ct.Text
                  text={intl.formatMessage({ id: "office.access" })}
                  textStyle={{
                    fontWeight: 700,
                    fontSize: 2,
                    fontFamily: "Poppins",
                    textTransform: "uppercase",
                    cursor: "default",
                  }}
                />
              </Ct.Column>
              <Ct.Column>
                <Ct.Text
                  text={intl.formatMessage({ id: "office.preferences" })}
                  textStyle={{
                    fontWeight: 700,
                    fontSize: 2,
                    fontFamily: "Poppins",
                    textTransform: "uppercase",
                    cursor: "default",
                  }}
                />
              </Ct.Column>
            </SettingsTitles>
          </StyledTextRow>
        </StickyHead>
        <ContentSection usersLoaded={!!displayedUsers.length}>
          <>
            {displayedUsers.length === 0 && (
              <Alert
                alertType={selectedUserTypes.length === 0 ? "bulb" : "warning"}
              >
                <Text
                  text={intl.formatMessage({
                    id:
                      selectedUserTypes.length === 0
                        ? "office.no-typology-selected"
                        : search.length === 0
                        ? "office.no-user-match-typology"
                        : "office.no-user-match-search",
                  })}
                />
              </Alert>
            )}
            {displayedUsers.map((user, index) => (
              <Fragment key={`User-${user.id}`}>
                {index > 0 && (
                  <>
                    <Ct.Spacer height={3} />
                    <Ct.Separator size="full" />
                    <Ct.Spacer height={3} />
                  </>
                )}
                <UserRow
                  user={user}
                  onEdit={() => {
                    dispatch(selectUserToEditAction(user.id))
                    setDisplayModalUserEdit(true)
                  }}
                />
              </Fragment>
            ))}
          </>
        </ContentSection>
      </>
    </StyledColumn>
  )
}

const IDTitle = styled.div`
  width: 30%;
`

const SettingsTitles = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;

  > *:first-child {
    width: 15%;
  }

  > *:nth-child(2) {
    align-items: center;
    width: 20rem;
  }

  > *:last-child {
    align-items: flex-end;
  }
`

const StyledColumn = styled((props) => <Ct.Column {...props} />)`
  height: 100%;
  align-items: flex-end;
  overflow: hidden;
  padding: 4rem;
`

interface WithTransition {
  usersLoaded: boolean
}

const ContentSection = styled((props) => (
  <Ct.Card {...props} />
))<WithTransition>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  height: ${({ usersLoaded }) => (usersLoaded ? "100%" : "auto")};
  overflow: auto;
  transition: 0.8s height ease-in-out;
  transform: translateZ(0);
`

const Controls = styled.div`
  display: flex;
  align-items: center;
`

const StickyHead = styled.header`
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  height: 7rem;
  width: 100%;
  flex-shrink: 0;
  background-color: ${colors.lavender};
`

const StyledTextRow = styled((props) => <Ct.Row {...props} />)`
  justify-content: space-between;
  flex-grow: 1;
  margin: 2rem 4rem;
`

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: fit-content;
  align-items: center;
  height: 3rem;
`

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
const CheckboxWrapper = styled.div`
  padding-right: 3rem;
`
