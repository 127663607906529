import { useIntl } from "react-intl"
import { Modal } from "../Commons/Modal"
import * as Ct from "ldlj"
import { Text } from "../Commons/Text"
import { sizes } from "../../styles/design.config"
import { UnitLabelEvents } from "../../utils/unit"
import { ChangeItem, ChangesList, Mailto, TextNoChanges } from "../ChangesList"
import { DateTime } from "luxon"

interface UnitLabelEventProps extends Ct.ModalComponentsProps {
  events: UnitLabelEvents[]
}

export const UnitLabelEventsModal = ({
  onClose,
  isDisplayed,
  events,
}: UnitLabelEventProps) => {
  const intl = useIntl()

  return (
    <Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top="20%"
    >
      <Ct.Card width="70vw">
        <Ct.CloseCross onClick={onClose} />
        <Ct.Spacer height={2} />
        <Text
          text={intl.formatMessage({
            id: `unit_labels.events-modal.title`,
          })}
          textStyle={{ fontSize: 5, fontWeight: 600 }}
        />
        <Ct.Spacer height={2} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={5} />

        <ChangesList height={"32rem"}>
          {events.length > 0 ? (
            events.map((e) => (
              <ChangeItem key={e.email + e.id}>
                <Ct.Text
                  text={intl.formatMessage({
                    id: `unit_labels.events.${e.event_type}`,
                  })}
                  textStyle={{
                    fontWeight: 700,
                  }}
                />

                <Mailto href={`mailto:${e.email}`}>{e.email}</Mailto>

                <Ct.Text
                  text={intl.formatMessage({
                    id: `unit_labels.events.at`,
                  })}
                />

                <Ct.Text
                  text={
                    " (" +
                    DateTime.fromJSDate(new Date(e.created_at)).toFormat(
                      "dd/MM/yyyy HH:mm:ss"
                    ) +
                    "). "
                  }
                />
              </ChangeItem>
            ))
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({
                  id: `unit_labels.events.empty`,
                })}
              />
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangesList>

        <Ct.Spacer height={4} />
        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={onClose}
          width={sizes.button.standard}
        />
      </Ct.Card>
    </Modal>
  )
}
