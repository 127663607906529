import styled from "styled-components/macro"
import { boxShadow, colors } from "../../../../styles/design.config"
import * as Ct from "ldlj"
import { getIdFromParams } from "../../../../utils/company"
import { useNavigate, useParams } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as PlusRounded } from "../../../../assets/plusRounded.svg"
import { ReactComponent as UniqCode } from "../../../../assets/uniqCode.svg"
import { Checkbox, Row } from "ldlj"
import { useRNBSelector } from "../../../../store/rootReducer"
import { IconActions } from "../../../../components/IconActions"
import {
  Account,
  AddRemoveUnitThunk,
  deactivateAccountThunk,
  deactivateOrReactivateAccountReset,
  getAccountInstructionsHistory,
  getAllAccountsThunk,
  reactivateAccountThunk,
} from "../../../../store/ducks/accounts.ducks"
import { CreateOrModifyAccountModal } from "../../../../components/CreateOrModifyAccountModal"
import {
  ChevronProps,
  ClickableTitleSort,
  SortToReturn,
  TitleTable,
} from "../../../../components/Commons/Table"
import { Alert } from "../../../../components/Commons/Alert"
import {
  sortAccountsByNumber,
  sortAccountsByDetails,
  sortAccountsDefaultSort,
  sortAccountsBySearch,
} from "../../../../utils/accounts"
import { copyObject } from "../../../../utils/object"
import { AccountInstructionsHistoryModal } from "../../../../components/AccountInstructionsHistoryModal"
import { Switch } from "../../../../components/Commons/Switch"
import { emptyOptionValue, Select } from "../../../../components/Commons/Select"
import { getUnitsThunk } from "../../../../store/ducks/invoicing.duck"

export const AccountingPlanAccounts = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userTypology = useRNBSelector((state) => state.user.typology)
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const units = useRNBSelector((state) => state.invoicing.units)

  const [search, setSearch] = useState<string>("")
  const [filterPrefixOnly, setFilterPrefixOnly] = useState<boolean>(false)
  const [
    createOrModifyAccountModalDisplayed,
    setCreateOrModifyAccountModalDisplayed,
  ] = useState(false)
  const [
    accountInstructionsHistoryModalDisplayed,
    setAccountInstructionsHistoryModalDisplayed,
  ] = useState(false)
  const [accountSelectedForHistory, setAccountSelectedForHistory] =
    useState<Account | null>(null)
  const [createOrModify, setCreateOrModify] = useState<"create" | "modify">(
    "create"
  )

  const [unitOptions, setUnitOptions] = useState<Ct.Option<string>[]>(
    units.map((unit) => ({
      value: unit.id.toString(),
      label: unit.unit,
    }))
  )

  useEffect(() => {
    if (units.length > 0) {
      setUnitOptions(
        units.map((unit) => ({
          value: unit.id.toString(),
          label: unit.unit,
        }))
      )
    }
  }, [units])

  const accounts = useRNBSelector((state) => state.accounts.all_accounts)
  const [sortedAccounts, setSortedAccounts] = useState<Account[]>([])
  const [accountToModify, setAccountToModify] = useState<Account>()
  const deactivateOrReactivateAccountStatus = useRNBSelector(
    (state) => state.accounts.reactivateOrDeactivateAccountStatus
  )
  const [lastSortOption, setLastSortOption] = useState<{
    option: SortOptionsValues | null
    asc: boolean
  }>({ option: null, asc: false })

  useEffect(() => {
    if (userTypology === "customer") {
      navigate("/Unauthorized")
    }
  }, [userTypology, navigate])

  useEffect(() => {
    dispatch(getAllAccountsThunk(selectedCompanyId))
    dispatch(getUnitsThunk(selectedCompanyId))
  }, [dispatch])

  useEffect(() => {
    if (accounts) {
      if (sortedAccounts.length === 0) {
        setSortedAccounts(copyObject(accounts))
      } else {
        const sortedAccountsUpdatedList = sortedAccounts.map((acc) =>
          accounts.find((newAcc) => newAcc.id === acc.id)
        ) as Account[]
        setSortedAccounts(sortedAccountsUpdatedList)
      }
    }
  }, [accounts])

  enum SortOptionsValues {
    "number",
    "details",
    "default",
  }

  useEffect(() => {
    if (lastSortOption.option === null && sortedAccounts.length > 0) {
      sorter(false)(SortOptionsValues.default)(copyObject(accounts))
    }
  }, [sortedAccounts])

  useEffect(() => {
    if (deactivateOrReactivateAccountStatus === "SUCCESS") {
      dispatch(deactivateOrReactivateAccountReset())
      dispatch(getAllAccountsThunk(selectedCompanyId))
    }
  }, [deactivateOrReactivateAccountStatus])

  const sorter =
    (asc: boolean) => (option: SortOptionsValues) => (accs: Account[]) => {
      setLastSortOption({ option: option, asc: asc })
      if (option === SortOptionsValues?.number) {
        setSortedAccounts(sortAccountsByNumber(accs, asc))
      } else if (option === SortOptionsValues?.details) {
        setSortedAccounts(sortAccountsByDetails(accs, asc))
      } else if (option === SortOptionsValues?.default) {
        setSortedAccounts(sortAccountsDefaultSort(accs))
      }
    }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)(sortedAccounts)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)(sortedAccounts)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        if (search) {
          setSortedAccounts(
            sortAccountsBySearch(copyObject(accounts), search, filterPrefixOnly)
          )
        } else {
          if (sortedAccounts.length > 0) {
            sorter(false)(SortOptionsValues.default)(copyObject(accounts))
          }
        }
      }
    }
  }, [columnToSort])

  useEffect(() => {
    if (search) {
      setSortedAccounts(
        sortAccountsBySearch(copyObject(accounts), search, filterPrefixOnly)
      )
    } else {
      if (sortedAccounts.length > 0) {
        sorter(false)(SortOptionsValues.default)(copyObject(accounts))
      }
    }
  }, [search, filterPrefixOnly])

  const columnWidth = [
    { width: "20" },
    { width: "30" },
    { width: "15" },
    { width: "15" },
    { width: "10" },
    { width: "10" },
  ]

  const columnsTitle: {
    title: string
  }[] = [
    { title: "number" },
    { title: "details" },
    { title: "unit1" },
    { title: "unit2" },
    { title: "activated" },
    { title: "actions" },
  ]

  const columnsConfig = columnWidth.map((widthConfig, index) => ({
    ...widthConfig,
    ...columnsTitle[index],
  }))

  return (
    <Wrapper>
      <CreateOrModifyAccountModal
        selectedAccount={accountToModify}
        createOrModify={createOrModify}
        onClose={() => {
          setCreateOrModifyAccountModalDisplayed(false)
          setAccountToModify(undefined)
        }}
        isDisplayed={createOrModifyAccountModalDisplayed}
      />

      {accountSelectedForHistory && (
        <AccountInstructionsHistoryModal
          accountSelected={accountSelectedForHistory}
          isDisplayed={accountInstructionsHistoryModalDisplayed}
          onClose={() => {
            setAccountInstructionsHistoryModalDisplayed(
              !accountInstructionsHistoryModalDisplayed
            )
          }}
        />
      )}

      <StyledSection>
        <Header>
          <StyledRow>
            <StyledInput
              name={"searchAccount"}
              id={"searchAccount"}
              label="Rechercher"
              value={search}
              suffix={<Search />}
              maxWidth={30}
              onChange={(event: { target: HTMLInputElement }) => {
                const value = event.target.value.trimStart().replace("  ", " ")
                setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
                if (search.length > 0 && value.length === 0) {
                  sorter(false)(SortOptionsValues.default)(copyObject(accounts))
                }
              }}
              shadowed={true}
              noBorder={true}
            />
            <Ct.Spacer width={4} />
            <Checkbox
              label={intl.formatMessage({
                id: "accounting-plan.accounts.filter-prefix",
              })}
              name={"filterAccounts"}
              isChecked={filterPrefixOnly}
              onChange={() => {
                setFilterPrefixOnly(!filterPrefixOnly)
              }}
            />
          </StyledRow>
          <Ct.Button
            width={30}
            label={intl.formatMessage({
              id: "accounting-plan.accounts.create",
            })}
            prefix={<PlusRounded />}
            onClick={() => {
              setCreateOrModify("create")
              setCreateOrModifyAccountModalDisplayed(true)
            }}
          />
        </Header>

        <Ct.Spacer height={2} />

        <TableWrapper>
          <StyledTableHeader>
            {columnsConfig.map(({ title, width }, index) => (
              <HeaderTitleWrapper
                calculatedWidth={Number(width)}
                key={title}
                centerContent={title === "actions" || title === "activated"}
              >
                {index < 2 ? (
                  <ClickableTitleSort
                    tid={`accounting-plan.accounts.table-header.${title}`}
                    intl={intl}
                    index={index}
                    sortToReturn={(column: SortToReturn) => {
                      setColumnToSort(column)
                    }}
                    currentChevron={currentChevron}
                  />
                ) : (
                  <TitleTable
                    tid={`accounting-plan.accounts.table-header.${title}`}
                    intl={intl}
                  />
                )}
              </HeaderTitleWrapper>
            ))}
          </StyledTableHeader>
          {sortedAccounts && sortedAccounts.length > 0 ? (
            <StyledTableBody>
              {sortedAccounts.map((acc: Account | undefined, index) => {
                return (
                  <Fragment key={index}>
                    <WrapperRows>
                      <PaddingRow>
                        <StyledFlex1 width={"20%"} flexDirection={"column"}>
                          <Ct.Text
                            text={acc?.number || ""}
                            textStyle={{
                              fontWeight: 700,
                              textTransform: "initial",
                              fontSize: 2,
                            }}
                          />
                          {(acc?.buy_third_party_account ||
                            acc?.sell_third_party_account) && (
                            <div>
                              <Ct.Spacer height={0.5} width={0} />
                              <Ct.Row>
                                <StyledUniqCode />
                                <Ct.Spacer width={0.5} height={0} />
                                <Ct.Text
                                  text={intl.formatMessage({
                                    id: acc.buy_third_party_account
                                      ? "accounting-plan.accounts.buy-third-party-account"
                                      : "accounting-plan.accounts.sell-third-party-account",
                                  })}
                                  textStyle={{
                                    fontSize: 1.4,
                                    color: "cornflower",
                                  }}
                                />
                              </Ct.Row>
                            </div>
                          )}
                        </StyledFlex1>

                        <StyledFlex1 width={"30%"}>
                          <Ct.Text
                            text={acc?.details || ""}
                            textStyle={{
                              fontWeight: 400,
                              textTransform: "initial",
                              fontSize: 2,
                            }}
                          />
                        </StyledFlex1>

                        <StyledFlex1 width={"15%"}>
                          <Select
                            intl={intl}
                            options={unitOptions}
                            value={
                              acc?.unit_id
                                ? unitOptions.find(
                                    (u) => Number(u.value) === acc.unit_id
                                  ) || emptyOptionValue
                                : emptyOptionValue
                            }
                            onChangeCallback={(e: Ct.Option<string> | null) => {
                              const unitSelected =
                                e?.value === "" || e?.value === undefined
                                  ? null
                                  : Number(e.value)

                              if (acc && acc.id) {
                                dispatch(
                                  AddRemoveUnitThunk(
                                    selectedCompanyId,
                                    acc?.id,
                                    1,
                                    unitSelected
                                  )
                                )
                              }
                            }}
                            label={""}
                            domain={"accounting-plan.accounts.table-header"}
                            optionType={"unit1"}
                            customWidth={"20rem"}
                            isClearable={true}
                          />
                        </StyledFlex1>

                        <StyledFlex1 width={"15%"}>
                          <Select
                            intl={intl}
                            options={unitOptions}
                            value={
                              acc?.unit_2_id
                                ? unitOptions.find(
                                    (u) => Number(u.value) === acc.unit_2_id
                                  ) || emptyOptionValue
                                : emptyOptionValue
                            }
                            onChangeCallback={(e: Ct.Option<string> | null) => {
                              const unitSelected =
                                e?.value === "" || e?.value === undefined
                                  ? null
                                  : Number(e.value)

                              if (acc && acc.id) {
                                dispatch(
                                  AddRemoveUnitThunk(
                                    selectedCompanyId,
                                    acc?.id,
                                    2,
                                    unitSelected
                                  )
                                )
                              }
                            }}
                            label={""}
                            domain={"accounting-plan.accounts.table-header"}
                            optionType={"unit2"}
                            customWidth={"20rem"}
                            isClearable={true}
                          />
                        </StyledFlex1>

                        <StyledFlex1 width={"10%"} centerContent>
                          <Switch
                            value={!acc?.deactivated_at}
                            onToggle={() => {
                              if (!acc) return
                              const booleanValue = acc.deactivated_at
                                ? false
                                : true

                              if (booleanValue) {
                                dispatch(
                                  deactivateAccountThunk(selectedCompanyId, acc)
                                )
                              } else {
                                dispatch(
                                  reactivateAccountThunk(selectedCompanyId, acc)
                                )
                              }
                            }}
                          />
                        </StyledFlex1>

                        <StyledFlex1 width={"10%"} centerContent>
                          <Ct.RowCenter>
                            <IconActions
                              actionsToDisplay={["seeDetails", "rename"]}
                              onRename={() => {
                                if (!acc) return
                                setCreateOrModify("modify")
                                setAccountToModify(acc)
                                setCreateOrModifyAccountModalDisplayed(true)
                              }}
                              onSetDisplayDetails={() => {
                                if (!acc) return
                                setAccountSelectedForHistory(acc)
                                setAccountInstructionsHistoryModalDisplayed(
                                  true
                                )
                                dispatch(
                                  getAccountInstructionsHistory(
                                    selectedCompanyId,
                                    acc.id
                                  )
                                )
                              }}
                            />
                          </Ct.RowCenter>
                        </StyledFlex1>
                      </PaddingRow>
                    </WrapperRows>
                  </Fragment>
                )
              })}
            </StyledTableBody>
          ) : (
            <RowCenterWrapper>
              <Alert alertType="info">
                {intl.formatMessage({
                  id: "accounting-plan.accounts.table-body.empty",
                })}
              </Alert>
            </RowCenterWrapper>
          )}
        </TableWrapper>
      </StyledSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.75rem 3rem 4.5rem 3rem;
`
const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`
const StyledRow = styled(Row)`
  align-items: center;
  width: 100%;
`
const TableWrapper = styled.div`
  height: 100%;
  overflow-y: hidden;
  border-radius: 2.5rem;
  margin: 0 2rem;
  border: 1px solid ${colors.lavender};
`
const StyledTableHeader = styled(Ct.TableHeader)`
  padding: 0 3rem 0 3rem;
`
const StyledTableBody = styled(Ct.TableBody)`
  height: 100%;
  overflow-y: scroll;
`
const StyledFlex1 = styled(Ct.Flex1)<{
  width?: string
  centerContent?: boolean
  flexDirection?: string
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : `row`};
  align-items: flex-start;
  align-self: center;
  justify-content: ${({ centerContent }) =>
    centerContent ? "center" : "unset"};
  max-width: ${({ width }) => (width ? width : `auto`)};
  min-width: 25rem;
`
const PaddingRow = styled(Ct.Row)`
  padding: 0 3rem;
  height: 12rem;
`
const WrapperRows = styled.div`
  border-bottom: 1px solid ${colors.lavender};
  :last-child {
    border-bottom: none;
  }
`
const RowCenterWrapper = styled((props) => <Ct.RowCenter {...props} />)`
  width: 100%;
  margin-top: 5rem;
  justify-content: center;
`
const HeaderTitleWrapper = styled.div<{
  calculatedWidth: number
  centerContent: boolean
}>`
  width: ${({ calculatedWidth }) => `${calculatedWidth}%`};
  min-width: 25rem;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ centerContent }) =>
    centerContent ? "center" : "unset"};
  user-select: none;
`
const StyledUniqCode = styled(({ ...props }) => <UniqCode {...props} />)`
  & path {
    fill: ${colors.cornflower};
  }
`
